import { ImportOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

type Props = {
  onData: (data: any) => void;
};

export const UploadJson = ({ onData }: Props) => {
  const handleChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e: any) => {
      onData(JSON.parse(e.target.result));
      Modal.success({
        type: "success",
        title: "Success",
        content: "Uploaded JSON file successfully",
      });
    };
  };
  return (
    <>
      <div className="upload-input">
        <Button icon={<ImportOutlined />} type="primary" onClick={() => document.getElementById("fileInput")?.click()}>
          Import JSON
        </Button>
        <input id="fileInput" type="file" accept=".json" style={{ display: "none" }} onChange={handleChange} />
      </div>
    </>
  );
};
