/* eslint-disable @typescript-eslint/no-unused-vars */
import "./GenerateVouchers.scss";
import { EyeOutlined, CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { FormInput } from "../../components/Form/Input";
import { PageUrl } from "../../constants/url";
import { UserRole } from "../../constants/auth";
import { useAuth } from "../../hooks/useAuth";
import { generateVouchers } from "../../services/VoucherServices";
import { Card } from "antd";

const GenerateVouchers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const accountAdminId = useRef<string>(params.id || "");

  const handleSave = async () => {
    try {
      const formValues = form.getFieldsValue();
      formValues.duration = parseInt(formValues.duration);
      formValues.quantity = parseInt(formValues.quantity);
      const res = (await generateVouchers(formValues)) as any;
      if (res?.errorCode === 200) {
        Modal.success({
          title: "Success",
          type: "success",
          content: `Generate vouchers successfully`,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!user?.userId) return;
    if (!user?.roles?.includes(UserRole.SiteAdmin)) {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAdminId.current, user]);

  return (
    <div className="generate-vouchers-container">
      <Card title="Generate Vouchers" bordered={false} style={{ width: "100%" }}>
        <Form form={form} onFinish={handleSave}>
          <div className="form-input">
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Duration" name="duration" rules={[{ required: true }]} />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Quantity" name="quantity" rules={[{ required: true }]} />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <Form.Item label="Expired at" name="expiredAt" rules={[{ required: true }]}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-controller">
            <Button type="primary" icon={<SaveOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
              Generate
            </Button>
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Voucher)}>
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default GenerateVouchers;
