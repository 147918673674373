// generate useAuth to get token from cookies and save to store
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers/store";
import { login, logout } from "../reducers/authSlice";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { DEFAULT_TIMEOUT } from "../constants/timeout";
import { PageUrl } from "../constants/url";

export const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin") as string;

  useEffect(() => {
    const handleLogout = () => {
      dispatch(logout());
      setTimeout(() => navigate(PageUrl.Signin), DEFAULT_TIMEOUT);
    };

    if (token) {
      const user = decodeToken(token) as any;
      if (user.exp < Date.now() / 1000) handleLogout();
      dispatch(login(user));
    } else {
      handleLogout();
    }
  }, [dispatch, navigate, token]);

  return { user: user, token: token };
};
