import instance, { apiConfig } from "./instance";
import { IAccountAdminFilter } from "../interfaces/filter";

const accountUrl = "/auth/v1/admin/account-admin";

export const getAccountAdmins = (filter?: IAccountAdminFilter) => {
  return instance.get(`${accountUrl}/get-list-account-admins`, apiConfig(filter ? filter : { page: 1, size: 1000 }));
};

export const getAccountAdminById = (id: string) => {
  return instance.get(`${accountUrl}/get-an-account-admin/${id}`, apiConfig());
};

export const addAccountAdmin = (data: any) => {
  return instance.put(`${accountUrl}/create-new-account-admin`, data, apiConfig());
};

export const editAccountAdmin = (id: string, data: any): Promise<any> => {
  return instance.post(`${accountUrl}/edit-an-account-admin/${id}`, data, apiConfig());
};

export const getAllActiveRoles = () => {
  return instance.get(`/auth/v1/admin/role/get-all-active-roles/`, apiConfig());
};

export const getListSiteAdmins = () => {
  return instance.get(`${accountUrl}/get-list-site-admins`, apiConfig({ page: 1, size: 1000 }));
};
