import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Checkbox, CheckboxOptionType, Col, DatePicker, Form, Row, Space, Table, Tag, Tooltip } from "antd";
import { EditOutlined, ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IPreRegisterFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { exportPreRegisters, getPreRegisters } from "../../services/PreRegisterServices";
import { ELECTIVE_OPTIONS } from "../../constants/filter";
import { FormSelect } from "../../components/Form/Select";
import dayjs from "dayjs";
import { customSessionStorage } from "../../utils/storage";
import { getListSiteAdmins } from "../../services/AccountAdminServices";
import {
  getGoalCategories,
  getPackageCategories,
  getSaleCategories,
  getTagCategories,
} from "../../services/CategoryService";
import { TAG_COLORS } from "../../constants/color";

const PreRegisterPortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IPreRegisterFilter>({ page: 1, size: 10 });

  const [siteAdmins, setSiteAdmins] = useState<any>([]);
  const [saleCategories, setSaleCategories] = useState<any>([]);
  const [goalCategories, setGoalCategories] = useState<any>([]);
  const [packageCategories, setPackageCategories] = useState<any>([]);
  const [tagCategories, setTagCategories] = useState<any>([]);

  const handleFilter = () => {
    const formValues = form.getFieldsValue();

    const filter: IPreRegisterFilter = {
      filter: formValues.filter,
      saleCategoryId: formValues.saleCategoryId,
      picId: formValues.picId,
      isLinked: formValues.isLinked,
      tags: formValues.tags?.length ? formValues.tags : undefined,
    };

    if (formValues.createdFrom) {
      filter.createdFrom = dayjs(formValues.createdFrom).startOf("day").toISOString();
    }

    if (formValues.createdTo) {
      filter.createdTo = dayjs(formValues.createdTo).endOf("day").toISOString();
    }

    filterData.current = { ...filter, page: 1, size: 10 };
    customSessionStorage("filterPreRegister", filterData.current);
    fetchPreRegisters();
  };

  const fetchPreRegisters = async () => {
    const response = await getPreRegisters(filterData.current);
    if (response) {
      setTableData((response as any).result.records || []);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const fetchSiteAdmins = async () => {
    const response = await getListSiteAdmins();
    if (response) {
      const siteAdmins = (response as any).result?.map((item: any) => ({
        label: item.userName,
        value: item._id,
      }));

      // Add not assigned option
      siteAdmins.unshift({ label: "Not assigned", value: "" });

      setSiteAdmins(siteAdmins);
    }
  };

  const fetchSaleCategories = async () => {
    const response = await getSaleCategories();
    if (response) {
      const saleCategories = (response as any).result?.map((item: any) => ({
        label: item.name,
        value: item._id,
      }));
      setSaleCategories(saleCategories);
    }
  };

  const fetchGoalCategories = async () => {
    const response = await getGoalCategories();
    if (response) {
      const goalCategories = (response as any).result?.map((item: any) => ({
        label: item.name,
        value: item.type,
      }));
      setGoalCategories(goalCategories);
    }
  };

  const fetchPackageCategories = async () => {
    const response = await getPackageCategories();
    if (response) {
      const packageCategories = (response as any).result?.map((item: any) => ({
        label: item.name,
        value: item.type,
      }));
      setPackageCategories(packageCategories);
    }
  };

  const fetchTagCategories = async () => {
    const response = await getTagCategories();
    if (response) {
      const tagCategories = (response as any).result?.map((item: any, index: number) => ({
        label: item.name,
        value: item._id,
        color: TAG_COLORS[index % TAG_COLORS.length],
      }));
      setTagCategories(tagCategories);
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchPreRegisters();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchPreRegisters();
  };

  const handleEditPreRegister = async (id: string) => {
    navigate(`${PageUrl.PreRegister}${PageUrl.Update}`.replace(":id", id));
  };

  const handleExportFiltered = async () => {
    const response = await exportPreRegisters(filterData.current);
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response as any]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Pre-registers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "Order",
        key: "order",
        align: "center",
        width: 50,
        render: (_, __, index) => {
          if (filterData.current.page && filterData.current.size) {
            return (filterData.current.page - 1) * filterData.current.size + index + 1;
          }
          return index + 1;
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        align: "center",
        width: 100,
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
        align: "center",
        width: 50,
      },
      {
        title: "Goals",
        dataIndex: "goals",
        key: "goals",
        align: "center",
        width: 225,
        render: (goals) => {
          return goals
            ?.map((goal: any) => {
              const goalData = goalCategories?.find((item: any) => item.value === goal);
              return goalData?.label || "";
            })
            .join(", ");
        },
      },
      {
        title: "Package",
        dataIndex: "package",
        key: "package",
        align: "center",
        width: 125,
        render: (value) => {
          const packageData = packageCategories?.find((item: any) => item.value === value);
          return packageData?.label || "";
        },
      },
      {
        title: "Sale status",
        dataIndex: ["saleInfo", "category", "name"],
        key: "status",
        align: "center",
        width: 150,
        render: (value) => value || "",
      },
      {
        title: "PICs",
        dataIndex: ["saleInfo", "pics"],
        key: "pics",
        align: "center",
        width: 150,
        render: (pics) => {
          if (pics?.length) {
            return pics.map((pic: any) => pic.name).join(", ");
          }
          return "";
        },
      },
      {
        title: "User linked",
        dataIndex: ["verifiedUser", "name"],
        key: "userLinked",
        align: "center",
        width: 125,
      },
      {
        title: "Revenue",
        dataIndex: ["saleInfo", "revenue"],
        key: "revenue",
        align: "center",
        width: 100,
        render: (value) => (value ? value.toLocaleString() : ""),
      },
      {
        title: "Note",
        dataIndex: ["saleInfo", "note"],
        key: "note",
        align: "center",
        width: 200,
        render: (value) => {
          if (value?.length > 50) {
            return (
              <Tooltip title={value}>
                <span>{value.slice(0, 50)}...</span>
              </Tooltip>
            );
          }
          return (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        align: "center",
        width: 200,
        render: (tags) => {
          if (tags?.length) {
            return tags.map((tag: any) => {
              const tagData = tagCategories?.find((item: any) => item.value === tag);
              return (
                <Tag key={tag} color={tagData.color} style={{ padding: "0 8px", margin: "2px" }}>
                  {tagData?.label}
                </Tag>
              );
            });
          }
          return "";
        },
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        width: 125,
        render: (value) => dayjs(value).format("DD/MM/YYYY"),
      },
      {
        title: "Updated at",
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "center",
        width: 125,
        render: (value) => {
          if (value === "0001-01-01T00:00:00Z") {
            return "";
          }
          return dayjs(value).format("DD/MM/YYYY");
        },
      },

      {
        title: "Action",
        key: "action",
        align: "center",
        width: 100,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.SiteAdmin) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit account">
                    <Button icon={<EditOutlined />} onClick={() => handleEditPreRegister(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const options = getColumn().map(({ key, title }) => ({
    label: title,
    value: key,
  }));
  const [checkedList, setCheckedList] = useState(options.map((item) => item.value) as string[]);

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (sessionStorage.getItem("filterPreRegister")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterPreRegister")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterPreRegister");
        } else {
          form.setFields([
            { name: "filter", value: sessionFilter.filter },
            { name: "saleCategoryId", value: sessionFilter.saleCategoryId },
            { name: "picId", value: sessionFilter.picId },
            { name: "isLinked", value: sessionFilter.isLinked },
            { name: "createdFrom", value: sessionFilter.createdFrom ? dayjs(sessionFilter.createdFrom) : null },
            { name: "createdTo", value: sessionFilter.createdTo ? dayjs(sessionFilter.createdTo) : null },
          ]);

          filterData.current = {
            filter: sessionFilter.filter,
            saleCategoryId: sessionFilter.saleCategoryId,
            picId: sessionFilter.picId,
            isLinked: sessionFilter.isLinked,
            createdFrom: sessionFilter.createdFrom,
            createdTo: sessionFilter.createdTo,
            page: 1,
            size: 10,
          };
        }
      }
      fetchSaleCategories();
      fetchGoalCategories();
      fetchPackageCategories();
      fetchSiteAdmins();
      fetchPreRegisters();
      fetchTagCategories();
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="pre-register-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormInput label="Filter" name="filter" allowClear />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <Form.Item label="Created from" name="createdFrom">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={8} md={8} xl={6}>
                <Form.Item label="Created to" name="createdTo">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8} xl={6}>
                <FormSelect label="User linked" name="isLinked" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormSelect label="Pic" name="picId" optionData={siteAdmins} allowClear />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormSelect label="Sale status" name="saleCategoryId" optionData={saleCategories} allowClear />
              </Col>
              <Col xs={8} md={8} xl={6}>
                <FormSelect mode="multiple" label="Tags" name="tags" optionData={tagCategories} allowClear />
              </Col>
            </Row>
            <Row>
              <Col className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0", display: "flex", gap: "8px" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.PreRegister}${PageUrl.Create}`)}
            >
              Add pre-register
            </Button>
            <Button icon={<ExportOutlined />} type="default" onClick={handleExportFiltered}>
              Export table data
            </Button>
          </div>
          <Checkbox.Group
            value={checkedList}
            options={
              options.filter((item) => item.value !== "action" && item.value !== "order") as CheckboxOptionType[]
            }
            onChange={(value) => {
              setCheckedList([...value, "action", "order"] as string[]);
            }}
            style={{ marginBottom: "16px", gap: "12px", justifyContent: "center", width: "100%" }}
          />
          <Table
            dataSource={checkedList.length === 0 ? [] : tableData}
            columns={getColumn().filter((item) => checkedList.includes(item.key as string))}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default PreRegisterPortal;
