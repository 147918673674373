import instance, { apiConfig } from "./instance";
import { IAchievementFilter } from "../interfaces/filter";

const accountUrl = "/practice/v1/admin/achievement";

export const getAchievements = (filter: IAchievementFilter) => {
  return instance.get(`${accountUrl}/get-list-achievements`, apiConfig(filter));
};

export const getAchievementById = (id: string) => {
  return instance.get(`${accountUrl}/get-an-achievement/${id}`, apiConfig());
};

export const addAchievement = (data: any) => {
  return instance.put(`${accountUrl}/create-new-achievement`, data, apiConfig());
};

export const editAchievement = (id: string, data: any): Promise<any> => {
  return instance.post(`${accountUrl}/edit-an-achievement/${id}`, data, apiConfig());
};
