import katex from "katex";

export const stringToLatex = (text: string) => {
  try {
    // check regex for html tags between 2 @ signs or backlash n or latex strings
    const regex = /(@@.*?@@|\\n|\$\$.*?\$\$)/g;
    // split text into array of strings
    const textArray = text.split(regex);
    // filter out empty strings
    const textArrayFiltered = textArray.filter((string) => string !== "");
    // filter undefined strings
    const textArrayFiltered2 = textArrayFiltered.filter((string) => string !== undefined);
    // filter out latex strings
    const latex = textArrayFiltered2.filter((string) => string.match(/^\$\$.*?\$\$/));
    // filter out html tags
    const htmlTags = textArrayFiltered2.filter((string) => string.match(/^@@.*?@@$/));
    // filter out backlash n
    const backlashN = textArrayFiltered2.filter((string) => string.match(/\\n/g));
    // // filter out text
    // const text = textArrayFiltered.filter((string) => !string.match(/(<([^>]+)>|\\n|\$.*?\$)/g));

    // create array to store text to display
    let textToDisplay = [];
    let htmlComponents = [];
    let latexComponents = [];
    let backlashNComponents = [];
    // for each html tag in htmlTags, return a react component without the @ signs
    for (let i = 0; i < htmlTags.length; i++) {
      const htmlTag = htmlTags[i];
      const htmlTagWithoutAtSigns = htmlTag.replace(/@@/g, "");
      const htmlTagWithReactComponent = <span dangerouslySetInnerHTML={{ __html: htmlTagWithoutAtSigns }} />;
      htmlComponents.push(htmlTagWithReactComponent);
    }

    // for each latex string in latex, return a MathComponent
    for (let i = 0; i < latex.length; i++) {
      const latexString = latex[i];
      const latexStringWithoutDollarSigns = latexString.replace(/\$\$/g, "");
      const latexStringWithMathComponent = katex.renderToString(latexStringWithoutDollarSigns, {
        displayMode: false,
        output: "htmlAndMathml",
      });
      const latexHtml = (
        <span style={{ margin: "0.5rem" }} dangerouslySetInnerHTML={{ __html: latexStringWithMathComponent }} />
      );
      latexComponents.push(latexHtml);
    }

    // for each backlash n in backlashN, return a react component
    for (let i = 0; i < backlashN.length; i++) {
      const backlashNStringWithReactComponent = <br />;
      backlashNComponents.push(backlashNStringWithReactComponent);
    }

    // go through text, if there is a latex string, replace it with the MathComponent
    // if there is a html tag, replace it with the html component not including the @ signs
    // if there is a backlash n, replace it with the backlash n component
    let displayingLatex = false;
    let displayingHtmlTag = false;
    let displayingBacklashN = false;
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (displayingLatex) {
        if (char === "$") {
          if (text[i + 1] === "$") {
            textToDisplay.push(latexComponents.shift());
            displayingLatex = false;
          } else {
            continue;
          }
        }
      } else if (displayingHtmlTag) {
        if (char === "@") {
          if (text[i + 1] === "@") {
            textToDisplay.push(htmlComponents.shift());
            displayingHtmlTag = false;
          } else {
            continue;
          }
        }
      } else if (displayingBacklashN) {
        if (char === "n") {
          textToDisplay.push(backlashNComponents.shift());
          displayingBacklashN = false;
        }
      } else {
        if (char === "$") {
          if (text[i + 1] === "$") {
            displayingLatex = true;
          } else {
            continue;
          }
        } else if (char === "@") {
          if (text[i + 1] === "@") {
            displayingHtmlTag = true;
          } else {
            continue;
          }
        } else if (char === "\\") {
          displayingBacklashN = true;
        } else {
          textToDisplay.push(char);
        }
      }
    }

    // return the text with the MathComponents as react components
    return {
      type: "success",
      content: (
        <span>
          {textToDisplay.map((text, index) => (
            <span key={index}>{text}</span>
          ))}
        </span>
      ),
    };
  } catch (error: any) {
    return {
      type: "error",
      content: <span>{error?.message}</span>,
    };
  }
};
