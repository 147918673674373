export enum SlideType {
  Content = "Content",
  Question = "Question",
}

export enum SlideQuestionTemplate {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

export enum SlideContentTemplate {
  // MainContent1Title = "MainContent1Title",
  MainContent2Image = "MainContent2Image",
  MainContent3Text = "MainContent3Text",
  MainContent4ImageTextLarge = "MainContent4ImageTextLarge",
  // MainContent5ImageTextNormal = "MainContent5ImageTextNormal",
  MainContent6VideoNormal = "MainContent6VideoNormal",
  MainContent7VideoPopupThumbnail = "MainContent7VideoPopupThumbnail",
  // MainContent8VideoPopup = "MainContent8VideoPopup",
  MainContent9Conclusion = "MainContent9Conclusion",
  MainContent10MemoryCard = "MainContent10MemoryCard",
  MainContent11Mixed = "MainContent11Mixed",
}
