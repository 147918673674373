export const MONTHLY_MISSION_IMAGES_TITLE = [
  "0-1",
  "0-2",
  "0-3",
  "1",
  "2-1",
  "2-2",
  "2-3",
  "3",
  "4-1",
  "4-2",
  "4-3",
  "4-4",
  "5",
  "6-1",
  "6-2",
  "6-3",
  "6-4",
  "7",
];
