import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  ToTopOutlined,
  ToolOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ACTIVE_STATUS_OPTIONS } from "../../constants/filter";
import { IRoadMapFilter } from "../../interfaces/filter";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { activateARoadMap, deactivateARoadMap, getRoadMaps, validateARoadMap } from "../../services/RoadMapServices";
import { getGrades } from "../../services/LessonServices";
import { customSessionStorage } from "../../utils/storage";

const RoadMapPortal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [gradeList, setGradeList] = useState([]);
  const [roadMapList, setRoadMapList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const filterData = useRef<IRoadMapFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterRoadMap", formValues);

    filterData.current = {
      ...filterData.current,
      ...{
        gradeId: formValues["gradeId"]?.join(",") || undefined,
        filter: formValues["filter"] || undefined,
        status: formValues["status"] === undefined ? undefined : formValues["status"],
      },
    };
    filterData.current.page = 1;
    getListRoadMaps();
  };

  const getListRoadMaps = async () => {
    const response = await getRoadMaps(filterData.current);
    if (response) {
      setRoadMapList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListRoadMaps();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListRoadMaps();
  };

  const handleActivateRoadMap = async (roadMapId: string) => {
    const response = await activateARoadMap(roadMapId);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Activated successfully!",
        duration: 1,
      });
      await getListRoadMaps();
    }
  };

  const handleDeactivateRoadMap = async (roadMapId: string) => {
    const response = await deactivateARoadMap(roadMapId);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Deactivated successfully!",
        duration: 1,
      });
      await getListRoadMaps();
    }
  };

  const handleValidateRoadMap = async (roadMapId: string) => {
    const response = await validateARoadMap(roadMapId);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Validated successfully!",
        duration: 1,
      });
    }
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        align: "center",
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 300,
      },
      {
        title: "Grade",
        dataIndex: "grades",
        key: "gradeName",
        align: "center",
        width: 150,
        render: (grades) => {
          const listGrades = grades.map((item: any) => item.name);
          return listGrades.join(",");
        },
      },
      {
        // category
        title: "Category",
        dataIndex: ["category", "name"],
        key: "category",
        align: "center",
        width: 150,
        sorter: { multiple: 5, field: "category.name" },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = ACTIVE_STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{ACTIVE_STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 300,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => navigate(`${PageUrl.RoadMap}${PageUrl.Update}`.replace(":id", record._id))}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Edit exam branches">
                    <Button
                      icon={<ToolOutlined />}
                      onClick={() =>
                        navigate(`${PageUrl.RoadMap}${PageUrl.EditExamBranches}`.replace(":id", record._id))
                      }
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Active">
                    <Button icon={<ToTopOutlined />} onClick={() => handleActivateRoadMap(record._id)} />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Inactive">
                    <Button
                      icon={<VerticalAlignBottomOutlined />}
                      onClick={() => handleDeactivateRoadMap(record._id)}
                    />
                  </Tooltip>
                </Space>

                <Space size="middle">
                  <Tooltip title="Validate">
                    <Button icon={<ReloadOutlined />} onClick={() => handleValidateRoadMap(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterRoadMap")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterRoadMap")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterRoadMap");
        } else {
          filterData.current = {
            ...filterData.current,
            gradeId: sessionFilter["gradeId"]?.join(",") || "",
            status: sessionFilter["status"] === undefined ? undefined : sessionFilter["status"],
            filter: sessionFilter["filter"] || "",
          };
          form.setFieldsValue(sessionFilter);
        }
        getListRoadMaps();
      } else {
        getListRoadMaps();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="road-map-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={6}>
                <FormInput label="Road map" name="filter" />
              </Col>
              <Col span={6}>
                <FormSelect
                  mode="multiple"
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                />
              </Col>
              <Col span={6}>
                <FormSelect label="Status" name="status" optionData={ACTIVE_STATUS_OPTIONS} allowClear />
              </Col>
              <Col span={1} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.RoadMap}${PageUrl.Create}`)}
            >
              Create a road map
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={roadMapList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default RoadMapPortal;
