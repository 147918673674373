import { IQuestionFilter } from "../interfaces/filter";
import instance, { apiConfig } from "./instance";

const practiceUrl = "/practice/v1/admin";
const categoryUrl = "/category/v1/admin";
const lessonUrl = "/lesson/v1/admin";

export const getTags = () => {
  return instance.get(`${practiceUrl}/question-tag/get-list-tags`, apiConfig({ page: 1, size: 1000 }));
};

export const getTopics = () => {
  return instance.get(`${practiceUrl}/topic/get-list-topics`, apiConfig({ page: 1, size: 1000 }));
};

export const getGrades = () => {
  return instance.get(`${categoryUrl}/grade/get-grade-category`, apiConfig());
};

export const getUnits = () => {
  const params = { page: 1, size: 1000 };
  return instance.get(`${lessonUrl}/unit/get-list-units`, apiConfig(params));
};

export const getUnitsByGradeId = (gradeId: string) => {
  const params = { gradeId, page: 1, size: 1000 };
  return instance.get(`${lessonUrl}/unit/get-list-units/`, apiConfig(params));
};

export const getLessonsByUnitId = (gradeId: string, unitId: string) => {
  return instance.get(`${lessonUrl}/lesson/get-list-lessons/`, apiConfig({ gradeId, unitId, page: 1, size: 1000 }));
};

export const getUnitDetailByUnitId = (unitId: string) => {
  return instance.get(`${lessonUrl}/unit/get-an-unit/${unitId}`, apiConfig());
};

export const getComplexities = () => {
  return instance.get(`${categoryUrl}/complexity/get-complexity-category`, apiConfig());
};

export const getFields = () => {
  return instance.get(`${categoryUrl}/field/get-field-category`, apiConfig());
};

export const getQuestions = (postData: IQuestionFilter) => {
  return instance.post(`${practiceUrl}/question/get-list-questions`, postData, apiConfig());
};

export const getQuestionById = (id: string) => {
  return instance.get(`${practiceUrl}/question/get-a-question/${id}`, apiConfig());
};

export const addQuestion = (data: any) => {
  return instance.put(`${practiceUrl}/question/create-new-question`, data, apiConfig());
};

export const editQuestion = (id: string, data: any): Promise<any> => {
  return instance.post(`${practiceUrl}/question/edit-a-question/${id}`, data, apiConfig());
};

export const publishQuestions = (listId: string[]) => {
  return instance.post(`${practiceUrl}/question/publish-questions`, listId, apiConfig());
};

export const unPublishQuestions = (listId: string[]) => {
  return instance.post(`${practiceUrl}/question/unpublish-questions`, listId, apiConfig());
};

export const rejectQuestions = (listId: string[]) => {
  return instance.post(`${practiceUrl}/question/reject-questions`, listId, apiConfig());
};

export const submitQuestions = (listId: string[]) => {
  return instance.post(`${practiceUrl}/question/submit-questions`, listId, apiConfig());
};

export const deleteQuestion = (id: string) => {
  return instance.delete(`${practiceUrl}/question/delete-a-question/${id}`, apiConfig());
};

export const deleteManyQuestions = (listId: string[]) => {
  return instance.post(`${practiceUrl}/question/delete-many-questions`, listId, apiConfig());
};

export const editQuestionMobileVersion = (id: string, data: any): Promise<any> => {
  return instance.post(`${practiceUrl}/question/edit-question-mobile-version/${id}`, data, apiConfig());
};
