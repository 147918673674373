import Descriptions from "antd/es/descriptions";
import { FormInput } from "../components/Form/Input";
import { FormSingleFileInput } from "../components/Form/SingleFileInput";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../components/UploadInput/UploadInput";
import { FormInstance } from "antd";
import { ISlide as ILessonSlide } from "../interfaces/ILesson";
import { ISlide as IStorySlide } from "../interfaces/IStory";
import { VoiceCodeFormOptions } from "../components/VoiceCodeDropDown/VoiceCodeDropDown";
import { FormSelect } from "../components/Form/Select";

export const generatePromptWithOptions = (
  slide: ILessonSlide | IStorySlide,
  numberTexts: number,
  numberImages: number,
  prefixName: (string | number)[],
  form: FormInstance<any>,
  folder: string,
  handleLoadImage: (field: NamePath, data: FileData) => void,
  handleRemoveImage: (field: NamePath) => void,
  handleChangePromptVoiceCode: (value: string) => void
) => {
  return (
    <Descriptions.Item className="prompt-wrapper" label="Content">
      <FormInput
        type="textarea"
        key="audioText-content"
        label="AudioText"
        name={[...prefixName, "content", "audioText"]}
        latexString={slide.content.audioText || ""}
      />
      <FormSelect
        key="voiceCode-content"
        label="VoiceCode"
        name={[...prefixName, "content", "audioTextVoiceCode"]}
        optionData={VoiceCodeFormOptions}
        callback={handleChangePromptVoiceCode}
      />

      <FormInput
        type="textarea"
        key="audioLink-content"
        label="Custom AudioLink"
        name={[...prefixName, "content", "audioLink"]}
        latexString={slide.content.audioLink || ""}
      />

      {!!numberTexts && (
        <span className="texts">
          {Array(numberTexts)
            .fill("")
            .map((_, index) => (
              <FormInput
                key={index}
                type="textarea"
                name={[...prefixName, ...["content", "texts", index]]}
                label={`Text ${index + 1}`}
                latexString={slide.content.texts?.length ? slide.content.texts[index] : ""}
              />
            ))}
        </span>
      )}
      {!!numberImages && (
        <span className="images">
          {Array(numberImages)
            .fill("")
            .map((_, index) => (
              <FormSingleFileInput
                key={index}
                label={`Image ${index + 1}`}
                name={[...prefixName, "content", "images", index]}
                initValue={slide.content.images?.length ? slide.content.images[index] : ""}
                handleUpload={handleLoadImage}
                handleRemove={handleRemoveImage}
                folder={folder}
              />
            ))}
        </span>
      )}
      <span>
        <FormSingleFileInput
          key="backgroundImage"
          label="Background image"
          name={[...prefixName, "content", "backgroundImage"]}
          initValue={slide.content.backgroundImage || ""}
          handleUpload={handleLoadImage}
          handleRemove={handleRemoveImage}
          folder={folder}
        />
      </span>
    </Descriptions.Item>
  );
};

export const getGenerateOptionFromPromptType = (type: string): { numTexts: number; numImgs: number } => {
  let res = { numTexts: 0, numImgs: 0 };
  switch (type) {
    case "QPrompt1_1":
    case "QPrompt1_3":
    case "QPrompt2_1":
    case "QPrompt2_4":
      res = { numTexts: 1, numImgs: 1 };
      break;
    case "QPrompt1_2":
      res = { numTexts: 2, numImgs: 1 };
      break;
    case "QPrompt2_2":
      res = { numTexts: 1, numImgs: 2 };
      break;
    case "QPrompt2_3":
      res = { numTexts: 2, numImgs: 2 };
      break;
    case "QPrompt3_1":
      res = { numTexts: 1, numImgs: 0 };
      break;
    case "QPrompt3_2":
      res = { numTexts: 0, numImgs: 1 };
      break;
    default:
      break;
  }

  return res;
};
