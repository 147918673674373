export const COMPLEXITIES = [
  {
    _id: "63ed285c85ddc879449ff11a",
    name: "Easy",
    score: 3,
    active: true,
  },
  {
    _id: "63ed28728f227d326f6dc8aa",
    name: "Medium",
    score: 4,
    active: true,
  },
  {
    _id: "63ed2877bec40963b35be6a1",
    name: "Hard",
    score: 5,
    active: true,
  },
];

export const COMPLEXITY_OPTIONS = [
  { label: "Dễ", value: "63ed285c85ddc879449ff11a" },
  { label: "Trung bình", value: "63ed28728f227d326f6dc8aa" },
  { label: "Khó", value: "63ed2877bec40963b35be6a1" },
];
