export const FILE_EXTENSION_SUPPORT: string[] = [
  ".apng",
  ".avif",
  ".gif",
  "jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".png",
  ".webp",
  ".bmp",
  ".ico",
  ".cur",
  ".tif",
  ".tiff",
];
