export enum NoteMessage {
  SaveChangeBeforeAction = "Please save changes before any actions.",
  FillAllFilterFirst = "Please fill in all filter and template information first, then click confirm button.",
  FillGradeAndUnitFirst = "Please fill in grade and unit field first, then click confirm button.",
  FillGradeFirst = "Please fill in grade field first, then click confirm button.",
  FillGradeAndExamNameFirst = "Please fill in exam name, type and grade field first, then click confirm button.",
  ReUploadImageAfterChangeEventName = "Please re-upload all images after change event name.",
  ClickSaveToCheckQuestionTopic = "Bấm save để kiểm tra thông tin topic của các câu hỏi.",
}
type NoteTextProps = {
  text: NoteMessage;
  style?: React.CSSProperties;
};

export const NoteText = ({ text, style }: NoteTextProps) => {
  return <div style={{ fontSize: "12px", ...style, color: "mediumblue" }}> * {text}</div>;
};

export default NoteText;
