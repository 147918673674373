import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import lessonReducer from "./lessonSlice";
import authReducer from "./authSlice";
import storyReducer from "./storySlice";

export const store = configureStore({
  reducer: { lesson: lessonReducer, auth: authReducer, story: storyReducer },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
