import instance, { apiConfig } from "./instance";
import { IRoadMapCategoryFilter } from "../interfaces/filter";

const roadMapUrl = "/practice/v1/admin/road-map-category";

export const getRoadMapCategories = (postData: IRoadMapCategoryFilter) => {
  return instance.get(`${roadMapUrl}/get-list-road-map-categorys`, apiConfig(postData));
};

export const getRoadMapCategoryById = (id: string) => {
  return instance.get(`${roadMapUrl}/get-a-road-map-category/${id}`, apiConfig());
};

export const addRoadMapCategory = (data: any) => {
  return instance.put(`${roadMapUrl}/create-new-road-map-category`, data, apiConfig());
};

export const editRoadMapCategory = (id: string, data: any): Promise<any> => {
  return instance.post(`${roadMapUrl}/edit-a-road-map-category/${id}`, data, apiConfig());
};
