import { EyeOutlined, CloseCircleOutlined, LockOutlined, EditOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { addUnit, editUnit } from "../../../services/LessonServices";
import { getGrades, getUnitDetailByUnitId } from "../../../services/QuestionServices";
import { convertToSelectOption } from "../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { FormSingleFileInput } from "../../../components/Form/SingleFileInput";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";
import "./UpdateUnit.scss";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../../../components/UploadInput/UploadInput";
import { PageUrl } from "../../../constants/url";
import { handleQuestionImages, handleUnitImages, handleUpdateLessons } from "./handleChangeUnit";

const UpdateUnit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth()!;
  const [form] = Form.useForm();

  const unitId = useRef<string>(params.id || "");
  const [gradeId, setGradeId] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [unitName, setUnitName] = useState("");
  const [oldUnitName, setOldUnitName] = useState("");
  const [oldGradeName, setOldGradeName] = useState("");
  const [isConfirmedGradeUnit, setIsConfirmedGradeUnit] = useState(false);
  const [folder, setFolder] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAllowEdit, setIsAllowEdit] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editPassword, setEditPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleLoadImage = (field: NamePath, imageData: FileData) => {
    form.setFieldValue(field, imageData.fileLink);
    setImage(imageData.fileName);
  };

  const handleRemoveImage = (field: NamePath) => {
    form.setFieldValue(field, "");
    setImage("");
  };

  const handleSaveUnit = async () => {
    try {
      const unit = form.getFieldsValue();
      unit.order = Number(unit.order);
      unit.gradeId = unit.gradeId || null;
      unit.quizId = unit.quizId || null;
      const grade = gradeList.find((item: any) => item._id === gradeId) as any;
      const escapedUnitName = oldUnitName && oldUnitName?.length > 0 ?  oldUnitName.replace(/[$]/g, '\\$'): unitName.replace(/[$]/g,'\\g');
      unit.image = unit.image ? unit.image.replace(escapedUnitName, unitName) : null;
      if (oldGradeName?.length > 0 && oldGradeName !== grade.name) {
        unit.image = unit.image?.replace(oldGradeName, grade.name);
        form.setFieldValue("image", unit.image);
        setImage(unit.image);
      }
      let response: any;
      if (unitId.current && grade) {
        response = await editUnit(unitId.current, unit);
        if (response.errorCode === 200 && unitName !== oldUnitName) {
          setIsLoading(true);
          // handle change unit and image in unit
          await handleUnitImages(oldGradeName, oldUnitName, grade.name, unitName);
          // handle change unit name in question
          await handleQuestionImages(grade._id, grade.name, unitId.current, unitName);
          // handle change unit name in lesson
          await handleUpdateLessons(grade._id, grade.name, unitId.current, unitName);
          setTimeout(() => {
            setIsLoading(false);
          }, DEFAULT_TIMEOUT * 4);
        }
      } else {
        response = await addUnit(unit);
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${unitId.current ? "Updated" : "Added"} successfully`,
      });
      unitId.current = response.result ? response.result : unitId.current;
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModalOk = async () => {
    if (editPassword === process.env.REACT_APP_EDIT_PASSWORD) {
      setIsEditModalVisible(false);
      setIsAllowEdit(true);
    } else {
      Modal.error({
        title: "Invalid Password",
        content: "Please enter a valid password to enable editing.",
      });
    }
  };

  useEffect(() => {
    if (gradeId && unitName) {
      const grade = gradeList.find((item: any) => item._id === gradeId) as any;
      grade && setFolder(`${grade.name}/${unitName}/common`);
    }
  }, [gradeId, gradeList, unitName]);

  useEffect(() => {
    const getUnit = async () => {
      const response = await getUnitDetailByUnitId(unitId.current);
      if (response) {
        const unitData = (response as any).result;
        setGradeId(unitData.grade.id);
        setUnitName(unitData.unitName);
        setOldUnitName(unitData.unitName);
        setOldGradeName(unitData.grade.name);
        unitData.grade.id && unitData.unitName && setIsConfirmedGradeUnit(true);
        form.setFieldsValue({
          gradeId: unitData.grade.id,
          unitName: unitData.unitName,
          order: unitData.order,
          description: unitData.description,
          quizId: unitData.quizId,
          image: unitData.image,
          active: unitData.active,
          premium: unitData.premium,
        });
        setImage(unitData.image || "");
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      unitId.current && (await getUnit());
    };

    // Check if the user has ADMIN role
    if (!user?.userId) return;
    if (user && user.roles.includes("ADMIN")) {
      setIsAdmin(true);
    }

    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId.current, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-unit-container">
      <Form form={form} onFinish={handleSaveUnit}>
        <div className="form-input">
          <Row>
            <Col xs={12} md={12} xl={6}>
              <FormSelect
                label="Grade"
                name="gradeId"
                optionData={convertToSelectOption(gradeList, "_id", "name")}
                callback={setGradeId}
                disabled={isConfirmedGradeUnit}
              />
            </Col>
            <Col xs={12} md={12} xl={6}>
              <FormInput
                label="Unit name"
                name="unitName"
                onChange={setUnitName}
                disabled={isConfirmedGradeUnit && !isAllowEdit}
              />
            </Col>
            {!isConfirmedGradeUnit && (
              <Button
                type="primary"
                style={{ marginLeft: "16px" }}
                onClick={() => setIsConfirmedGradeUnit(true)}
                disabled={!gradeId || !unitName}
              >
                Confirm
              </Button>
            )}
            {isConfirmedGradeUnit && (
              <>
                <Col xs={12} md={12} xl={6}>
                  <FormInput type="number" label="Order" name="order" />
                </Col>
                <Col xs={12} md={12} xl={6}>
                  <FormInput label="QuizId" name="quizId" />
                </Col>
              </>
            )}
          </Row>
          {isConfirmedGradeUnit && (
            <Row>
              <Col xs={12} md={12} xl={6}>
                <FormSelect label="Active" name="active" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col xs={12} md={12} xl={6}>
                <FormSelect label="Premium" name="premium" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col xs={12} md={12} xl={6}>
                <FormInput label="Description" name="description" />
              </Col>
              <Col xs={12} md={12} xl={6}>
                <FormSingleFileInput
                  name="image"
                  label="Image"
                  initValue={image}
                  folder={folder}
                  handleUpload={handleLoadImage}
                  handleRemove={handleRemoveImage}
                />
              </Col>
            </Row>
          )}
        </div>
        {isConfirmedGradeUnit && (
          <div className="form-controller">
            {isAdmin && unitId.current && (
              <Button type="primary" icon={<EditOutlined />} onClick={() => setIsEditModalVisible(true)}>
                Edit unit name
              </Button>
            )}
            <Button
              type="primary"
              icon={<EyeOutlined />}
              style={{ backgroundColor: "#2abb2a" }}
              onClick={handleSaveUnit}
            >
              Save
            </Button>
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Units)}>
              Cancel
            </Button>
          </div>
        )}
      </Form>
      <Modal
        title="Enter Password to Edit"
        open={isEditModalVisible}
        onOk={handleEditModalOk}
        onCancel={() => setIsEditModalVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Enter Password"
          value={editPassword}
          onChange={(e) => setEditPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default UpdateUnit;
