import instance, { apiConfig } from "./instance";

const categoryUrl = "/category/v1/admin";

const DEFAULT_PARAMS = { page: 1, size: 1000 };

export const getGradeCategories = () => {
  return instance.get(`${categoryUrl}/grade/get-grade-category`, apiConfig(DEFAULT_PARAMS));
};

export const getComplexityCategories = () => {
  return instance.get(`${categoryUrl}/complexity/get-complexity-category`, apiConfig(DEFAULT_PARAMS));
};

export const getSaleCategories = () => {
  return instance.get(`${categoryUrl}/sale/get-sale-categories`, apiConfig(DEFAULT_PARAMS));
};

export const getGoalCategories = () => {
  return instance.get(`${categoryUrl}/pre-registration/get-goal-categories`, apiConfig(DEFAULT_PARAMS));
};

export const getPackageCategories = () => {
  return instance.get(`${categoryUrl}/pre-registration/get-package-categories`, apiConfig(DEFAULT_PARAMS));
};

export const getTagCategories = () => {
  return instance.get(`${categoryUrl}/tag/get-tag-categories`, apiConfig({ ...DEFAULT_PARAMS }));
};
