import { Form, Row, Col, Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { PageUrl } from "../../../constants/url";
import "./UpdateCategory.scss";
import Card from "antd/es/card/Card";
import { CloseCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
  editRoadMapCategory,
  addRoadMapCategory,
  getRoadMapCategoryById,
} from "../../../services/RoadMapCategoryServices";
import { ACTIVE_STATUS_OPTIONS } from "../../../constants/filter";

const UpdateRoadMapCategory = () => {
  const params = useParams();
  const [roadMapCategoryId, setRoadMapCategoryId] = useState<string>(params.id || "");
  const { user } = useAuth()!;

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveRoadMapCategory = () => {
    try {
      form.validateFields().then(async () => {
        const formValues = form.getFieldsValue();
        const data = {
          name: formValues.name,
          status: formValues.status ? Number(formValues.status) : 0,
        };
        let response: any;
        if (roadMapCategoryId) {
          response = await editRoadMapCategory(roadMapCategoryId, { ...data });
        } else {
          response = await addRoadMapCategory(data);
        }
        Modal.success({
          title: "Success",
          type: "success",
          content: `${roadMapCategoryId ? "Updated" : "Added"} successfully`,
        });
        setRoadMapCategoryId(response.result ? response.result : roadMapCategoryId);
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getRoadMapCategory = async () => {
      setIsLoading(true);
      const response = await getRoadMapCategoryById(roadMapCategoryId);
      setIsLoading(false);
      if (response) {
        const data = (response as any).result;
        form.setFieldsValue({
          name: data.name,
          status: data.status,
        });
      }
    };
    const getAllInformation = async () => {
      roadMapCategoryId && (await getRoadMapCategory());
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadMapCategoryId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Card title="Road map category">
        <div className="update-road-map-category-container">
          <Form form={form} onFinish={handleSaveRoadMapCategory}>
            <div className="form-input">
              <Row>
                <Col xs={8} md={8}>
                  <FormInput label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]} />
                </Col>
                <Col span={6}>
                  <FormSelect label="Status" name="status" optionData={ACTIVE_STATUS_OPTIONS} allowClear />
                </Col>
              </Row>
            </div>
          </Form>

          <div className="road-map-control">
            <Button
              type="primary"
              icon={roadMapCategoryId ? <SaveOutlined /> : <PlusOutlined />}
              style={{ backgroundColor: "#2abb2a" }}
              onClick={handleSaveRoadMapCategory}
            >
              { roadMapCategoryId ? 'Save' : 'Add'} road map
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => navigate(PageUrl.RoadMapCategory)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UpdateRoadMapCategory;
