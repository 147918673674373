import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Row, Space, Table, Tooltip, message } from "antd";
import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IEmployeeFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { getEmployees } from "../../services/EmployeeServices";
import { customSessionStorage } from "../../utils/storage";

const EmployeePortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IEmployeeFilter>({ page: 1, size: 10 });

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterEmployee", formValues);
    filterData.current = { ...filterData.current, ...{ filter: formValues.filter } };
    filterData.current.page = 1;
    fetchEmployees();
  };

  const fetchEmployees = async () => {
    const response = await getEmployees(filterData.current);
    if (response) {
      setTableData((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchEmployees();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    // const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
    //   (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    // );
    // filterData.current.sort = reSorter
    //   .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
    //   .join(";");
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchEmployees();
  };

  const handleEditEmployee = async (id: string) => {
    navigate(`${PageUrl.Employees}${PageUrl.Update}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },

      {
        title: "Full name",
        dataIndex: "fullName",
        key: "fullName",
        align: "center",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        align: "center",
        width: 200,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "center",
        width: 300,
      },
      {
        title: "Birthday",
        dataIndex: "birthday",
        key: "birthday",
        align: "center",
        width: 200,
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        align: "center",
        width: 100,
        render: (e) => (!!e ? "Nam" : "Nữ"),
      },

      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        align: "center",
        width: 100,
        render: (e) => (!!e ? "Yes" : "No"),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 300,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit employee">
                    <Button icon={<EditOutlined />} onClick={() => handleEditEmployee(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (sessionStorage.getItem("filterEmployee")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterEmployee")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterEmployee");
        } else {
          form.setFieldsValue(sessionFilter);
          filterData.current = { ...filterData.current, filter: sessionFilter?.filter };
        }
        fetchEmployees();
      } else {
        fetchEmployees();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Manager)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="employee-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ gap: "8px" }}>
              <Col>
                <FormInput label="Filter name" name="filter" allowClear />
              </Col>
              <Col className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Employees}${PageUrl.Create}`)}
            >
              Add an employee
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={tableData}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeePortal;
