import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  ToTopOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ACTIVE_STATUS_OPTIONS } from "../../constants/filter";
import { ICalibrationFilter } from "../../interfaces/filter";
import { convertStringToDate } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { activeCalibration, inactiveCalibration, getCalibrations } from "../../services/CalibrationServices";
import { customSessionStorage } from "../../utils/storage";

const CalibrationPortal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [calibrationList, setCalibrationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const filterData = useRef<ICalibrationFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterCalibration", formValues);

    filterData.current = {
      ...filterData.current,
      ...{
        filter: formValues["filter"] || undefined,
        status: formValues["status"] === undefined ? undefined : formValues["status"],
      },
    };
    filterData.current.page = 1;
    getListCalibrations();
  };

  const getListCalibrations = async () => {
    const response = await getCalibrations(filterData.current);
    if (response) {
      setCalibrationList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListCalibrations();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListCalibrations();
  };

  const handleActivateCalibration = async (calibrationId: string) => {
    const response = await activeCalibration(calibrationId);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Activated successfully!",
        duration: 1,
      });
      await getListCalibrations();
    }
  };

  const handleDeactivateCalibration = async (calibrationId: string) => {
    const response = await inactiveCalibration(calibrationId);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Deactivated successfully!",
        duration: 1,
      });
      await getListCalibrations();
    }
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 300,
      },
      {
        title: "Elo range",
        dataIndex: "eloRange",
        key: "eloRange",
        align: "center",
        width: 150,
        render: (eloRange: number[]) => {
          if (eloRange?.length === 2) {
            return `${eloRange[0]} - ${eloRange[1]}`;
          }
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = ACTIVE_STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{ACTIVE_STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 300,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => navigate(`${PageUrl.Calibration}${PageUrl.Update}`.replace(":id", record._id))}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Active">
                    <Button
                      disabled={record.status === 1}
                      icon={<ToTopOutlined />}
                      onClick={() => handleActivateCalibration(record._id)}
                    >
                      Active
                    </Button>
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Inactive">
                    <Button
                      disabled={record.status === 0}
                      icon={<VerticalAlignBottomOutlined />}
                      onClick={() => handleDeactivateCalibration(record._id)}
                    >
                      Inactive
                    </Button>
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterCalibration")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterCalibration")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterCalibration");
        } else {
          filterData.current = {
            ...filterData.current,
            status: sessionFilter["status"] === undefined ? undefined : sessionFilter["status"],
            filter: sessionFilter["filter"] || undefined,
          };
          form.setFieldsValue(sessionFilter);
        }
        getListCalibrations();
      } else {
        getListCalibrations();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="calibration-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter} style={{ width: "50%" }}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={11}>
                <FormInput label="Calibration" name="filter" />
              </Col>
              <Col span={11}>
                <FormSelect label="Status" name="status" optionData={ACTIVE_STATUS_OPTIONS} allowClear />
              </Col>
              <Col span={1} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Calibration}${PageUrl.Create}`)}
            >
              Create a calibration
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={calibrationList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default CalibrationPortal;
