import React, { useEffect } from "react";
import "./CustomTag.scss";
import { Tag } from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";

interface TagInfo {
  label: string;
  value: string;
}

interface CustomTagProps {
  tagsData: TagInfo[];
  checkedTags?: string[];
  onChange?: (listTags: string[]) => void;
}

const CustomTag = ({ tagsData, checkedTags, onChange }: CustomTagProps) => {
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    onChange && onChange(nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  useEffect(() => {
    if (checkedTags) {
      setSelectedTags(checkedTags);
    }
  }, [checkedTags]);

  return (
    <div className="custom-tag">
      {tagsData.map<React.ReactNode>((tag) => (
        <Tag className={selectedTags.includes(tag.value) ? "tag-checked" : "tag-unchecked"} key={tag.value}>
          <CheckableTag
            checked={selectedTags.includes(tag.value)}
            onChange={(checked) => handleChange(tag.value, checked)}
          >
            # {tag.label}
          </CheckableTag>
        </Tag>
      ))}
    </div>
  );
};

export default CustomTag;
