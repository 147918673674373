import {
  CheckOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  SaveOutlined,
  ExportOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Form, Button, Row, Col, Descriptions, Modal } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import {
  addLesson,
  editLesson,
  getLessonById,
  publishLessons,
  rejectLessons,
  submitLessons,
  unPublishLessons,
} from "../../../services/LessonServices";
import { getGrades, getUnitsByGradeId } from "../../../services/QuestionServices";
import { cleanObject, convertToSelectOption, getAllKeys, randomId } from "../../../utils/utils";
import "./UpdateLesson.scss";
import { useAuth } from "../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { ListSections } from "../../../components/Sections/ListSections";
import { INITIAL_LESSON_STATE, INITIAL_SECTION_STATE, addSection, updateLesson } from "../../../reducers/lessonSlice";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { debounce, merge } from "lodash";
import { previewWindow } from "../../../utils/preview";
import { UserRole } from "../../../constants/auth";
import { PageUrl, PreviewUrl } from "../../../constants/url";
import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { FlowCategory, isDisabledFlow } from "../../../constants/flow";
import { UploadJson } from "../../../components/UploadJson/UploadJson";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";
import { SlideContentTemplate } from "../../../configs/slideConfigs";

const UpdateLesson = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAuth();
  const { user, token } = currentUser!;

  const params = useParams();
  const lessonId = useRef<string>(params.id || "");
  const lesson = useAppSelector((state) => state.lesson);
  const sections = lesson.sections;

  const [gradeList, setGradeList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const [isConfirmedGradeUnit, setIsConfirmedGradeUnit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<FlowCategory>(0);

  const onSelectGrade = async (gradeId: string) => {
    form.setFieldValue("unitId", undefined);
    const unitRes = await getUnitsByGradeId(gradeId);
    if (unitRes) {
      setUnitList((unitRes as any).result.records);
    }
  };

  const onSelectUnit = (unitId: string) => {
    setTimeout(() => {
      const grade = gradeList.find((grade: any) => grade._id === lesson.gradeId) as any;
      const unit = unitList.find((unit: any) => unit._id === unitId) as any;
      let newData = { ...lesson };
      if (grade && unit) {
        newData = merge({}, newData, { imageFolder: `lesson/${grade.name}/${unit.unitName}/`, unitId });
        dispatch(updateLesson(newData));
      }
    }, DEFAULT_TIMEOUT + 50);
  };

  const handleSaveAndPreview = () => {
    try {
      form
        .validateFields()
        .then(async () => {
          let response: any;
          const cleanLesson = cleanObject(lesson);
          cleanLesson.sections.forEach((section: any) => {
            section.slides.forEach((slide: any) => {
              const explanation = slide?.explanation;
              explanation?.texts?.forEach((text: any, index: number) => {
                if (text === null) {
                  explanation.texts[index] = "";
                }
              });
              explanation?.images?.forEach((image: any, index: number) => {
                if (image === null) {
                  explanation.images[index] = "";
                }
              });

              if (slide?.template?.content === SlideContentTemplate.MainContent11Mixed) {
                const content = slide?.content;
                content?.texts?.forEach((text: any, index: number) => {
                  if (text === null) {
                    content.texts[index] = "";
                  }
                });
                content?.images?.forEach((image: any, index: number) => {
                  if (image === null || image === undefined) {
                    content.images[index] = "";
                  }
                });
              }

              if (slide?.template?.content === SlideContentTemplate.MainContent10MemoryCard) {
                const content = slide?.content;
                if (!content?.texts?.length) content.texts = [""];
                content?.texts?.forEach((text: any, index: number) => {
                  if (text === null) {
                    content.texts[index] = "";
                  }
                });
                while (content?.images?.length < 2) content.images.push("");
                content?.images?.forEach((image: any, index: number) => {
                  if (image === null || image === undefined) {
                    content.images[index] = "";
                  }
                });
              }
            });
          });
          if (lessonId.current) {
            response = await editLesson(lessonId.current, cleanLesson);
          } else {
            response = await addLesson(cleanLesson);
          }
          Modal.success({
            title: "Success",
            type: "success",
            content: `${lessonId.current ? "Updated" : "Added"} successfully`,
          });
          lessonId.current = response.result ? response.result : lessonId.current;
          previewWindow({ url: PreviewUrl.Lesson, data: { token, lessonId: lessonId.current } });
        })
        .catch((error) => {
          Modal.error({
            title: "Error",
            type: "error",
            content: `Data is not valid`,
          });
        });
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: `error`,
      });
    }
  };

  const handleAddSection = () => {
    const newKey = randomId();
    const newData = { ...INITIAL_SECTION_STATE, sectionKey: newKey };
    form.setFieldValue(["sections", sections.length], newData);
    dispatch(addSection(newKey));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = (e: any) => {
    const allLessonKeys = getAllKeys(INITIAL_LESSON_STATE);
    const allEventKeys = getAllKeys(e);
    const changedKey = allEventKeys[allEventKeys.length - 1];
    if (!allLessonKeys.includes(changedKey)) {
      return;
    }

    if (changedKey === "solutions" || changedKey === "question") {
      return;
    }

    if (Object.keys(e).includes("order")) {
      e.order = Number(e.order);
    }
    let data = { ...lesson };
    data = merge({}, data, e);
    dispatch(updateLesson(data));
  };

  const handleChangeLessonStatus = async (func: any, nextStatus: FlowCategory) => {
    try {
      const res = await func.then();
      if (res) {
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadJon = (data: any) => {
    const newSections = data.sections.map((section: any, index: number) => {
      form.setFieldValue(["sections", index], section);
      section.slides.forEach((slide: any, i: number) => {
        form.setFieldValue(["sections", index, "slides", i], slide);
        slide = { ...slide, slideKey: randomId() };
        return slide;
      });
      section = { ...section, sectionKey: randomId() };
      return section;
    });
    let newData = { ...lesson };
    newData = merge({}, newData, { sections: newSections });
    dispatch(updateLesson(newData));
  };

  const handleExportJson = async () => {
    const lessonRes = await getLessonById(lessonId.current);
    if (lessonRes) {
      const lessonData = (lessonRes as any).result;
      const data = JSON.stringify(lessonData);
      const a = document.createElement("a");
      const blob = new Blob([data], { type: "application/json" });
      a.href = window.URL.createObjectURL(blob);
      a.download = `${lessonData.title}.json`;
      a.click();
    }
  };

  useEffect(() => {
    const getLesson = async () => {
      const lessonRes = await getLessonById(lessonId.current);
      if (lessonRes) {
        const lessonData = (lessonRes as any).result;
        setStatus(lessonData.status);
        lessonData.gradeId = lessonData.grade?.id;
        onSelectGrade(lessonData.gradeId);
        lessonData.unitId = lessonData.unit?.id;
        form.setFieldsValue(lessonData);
        if (lessonData.gradeId && lessonData.unitId) {
          setIsConfirmedGradeUnit(true);
          lessonData.imageFolder = `lesson/${lessonData.grade?.name}/${lessonData.unit?.name}/`;
        }
        lessonData.sections = lessonData.sections.map((section: any) => {
          section.slides = section.slides.map((slide: any) => {
            slide = { ...slide, slideKey: randomId() };
            return slide;
          });
          section = { ...section, sectionKey: randomId() };
          return section;
        });
        dispatch(updateLesson(lessonData));
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      lessonId.current && (await getLesson());
    };

    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId.current]);

  useEffect(() => {
    form.resetFields();
    dispatch(updateLesson(INITIAL_LESSON_STATE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceHandleChange = useMemo(() => debounce(handleFormChange, DEFAULT_TIMEOUT), [handleFormChange]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-lesson-container">
      <Form form={form} onFinish={handleSaveAndPreview} onValuesChange={debounceHandleChange}>
        <div className="form-input">
          <Descriptions>
            <Descriptions.Item label="Lesson info">
              <Row>
                <Col xs={8} md={8} xl={6}>
                  <FormInput
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Invalid field" }]}
                    latexString={lesson.title}
                  />
                </Col>
                <Col xs={8} md={8} xl={6}>
                  <FormInput label="Description" name="description" />
                </Col>
              </Row>
              <Row>
                <Col xs={8} md={8} xl={6}>
                  <FormSelect
                    label="Grade"
                    name="gradeId"
                    optionData={convertToSelectOption(gradeList, "_id", "name")}
                    callback={onSelectGrade}
                    rules={[{ required: true, message: "Invalid field" }]}
                    disabled={isConfirmedGradeUnit}
                  />
                </Col>
                <Col xs={8} md={8} xl={6}>
                  <FormSelect
                    label="Unit"
                    name="unitId"
                    optionData={convertToSelectOption(unitList, "_id", "unitName")}
                    rules={[{ required: true, message: "Invalid field" }]}
                    callback={onSelectUnit}
                    disabled={isConfirmedGradeUnit}
                  />
                </Col>
                <Col xs={8} md={8} xl={6}>
                  <FormInput
                    type="number"
                    label="Order"
                    name="order"
                    rules={[{ required: true, message: "Invalid field" }]}
                  />
                </Col>
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </div>
        {!isConfirmedGradeUnit && (
          <>
            <NoteText style={{ margin: "4px 0 0 100px" }} text={NoteMessage.FillGradeAndUnitFirst} />
            <Button
              type="primary"
              style={{ marginLeft: "100px" }}
              onClick={() => setIsConfirmedGradeUnit(true)}
              disabled={!lesson.gradeId || !lesson.unitId}
            >
              Confirm
            </Button>
          </>
        )}
        {sections.length && isConfirmedGradeUnit ? (
          <>
            <div style={{ margin: "0 0 16px 100px" }}>
              <UploadJson onData={handleUploadJon} />
              {lessonId.current && (
                <>
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    style={{ backgroundColor: "#2abb2a", marginRight: "5px" }}
                    onClick={handleExportJson}
                  >
                    Export JSON
                  </Button>
                  {!isDisabledFlow(status, FlowCategory.Reviewing) && (
                    <Button
                      type="primary"
                      icon={<SettingOutlined />}
                      style={{ backgroundColor: "#a746ff" }}
                      onClick={() =>
                        window.open(
                          `${PageUrl.Lessons}${PageUrl.UpdateMobileSections}`.replace(":id", lessonId.current),
                          "_blank"
                        )
                      }
                    >
                      Edit mobile sections
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className="list-sections">
              <ListSections />
            </div>
          </>
        ) : (
          <></>
        )}
        {isConfirmedGradeUnit && (
          <>
            <NoteText style={{ margin: "4px 0" }} text={NoteMessage.SaveChangeBeforeAction} />
            <div className="form-controller">
              {user?.roles?.includes(UserRole.ContentCreator) && (
                <>
                  <Button type="default" icon={<PlusOutlined />} onClick={handleAddSection}>
                    Add section
                  </Button>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{ backgroundColor: "#2abb2a" }}
                    onClick={handleSaveAndPreview}
                  >
                    Save and preview
                  </Button>
                  <ConfirmButton
                    title="Submit"
                    buttonType="primary"
                    icon={<CheckOutlined />}
                    onConfirm={() =>
                      handleChangeLessonStatus(submitLessons([lessonId.current]), FlowCategory.Reviewing)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !lessonId.current}
                  />
                </>
              )}
              {user?.roles?.includes(UserRole.Reviewer) && lessonId.current && (
                <>
                  <ConfirmButton
                    title="Publish"
                    buttonType="primary"
                    icon={<UploadOutlined />}
                    onConfirm={() =>
                      handleChangeLessonStatus(publishLessons([lessonId.current]), FlowCategory.Published)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.Published)}
                  />
                  <ConfirmButton
                    title="Unpublish"
                    buttonType="default"
                    icon={<DownloadOutlined />}
                    onConfirm={() =>
                      handleChangeLessonStatus(unPublishLessons([lessonId.current]), FlowCategory.UnPublished)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
                  />
                  <ConfirmButton
                    title="Reject"
                    buttonType="dashed"
                    icon={<CloseOutlined />}
                    onConfirm={() =>
                      handleChangeLessonStatus(rejectLessons([lessonId.current]), FlowCategory.RequestEdit)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
                  />
                </>
              )}
              <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Lessons)}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default UpdateLesson;
