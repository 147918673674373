export const ACHIEVEMENT_MENU_ITEMS = [
  { label: "Bài giảng", value: 1 },
  { label: "Bài thi", value: 2 },
  { label: "Xếp hạng", value: 3 },
  { label: "Chuyên cần", value: 4 },
  { label: "Khác", value: 5 },
];

export const ACHIEVEMENT_TYPES = [
  { label: "Common", value: 1 },
  { label: "Multiple", value: 2 },
];
