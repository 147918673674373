import { POST_MESSAGE_TIMEOUT, PREVIEW_TIMEOUT } from "../constants/timeout";

interface IPreviewProps {
  url: string;
  data: any;
  directly?: boolean;
}

export const previewWindow = ({ url, data, directly }: IPreviewProps): Promise<any> => {
  return new Promise((res) => {
    setTimeout(
      () => {
        const previewUrl = process.env.REACT_APP_PREVIEW_URL;
        const newWindow = window.open(`${previewUrl}${url}`, "_blank");
        setTimeout(() => {
          newWindow?.postMessage(data, "*");
          res("success");
        }, POST_MESSAGE_TIMEOUT);
      },
      directly ? 0 : PREVIEW_TIMEOUT
    );
  });
};
