import { CloseCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Descriptions, Modal } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import Loading from "../../../components/Loading/Loading";
import { editMobileSections, getLessonById } from "../../../services/LessonServices";
import { cleanNullValues, cleanObject, getAllKeys, randomId } from "../../../utils/utils";
import "./UpdateMobileSections.scss";
import { useAuth } from "../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { ListSections } from "../../../components/MobileSections/ListSections";
import {
  INITIAL_LESSON_STATE,
  INITIAL_SECTION_STATE,
  addMobileSection,
  updateLesson,
} from "../../../reducers/lessonSlice";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { debounce, merge } from "lodash";
import { previewWindow } from "../../../utils/preview";
import { UserRole } from "../../../constants/auth";
import { PageUrl, PreviewUrl } from "../../../constants/url";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";
import { SlideContentTemplate } from "../../../configs/slideConfigs";

const UpdateMobileSections = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAuth();
  const { user, token } = currentUser!;

  const params = useParams();
  const lessonId = useRef<string>(params.id || "");
  const lesson = useAppSelector((state) => state.lesson);
  const sections = lesson.mobileSections;

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveAndPreview = () => {
    try {
      form
        .validateFields()
        .then(async () => {
          const mobileSections: any[] = [];
          const cleanLesson = cleanObject(lesson);
          cleanLesson.mobileSections.forEach((section: any) => {
            const newSection = { ...section };
            newSection.slides.forEach((slide: any) => {
              const explanation = slide?.explanation;
              if (explanation) {
                cleanNullValues(explanation.texts);
                cleanNullValues(explanation.images);
              }

              if (slide?.template?.content === SlideContentTemplate.MainContent11Mixed) {
                const content = slide?.content;
                if (content) {
                  cleanNullValues(content.texts);
                  cleanNullValues(content.images);
                }
              }

              if (slide?.template?.content === SlideContentTemplate.MainContent10MemoryCard) {
                const content = slide?.content;
                if (!content?.texts?.length) content.texts = [""];
                while (content?.images?.length < 2) content.images.push("");
                cleanNullValues(content.texts);
                cleanNullValues(content.images);
              }
            });
            mobileSections.push(newSection);
          });
          await editMobileSections(lessonId.current, { mobileSections });
          Modal.success({
            title: "Success",
            type: "success",
            content: `Updated successfully`,
          });
          previewWindow({ url: PreviewUrl.Lesson, data: { token, lessonId: lessonId.current } });
        })
        .catch((error) => {
          console.log(error);
          Modal.error({
            title: "Error",
            type: "error",
            content: `Data is not valid`,
          });
        });
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: `error`,
      });
    }
  };

  const handleAddSection = () => {
    const newKey = randomId();
    const newData = { ...INITIAL_SECTION_STATE, sectionKey: newKey };
    form.setFieldValue(["mobileSections", sections.length], newData);
    dispatch(addMobileSection(newKey));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = (e: any) => {
    const allLessonKeys = getAllKeys(INITIAL_LESSON_STATE);
    const allEventKeys = getAllKeys(e);
    const changedKey = allEventKeys[allEventKeys.length - 1];
    if (!allLessonKeys.includes(changedKey)) {
      return;
    }

    if (changedKey === "solutions" || changedKey === "question") {
      return;
    }

    if (Object.keys(e).includes("order")) {
      e.order = Number(e.order);
    }
    let data = { ...lesson };
    data = merge({}, data, e);
    dispatch(updateLesson(data));
  };

  useEffect(() => {
    const getLesson = async () => {
      const lessonRes = await getLessonById(lessonId.current);
      if (lessonRes) {
        const lessonData = (lessonRes as any).result;
        form.setFieldsValue(lessonData);
        lessonData.mobileSections = lessonData.mobileSections.map((section: any) => {
          section.slides = section.slides.map((slide: any) => {
            slide = { ...slide, slideKey: randomId() };
            return slide;
          });
          section = { ...section, sectionKey: randomId() };
          return section;
        });
        console.log(lessonData);
        dispatch(updateLesson(lessonData));
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      lessonId.current && (await getLesson());
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId.current]);

  useEffect(() => {
    form.resetFields();
    dispatch(updateLesson(INITIAL_LESSON_STATE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceHandleChange = useMemo(() => debounce(handleFormChange, DEFAULT_TIMEOUT), [handleFormChange]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-lesson-mobile-sections-container">
      <Form form={form} onFinish={handleSaveAndPreview} onValuesChange={debounceHandleChange}>
        <div className="form-input">
          <Descriptions>
            <Descriptions.Item label="Lesson">
              <Row>
                <Col xs={8} md={8} xl={6}>
                  <FormInput
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: "Invalid field" }]}
                    latexString={lesson.title}
                    readonly
                  />
                </Col>
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </div>
        {sections.length ? (
          <div className="list-sections">
            <ListSections />
          </div>
        ) : (
          <></>
        )}
        <NoteText style={{ margin: "4px 0" }} text={NoteMessage.SaveChangeBeforeAction} />
        <div className="form-controller">
          {user?.roles?.includes(UserRole.ContentCreator) && (
            <>
              <Button type="default" icon={<PlusOutlined />} onClick={handleAddSection}>
                Add section
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                style={{ backgroundColor: "#2abb2a" }}
                onClick={handleSaveAndPreview}
              >
                Save and preview
              </Button>
            </>
          )}
          <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Lessons)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateMobileSections;
