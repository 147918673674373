import Cookies from "universal-cookie";
import instance from "./instance";

const utilitiesUrl = "/utilities/api/v1/admin";

const config = (params?: any, contentType?: string) => {
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");
  return {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": contentType },
    params,
  };
};

export const uploadFile = (file: File, folder?: string) => {
  return instance.post(`${utilitiesUrl}/files/upload`, { file, folder }, config(undefined, "multipart/form-data"));
};

export const uploadMultiFiles = (formData: FormData) => {
  return instance.post(`${utilitiesUrl}/files/upload`, formData, config(undefined, "multipart/form-data"));
};
