import { IMonthlyMissionFilter } from "../interfaces/filter";
import instance, { apiConfig } from "./instance";

const monthlyMissionUrl = "/practice/v1/admin/monthly-mission";

export const getMonthlyMissions = (filter: IMonthlyMissionFilter) => {
  return instance.get(`${monthlyMissionUrl}/get-list-monthly-missions`, apiConfig(filter));
};

export const getMonthlyMissionById = (id: string) => {
  return instance.get(`${monthlyMissionUrl}/get-a-monthly-mission/${id}`, apiConfig());
};

export const addMonthlyMission= (data: any) => {
  return instance.put(`${monthlyMissionUrl}/create-new-monthly-mission`, data, apiConfig());
};

export const editMonthlyMission= (id: string, data: any): Promise<any> => {
  return instance.post(`${monthlyMissionUrl}/edit-a-monthly-mission/${id}`, data, apiConfig());
};

export const activeMonthlyMission = (id: string) => {
  return instance.get(`${monthlyMissionUrl}/active-monthly-mission/${id}`, apiConfig());
};

export const inactiveMonthlyMission = (id: string) => {
  return instance.get(`${monthlyMissionUrl}/inactive-monthly-mission/${id}`, apiConfig());
};
