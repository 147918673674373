import { ISelectOption } from "../../interfaces/filter";

const voiceCodeList = [
  { name: "HN - Ngọc Huyên", code: "hn_female_ngochuyen_full_48k-fhg" },
  { name: "HN - Anh Khôi - news", code: "hn_male_phuthang_news65dt_44k-fhg" },
  { name: "HN - Thanh Long - talk", code: "hn_male_thanhlong_talk_48k-fhg" },
  { name: "HN - Mai Phương - vdts", code: "hn_female_maiphuong_vdts_48k-fhg" },
  { name: "HN - Mạnh Dũng - news", code: "hn_male_manhdung_news_48k-fhg" },
  { name: "HN - Anh Khôi - story telling", code: "hn_male_phuthang_stor80dt_48k-fhg" },
  { name: "HN - Ngọc Lan - story telling", code: "hn_female_hermer_stor_48k-fhg" },
  { name: "HN - Nguyệt Dương - story telling", code: "hn_female_lenka_stor_48k-phg" },
  { name: "HN - Mạnh Dũng (nhấn nhá) - news", code: "hn_male_manhdung_news_48k-phg" },
  { name: "HN - Việt Bách - child", code: "hn_male_vietbach_child_22k-vc" },
  { name: "SG - Tường Vy - call", code: "sg_female_tuongvy_call_44k-fhg" },
  { name: "SG - Lan Trinh - vdts", code: "sg_female_lantrinh_vdts_48k-fhg" },
  { name: "SG - Trung Kiên - vdts", code: "sg_male_trungkien_vdts_48k-fhg" },
  { name: "SG - Minh Hoàng - full", code: "sg_male_minhhoang_full_48k-fhg" },
  { name: "SG - Thảo Trinh - full", code: "sg_female_thaotrinh_full_48k-fhg" },
  { name: "Huế - Duy Phương - full", code: "hue_male_duyphuong_full_48k-fhg" },
  { name: "SG - Thảo Trinh (nhấn nhá) - full", code: "sg_female_thaotrinh_full_44k-phg" },
  { name: "SG - Chí Đạt - ebook", code: "sg_male_chidat_ebook_48k-phg" },
  { name: "Huế - Hương Giang - full", code: "hue_female_huonggiang_full_48k-fhg" },
];

export const VoiceCodeFormOptions: ISelectOption[] = voiceCodeList.map((voiceCode) => {
  return {
    value: voiceCode.code,
    label: voiceCode.name,
  };
});
