import { Button, Checkbox, Descriptions, Form, Input, Radio } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { INITIAL_SLIDE_STATE, updateSlide } from "../../reducers/lessonSlice";
import { FormInput } from "../Form/Input";
import { FormSingleFileInput } from "../Form/SingleFileInput";
import { FormSelect } from "../Form/Select";
import { useEffect, useRef, useState } from "react";
import { createObjectFromKeys, enumToArray } from "../../utils/utils";
import { SlideContentTemplate, SlideQuestionTemplate, SlideType } from "../../configs/slideConfigs";

import SingleFileInput, { FileData } from "../UploadInput/UploadInput";
import { InternalNamePath, NamePath } from "antd/es/form/interface";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import "../UpdateQuestion/UpdateQuestion.scss";
import { generatePromptWithOptions, getGenerateOptionFromPromptType } from "../../utils/prompt";
import { generateContentWithOptions, getGenerateOptionFromContentType } from "../../utils/content";
import { FILE_EXTENSION_SUPPORT } from "../../constants/fileExtensions";
import { assign, isEqual, isInteger, merge, omit } from "lodash";
import {
  PromptTypeOptions,
  PromptTypes,
  QDetailOptions,
  QuestionTypeConfigs,
  SelectOption,
  TypeOptions,
} from "../../configs/questionConfigs";
import { VoiceCodeFormOptions } from "../VoiceCodeDropDown/VoiceCodeDropDown";

export const SlideItem = ({ sectionIndex, index }: { sectionIndex: number; index: number }) => {
  const dispatch = useAppDispatch();
  let slide = useAppSelector((state) => state.lesson.sections[sectionIndex].slides[index]);
  const folder = useAppSelector((state) => state.lesson.imageFolder);
  const form = Form.useFormInstance();
  const prefixName = ["sections", sectionIndex, "slides", index];

  const [slideType, setSlideType] = useState<string>("");
  const [templateOption, setTemplateOption] = useState<string>("");
  const isVertical = useRef<boolean>(false);

  const [promptOptions, setPromptOptions] = useState<SelectOption[]>();
  const [selectedPromptOption, setSelectedPromptOption] = useState<string>();

  const [typeOptions, setTypeOptions] = useState<SelectOption[]>();
  const [extraOptions, setExtraOptions] = useState<SelectOption[]>();
  const [selectedExtraOption, setSelectedExtraOption] = useState<string>();

  const [numberChoices, setNumberChoices] = useState<number>();
  const [generateDisable, setGenerateDisable] = useState(true);
  const [targetNumber, setTargetNumber] = useState<number>();

  const [explanationTexts, setExplanationTexts] = useState<string[]>(
    slide?.explanation?.texts?.length ? slide?.explanation?.texts : [""]
  );
  const [explanationImages, setExplanationImages] = useState<string[]>(
    slide?.explanation?.images?.length ? slide?.explanation?.images : [""]
  );

  const [mixedContentLength, setMixedContentLength] = useState<number>(1);

  const setFieldValue = (path: NamePath, value: any) => {
    if (typeof path === "number" || typeof path === "string") {
      form.setFieldValue([...prefixName, path], value);
    } else {
      form.setFieldValue([...prefixName, ...path], value);
    }
  };

  const getFieldValue = (path: NamePath) => {
    if (typeof path === "number" || typeof path === "string") {
      return form.getFieldValue([...prefixName, path]);
    } else {
      return form.getFieldValue([...prefixName, ...path]);
    }
  };

  const assignToSlide = (data: any) => {
    slide = assign({}, { ...slide }, { ...data });
    dispatch(updateSlide({ sectionIndex, index, slide }));
  };

  const mergeToSlide = (data: any) => {
    slide = merge({}, { ...slide }, { ...data });
    dispatch(updateSlide({ sectionIndex, index, slide }));
  };

  const removeContent = () => {
    setFieldValue(["template", "content"], "");
    setFieldValue(["content"], INITIAL_SLIDE_STATE.content);
    mergeToSlide({ template: { content: "" } });
    assignToSlide({ content: INITIAL_SLIDE_STATE.content });
  };

  const removeTemplateQuestion = () => {
    setFieldValue(["template", "question"], "");
    mergeToSlide({ template: { question: "" } });
  };

  const removeNumberChoicesTarget = () => {
    setNumberChoices(undefined);
    setTargetNumber(undefined);
    setFieldValue("numberChoices", undefined);
    setFieldValue("targetNumber", undefined);
  };

  const removeQuestion = () => {
    setFieldValue("question", INITIAL_SLIDE_STATE.question);
    setFieldValue("explanation", INITIAL_SLIDE_STATE.explanation);
    assignToSlide({ question: INITIAL_SLIDE_STATE.question });
    assignToSlide({ explanation: INITIAL_SLIDE_STATE.explanation });
  };

  const handleChangeSlideType = (value: string) => {
    setSlideType(value);
    setFieldValue("slideType", value);

    let templateOptions;
    if (value === SlideType.Question) {
      // remove content data
      templateOptions = enumToArray(SlideQuestionTemplate);
      handleChangeTemplateOption(templateOptions[1].value);
      setNumberChoices(undefined);
    }
    if (value === SlideType.Content) {
      templateOptions = enumToArray(SlideContentTemplate);
      handleChangeTemplateOption(templateOptions[0].value);
    }
  };

  const handleChangeTemplateOption = (value: string) => {
    switch (value) {
      case SlideQuestionTemplate.Vertical:
        // remove prompt
        removeContent();
        isVertical.current = true;
        setSelectedPromptOption(undefined);
        handleChangeQuestionType(QuestionTypeConfigs[0].value);
        break;
      case SlideQuestionTemplate.Horizontal:
        removeContent();
        isVertical.current = false;
        form.setFieldValue([...prefixName, "content", "backgroundImage"], "");
        mergeToSlide({ content: { backgroundImage: "" } });
        handleChangePromptType(PromptTypes[0].value);
        handleChangeQuestionType(QuestionTypeConfigs[0].value);
        break;
      default:
        removeTemplateQuestion();
        removeContent();
        isVertical.current = false;
        form.setFieldValue([...prefixName, "content", "backgroundImage"], "");
        mergeToSlide({ content: { backgroundImage: "" } });
        let mergeData = merge({}, { ...slide }, { template: { content: value, question: "" } });
        mergeData = merge({}, mergeData, { content: INITIAL_SLIDE_STATE.content });
        dispatch(updateSlide({ sectionIndex, index, slide: mergeData }));
        break;
    }
    setTemplateOption(value);
    setFieldValue("templateOption", value);
  };

  const handleChangePromptType = (value: string) => {
    setFieldValue("promptType", value);
    setPromptOptions(PromptTypeOptions[value]);
    handleChangePromptTypeOption(PromptTypeOptions[value][0].value);
  };

  const handleChangePromptTypeOption = (value: string) => {
    setSelectedPromptOption(value);
    setFieldValue(["template", "content"], value);
    mergeToSlide({ template: { content: value } });
  };

  const handleChangeQuestionType = (value: string) => {
    setFieldValue("questionType", value);
    setTypeOptions(TypeOptions[value]);
    handleChangeTypeOption(TypeOptions[value][0].value);
  };

  const handleChangeTypeOption = (value: string) => {
    const newTemplate = isVertical.current
      ? { template: { question: `${SlideQuestionTemplate.Vertical}${value}` } }
      : { template: { question: value } };
    setFieldValue(["template", "question"], value);
    mergeToSlide(newTemplate);

    setExtraOptions(QDetailOptions[value]);
    handleChangeExtraOption(QDetailOptions[value][0].value);
    removeNumberChoicesTarget();
    removeQuestion();
  };

  const handleChangeExtraOption = (value: string) => {
    setSelectedExtraOption(value);
    setFieldValue("extraOptions", value);
    removeNumberChoicesTarget();
    removeQuestion();
  };

  const handleAddExplainTexts = () => {
    setExplanationTexts([...explanationTexts, ""]);
  };

  const handleRemoveExplainTexts = () => {
    setExplanationTexts((prev) => prev.slice(0, -1));
    assignToSlide({ explanation: { texts: explanationTexts.slice(0, -1), images: explanationImages } });
  };

  const handleAddExplainImages = () => {
    setExplanationImages([...explanationImages, ""]);
  };

  const handleRemoveExplainImages = () => {
    setExplanationImages((prev) => prev.slice(0, -1));
    assignToSlide({ ...{ explanation: { images: explanationImages.slice(0, -1), texts: explanationTexts } } });
  };

  const handleLoadImage = (name: NamePath, imageData: FileData) => {
    const data = createObjectFromKeys((name as InternalNamePath).slice(4), imageData.fileLink);
    form.setFieldValue(name, imageData?.fileLink && imageData.fileLink[0].objectId);
    mergeToSlide(data);
  };

  const handleRemoveImage = (name: NamePath) => {
    const data = createObjectFromKeys((name as InternalNamePath).slice(4), "");
    form.setFieldValue(name, "");
    mergeToSlide(data);
  };

  const handleChangeQuestionVoiceCode = (value: string) => {
    mergeToSlide({ question: { audioVoiceCode: value } });
  };

  const handleChangeExplanationVoiceCode = (value: string) => {
    mergeToSlide({ explanation: { audioVoiceCode: value } });
  };

  const handleChangeContentVoiceCode = (value: string) => {
    mergeToSlide({ content: { audioVoiceCode: value } });
  };

  const generatePrompt = (promptType: string): JSX.Element => {
    const option = getGenerateOptionFromPromptType(promptType);
    return generatePromptWithOptions(
      slide,
      option.numTexts,
      option.numImgs,
      prefixName,
      form,
      folder || "",
      handleLoadImage,
      handleRemoveImage,
      handleChangeContentVoiceCode
    );
  };

  const generateContent = (contentType: string): JSX.Element => {
    if (contentType === SlideContentTemplate.MainContent11Mixed) {
      return (
        <>
          <Descriptions>
            <Descriptions.Item className="mixed-content" label="Content">
              {Array(mixedContentLength)
                .fill("")
                .map((_, index) => (
                  <span key={index} className="text-image">
                    <FormInput
                      type="textarea"
                      name={[...prefixName, ...["content", "texts", index]]}
                      label={`Text ${index + 1}`}
                      latexString={slide.content.texts?.length ? slide.content.texts[index] : ""}
                    />
                    <FormSingleFileInput
                      label={`Image ${index + 1}`}
                      name={[...prefixName, ...["content", "images", index]]}
                      initValue={slide.content.images?.length ? slide.content.images[index] : ""}
                      handleUpload={handleLoadImage}
                      handleRemove={handleRemoveImage}
                      folder={folder}
                    />
                  </span>
                ))}
              <span className="control">
                <Button
                  type="ghost"
                  block
                  icon={<PlusOutlined />}
                  onClick={() => setMixedContentLength((len) => len + 1)}
                />
                <Button
                  style={mixedContentLength > 1 ? { display: "block" } : { display: "none" }}
                  type="ghost"
                  block
                  icon={<MinusOutlined />}
                  onClick={() => {
                    setMixedContentLength((len) => len - 1);
                    form.setFieldValue([...prefixName, ...["content", "texts", mixedContentLength - 1]], "");
                    if (mixedContentLength === slide.content?.texts?.length) {
                      const texts = slide.content?.texts?.slice(0, -1);
                      const images = slide.content?.images?.slice(0, -1);
                      assignToSlide({ content: { texts, images } });
                    }
                  }}
                />
              </span>
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    const option = getGenerateOptionFromContentType(contentType);
    return generateContentWithOptions({
      slide,
      numberTexts: option.numTexts,
      numberImages: option.numImgs,
      numberVideos: option.numVideos,
      folder: folder || "",
      prefixName,
      handleLoadImage,
      handleRemoveImage,
      hasAudio: true,
      handleChangeContentVoiceCode,
    });
  };

  const generateQuestionForm = (type: "texts" | "images") => {
    if (!numberChoices) return <></>;
    const choices = Array(numberChoices).fill("") as string[];
    let result;
    switch (type) {
      case "texts":
        result = choices.map((_, index) => (
          <FormInput
            type="textarea"
            key={`choice-${index}`}
            name={[...prefixName, "question", "choices", index]}
            label={`Choice ${index + 1}`}
            latexString={slide.question.choices?.length ? slide.question.choices[index] : ""}
          />
        ));
        break;
      case "images":
        result = choices.map((_, index) => (
          <FormSingleFileInput
            key={`choice-${index}-${numberChoices}`}
            name={[...prefixName, "question", "choices", index]}
            label={`Choice ${index + 1}`}
            initValue={slide.question.choices?.length ? slide.question.choices[index] : ""}
            handleUpload={handleLoadImage}
            handleRemove={handleRemoveImage}
            folder={folder}
          />
        ));
        break;
      default:
        break;
    }

    return <span className={type}>{result}</span>;
  };

  const generateSolutionForm = (
    type: "radio" | "checkbox" | "gap-fill" | "gap-fill-image" | "drag-drop" | "drag-drop-multi"
  ) => {
    if (!numberChoices) return <></>;
    let choices = Array(numberChoices).fill("") as string[];
    if ((type === "drag-drop" || type === "drag-drop-multi") && targetNumber) {
      choices = Array(targetNumber).fill("") as string[];
    }
    let result;
    switch (type) {
      case "radio":
        result = (
          <Form.Item key="solution" name={[...prefixName, "question", "solutions"]} valuePropName="checked">
            <Radio.Group
              name="solution"
              value={slide?.question?.solutions?.findIndex((solution) => solution)}
              onChange={(e) => {
                let solutions = Array(numberChoices).fill(false);
                solutions[e.target.value] = true;
                mergeToSlide({ question: { solutions } });
              }}
            >
              {choices.map((_, index) => (
                <Radio name="solution" key={`solution-${index}`} value={index}>
                  Solution {index + 1}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
        break;
      case "checkbox":
        result = (
          <Form.Item key="solution" name={[...prefixName, "question", "solutions"]}>
            <Checkbox.Group
              name="solution"
              value={slide?.question?.solutions
                ?.map((solution, i) => (solution ? i : false))
                .filter((solution) => solution !== false)}
              onChange={(e: any) => {
                let solutions = Array(numberChoices).fill(false);
                e.forEach((value: number) => (solutions[value] = true));
                mergeToSlide({ question: { solutions } });
              }}
            >
              {choices.map((_, index) => (
                <Checkbox name="solution" key={`solution-${index}`} value={index}>
                  Solution {index + 1}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
        break;
      case "gap-fill":
        result = (
          <>
            {choices.map((_, index) => (
              <FormInput
                key={`solution-${index}`}
                name={[...prefixName, "question", "solutions", index]}
                label={`Solution ${index + 1}`}
                onChange={(e) => {
                  const solutions = [...(slide.question.solutions as string[])];
                  solutions[index] = e;
                  mergeToSlide({ question: { solutions } });
                }}
              />
            ))}
          </>
        );
        break;
      case "gap-fill-image":
        result = (
          <>
            {choices.map((_, index) => (
              <span style={{ display: "grid", gap: "32px" }} key={index}>
                <FormInput
                  key={`solution-${index}`}
                  name={[...prefixName, "question", "solutions", index]}
                  label={`Solution ${index + 1}`}
                  onChange={(e) => {
                    const solutions = [...(slide.question.solutions as string[])];
                    solutions[index] = e;
                    mergeToSlide({ question: { solutions } });
                  }}
                  latexString={slide.question.solutions?.length ? (slide.question.solutions[index] as string) : ""}
                />
                <FormSingleFileInput
                  key={`choice-${index}`}
                  name={[...prefixName, "question", "choices", index]}
                  label={`Image ${index + 1}`}
                  initValue={slide.question.choices?.length ? slide.question.choices[index] : ""}
                  handleUpload={handleLoadImage}
                  handleRemove={handleRemoveImage}
                  folder={folder}
                />
              </span>
            ))}
          </>
        );
        break;
      case "drag-drop":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <FormInput
                type="number"
                key={`solution-${index}`}
                name={[...prefixName, "question", "solutions", index]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      const solutions = getFieldValue(["question", "solutions"]);
                      if (Object.values(solutions).filter((item) => item === value).length > 1) {
                        return Promise.reject("Exist solution");
                      } else {
                        if (Number(value) > Number(numberChoices) || Number(value) <= 0)
                          return Promise.reject("Invalid target");
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                onChange={(e) => {
                  if (e === "") return;
                  const solutions = [...(slide.question.solutions as number[])];
                  solutions[index] = Number(e);
                  mergeToSlide({ question: { solutions } });
                }}
              />
            ))}
          </span>
        );
        break;
      case "drag-drop-multi":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <FormInput
                type="number"
                key={`solution-${index}`}
                name={[...prefixName, "question", "solutions", index]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      if (Number(value) > Number(numberChoices) || Number(value) <= 0)
                        return Promise.reject("Invalid target");
                      return Promise.resolve();
                    },
                  },
                ]}
                onChange={(e) => {
                  if (e === "") return;
                  const solutions = [...(slide.question.solutions as number[])];
                  solutions[index] = Number(e);
                  mergeToSlide({ question: { solutions } });
                }}
              />
            ))}
          </span>
        );
        break;
      default:
        break;
    }

    return result;
  };

  const generateQuestionAndSolution = (questionType: string) => {
    let question;
    let solution;
    switch (questionType) {
      case "QAnswer1-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer1-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer2-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer2-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer3-1":
        solution = generateSolutionForm("gap-fill");
        break;
      case "QAnswer3-2":
        solution = generateSolutionForm("gap-fill-image");
        break;
      case "QAnswer4-1":
      case "QAnswer4-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop");
        break;
      case "QAnswer5-1":
      case "QAnswer5-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop-multi");
        break;
      default:
        break;
    }

    return (
      <>
        <Descriptions.Item className="question-wrapper" label="Question">
          <FormInput
            type="textarea"
            name={[...prefixName, "question", "texts", 0]}
            label="Text"
            latexString={slide.question.texts?.length ? (slide.question.texts[0] as string) : ""}
          />

          <FormInput
            type="textarea"
            key="audioText-question"
            label="AudioText"
            name={[...prefixName, "question", "audioText"]}
            latexString={slide.question.audioText || ""}
          />
          <FormSelect
            label="VoiceCode"
            name={[...prefixName, "question", "audioTextVoiceCode"]}
            optionData={VoiceCodeFormOptions}
            callback={handleChangeQuestionVoiceCode}
          />

          <FormInput
            type="textarea"
            key="audioLink-question"
            label="Custom AudioLink"
            name={[...prefixName, "question", "audioLink"]}
            latexString={slide.question.audioLink || ""}
          />

          {question}
          <FormSingleFileInput
            name={[...prefixName, "question", "backgroundImage"]}
            label="Background image"
            initValue={slide.question.backgroundImage || ""}
            handleUpload={handleLoadImage}
            handleRemove={handleRemoveImage}
            folder={folder}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Solution">{solution}</Descriptions.Item>
      </>
    );
  };

  const generateChoices = () => {
    removeQuestion();

    setNumberChoices(Number(getFieldValue("numberChoices")));
    setTargetNumber(Number(getFieldValue("targetNumber")));
    if (selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") {
      mergeToSlide({ question: { targets: Array(Number(getFieldValue("targetNumber"))).fill("") } });
    }
    const questionType = form.getFieldValue([...prefixName, "template", "question"]);
    if (questionType?.includes("Gapfill")) {
      mergeToSlide({ question: { choices: Array(Number(getFieldValue("numberChoices"))).fill("") } });
    }
  };

  const generateTargets = () => {
    const res = Array(targetNumber)
      .fill(0)
      .map((_, i) => (
        <Form.Item
          key={`target-${i}-${targetNumber}`}
          label={`Target ${i + 1}`}
          name={[...prefixName, "question", "targets", i]}
        >
          <SingleFileInput
            inputId={[...prefixName, "question", "targets", i].toString()}
            onData={(data: FileData) => handleLoadImage([...prefixName, "question", "targets", i], data)}
            initValue={slide.question.targets?.length ? slide.question.targets[i] : ""}
            folder={folder}
          />
        </Form.Item>
      ));
    return res;
  };

  useEffect(() => {
    const initSlide = (slideData = slide) => {
      // set Template
      const template = slideData.template;

      if (template.question) {
        setSlideType(SlideType.Question);
        setFieldValue("slideType", SlideType.Question);

        isVertical.current = template.question.includes(SlideQuestionTemplate.Vertical);
        if (isVertical.current) {
          // case hasn't prompt
          setTemplateOption(SlideQuestionTemplate.Vertical);
          setFieldValue("templateOption", SlideQuestionTemplate.Vertical);
        } else {
          // case has prompt
          setTemplateOption(SlideQuestionTemplate.Horizontal);
          setFieldValue("templateOption", SlideQuestionTemplate.Horizontal);
          // set Prompt template
          const promptType = template.content.slice(0, 8);
          setFieldValue("promptType", promptType);
          setPromptOptions(PromptTypeOptions[promptType]);
          setSelectedPromptOption(template.content);
          setFieldValue(["template", "content"], template.content);
          mergeToSlide({ template: { content: template.content } });
        }
        // set Question
        const templateQuestion = isVertical.current
          ? template.question.slice(SlideQuestionTemplate.Vertical.length)
          : template.question;
        setFieldValue(["template", "question"], templateQuestion);
        const questionType = templateQuestion?.includes("DragDrop")
          ? QuestionTypeConfigs[1].value
          : QuestionTypeConfigs[0].value;
        setFieldValue("questionType", questionType);
        setTypeOptions(TypeOptions[questionType]);

        setExtraOptions(QDetailOptions[templateQuestion]);
        let questionHasImages = FILE_EXTENSION_SUPPORT.some((item) =>
          JSON.stringify(slideData.question).includes(item)
        );
        if (slideData.question.targets?.length)
          questionHasImages = !FILE_EXTENSION_SUPPORT.some((item) =>
            JSON.stringify(slideData.question.targets).includes(item)
          );
        const extraOption = templateQuestion ? QDetailOptions[templateQuestion][questionHasImages ? 1 : 0].value : "";
        setSelectedExtraOption(extraOption);
        setFieldValue("extraOptions", extraOption);

        // set Number choices + target choices
        setTargetNumber(slideData.question.targets?.length);
        setFieldValue("targetNumber", slideData.question.targets?.length);
        const numberChoices = slideData.template.question.includes("Gapfill")
          ? slideData.question.solutions?.length
          : slideData.question.choices?.length;
        setNumberChoices(numberChoices);
        setFieldValue("numberChoices", numberChoices);
        // set Choices
        setFieldValue(["question", "texts"], slideData.question?.texts);
        slideData.question.choices?.forEach((item: string, index: number) => {
          setFieldValue(["questions", index], item);
        });

        // set Solutions
        switch (extraOption) {
          case "QAnswer1-1":
          case "QAnswer1-2":
            const correctIndex = (slideData.question.solutions as boolean[]).findIndex((item: boolean) => item);
            if (correctIndex !== -1) {
              setFieldValue(["question", "solutions"], correctIndex);
            }
            break;
          case "QAnswer2-1":
          case "QAnswer2-2":
            let correctIndexes: number[] = [];
            (slideData.question.solutions as boolean[]).filter((item: boolean, index: number) => {
              if (item) correctIndexes.push(index);
              return item;
            });
            setFieldValue(["question", "solutions"], correctIndexes);
            break;
          case "QAnswer3-1":
          case "QAnswer3-2":
            (slideData.question.solutions as string[]).forEach((item: string, index: number) =>
              setFieldValue(["question", "solutions", index], item)
            );
            break;
          case "QAnswer4-1":
          case "QAnswer4-2":
            // solution = Object.values(solutions).map((item) => Number(item));
            break;
          default:
            break;
        }

        // set Explanation
        slideData.explanation.texts?.forEach((item: string, index: number) =>
          setFieldValue(["explanation", "texts", index], item)
        );
        slideData.explanation.images?.forEach((item: string, index: number) => {
          setFieldValue(["explanation", "images", index], item);
        });
      } else {
        setSlideType(SlideType.Content);
        setFieldValue("slideType", SlideType.Content);
        setTemplateOption(template.content);
        setFieldValue("templateOption", template.content);
        if (template.content === SlideContentTemplate.MainContent11Mixed) {
          setMixedContentLength(() => slideData.content.texts?.length || 1);
        }
      }

      // set Contents
      slideData.content.texts?.forEach((item: string, index: number) =>
        setFieldValue(["content", "texts", index], item)
      );
      slideData.content.images?.forEach((item: string, index: number) => {
        setFieldValue(["content", "images", index], item);
      });
      slideData.content.video?.forEach((item: string, index: number) => {
        setFieldValue(["content", "video", index], item);
      });
    };
    !isEqual(omit(slide, ["slideKey"]), omit(INITIAL_SLIDE_STATE, ["slideKey"])) && initSlide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Descriptions key={`Slide-${index}`} column={1}>
      <Descriptions.Item label="Slide info">
        <FormInput name={[...prefixName, "title"]} label="Slide title" latexString={slide.title} />
        <FormSingleFileInput
          name={[...prefixName, "backgroundImage"]}
          label="Background"
          initValue={slide.backgroundImage || ""}
          handleUpload={handleLoadImage}
          handleRemove={handleRemoveImage}
          folder={folder}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Slide Template">
        <FormSelect
          label="Slide type"
          name={[...prefixName, "slideType"]}
          optionData={enumToArray(SlideType)}
          callback={handleChangeSlideType}
          rules={[{ required: true, message: "Invalid field" }]}
        />
        <FormSelect
          label="Template option"
          name={[...prefixName, "templateOption"]}
          optionData={enumToArray(
            slideType === SlideType.Content
              ? SlideContentTemplate
              : slideType === SlideType.Question
              ? SlideQuestionTemplate
              : []
          )}
          callback={handleChangeTemplateOption}
          rules={[{ required: true, message: "Invalid field" }]}
        />
        {templateOption === SlideQuestionTemplate.Vertical && (
          <FormSingleFileInput
            name={[...prefixName, "content", "backgroundImage"]}
            label="Content background"
            initValue={slide.content.backgroundImage || ""}
            handleUpload={handleLoadImage}
            handleRemove={handleRemoveImage}
            folder={folder}
          />
        )}
      </Descriptions.Item>
      {slideType === SlideType.Question && (
        <div className="update-question">
          <Descriptions column={1}>
            {templateOption && templateOption !== SlideQuestionTemplate.Vertical && (
              <Descriptions.Item label="Temp. Content">
                <FormSelect
                  label="Type"
                  name={[...prefixName, "promptType"]}
                  optionData={PromptTypes}
                  callback={handleChangePromptType}
                />
                {promptOptions && (
                  <FormSelect
                    label="Option"
                    name={[...prefixName, "template", "content"]}
                    optionData={promptOptions}
                    callback={handleChangePromptTypeOption}
                    rules={[{ required: true, message: "Invalid field" }]}
                  />
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Temp. Question">
              <FormSelect
                label="Type"
                name={[...prefixName, "questionType"]}
                optionData={
                  templateOption === SlideQuestionTemplate.Vertical
                    ? QuestionTypeConfigs.slice(0, -1)
                    : QuestionTypeConfigs
                }
                callback={handleChangeQuestionType}
                rules={[{ required: true, message: "Invalid field" }]}
              />
              {typeOptions && (
                <FormSelect
                  label="Option"
                  name={[...prefixName, "template", "question"]}
                  optionData={typeOptions}
                  callback={handleChangeTypeOption}
                  rules={[{ required: true, message: "Invalid field" }]}
                />
              )}
              {extraOptions && (
                <FormSelect
                  label="Detail"
                  name={[...prefixName, "extraOptions"]}
                  optionData={
                    templateOption === SlideQuestionTemplate.Vertical ? extraOptions.slice(0, -1) : extraOptions
                  }
                  callback={handleChangeExtraOption}
                  rules={[{ required: true, message: "Invalid field" }]}
                />
              )}
            </Descriptions.Item>
            {selectedPromptOption && generatePrompt(selectedPromptOption)}
            {["QAnswer4-1", "QAnswer5-1"].some((item) => item === selectedExtraOption) && (
              <>
                <Descriptions.Item label="Target choices">
                  <FormInput
                    label="Target num"
                    type="number"
                    value={targetNumber}
                    name={[...prefixName, "targetNumber"]}
                    rules={[
                      { required: true, message: "Please enter a value" },
                      {
                        validator(_, value) {
                          let range = [2, 4];
                          if (Number(value) <= range[1] && Number(value) >= range[0]) {
                            setGenerateDisable(false);
                            return Promise.resolve();
                          } else {
                            setGenerateDisable(true);
                            return Promise.reject("Invalid field");
                          }
                        },
                      },
                    ]}
                  />
                </Descriptions.Item>
                {targetNumber && (selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") && (
                  <Descriptions.Item label="Target">{generateTargets()}</Descriptions.Item>
                )}
              </>
            )}
            {selectedExtraOption && (
              <Descriptions.Item label="Number choices">
                <Form.Item
                  label="Choices"
                  name={[...prefixName, "numberChoices"]}
                  rules={[
                    { required: true, message: "Please enter a value" },
                    {
                      validator(_, value) {
                        if (!isInteger(Number(value))) {
                          return Promise.reject("Invalid field");
                        }
                        const targetNumber = getFieldValue("targetNumber");
                        let range = [2, 6];
                        if (slide.template.question.includes("Gapfill")) {
                          range[0] = 1;
                        }
                        if (selectedExtraOption === "QAnswer4-2" || selectedExtraOption === "QAnswer5-2") {
                          range = [2, 4];
                        }
                        if (
                          selectedExtraOption &&
                          Number(value) <= range[1] &&
                          Number(value) >= range[0] &&
                          (!targetNumber ||
                            (Number(targetNumber) && Number(value) >= Number(targetNumber)) ||
                            slide.template.question === "DragDropMulti")
                        ) {
                          setGenerateDisable(false);
                          return Promise.resolve();
                        } else {
                          setGenerateDisable(true);
                          return Promise.reject("Invalid field");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    value={numberChoices}
                    type="number"
                    placeholder="Enter a number"
                    addonAfter={
                      <Button disabled={generateDisable} type="primary" onClick={generateChoices}>
                        Generate questions
                      </Button>
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
            )}
            {numberChoices && (
              <>
                {selectedExtraOption && generateQuestionAndSolution(selectedExtraOption)}
                <Descriptions.Item label="Explanation" className="explain-wrapper">
                  <span className="texts">
                    {explanationTexts.map((_, index) => (
                      <FormInput
                        type="textarea"
                        key={`text-explain-${index}`}
                        label={`Text ${index + 1}`}
                        name={[...prefixName, "explanation", "texts", index]}
                        latexString={slide.explanation.texts?.length ? (slide.explanation.texts[0] as string) : ""}
                      />
                    ))}
                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainTexts} />
                      <Button
                        style={explanationTexts.length > 1 ? { display: "block" } : { display: "none" }}
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainTexts}
                      />
                    </span>
                  </span>

                  <FormInput
                    type="textarea"
                    key="audioText-explain"
                    label="AudioText"
                    name={[...prefixName, "explanation", "audioText"]}
                    latexString={slide.explanation.audioText || ""}
                  />
                  <FormSelect
                    label="VoiceCode"
                    name={[...prefixName, "explanation", "audioTextVoiceCode"]}
                    optionData={VoiceCodeFormOptions}
                    callback={handleChangeExplanationVoiceCode}
                  />

                  <FormInput
                    type="textarea"
                    key="audioLink-explain"
                    label="Custom AudioLink"
                    name={[...prefixName, "explanation", "audioLink"]}
                    latexString={slide.explanation.audioLink || ""}
                  />

                  <span className="images">
                    {explanationImages.map((_, index) => (
                      <FormSingleFileInput
                        key={`image-explain-${index}-${numberChoices}`}
                        label={`Image ${index + 1}`}
                        name={[...prefixName, "explanation", "images", index]}
                        initValue={slide.explanation.images?.length ? slide.explanation.images[index] : ""}
                        handleUpload={handleLoadImage}
                        handleRemove={handleRemoveImage}
                        folder={folder}
                      />
                    ))}
                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainImages} />
                      <Button
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainImages}
                        style={explanationImages.length > 1 ? { display: "block" } : { display: "none" }}
                      />
                    </span>
                  </span>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </div>
      )}
      {slideType === SlideType.Content && templateOption && (
        <div className="content-type">{generateContent(templateOption)}</div>
      )}
    </Descriptions>
  );
};
