export interface SelectOption {
  label: string;
  value: string;
  type?: number;
}

export const PromptTypes = [
  { label: "QPrompt1", value: "QPrompt1" },
  { label: "QPrompt2", value: "QPrompt2" },
  { label: "QPrompt3", value: "QPrompt3" },
];

export const PromptTypeOptions: { [key: string]: SelectOption[] } = {
  QPrompt1: [
    { label: "QPrompt1_1", value: "QPrompt1_1" },
    { label: "QPrompt1_2", value: "QPrompt1_2" },
    { label: "QPrompt1_3", value: "QPrompt1_3" },
  ],
  QPrompt2: [
    { label: "QPrompt2_1", value: "QPrompt2_1" },
    { label: "QPrompt2_2", value: "QPrompt2_2" },
    { label: "QPrompt2_3", value: "QPrompt2_3" },
    { label: "QPrompt2_4", value: "QPrompt2_4" },
  ],
  QPrompt3: [
    { label: "QPrompt3_1", value: "QPrompt3_1" },
    { label: "QPrompt3_2", value: "QPrompt3_2" },
  ],
};

export const QuestionTypeConfigs: SelectOption[] = [
  { label: "Answer", value: "QAnswer" },
  { label: "DragDrop", value: "QDragDrop" },
];

export const TypeOptions: { [key: string]: SelectOption[] } = {
  QAnswer: [
    { label: "Multiple choices", value: "MultipleChoice" },
    { label: "Multiple responses", value: "MultipleResponse" },
    { label: "Gap fill", value: "Gapfill" },
  ],
  QDragDrop: [
    { label: "Single", value: "DragDrop" },
    { label: "Multiple", value: "DragDropMulti" },
  ],
};

export const QDetailOptions: { [key: string]: SelectOption[] } = {
  MultipleChoice: [
    { label: "Texts", value: "QAnswer1-1" },
    { label: "Images", value: "QAnswer1-2" },
  ],
  MultipleResponse: [
    { label: "Texts", value: "QAnswer2-1" },
    { label: "Images", value: "QAnswer2-2" },
  ],
  Gapfill: [
    { label: "Texts", value: "QAnswer3-1" },
    { label: "Images", value: "QAnswer3-2" },
  ],
  DragDrop: [
    { label: "Matching", value: "QAnswer4-1" },
    { label: "Ordering", value: "QAnswer4-2" },
  ],
  DragDropMulti: [
    { label: "Matching", value: "QAnswer5-1" },
    { label: "Ordering", value: "QAnswer5-2" },
  ],
};
