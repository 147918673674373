import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Card, Modal, Space, Table, Tooltip, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { STATUS_OPTIONS } from "../../constants/filter";
import { IExamFilter } from "../../interfaces/filter";
import { convertStringToDate } from "../../utils/utils";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { getExams, summarizeExam } from "../../services/ExamServices";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { ExamType } from "../../configs/examConfigs";
import { FlowCategory } from "../../constants/flow";

const MonthExamPortal = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const [examList, setExamList] = useState<any[]>([]);

  const tableRef = useRef<any>();
  const filterData = useRef<IExamFilter>({
    page: 1,
    size: 50,
    type: ExamType.Monthly,
    status: FlowCategory.Published.toString(),
  });
  const tableTotal = useRef<number>();

  const getListMonthExams = async () => {
    const response = await getExams(filterData.current);
    if (response) {
      setExamList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListMonthExams();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListMonthExams();
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Grade",
        dataIndex: "grades",
        key: "gradeName",
        align: "center",
        width: 150,
        render: (grades) => {
          const listGrades = grades.map((item: any) => item.name);
          return listGrades.join(",");
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
        width: 150,
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
        width: 150,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
        width: 150,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.SiteAdmin) && (
              <>
                <Space size="middle">
                  <ConfirmButton
                    title="Summarize"
                    tooltip={true}
                    icon={<CheckOutlined />}
                    onConfirm={() => handleSummarizeExam(record._id)}
                  />
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const handleSummarizeExam = async (id: string) => {
    try {
      const res = await summarizeExam(id);
      if (res) {
        Modal.success({
          title: "Success",
          type: "success",
          content: "Updated successfully",
        });
        getListMonthExams();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      await getListMonthExams();
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <Card title={"Month Exam"}>
      {contextHolder}
      <div className="month-exam-portal-container">
        <div className="table-container">
          <Table
            dataSource={examList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </Card>
  );
};

export default MonthExamPortal;
