/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloseCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Modal, Row, TimePicker } from "antd";
import { NamePath } from "antd/es/form/interface";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import { FormSingleFileInput } from "../../../components/Form/SingleFileInput";
import Loading from "../../../components/Loading/Loading";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";
import { FileData } from "../../../components/UploadInput/UploadInput";
import { MONTHLY_MISSION_STATUSES } from "../../../configs/monthlyMissionConfigs";
import { UserRole } from "../../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { useAuth } from "../../../hooks/useAuth";
import { IMonthlyMission } from "../../../interfaces/IMonthlyMission";
import { addMonthlyMission, editMonthlyMission, getMonthlyMissionById } from "../../../services/MonthlyMissionServices";
import { datePickerToDateFormat } from "../../../utils/utils";
import { MONTHLY_MISSION_IMAGES_TITLE } from "./configs";
import "./UpdateMonthlyMission.scss";

const UpdateMonthlyMission = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const monthlyMissionId = useRef<string>(params.id || "");
  const [duration, setDuration] = useState<string[]>([]);
  const [imageList, setImageList] = useState<string[]>(MONTHLY_MISSION_IMAGES_TITLE);
  const [folder, setFolder] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      const formValues = form.getFieldsValue();
      const [startHourMin, endHourMin] = [formValues.Hm[0]?.$d, formValues.Hm[1]?.$d];
      const startDate = dayjs(
        `${duration[0]}T${startHourMin.getHours()}:${startHourMin.getMinutes()}`,
        "DD-MM-YYYYTHH:mm"
      )
        .utc()
        .format();
      const endDate = dayjs(`${duration[1]}T${endHourMin.getHours()}:${endHourMin.getMinutes()}`, "DD-MM-YYYYTHH:mm")
        .utc()
        .format();
      const eventData: IMonthlyMission = {
        name: formValues.name,
        description: formValues.description,
        startDate,
        endDate,
        status: Number(formValues.status),
        monsterName: formValues.monsterName,
        imageUrls: formValues.imageUrls,
        startingHp: Number(formValues.startingHp),
      };

      let response: any;
      if (monthlyMissionId.current) {
        response = await editMonthlyMission(monthlyMissionId.current, eventData);
      } else {
        response = await addMonthlyMission(eventData);
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${monthlyMissionId.current ? "Updated" : "Added"} successfully`,
      });
      monthlyMissionId.current = response.result ? response.result : monthlyMissionId.current;
    } catch (error) {
      console.log("error:", error);
    }
  };


  const handleLoadImage = (name: NamePath, imageData: FileData) => {
    form.setFieldValue(name, imageData?.fileLink);
  };

  const handleClearImage = (name: NamePath) => {
    form.setFieldValue(name, "");
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (monthlyMissionId.current) {
        const res = (await getMonthlyMissionById(monthlyMissionId.current)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          form.setFieldsValue(res.result);
          form.setFields([
            { name: "Hm", value: [dayjs(res.result.startDate), dayjs(res.result.endDate)] },
          ]);
          setDuration([datePickerToDateFormat(res.result.startDate), datePickerToDateFormat(res.result.endDate)]);
          // setImageList(() => res.result.imageUrls || [""]);
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-monthly-mission-container">
      <Form form={form} onFinish={handleSave}>
        <div className="form-input" style={{ display: "grid", gap: "16px" }}>
          <Card title="Monthly Mission settings">
            <Row>
              <Col xs={12} md={12} xl={12} xxl={6} flex={1}>
                <FormInput
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                  onChange={(e) => setFolder(e ? `monthly-mission/${e}` : undefined)}
                />
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6} flex={1}>
                <FormSelect
                  label="Status"
                  name="status"
                  optionData={MONTHLY_MISSION_STATUSES}
                  allowClear
                  rules={[{ required: true }]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} xl={12} xxl={6}>
                <Form.Item label="Duration" name="duration" rules={[{ required: true }]}>
                  <DatePicker.RangePicker
                    allowClear
                    defaultValue={
                      !duration[0] && !duration[1]
                        ? null
                        : [dayjs(duration[0], "DD/MM/YYYY"), dayjs(duration[1], "DD/MM/YYYY")]
                    }
                    format={["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]}
                    onChange={(_, date) => setDuration(date)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <Form.Item label="Hour&Min" name="Hm" rules={[{ required: true, message: "Please select exam time" }]}>
                  <TimePicker.RangePicker allowClear format={["HH:mm"]} />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <FormInput label="Description" name="description" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Images" style={{ marginBottom: "unset", marginTop: "8px" }}>
                  <span className="images">
                    {imageList.map((title, index: number) => (
                      <FormSingleFileInput
                        key={index}
                        label={title}
                        name={["imageUrls", index]}
                        initValue={form.getFieldValue(["imageUrls", index])}
                        handleUpload={handleLoadImage}
                        handleRemove={handleClearImage}
                        folder={folder}
                      />
                    ))}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <NoteText style={{ margin: "4px 0 4px 100px" }} text={NoteMessage.ReUploadImageAfterChangeEventName} />
          </Card>

          <Card title="Monster settings">
            <Row>
              <Col xs={12} md={12} xl={12}>
                <FormInput label="Name" name="monsterName" rules={[{ required: true }]} />
              </Col>
            </Row>
            <Row className="last-row">
              <Col xs={6} md={6} xl={6}>
                <FormInput type="number" label="Starting HP" name="startingHp" rules={[{ required: true }]} />
              </Col>
            </Row>
          </Card>
          <div className="form-controller">
            <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
              Save
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => navigate(PageUrl.MonthlyMission)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UpdateMonthlyMission;
