import instance, { apiConfig } from "./instance";
import { IRoadMapFilter } from "../interfaces/filter";

const roadMapUrl = "/practice/v1/admin/road-map";

export const getRoadMaps = (postData: IRoadMapFilter) => {
  return instance.get(`${roadMapUrl}/get-list-road-maps`, apiConfig(postData));
};

export const getRoadMapById = (id: string) => {
  return instance.get(`${roadMapUrl}/get-a-road-map/${id}`, apiConfig());
};

export const addRoadMap = (data: any) => {
  return instance.put(`${roadMapUrl}/create-new-road-map`, data, apiConfig());
};

export const editRoadMap = (id: string, data: any): Promise<any> => {
  return instance.post(`${roadMapUrl}/edit-a-road-map/${id}`, data, apiConfig());
};

export const activateARoadMap = (id: string) => {
  return instance.post(`${roadMapUrl}/activate-a-road-map/${id}`, {}, apiConfig());
};

export const deactivateARoadMap = (id: string) => {
  return instance.post(`${roadMapUrl}/deactivate-a-road-map/${id}`, {}, apiConfig());
};

export const validateARoadMap = (id: string) => {
  return instance.get(`${roadMapUrl}/validate-a-road-map/${id}`, apiConfig());
};

export const getAnExam = (examId: string, roadMapId: string) => {
  return instance.get(`${roadMapUrl}/get-an-exam/${roadMapId}/${examId}`, apiConfig());
};

export const addExam = (roadMapId: string, data: any) => {
  return instance.post(`${roadMapUrl}/create-new-exam/${roadMapId}`, data, apiConfig());
};

export const editExam = (examId: string, roadMapId: string, data: any): Promise<any> => {
  return instance.post(`${roadMapUrl}/edit-an-exam/${roadMapId}/${examId}`, data, apiConfig());
};

export const deleteExam = (examId: string, roadMapId: string): Promise<any> => {
  return instance.post(`${roadMapUrl}/delete-an-exam/${roadMapId}/${examId}`, undefined, apiConfig());
};

export const editExamBranches = (examId: string, roadMapId: string, data: any): Promise<any> => {
  return instance.post(`${roadMapUrl}/edit-an-exam-branches/${roadMapId}/${examId}`, data, apiConfig());
};
