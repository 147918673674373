import React from "react";
import { Form, Input, Button } from "antd";
import { useAppDispatch } from "../../hooks/hooks";
import { signin } from "../../reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { PageUrl } from "../../constants/url";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSignin = async (account: { userName: string; password: string }) => {
    dispatch(signin(account)).then((res) => {
      if (res.type.includes("fulfilled")) {
        navigate(PageUrl.Questions);
      }
    });
  };

  return (
    <div style={{ backgroundColor: "#f0f2f5", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Form name="normal_login" className="login-form" onFinish={onSignin} style={{ width: 300 }}>
          <h2 style={{ textAlign: "center", marginBottom: 20 }}>Admin Login</h2>
          <Form.Item name="userName" rules={[{ required: true, message: "Please input your Username!" }]}>
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: "100%" }}>
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
