import { Form, Row, Col, Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import "./UpdateCalibration.scss";
import Card from "antd/es/card/Card";
import {
  CloseCircleOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  editCalibration,
  addCalibration,
  getCalibrationById,
  activeCalibration,
  inactiveCalibration,
} from "../../../services/CalibrationServices";

const UpdateCalibration = () => {
  const params = useParams();
  const [calibrationId, setCalibrationId] = useState<string>(params.id || "");
  const { user } = useAuth()!;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveCalibration = () => {
    try {
      form.validateFields().then(async () => {
        const formValues = form.getFieldsValue();
        formValues.eloRange = formValues.eloRange.map((e: any) => parseInt(e));
        let response: any;
        if (calibrationId) {
          response = await editCalibration(calibrationId, { ...formValues });
        } else {
          response = await addCalibration(formValues);
        }
        Modal.success({
          title: "Success",
          type: "success",
          content: `${calibrationId ? "Updated" : "Added"} successfully`,
        });
        setCalibrationId(response.result ? response.result : calibrationId);
      });
    } catch (error) {}
  };

  const handleActivateCalibration = async (calibrationId: string) => {
    const response = await activeCalibration(calibrationId);
    if (response) {
      Modal.success({
        title: "Success",
        type: "success",
        content: "Activated successfully!",
      });
    } else {
      Modal.error({
        title: "Error",
        content: "Failed to deactivate calibration",
      });
    }
  };

  const handleDeactivateCalibration = async (calibrationId: string) => {
    const response = await inactiveCalibration(calibrationId);
    if (response) {
      Modal.success({
        title: "Success",
        type: "success",
        content: "Inactivated successfully!",
      });
    } else {
      Modal.error({
        title: "Error",
        content: "Failed to inactivate calibration",
      });
    }
  };

  const genQuestionSets = () => {
    const questionSet = [1, 2, 3];
    const question = [1, 2, 3, 4, 5];
    return (
      <div className="question-sets-container">
        {questionSet.map((set) => (
          <div key={set} className="question-set">
            <h4>Question Set {set}</h4>
            {question.map((q) => (
              <FormInput
                key={q}
                label={`Q${q}`}
                name={["questionSets", set - 1, q - 1]}
                rules={[{ required: true, message: "Please enter a question" }]}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const getCalibration = async () => {
      const response = await getCalibrationById(calibrationId);
      if (response) {
        const data = (response as any).result;
        const mapQuestionSets = data.questionSets?.map((set: any) => {
          return set.map((q: any) => q.questionId);
        });
        form.setFieldsValue({
          name: data.name,
          eloRange: data.eloRange,
          questionSets: mapQuestionSets,
        });
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      calibrationId && (await getCalibration());
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calibrationId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Card title="Calibration">
        <div className="update-calibration-container">
          <Form form={form} onFinish={handleSaveCalibration}>
            <div className="form-input">
              <Row style={{ width: "1200px" }}>
                <Col xs={6} md={6}>
                  <FormInput label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]} />
                </Col>
                <Col xs={6} md={6}>
                  <FormInput
                    type="number"
                    label="Elo from"
                    name={["eloRange", 0]}
                    rules={[{ required: true, message: "Please enter a number" }]}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <FormInput
                    type="number"
                    label="Elo to"
                    name={["eloRange", 1]}
                    rules={[{ required: true, message: "Please enter a number" }]}
                  />
                </Col>
              </Row>
              {genQuestionSets()}
            </div>
          </Form>
          <div className="calibration-control">
            <Button
              type="primary"
              icon={calibrationId ? <SaveOutlined /> : <PlusCircleOutlined />}
              style={{ backgroundColor: "#2abb2a" }}
              onClick={handleSaveCalibration}
            >
              {calibrationId ? "Update" : "Add"}
            </Button>
            {calibrationId && (
              <>
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={() => handleActivateCalibration(calibrationId)}
                >
                  Active
                </Button>
                <Button
                  type="dashed"
                  danger
                  icon={<DownloadOutlined />}
                  onClick={() => handleDeactivateCalibration(calibrationId)}
                >
                  Inactive
                </Button>
              </>
            )}
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Calibration)}>
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UpdateCalibration;
