import instance, { apiConfig } from "./instance";
import { IQuizFilter } from "../interfaces/filter";

const quizUrl = "/practice/v1/admin/quiz";

export const getQuizzes = (postData: IQuizFilter) => {
  return instance.get(`${quizUrl}/get-list-quizzes`, apiConfig(postData));
};

export const getQuizById = (id: string) => {
  return instance.get(`${quizUrl}/get-a-quiz/${id}`, apiConfig());
};

export const getQuizDetailByQuizId = (quizId: string) => {
  return instance.get(`${quizUrl}/get-a-quiz/${quizId}`, apiConfig());
};

export const addQuiz = (data: any) => {
  return instance.put(`${quizUrl}/create-new-quiz`, data, apiConfig());
};

export const editQuiz = (id: string, data: any): Promise<any> => {
  return instance.post(`${quizUrl}/edit-a-quiz/${id}`, data, apiConfig());
};

export const publishQuizzes = (listId: string[]) => {
  return instance.post(`${quizUrl}/publish-quizzes`, listId, apiConfig());
};

export const unPublishQuizzes = (listId: string[]) => {
  return instance.post(`${quizUrl}/unpublish-quizzes`, listId, apiConfig());
};

export const rejectQuizzes = (listId: string[]) => {
  return instance.post(`${quizUrl}/reject-quizzes`, listId, apiConfig());
};

export const submitQuizzes = (listId: string[]) => {
  return instance.post(`${quizUrl}/submit-quizzes`, listId, apiConfig());
};
