import { Form, Input, Space, Tooltip } from "antd";
import { Rule } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import TextArea from "antd/es/input/TextArea";
import { stringToLatex } from "../../utils/latexPreview";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

type FormInputProps = {
  label: string;
  name: NamePath;
  type?: string;
  rules?: Rule[];
  prefix?: string;
  rows?: number;
  allowClear?: boolean;
  colon?: boolean;
  latexString?: string;
  value?: string | number | readonly string[];
  disabled?: boolean;
  onChange?: (e: string) => void;
  readonly?: boolean;
};

export const FormInput = ({
  label,
  name,
  type,
  value,
  rules,
  prefix,
  rows,
  allowClear,
  colon,
  latexString,
  disabled,
  onChange,
  readonly,
}: FormInputProps) => {
  const latex = latexString ? stringToLatex(latexString) : "";
  return (
    <span style={{ position: "relative" }}>
      {latex && (
        <Tooltip color={latex.type === "success" ? "green" : "red"} title={latex.content}>
          <Space
            className="latex-preview"
            style={{ cursor: "pointer", columnGap: "8px", position: "absolute", top: "-24px", left: "145px" }}
          >
            <span>
              <label style={{ fontSize: "12px" }}>Latex&nbsp;</label>
              {latex.type === "success" ? (
                <CheckCircleFilled style={{ color: "green" }} />
              ) : (
                <CloseCircleFilled style={{ color: "red" }} />
              )}
            </span>
          </Space>
        </Tooltip>
      )}
      <Form.Item label={label} name={name} rules={rules} colon={colon}>
        {type === "textarea" ? (
          <TextArea
            rows={rows || 1}
            placeholder="Enter a value"
            value={value}
            autoComplete="off"
            onChange={(e) => onChange && onChange(e.target.value)}
            disabled={disabled}
            readOnly={readonly}
          />
        ) : (
          <Input
            type={type}
            placeholder="Enter a value"
            value={value}
            prefix={prefix}
            prefixCls="prefix-input"
            autoComplete="off"
            onChange={(e) => onChange && onChange(e.target.value)}
            allowClear={allowClear}
            disabled={disabled}
            readOnly={readonly}
          />
        )}
      </Form.Item>
    </span>
  );
};
