import { CopyObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";
import { FlowCategory } from "../../../constants/flow";
import { getLessonById, rejectLessons, editLesson, getLessons } from "../../../services/LessonServices";
import { sendCommandToS3 } from "../../../services/s3Services";
import { editQuestion, getQuestionById, getQuestions, rejectQuestions } from "../../../services/QuestionServices";

const regexImage = /admin\/[^"]+/g;

const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

const extractFileNameAndExt = (str: string) => {
  if (str.includes(".")) return [str.slice(0, str.lastIndexOf(".")), str.slice(str.lastIndexOf(".") + 1, str.length)];
  return ["", ""];
};

const replaceImageFolderS3 = async (source: string, target: string) => {
  await sendCommandToS3(
    new CopyObjectCommand({
      CopySource: encodeURIComponent(`/${bucketName}/${source}`),
      Bucket: bucketName,
      Key: target,
    })
  ).then((res) => {
    console.log("res:", res);
  });
};

const replaceS3Data = (sourceImages: string[], targetImages: string[]) => {
  if (sourceImages?.length) {
    sourceImages.forEach(async (sourceImage, index) => await replaceImageFolderS3(sourceImage, targetImages[index]));
  }
};

const getAndConvertImages = (lesson: any, gradeName: string, unitName: string, createdAt: number) => {
  const images = JSON.stringify(lesson).match(regexImage);
  const convertedImages = images?.map((image) => {
    let prefix, fileName;
    let simplifiedString;
    if (image.includes("/")) {
      const parts = image.split("/");
      prefix = parts[0];
      const nameParts = extractFileNameAndExt(parts[parts.length - 1]);

      fileName = `${nameParts[0]?.replaceAll(`-${createdAt}`, "")}-${createdAt}.${nameParts[1]}`;
    }
    if (gradeName) {
      simplifiedString = `${prefix}/lesson/${gradeName}/${fileName}`;
      if (unitName) {
        simplifiedString = `${prefix}/lesson/${gradeName}/${unitName}/${fileName}`;
      }
    }

    return simplifiedString;
  });

  return convertedImages;
};

const replaceLesson = (lesson: any, replacementArray: string[]) => {
  return JSON.stringify(lesson).replace(regexImage, (match) => {
    const replacement = replacementArray.shift();
    return replacement !== undefined ? replacement : match;
  });
};

const optimizeLesson = (l: any) => {
  const lesson = JSON.parse(l);
  lesson.gradeId = lesson.grade.id;
  lesson.unitId = lesson.unit.id;
  delete lesson._id;
  delete lesson.grade;
  delete lesson.unit;
  delete lesson.recordInfo;
  return { ...lesson };
};

const handleLesson = async (id: string, gradeName: string, newUnitName: string) => {
  try {
    const res = (await getLessonById(id)) as any;
    if ([FlowCategory.Published, FlowCategory.Reviewing, FlowCategory.UnPublished].includes(res.result?.status)) {
      await rejectLessons([id]);
    }
    const convertedImages = getAndConvertImages(
      res.result,
      gradeName,
      newUnitName,
      Math.floor(new Date(res.result.recordInfo.createdAt).getTime() / 1000)
    );
    replaceS3Data(JSON.stringify(res.result).match(regexImage) || [], convertedImages as string[]);
    const replacedLesson = replaceLesson(res.result, convertedImages as string[]);
    await editLesson(id, optimizeLesson(replacedLesson));
  } catch (error) {
    console.log("error:", error);
  }
};

export const handleUpdateLessons = async (gradeId: string, gradeName: string, unitId: string, newUnitName: string) => {
  await getLessons({
    page: 1,
    size: 10000,
    sort: "recordInfo.createdAt,desc",
    gradeId,
    unitId,
  }).then((res: any) => {
    const listIds = res.result?.records?.map((item: any) => item._id);
    listIds.forEach((lessonId: string, index: number) =>
      setTimeout(() => handleLesson(lessonId, gradeName, newUnitName), index * 100)
    );
  });
};

// handle change images in unit
const getS3Objects = (prefix: string) => {
  return new Promise(async (resolve, reject) => {
    await sendCommandToS3(
      new ListObjectsV2Command({
        Bucket: bucketName,
        Prefix: prefix,
        MaxKeys: 100000,
      })
    )
      .then(async (res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const handleUnitImages = async (
  oldGradeName: string,
  oldUnitName: string,
  newGradeName: string,
  newUnitName: string
) => {
  const unitImages = (await getS3Objects(`admin/${oldGradeName}/${oldUnitName}/common/`)) as any;
  if (unitImages?.Contents?.length) {
    unitImages?.Contents?.forEach(async (image: any) => {
      let newName = image.Key.replace(oldUnitName, newUnitName);
      if (oldGradeName !== newGradeName) {
        newName = newName.replace(oldGradeName, newGradeName);
      }
      await replaceImageFolderS3(image.Key, newName);
    });
  }
};

// handle change images in questions
const replaceImages = (question: any, gradeName: string, unitName: string, lessonName: string, createdAt: number) => {
  const images = JSON.stringify(question).match(/admin\/[^"]+/g);
  const replacedImages = images?.map((image) => {
    let prefix, fileName;
    let simplifiedString = "";
    if (image.includes("/")) {
      const parts = image.split("/");
      prefix = parts[0];
      const nameParts = extractFileNameAndExt(parts[parts.length - 1]);
      fileName = `${nameParts[0]}-${createdAt}.${nameParts[1]}`;
      fileName = `${nameParts[0]?.replaceAll(`-${createdAt}`, "")}-${createdAt}.${nameParts[1]}`;
    }
    if (gradeName) {
      simplifiedString = `${prefix}/question/${gradeName}/common/${fileName}`;
      if (unitName) {
        simplifiedString = `${prefix}/question/${gradeName}/${unitName}/common/${fileName}`;
        if (lessonName) {
          simplifiedString = `${prefix}/question/${gradeName}/${unitName}/${lessonName}/${fileName}`;
        }
      }
    }

    return simplifiedString;
  });

  return replacedImages;
};

const optimizeQuestion = (q: any) => {
  const question = JSON.parse(q);
  delete question._id;
  delete question.recordInfo;
  question.lessons = question.lessons?.map((item: any) => item.id);
  question.units = question.units?.map((item: any) => item.id);
  question.difficulty = question.difficulty.map((item: any) => ({
    gradeId: item.grade?.id,
    complexityId: item.complexity?.id,
  }));
  question.fields = question.fields?.map((item: any) => item.id);
  question.topics = question.topics?.map((item: any) => item.id);
  return { ...question };
};

const replaceS3Questions = async (images1: string[], images2: string[]) => {
  if (images1?.length) {
    images1.forEach(async (image1, index) => await replaceImageFolderS3(image1, images2[index]));
  }
};

const replaceQuestion = (question: any, replacementArray: string[]) => {
  return JSON.stringify(question).replace(/admin\/[^"]+/g, (match) => {
    const replacement = replacementArray.shift();
    return replacement !== undefined ? replacement : match;
  });
};

const handleQuestion = async (id: string, gradeName: string, newUnitName: string) => {
  try {
    const res = (await getQuestionById(id)) as any;
    if ([FlowCategory.Published, FlowCategory.Reviewing, FlowCategory.UnPublished].includes(res.result.status)) {
      await rejectQuestions([id]);
    }
    const lessonName = res.result.lessons.length ? res.result.lessons[0].name : "";
    const createdAt = Math.floor(new Date(res.result.recordInfo.createdAt).getTime() / 1000);
    const replacedImages = replaceImages(res.result, gradeName, newUnitName, lessonName, createdAt);
    if (replaceImages?.length) {
      replaceS3Questions(JSON.stringify(res.result).match(/admin\/[^"]+/g) as string[], replacedImages as string[]);
      await editQuestion(id, optimizeQuestion(replaceQuestion(res.result, replacedImages as string[])));
    }
  } catch (error) {
    console.log("error:", id, error);
  }
};

export const handleQuestionImages = async (gradeId: string, gradeName: string, unitId: string, newUnitName: string) => {
  await getQuestions({
    page: 1,
    size: 10000,
    sort: [
      {
        field: "recordInfo.createdAt",
        order: "desc",
      },
    ],
    filters: [
      {
        gradeId,
        unitId,
      },
    ],
  }).then(async (res: any) => {
    const listIds = res.result.records.map((item: any) => item._id);
    listIds.forEach((questionId: string, index: number) =>
      setTimeout(() => handleQuestion(questionId, gradeName, newUnitName), index * 100)
    );
  });
};
