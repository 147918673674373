import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStory, ISlide } from "../interfaces/IStory";
import { randomId } from "../utils/utils";

export const INITIAL_SLIDE_STATE: ISlide = {
  title: "",
  template: { question: "", content: "", username: "", avatar: "" },
  content: {
    texts: [],
    audioText: "",
    audioVoiceCode: "",
    audioLink: "",
    images: [],
    video: [],
    backgroundImage: "",
    gifs: [],
  },
  question: {
    texts: [],
    audioText: "",
    audioVoiceCode: "",
    audioLink: "",
    targets: [],
    choices: [],
    solutions: [],
    backgroundImage: "",
  },
  explanation: { texts: [], images: [], audioText: "", audioVoiceCode: "", audioLink: "" },
  slideKey: randomId(),
};

export const INITIAL_STORY_STATE: IStory = {
  recordId: null,
  title: "",
  gradeId: "",
  imageFolder: "",
  backgroundImage: "",
  thumbnailImage: "",
  description: "",
  slides: [INITIAL_SLIDE_STATE],
};

export const storySlice = createSlice({
  name: "story",
  initialState: INITIAL_STORY_STATE,
  reducers: {
    updateStory: (state, action: PayloadAction<IStory>) => {
      return { ...state, ...action.payload };
    },
    addSlide: (state, action: PayloadAction<string>) => {
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: action.payload };
      state.slides = [...state.slides, newSlide];
    },
    addSlideAbove: (state, action: PayloadAction<{ index: number; slideKey: string }>) => {
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: action.payload.slideKey };
      state.slides.splice(action.payload.index, 0, newSlide);
    },
    updateSlide: (state, action: PayloadAction<{ index: number; slide: ISlide }>) => {
      const { index, slide } = action.payload;
      state.slides[index] = slide;
    },
    moveSlide: (state, action: PayloadAction<{ index: number; direction: "up" | "down" }>) => {
      const { index, direction } = action.payload;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= state.slides.length) return;
      const slide = state.slides[index];
      state.slides.splice(index, 1);
      state.slides.splice(newIndex, 0, slide);
    },
    removeSlide: (state, action: PayloadAction<number>) => {
      state.slides.splice(action.payload, 1);
    },

    //Update Audio Link
    updateAudioLink: (
      state,
      action: PayloadAction<{
        slideIndex: number;
        explanationAudioLink: string;
        contentAudioLink: string;
        questionAudioLink: string;
      }>
    ) => {
      const { slideIndex, explanationAudioLink, contentAudioLink, questionAudioLink } = action.payload;
      const slide = state.slides[slideIndex];

      slide.explanation.audioLink = explanationAudioLink;
      slide.content.audioLink = contentAudioLink;
      slide.question.audioLink = questionAudioLink;
    },
  },
});

export const { updateStory, addSlide, addSlideAbove, updateSlide, moveSlide, updateAudioLink, removeSlide } = storySlice.actions;
export default storySlice.reducer;
