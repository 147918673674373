import instance from "./instance";

const adminUrl = "/auth/v1/admin";

interface AuthResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export const signinApi = (account: { userName: string; password: string }): Promise<AuthResponse> => {
  return instance.post(`${adminUrl}/auth/token`, account);
};
