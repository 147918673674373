import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Space, Table, Tooltip } from "antd";
import { EditOutlined, ImportOutlined, InfoCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IAccountUserFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { getAccountUsers, importAccountUsers } from "../../services/AccountUserServices";
import { ELECTIVE_OPTIONS, ACTIVE_STATUS_OPTIONS } from "../../constants/filter";
import { FormSelect } from "../../components/Form/Select";
import { datePickerToDateFormat } from "../../utils/utils";
import dayjs from "dayjs";
import { customSessionStorage } from "../../utils/storage";
import { getListSiteAdmins } from "../../services/AccountAdminServices";
import { getSaleCategories } from "../../services/CategoryService";

const AccountUserPortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isFectching, setIsFetching] = useState(false);

  const [siteAdminList, setSiteAdminList] = useState([]);
  const [saleCategories, setSaleCategories] = useState([]);

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IAccountUserFilter>({ page: 1, size: 10 });

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterAccountUser", formValues);

    const filter: IAccountUserFilter = {
      filter: formValues.filter,
      status: formValues.status,
      emailValidated: formValues.emailValidated,
      isPremium: formValues.isPremium,
      didRoadMap: formValues.didRoadMap,
      lastLoginFrom: datePickerToDateFormat(formValues.lastLoginFrom),
      lastLoginTo: datePickerToDateFormat(formValues.lastLoginTo),
      createdFrom: datePickerToDateFormat(formValues.createdFrom),
      createdTo: datePickerToDateFormat(formValues.createdTo),
      dailyActiveFrom: datePickerToDateFormat(formValues.dailyActiveFrom),
      dailyActiveTo: datePickerToDateFormat(formValues.dailyActiveTo),
      dailyActiveCountFrom30Days: formValues.dailyActiveCountFrom30Days,
      dailyActiveCountTo30Days: formValues.dailyActiveCountTo30Days,
      dailyActiveCountFrom: formValues.dailyActiveCountFrom,
      dailyActiveCountTo: formValues.dailyActiveCountTo,
      picId: formValues.picId,
      saleCategoryId: formValues.saleCategoryId,
    };
    filterData.current = { ...filterData.current, ...filter };
    filterData.current.page = 1;
    fetchAccountUsers();
  };

  const saveResult = (data: any) => {
    const url = URL.createObjectURL(
      new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Kết quả.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onImportAccountUsers = async (data: any) => {
    const formData = new FormData();
    formData.append("file", data.target?.files[0]);
    const res = (await importAccountUsers(formData)) as any;
    if (res?.response?.status === 422) {
      saveResult(res.response.data);
    }
    if (res?.status === 200) {
      saveResult(res.data);
    }
  };

  const onClearFileInput = (e: any) => {
    e.target.value = "";
  };

  const fetchAccountUsers = async () => {
    setIsFetching(true);
    const response = await getAccountUsers(filterData.current);
    if (response) {
      setTableData((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
    setIsFetching(false);
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchAccountUsers();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchAccountUsers();
  };

  const handleEditAccount = async (id: string) => {
    navigate(`${PageUrl.AccountUsers}${PageUrl.Update}`.replace(":id", id));
  };

  const handleShowAccountActivities = async (id: string) => {
    navigate(`${PageUrl.AccountUsers}${PageUrl.AccountActivities}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        align: "center",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "center",
        width: 100,
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        align: "center",
        width: 100,
      },
      {
        title: "Elo",
        dataIndex: "elo",
        key: "elo",
        align: "center",
        width: 75,
        render: (e: any) => (
          <Tooltip title={e}>
            <span>{e?.toString()?.split(".")[0]}</span>
          </Tooltip>
        ),
      },
      {
        title: "PICs",
        dataIndex: ["saleInfo", "pics"],
        key: "pics",
        align: "center",
        width: 100,
        render: (pics) => {
          if (pics?.length) {
            return pics.map((pic: any) => pic.name).join(", ");
          }
          return "";
        },
      },
      {
        title: "Sale status",
        dataIndex: ["saleInfo", "category", "name"],
        key: "status",
        align: "center",
        width: 150,
        render: (value) => value || "",
      },
      {
        title: "Note",
        dataIndex: ["saleInfo", "note"],
        key: "note",
        align: "center",
        width: 200,
        render: (value) => {
          if (value?.length > 50) {
            return (
              <Tooltip title={value}>
                <span>{value.slice(0, 50)}...</span>
              </Tooltip>
            );
          }
          return (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        title: "Last login",
        dataIndex: "lastLogin",
        key: "lastLogin",
        align: "center",
        width: 125,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        width: 125,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 200,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.SiteAdmin) && (
              <>
                <Space size="middle">
                  <Tooltip title="User activities">
                    <Button icon={<InfoCircleOutlined />} onClick={() => handleShowAccountActivities(record._id)} />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Edit account">
                    <Button icon={<EditOutlined />} onClick={() => handleEditAccount(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [siteAdminRes, saleCategoryRes] = await Promise.all([getListSiteAdmins(), getSaleCategories()]);
      siteAdminRes &&
        setSiteAdminList((siteAdminRes as any).result?.map((item: any) => ({ label: item.userName, value: item._id })));
      saleCategoryRes &&
        setSaleCategories((saleCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item._id })));
      if (sessionStorage.getItem("filterAccountUser")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterAccountUser")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterAccountUser");
        } else {
          form.setFields([
            { name: "filter", value: sessionFilter.filter },
            { name: "status", value: sessionFilter.status },
            { name: "emailValidated", value: sessionFilter.emailValidated },
            { name: "isPremium", value: sessionFilter.isPremium },
            { name: "didRoadMap", value: sessionFilter.didRoadMap },
            {
              name: "lastLoginFrom",
              value: sessionFilter.lastLoginFrom ? dayjs(sessionFilter.lastLoginFrom) : undefined,
            },
            {
              name: "lastLoginTo",
              value: sessionFilter.lastLoginTo ? dayjs(sessionFilter.lastLoginTo) : undefined,
            },
            {
              name: "createdFrom",
              value: sessionFilter.createdFrom ? dayjs(sessionFilter.createdFrom) : undefined,
            },
            {
              name: "createdTo",
              value: sessionFilter.createdTo ? dayjs(sessionFilter.createdTo) : undefined,
            },
            {
              name: "dailyActiveFrom",
              value: sessionFilter.dailyActiveFrom ? dayjs(sessionFilter.dailyActiveFrom) : undefined,
            },
            {
              name: "dailyActiveTo",
              value: sessionFilter.dailyActiveTo ? dayjs(sessionFilter.dailyActiveTo) : undefined,
            },
            { name: "dailyActiveCountFrom30Days", value: sessionFilter.dailyActiveCountFrom30Days },
            { name: "dailyActiveCountTo30Days", value: sessionFilter.dailyActiveCountTo30Days },
            { name: "dailyActiveCountFrom", value: sessionFilter.dailyActiveCountFrom },
            { name: "dailyActiveCountTo", value: sessionFilter.dailyActiveCountTo },
            { name: "picId", value: sessionFilter.picId },
            { name: "saleCategoryId", value: sessionFilter.saleCategoryId },
          ]);
          const parseDateFilter = {
            lastLoginFrom: datePickerToDateFormat(sessionFilter.lastLoginFrom),
            lastLoginTo: datePickerToDateFormat(sessionFilter.lastLoginTo),
            createdFrom: datePickerToDateFormat(sessionFilter.createdFrom),
            createdTo: datePickerToDateFormat(sessionFilter.createdTo),
            dailyActiveFrom: datePickerToDateFormat(sessionFilter.dailyActiveFrom),
            dailyActiveTo: datePickerToDateFormat(sessionFilter.dailyActiveTo),
          };
          filterData.current = { ...filterData.current, ...sessionFilter, ...parseDateFilter };
        }
        fetchAccountUsers();
      } else {
        fetchAccountUsers();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="account-user-portal-container">
        <Collapse defaultActiveKey={["1"]} size="large">
          <Collapse.Panel header="Filter" key="1">
            <div className="filter-container">
              <Form form={form} onFinish={handleFilter}>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <FormInput label="Filter" name="filter" allowClear />
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect label="Status" name="status" optionData={ACTIVE_STATUS_OPTIONS} allowClear />
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect
                      label="Email validated"
                      name="emailValidated"
                      optionData={ELECTIVE_OPTIONS}
                      allowClear
                    />
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect label="Is premium" name="isPremium" optionData={ELECTIVE_OPTIONS} allowClear />
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect label="Did road map" name="didRoadMap" optionData={ELECTIVE_OPTIONS} allowClear />
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Last login from" name="lastLoginFrom">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Last login to" name="lastLoginTo">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Created from" name="createdFrom">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Created to" name="createdTo">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Daily active from" name="dailyActiveFrom">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="Daily active to" name="dailyActiveTo">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="DAC from (30 days)" name="dailyActiveCountFrom30Days">
                      <Input type="number" placeholder="Daily active count from" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="DAC to (30 days)" name="dailyActiveCountTo30Days">
                      <Input type="number" placeholder="Daily active count to" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="DAC from (lifetime)" name="dailyActiveCountFrom">
                      <Input type="number" placeholder="Daily active count from" />
                    </Form.Item>
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <Form.Item label="DAC to (lifetime)" name="dailyActiveCountTo">
                      <Input type="number" placeholder="Daily active count to" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect label="Pic" name="picId" optionData={siteAdminList} allowClear />
                  </Col>
                  <Col xs={9} md={9} xl={6}>
                    <FormSelect label="Sale status" name="saleCategoryId" optionData={saleCategories} allowClear />
                  </Col>
                </Row>
                <Row>
                  <Col className="filter-controller">
                    <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Collapse.Panel>
        </Collapse>
        <Collapse defaultActiveKey={["1"]} size="large">
          <Collapse.Panel header="Table data" key="1">
            <div className="table-container">
              {(user?.roles?.includes(UserRole.SiteAdmin) || user?.roles?.includes(UserRole.Admin)) && (
                <div style={{ marginBottom: "16px", display: "flex", gap: "16px" }}>
                  <Button
                    type="default"
                    icon={<PlusOutlined />}
                    onClick={() => navigate(`${PageUrl.AccountUsers}${PageUrl.Create}`)}
                  >
                    Add an account
                  </Button>
                  <Button
                    type="default"
                    icon={<ImportOutlined />}
                    onClick={() => document.getElementById("fileInput")?.click()}
                  >
                    Import accounts
                  </Button>
                  <input
                    id="fileInput"
                    type="file"
                    accept=".xlsx"
                    style={{ display: "none" }}
                    onChange={onImportAccountUsers}
                    onClick={onClearFileInput}
                  />
                </div>
              )}
              <Table
                dataSource={tableData}
                columns={getColumn()}
                pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
                onChange={handleTableChange}
                ref={tableRef}
                rowKey={(record) => record._id}
                loading={isFectching}
                style={{ border: "1px solid #f0f0f0", borderRadius: "8px" }}
              />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

export default AccountUserPortal;
