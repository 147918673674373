import "./UpdateQuestion.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Descriptions,
  Select,
  Button,
  Radio,
  Modal,
  Divider,
  Space,
  Tooltip,
  Tag,
  Spin,
} from "antd";
import type { InputRef } from "antd";
import { useNavigate, useParams } from "react-router";
import {
  addQuestion,
  editQuestion,
  getComplexities,
  getFields,
  getGrades,
  getLessonsByUnitId,
  getQuestionById,
  getTags,
  getTopics,
  getUnits,
  getUnitsByGradeId,
  publishQuestions,
  rejectQuestions,
  submitQuestions,
  unPublishQuestions,
} from "../../services/QuestionServices";
import SingleFileInput, { FileData } from "../UploadInput/UploadInput";
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Loading from "../Loading/Loading";
import { convertToSelectOption, findValueByPrefix } from "../../utils/utils";
import Cookies from "universal-cookie";
import { decodeToken } from "react-jwt";
import { previewWindow } from "../../utils/preview";
import { UserRole } from "../../constants/auth";
import { NamePath } from "antd/es/form/interface";
import { FILE_EXTENSION_SUPPORT } from "../../constants/fileExtensions";
import { FormSelect } from "../Form/Select";
import { FormInput } from "../Form/Input";
import { FormSingleFileInput } from "../Form/SingleFileInput";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { PageUrl, PreviewUrl } from "../../constants/url";
import { ConfirmButton } from "../ConfirmButton/ConfirmButton";
import { FlowCategory, isDisabledFlow } from "../../constants/flow";
import {
  SelectOption,
  PromptTypeOptions,
  QuestionTypeConfigs,
  TypeOptions,
  QDetailOptions,
  PromptTypes,
} from "../../configs/questionConfigs";
import NoteText, { NoteMessage } from "../NoteText/NoteText";
import { isInteger } from "lodash";
import { VoiceCodeFormOptions } from "../VoiceCodeDropDown/VoiceCodeDropDown";

const { Option } = Select;
const genDefaultFilter = () => {
  return { gradeList: [], unitList: [], lessonList: [], gradeId: "", unitId: "", lessonId: "" };
};

const calibrationTopicId = "6617a03bcc3df002fd0cab99"

const UpdateQuestion: React.FC = () => {
  const [form] = Form.useForm();
  const [, setValuesForm] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generateDisable, setGenerateDisable] = useState(false);

  const [fieldsList, setFieldsList] = useState<any[]>([]);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [complexitiesList, setComplexitiesList] = useState<any[]>([]);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [addingTag, setAddingTag] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [topicList, setTopicList] = useState<string[]>([""]);
  const tagInputRef = useRef<InputRef>(null);
  const gradesList = useRef<any[]>([]);
  const unitsList = useRef<any[]>([]);
  const topicsList = useRef<any[]>([]);
  const [difficultiesList, setDifficultiesList] = useState<any[]>([]);
  const [folder, setFolder] = useState<string>("");

  const [selectedPromptOption, setSelectedPromptOption] = useState<string>();
  const [promptOptions, setPromptOptions] = useState<SelectOption[]>();
  const [explanationTexts, setExplanationTexts] = useState<string[]>([""]);
  const [explanationImages, setExplanationImages] = useState<string[]>([""]);
  const [filters, setFilters] = useState<any[]>([genDefaultFilter()]);

  const [selectedExtraOption, setSelectedExtraOption] = useState<string>();
  const [selectedTypeOption, setSelectedTypeOption] = useState<string>();
  const [typeOptions, setTypeOptions] = useState<SelectOption[]>();
  const [extraOptions, setExtraOptions] = useState<SelectOption[]>();

  const [numberChoices, setNumberChoices] = useState<number>();
  const [targetNumber, setTargetNumber] = useState<number>();
  const solution = useRef<string | any[]>("");
  const [listImages, setListImages] = useState<{ [T: string]: string }>({});

  const [status, setStatus] = useState<number>(FlowCategory.Draft);

  const [roles, setRoles] = useState<string[]>([""]);
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");
  // const questionId = useRef<string>(params.id || "");
  const [questionId, setQuestionId] = useState<string>(params.id || "");

  const [isConfirmedGradeUnit, setIsConfirmedGradeUnit] = useState(false);

  const initQuestion = (data: any) => {
    setStatus(data.status);
    // set Template
    const template = data.template as { prompt: string; question: string };

    const promptType = template.prompt.slice(0, 8);
    form.setFieldValue("promptType", promptType);
    setPromptOptions(PromptTypeOptions[promptType]);
    setSelectedPromptOption(template.prompt);
    form.setFieldValue("promptOption", template.prompt);

    const questionType = template.question.includes("DragDrop")
      ? QuestionTypeConfigs[1].value
      : QuestionTypeConfigs[0].value;
    let questionHasImages = FILE_EXTENSION_SUPPORT.some((item) => JSON.stringify(data.question).includes(item));
    if (data.question.targets.length)
      questionHasImages = !FILE_EXTENSION_SUPPORT.some((item) => JSON.stringify(data.question.targets).includes(item));
    if (data.recordInfo.createdBy.name === "MIGRATEDB") questionHasImages = false;
    setTypeOptions(TypeOptions[questionType]);
    setSelectedTypeOption(template.question);
    setExtraOptions(QDetailOptions[template.question]);
    const extraOption = QDetailOptions[template.question][questionHasImages ? 1 : 0].value;
    setSelectedExtraOption(extraOption);
    form.setFieldValue("type", questionType);
    form.setFieldValue("typeOptions", template.question);
    form.setFieldValue("extraOptions", extraOption);
    setIsConfirmedGradeUnit(true);
    // set Grades, Units, Lessons
    if (data.units?.length) {
      while (filters.length < data.units.length) filters.push(genDefaultFilter());
      data.units.forEach(async (item: any, index: number) => {
        const gradeId = unitsList.current.find((i) => i._id === item.id)?.gradeId;
        form.setFieldValue(["filter", "grade", index + 1], gradeId);
        await onSelectGrade(index, gradeId);
        form.setFieldValue(["filter", "unit", index + 1], item.id);
        await onSelectUnit(index, item.id);
        data.lessons.forEach((lesson: any) => {
          const idInFilters = filters.findIndex((filter: any, index: number) =>
            filter.lessonList.find((item: any) => item._id === lesson.id)
          );
          if (idInFilters > -1) {
            form.setFieldValue(["filter", "lesson", idInFilters + 1], lesson?.id);
            onSelectLesson(index, lesson?.id);
          }
        });
      });
    } else {
      form.setFieldValue(["filter", "grade", 1], data.difficulty[0].grade.id);
      onSelectGrade(0, data.difficulty[0].grade.id);
    }

    // set Difficulties
    setDifficultiesList(data.difficulty);
    data.difficulty.forEach((item: any, index: number) => {
      form.setFieldValue(["difficulty", "grade", index], item.grade.id);
      form.setFieldValue(["difficulty", "complexity", index], item.complexity.id);
    });

    form.setFieldValue("elo", data.elo);

    // set Prompts
    data.prompt.texts.forEach((item: string, index: number) =>
      form.setFieldValue(["prompt", `text${index + 1}`], item)
    );
    data.prompt.images.forEach((item: string, index: number) => {
      form.setFieldValue(["prompt", `image${index + 1}`], item);
      listImages[["prompt", `image${index + 1}`].toString()] = item;
    });
    form.setFieldValue(["prompt", "audioText"], data.prompt.audioText);
    form.setFieldValue(["prompt", "audioLink"], data.prompt.audioLink);

    // set Number choices + target choices
    setTargetNumber(data.question.targets.length);
    form.setFieldValue("targetNumber", data.question.targets.length);
    setNumberChoices(data.question.choices.length);
    form.setFieldValue("numberChoices", data.question.choices.length);

    // set Choices
    form.setFieldValue(["question", "text"], data.question.texts[0]);
    form.setFieldValue(["question", "audioText"], data.question.audioText);
    form.setFieldValue(["question", "audioLink"], data.question.audioLink);

    data.question.choices.forEach((item: string, index: number) => {
      form.setFieldValue(["question", `choice-${index + 1}`], item);
      if (questionHasImages) {
        listImages[["question", `choice-${index + 1}`].toString()] = item;
      }
    });

    // set Solutions
    switch (extraOption) {
      case "QAnswer1-1":
      case "QAnswer1-2":
        const correctIndex = data.question.solutions.findIndex((item: boolean) => item);
        if (correctIndex !== -1) {
          solution.current = `solution-${correctIndex + 1}`;
          form.setFieldValue("solution", `solution-${correctIndex + 1}`);
        }
        break;
      case "QAnswer2-1":
      case "QAnswer2-2":
        let correctIndexes: number[] = [];
        data.question.solutions.filter((item: boolean, index: number) => {
          if (item) correctIndexes.push(index + 1);
          return item;
        });
        form.setFieldValue("solution", correctIndexes);
        break;
      case "QAnswer3-1":
      case "QAnswer3-2":
        data.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        break;
      case "QAnswer4-1":
      case "QAnswer5-1":
        data.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        data.question.targets.forEach((item: string, index: number) => {
          listImages[["target", index + 1].toString()] = item;
          form.setFieldValue(["target", index + 1], item);
        });
        data.question.choices.forEach((item: string, index: number) => {
          form.setFieldValue(["question", `choice-${index + 1}`], item);
          listImages[["question", `choice-${index + 1}`].toString()] = item;
        });
        break;
      case "QAnswer4-2":
      case "QAnswer5-2":
        data.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        break;
      default:
        break;
    }

    // set Topics
    data.topics?.length && setTopicList(data.topics);
    data.topics?.forEach((item: any, index: number) => form.setFieldValue(["topic", index], item.id));

    // set Explanation
    data.explanation?.texts?.length && setExplanationTexts(data.explanation?.texts);
    data.explanation.texts.forEach((item: string, index: number) =>
      form.setFieldValue(["explanation", `text-explain-${index + 1}`], item)
    );
    data.explanation?.images?.length && setExplanationImages(data.explanation?.images);
    data.explanation.images.forEach((item: string, index: number) => {
      form.setFieldValue(["explanation", `image-explain-${index + 1}`], item);
      listImages[["explanation", `image-explain-${index + 1}`].toString()] = item;
    });
    form.setFieldValue(["explanation", "audioText"], data.explanation.audioText);
    form.setFieldValue(["explanation", "audioLink"], data.explanation.audioLink);

    // set Others
    setListImages({ ...listImages });
    data.tags?.length && setSelectedTags(data.tags);
    data.fields?.length && setSelectedFields(data.fields.map((item: any) => item.name));
    form.setFieldsValue({
      fields: data.fields.map((item: any) => item.id) || undefined,
      tags: data.tags || undefined,
      isElective: data.isElective || false,
    });
  };

  const handleSaveAndPreview = () => {
    let questionType;
    const difficulties = form.getFieldValue("difficulty");
    const explanations = form.getFieldValue("explanation");
    const lessonFilters = form.getFieldValue("filter");
    const prompts = form.getFieldValue("prompt");
    const questions = form.getFieldValue("question");
    const eloScore = form.getFieldValue("elo");
    let topics = form.getFieldValue("topic");
    topics = topics?.filter((item: string) => !!item);

    switch (selectedTypeOption) {
      case "MultipleChoice":
      case "MultipleResponse":
        questionType = 2;
        break;
      case "Gapfill":
        questionType = 1;
        break;
      case "DragDrop":
      case "DragDropMulti":
        questionType = 0;
        break;
      default:
        break;
    }

    const getTarget = (): string[] => {
      if (!targetNumber) return [];
      if (selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") {
        let formData = form.getFieldValue("target") as string[];
        formData = formData?.filter((item) => !!item) || [];
        return formData;
      } else {
        return [];
      }
    };

    const getSolution = (solutions: any) => {
      let solution;
      switch (selectedExtraOption) {
        case "QAnswer1-1":
        case "QAnswer1-2":
          solution = Array(numberChoices).fill(false);
          solution[Number(solutions?.split("-")[1]) - 1] = true;
          break;
        case "QAnswer2-1":
        case "QAnswer2-2":
          solution = Array(numberChoices).fill(false);
          solutions.forEach((index: number) => {
            solution[index - 1] = true;
          });
          break;
        case "QAnswer3-1":
        case "QAnswer3-2":
          solution = findValueByPrefix(solutions, "solution");
          break;
        case "QAnswer4-1":
        case "QAnswer4-2":
          solution = Object.values(solutions).map((item) => {
            return Number(item);
          });
          break;
        case "QAnswer5-1":
        case "QAnswer5-2":
          solution = Object.values(solutions).map((item) => {
            return Number(item);
          });
          break;
        default:
          break;
      }
      return solution;
    };

    const choices =
      selectedExtraOption === "QAnswer3-1" ? Array(numberChoices).fill("") : findValueByPrefix(questions, "choice");

    let uploadData = {
      folderName: folder,
      type: questionType,
      template: {
        prompt: selectedPromptOption,
        question: form.getFieldValue("typeOptions"),
      },
      prompt: {
        texts: findValueByPrefix(prompts, "text"),
        audioText: form.getFieldValue(["prompt", "audioText"]),
        audioTextVoiceCode: form.getFieldValue(["prompt", "audioTextVoiceCode"]),
        images: findValueByPrefix(prompts, "image"),
      },
      question: {
        texts: findValueByPrefix(questions, "text"),
        audioText: form.getFieldValue(["question", "audioText"]),
        audioTextVoiceCode: form.getFieldValue(["question", "audioTextVoiceCode"]),
        targets: getTarget(),
        choices,
        solutions: getSolution(form.getFieldValue("solution")),
      },
      explanation: {
        texts: findValueByPrefix(explanations, "text-explain"),
        audioText: form.getFieldValue(["explanation", "audioText"]),
        audioTextVoiceCode: form.getFieldValue(["explanation", "audioTextVoiceCode"]),
        images: findValueByPrefix(explanations, "image-explain"),
      },
      isElective: form.getFieldValue("isElective") || false,
      difficulty: difficulties?.grade?.map((item: any, index: number) => ({
        gradeId: item,
        complexityId: difficulties.complexity[index],
      })),
      lessons: Object.values(lessonFilters?.lesson || [""]).filter((item) => !!item),
      units: Object.values(lessonFilters?.unit || [""]).filter((item) => !!item),
      fields: form.getFieldValue("fields"),
      tags: form.getFieldValue("tags"),
      topics: topics?.length ? topics : null,
      elo: hasCalibrationTopic()? parseFloat(eloScore):null
    };

    saveQuestion(uploadData);
  };

  const saveQuestion = async (data: any) => {
    try {
      let response: any;
      setIsSubmitting(true);
      if (questionId) {
        response = await editQuestion(questionId, data);
        if (response.result?.prompt?.audioLink) {
          form.setFieldValue(["prompt", "audioLink"], response.result.prompt.audioLink);
        }
        if (response.result?.question?.audioLink) {
          form.setFieldValue(["question", "audioLink"], response.result.question.audioLink);
        }

        if (response.result?.explanation?.audioLink) {
          form.setFieldValue(["explanation", "audioLink"], response.result.explanation.audioLink);
        }
      } else {
        response = await addQuestion(data);
        setQuestionId(response.result ? response.result : questionId);
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${!questionId ? "Added" : "Updated"} successfully`,
      });

      setIsSubmitting(false);
      previewWindow({ url: PreviewUrl.Question, data: { token, questionId: questionId } });
    } catch (error) {
      Modal.error({
        title: "Error",
        content: "Có lỗi xảy ra, vui lòng thử lại sau",
      });
    }
  };

  const handleChangeQuestionType = (value: string) => {
    setTypeOptions(TypeOptions[value]);
    setSelectedTypeOption(TypeOptions[value][0].value);
    setExtraOptions(QDetailOptions[TypeOptions[value][0].value]);
    setSelectedExtraOption(QDetailOptions[TypeOptions[value][0].value][0].value);
    setNumberChoices(undefined);
    solution.current = "";
    form.setFieldValue("typeOptions", TypeOptions[value][0].value);
    form.setFieldValue("extraOptions", QDetailOptions[TypeOptions[value][0].value][0].value);
    resetQuestions();
  };

  const handleChangeTypeOption = (value: string) => {
    setSelectedTypeOption(value);
    setExtraOptions(QDetailOptions[value]);
    setSelectedExtraOption(QDetailOptions[value][0].value);
    setNumberChoices(undefined);
    setTargetNumber(undefined);
    solution.current = "";
    form.setFieldValue("extraOptions", QDetailOptions[value][0].value);
    resetQuestions();
  };

  const handleChangeExtraOption = (value: string) => {
    setSelectedExtraOption(value);
    setNumberChoices(undefined);
    solution.current = "";
    resetQuestions();
  };

  const handleChangePromptType = (value: string) => {
    setPromptOptions(PromptTypeOptions[value]);
    setSelectedPromptOption(PromptTypeOptions[value][0].value);
    form.setFieldValue("promptOption", PromptTypeOptions[value][0].value);
    form.setFieldValue("prompt", undefined);
  };

  const handleChangePromptTypeOption = (value: string) => {
    setSelectedPromptOption(value);
    form.setFieldValue("prompt", undefined);
  };

  const handleAddTopic = () => {
    setTopicList([...topicList, ""]);
  };

  const handleRemoveTopic = () => {
    form.setFieldValue(["topic", topicList.length - 1], undefined);
    topicList.splice(-1);
    setTopicList([...topicList]);
  };

  const handleAddExplainTexts = () => {
    if (!form.getFieldValue(["explanation", `text-explain-${explanationTexts.length}`])) {
      form.setFieldValue(["explanation", `text-explain-${explanationTexts.length}`], "");
    }
    setExplanationTexts([...explanationTexts, ""]);
  };

  const handleRemoveExplainTexts = () => {
    form.setFieldValue(["explanation", `text-explain-${explanationTexts.length}`], undefined);
    explanationTexts.splice(-1);
    setExplanationTexts([...explanationTexts]);
  };

  const handleAddExplainImages = () => {
    if (!form.getFieldValue(["explanation", `image-explain-${explanationImages.length}`])) {
      form.setFieldValue(["explanation", `image-explain-${explanationImages.length}`], "");
    }
    setExplanationImages([...explanationImages, ""]);
  };

  const handleRemoveExplainImages = () => {
    form.setFieldValue(["explanation", `image-explain-${explanationTexts.length}`], undefined);
    explanationImages.splice(-1);
    setExplanationImages([...explanationImages]);
  };

  const handleLoadImage = (field: NamePath, imageData: FileData) => {
    const key = field.toString();
    form.setFieldValue(field, imageData.fileLink);
    setListImages({ ...listImages, [key]: imageData.fileName });
  };

  const handleRemoveImage = (field: NamePath) => {
    const key = field.toString();
    form.setFieldValue(field, "");
    delete listImages[key];
    setListImages({ ...listImages });
  };

  const generateAudioText = (type: string) => {
    return (
      <>
        <FormInput
          type="textarea"
          key={`audioText-${type}`}
          label="AudioText"
          name={[type, "audioText"]}
          latexString={form.getFieldValue([type, "audioText"]) || ""}
          // latexString={slide.explanation.audioText || ""}
        />
        <FormSelect
          key={`voiceCode-${type}`}
          label="VoiceCode"
          name={[type, "audioTextVoiceCode"]}
          optionData={VoiceCodeFormOptions}
          // latexString={form.getFieldValue([type, "audioTextVoiceCode"]) || ""}

          // callback={handleChangeExplanationVoiceCode}
        />

        <FormInput
          type="textarea"
          key={`audioLink-${type}`}
          label="Custom AudioLink"
          name={[type, "audioLink"]}
          latexString={form.getFieldValue([type, "audioLink"]) || ""}
          rows={2}
          // latexString={}
        />
      </>
    );
  };

  const generatePrompt = (promptType: string): JSX.Element => {
    const generatePromptWithOptions = (numberTexts: number, numberImages: number) => {
      return (
        <Descriptions.Item className="prompt-wrapper" label="Prompt">
          {!!numberTexts && (
            <span className="texts">
              {Array(numberTexts)
                .fill("")
                .map((_, index) => (
                  <FormInput
                    type="textarea"
                    name={["prompt", `text${index + 1}`]}
                    label={`Text ${index + 1}`}
                    latexString={form.getFieldValue(["prompt", `text${index + 1}`])}
                  />
                ))}
            </span>
          )}
          {!!numberImages && (
            <span className="images">
              {Array(numberImages)
                .fill("")
                .map((_, index) => (
                  <FormSingleFileInput
                    label={`Image ${index + 1}`}
                    name={["prompt", `image${index + 1}`]}
                    initValue={listImages[["prompt", `image${index + 1}`].toString()]}
                    handleUpload={handleLoadImage}
                    handleRemove={handleRemoveImage}
                    folder={folder}
                  />
                ))}
            </span>
          )}

          {generateAudioText("prompt")}
        </Descriptions.Item>
      );
    };
    let prompt = <></>;
    switch (promptType) {
      case "QPrompt1_1":
      case "QPrompt1_3":
      case "QPrompt2_1":
      case "QPrompt2_4":
        prompt = generatePromptWithOptions(1, 1);
        break;
      case "QPrompt1_2":
        prompt = generatePromptWithOptions(2, 1);
        break;
      case "QPrompt2_2":
        prompt = generatePromptWithOptions(1, 2);
        break;
      case "QPrompt2_3":
        prompt = generatePromptWithOptions(2, 2);
        break;
      case "QPrompt3_1":
        prompt = generatePromptWithOptions(1, 0);
        break;
      case "QPrompt3_2":
        prompt = generatePromptWithOptions(0, 1);
        break;
      default:
        break;
    }

    return prompt;
  };

  const generateQuestionForm = (type: "texts" | "images") => {
    if (!numberChoices) return <></>;
    const choices = Array(numberChoices).fill("") as string[];
    let result;
    switch (type) {
      case "texts":
        result = choices.map((_, index) => (
          <FormInput
            type="textarea"
            key={`choice-${index + 1}`}
            name={["question", `choice-${index + 1}`]}
            label={`Choice ${index + 1}`}
            latexString={form.getFieldValue(["question", `choice-${index + 1}`])}
          />
        ));
        break;
      case "images":
        result = choices.map((_, index) => (
          <FormSingleFileInput
            key={`choice-${index + 1}`}
            name={["question", `choice-${index + 1}`]}
            label={`Choice ${index + 1}`}
            initValue={listImages[["question", `choice-${index + 1}`].toString()]}
            handleUpload={handleLoadImage}
            handleRemove={handleRemoveImage}
            folder={folder}
          />
        ));
        break;
      default:
        break;
    }

    return <span className={type}>{result}</span>;
  };

  const generateSolutionForm = (
    type: "radio" | "checkbox" | "gap-fill" | "gap-fill-image" | "drag-drop" | "drag-drop-multi"
  ) => {
    if (!numberChoices) return <></>;
    let choices = Array(numberChoices).fill("") as string[];
    if ((type === "drag-drop" || type === "drag-drop-multi") && targetNumber) {
      choices = Array(targetNumber).fill("") as string[];
    }
    let result;
    switch (type) {
      case "radio":
        result = (
          <Form.Item key="solution" name="solution">
            <Radio.Group name="solution" value={solution.current} onChange={(e) => (solution.current = e.target.value)}>
              {choices.map((_, index) => (
                <Radio name="solution" key={`solution-${index + 1}`} value={`solution-${index + 1}`}>
                  Solution {index + 1}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
        break;
      case "checkbox":
        result = (
          <Form.Item key="solution" name="solution">
            <Checkbox.Group name="solution" value={solution.current as any} onChange={(e) => (solution.current = e)}>
              {choices.map((_, index) => (
                <Checkbox name="solution" key={`solution-${index + 1}`} value={index + 1}>
                  Solution {index + 1}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
        break;
      case "gap-fill":
        result = (
          <>
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
              >
                <Input name={`solution-${index + 1}`} placeholder="Enter a solution" />
              </Form.Item>
            ))}
          </>
        );
        break;
      case "gap-fill-image":
        result = (
          <>
            {choices.map((_, index) => (
              <span style={{ display: "grid", gap: "32px" }}>
                <Form.Item
                  key={`solution-${index + 1}`}
                  name={["solution", `solution-${index + 1}`]}
                  label={`Solution ${index + 1}`}
                >
                  <Input name={`solution-${index + 1}`} placeholder="Enter solution" />
                </Form.Item>
                <Form.Item
                  key={`choice-${index + 1}`}
                  name={["question", `choice-${index + 1}`]}
                  label={`Image ${index + 1}`}
                >
                  <SingleFileInput
                    initValue={listImages[["question", `choice-${index + 1}`].toString()]}
                    inputId={`choice-${index + 1}`}
                    onData={(data: FileData) => handleLoadImage(["question", `choice-${index + 1}`], data)}
                    onRemove={() => handleRemoveImage(["question", `choice-${index + 1}`])}
                    folder={folder}
                  />
                </Form.Item>
              </span>
            ))}
          </>
        );
        break;
      case "drag-drop":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      const solutions = form.getFieldValue("solution");
                      if (Object.values(solutions).filter((item) => item === value).length > 1) {
                        return Promise.reject("Exist solution");
                      } else {
                        if (Number(value) > Number(numberChoices) || Number(value) <= 0)
                          return Promise.reject("Must be less than target");
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Enter a solution" allowClear />
              </Form.Item>
            ))}
          </span>
        );
        break;
      case "drag-drop-multi":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      if (Number(value) > Number(numberChoices) || Number(value) < 0)
                        return Promise.reject("Invalid target");
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Enter a solution" />
              </Form.Item>
            ))}
          </span>
        );
        break;
      default:
        break;
    }

    return result;
  };

  const generateQuestionAndSolution = (questionType: string) => {
    let question;
    let solution;
    switch (questionType) {
      case "QAnswer1-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer1-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer2-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer2-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer3-1":
        solution = generateSolutionForm("gap-fill");
        break;
      case "QAnswer3-2":
        solution = generateSolutionForm("gap-fill-image");
        break;
      case "QAnswer4-1":
      case "QAnswer4-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop");
        break;
      case "QAnswer5-1":
      case "QAnswer5-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop-multi");
        break;
      default:
        break;
    }

    return (
      <>
        <Descriptions.Item className="question-wrapper" label="Question">
          <FormInput
            type="textarea"
            name={["question", "text"]}
            label="Text"
            latexString={form.getFieldValue(["question", "text"])}
          />
          {generateAudioText("question")}

          {question}
        </Descriptions.Item>
        <Descriptions.Item label="Solution">{solution}</Descriptions.Item>
      </>
    );
  };

  const generateChoices = () => {
    setNumberChoices(Number(form.getFieldValue("numberChoices")));
    setTargetNumber(Number(form.getFieldValue("targetNumber")));
    resetQuestions();
  };

  const generateTargets = () => {
    const res = Array(targetNumber)
      .fill(0)
      .map((_, i) => (
        <Form.Item key={`target-${i + 1}`} label={`Target ${i + 1}`} name={["target", i + 1]}>
          <SingleFileInput
            initValue={listImages[["target", i + 1].toString()]}
            inputId={`target-${i + 1}`}
            onData={(data: FileData) => handleLoadImage(["target", i + 1], data)}
            onRemove={() => handleRemoveImage(["target", i + 1])}
            folder={folder}
          />
        </Form.Item>
      ));
    return res;
  };

  const onSelectGrade = async (index: number, gradeId: string) => {
    const unitRes = await getUnitsByGradeId(gradeId);
    if (unitRes) {
      filters[index].gradeId = gradeId;

      form.setFieldValue(["filter", "unit", index + 1], undefined);
      filters[index].unitId = "";
      filters[index].unitList = (unitRes as any).result?.records;
      form.setFieldValue(["filter", "lesson", index + 1], undefined);
      filters[index].lessonId = "";
      filters[index].lessonList = [];
      setFilters([...filters]);
    }
  };
  const onSelectUnit = async (index: number, unitId: string) => {
    const lessonRes = await getLessonsByUnitId(filters[index].gradeId, unitId);
    if (lessonRes) {
      filters[index].unitId = unitId;
      filters[index].lessonId = "";
      form.setFieldValue(["filter", "lesson", index + 1], undefined);
      filters[index].lessonList = (lessonRes as any).result?.records;
      setFilters([...filters]);
    }
  };

  const onSelectLesson = (index: number, lessonId: string) => {
    filters[index].lessonId = lessonId;
    setFilters([...filters]);
  };

  const addFilterRow = () => {
    setFilters([...filters, genDefaultFilter()]);
  };

  const removeFilterRow = () => {
    filters.splice(-1);
    setFilters([...filters]);
  };

  const FilterRow = ({ filter, isLastItem, index }: { filter: any; isLastItem: boolean; index: number }) => {
    const { unitList, lessonList } = filter;

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <FormSelect
          label="Grade"
          name={["filter", "grade", index + 1]}
          optionData={convertToSelectOption(gradesList.current, "_id", "name")}
          callback={(e: string) => onSelectGrade(index, e)}
          rules={[{ required: true, message: "Please select an option" }]}
          disabled={isConfirmedGradeUnit && index === 0}
        />
        <FormSelect
          label="Unit"
          name={["filter", "unit", index + 1]}
          optionData={convertToSelectOption(unitList, "_id", "unitName")}
          callback={(e: string) => onSelectUnit(index, e)}
          allowClear
          onClear={() => onSelectGrade(index, filters[index].gradeId)}
          disabled={isConfirmedGradeUnit && index === 0}
        />
        <FormSelect
          label="Lesson"
          name={["filter", "lesson", index + 1]}
          optionData={convertToSelectOption(lessonList, "_id", "lessonName")}
          callback={(e: string) => onSelectLesson(index, e)}
          allowClear
          onClear={() => onSelectUnit(index, filters[index].unitId)}
          disabled={isConfirmedGradeUnit && index === 0}
        />
        {isLastItem && (
          <span className="filter-control">
            <Form.Item>
              <Button type="ghost" block icon={<PlusOutlined />} onClick={addFilterRow}></Button>
            </Form.Item>
            <Form.Item style={filters.length > 1 ? { display: "block" } : { display: "none" }}>
              <Button type="ghost" block icon={<MinusOutlined />} onClick={removeFilterRow}></Button>
            </Form.Item>
          </span>
        )}
      </div>
    );
  };

  const handleController = async (func: any, type: number) => {
    try {
      const res = await func.then();
      if (res) {
        setStatus(type);
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });
      }
    } catch (error) {}
  };

  const addATag = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setTagsList((tagsList) => {
      tagsList.push(addingTag);
      return [...tagsList];
    });
    setAddingTag("");
    setTimeout(() => {
      tagInputRef.current?.focus();
    }, 0);
  };

  const resetQuestions = () => {
    form.resetFields(["target"]);
    form.resetFields(["question"]);
    form.resetFields(["solution"]);
    form.resetFields(["explanation"]);
    Object.keys(listImages).forEach((key) => {
      if (
        key.includes("question") ||
        key.includes("solution") ||
        key.includes("explanation") ||
        key.includes("target")
      ) {
        delete listImages[key];
      }
    });
  };

  const hasCalibrationTopic =()=>{
    let result = false
    let topics = form.getFieldValue("topic");
    topics = topics?.filter((item: string) => !!item);
    if(topics?.length > 0 && topics.includes(calibrationTopicId)){
      result = true
    }
    return result
  }

  useEffect(() => {
    let folder = "question/";
    const defaultFilter = filters[0];
    const gradeName = gradesList.current.find((item) => item._id === defaultFilter.gradeId)?.name;
    if (gradeName) {
      folder += gradeName;
      const unitName = defaultFilter.unitList.find((item: any) => item._id === defaultFilter.unitId)?.unitName;
      if (unitName) {
        folder += `/${unitName}`;
        const lessonName = defaultFilter.lessonList.find(
          (item: any) => item._id === defaultFilter.lessonId
        )?.lessonName;
        folder += lessonName ? `/${lessonName}` : "/common";
      } else {
        folder += `/common`;
      }
    }

    setFolder(folder);

    const uniqueGradeIdObjects = filters.reduce((filter, item) => {
      if (!item.gradeId) return filter;
      if (!filter[item.gradeId]) {
        filter[item.gradeId] = item;
      }
      return filter;
    }, {});

    const difficulties = Object.keys(uniqueGradeIdObjects);

    setDifficultiesList(() => difficulties);
    difficulties.forEach((gradeId, index) => {
      form.setFieldValue(["difficulty", "grade", index], gradeId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const getQuestionData = async (id: string) => {
      const res = await getQuestionById(id);
      if (res) {
        const data = (res as any).result;
        initQuestion(data);
      }
    };

    const getAllInformation = async () => {
      setIsLoading(true);
      const [fieldRes, complexityRes, tagRes, gradeRes, unitRes, topicRes] = await Promise.all([
        getFields(),
        getComplexities(),
        getTags(),
        getGrades(),
        getUnits(),
        getTopics(),
      ]);

      fieldRes && setFieldsList((fieldRes as any).result);
      complexityRes && setComplexitiesList((complexityRes as any).result);
      tagRes && setTagsList((tagRes as any).result?.records);
      gradeRes && (gradesList.current = (gradeRes as any).result);
      unitRes && (unitsList.current = (unitRes as any).result?.records);
      topicRes && (topicsList.current = (topicRes as any).result?.records);

      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    const getRoles = async () => {
      const cookies = new Cookies();
      const token = cookies.get("signinAdmin");
      if (token) {
        const decode = decodeToken(cookies.get("signinAdmin"));
        decode && setRoles((decode as any).roles);
        await getAllInformation();
        questionId && getQuestionData(questionId);
      } else {
        navigate(PageUrl.Signin);
      }
    };

    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagDetails = selectedTags?.map((item) => (
    <Tag key={item} style={{ margin: "4px" }}>
      {item}
    </Tag>
  ));

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-question">
      {isSubmitting && (
        <Spin
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            background: "rgba(0, 0, 0, 0.5)",
          }}
          indicator={<LoadingOutlined style={{ fontSize: 48, top: "50%" }} spin />}
        />
      )}
      <Form form={form} onFinish={handleSaveAndPreview} onValuesChange={(values) => setValuesForm(values)}>
        <Descriptions column={1}>
          <div style={{ margin: "0 0 16px 100px" }}>
            {questionId && (
              <>
                {!isDisabledFlow(status, FlowCategory.Reviewing) && (
                  <Button
                    type="primary"
                    icon={<SettingOutlined />}
                    style={{ backgroundColor: "#a746ff" }}
                    onClick={() =>
                      window.open(
                        `${PageUrl.Questions}${PageUrl.UpdateQuestionMobileVersion}`.replace(":id", questionId),
                        "_blank"
                      )
                    }
                  >
                    Edit mobile version
                  </Button>
                )}
              </>
            )}
          </div>
          <Descriptions.Item className="filter-wrapper" label="Filter">
            {filters.map((filter, index) => (
              <FilterRow
                key={`filter-row-${index + 1}`}
                filter={filter}
                index={index}
                isLastItem={index === filters.length - 1}
              />
            ))}
          </Descriptions.Item>
          {difficultiesList.length && (
            <Descriptions.Item label="Difficulty">
              {difficultiesList?.map((_, index) => (
                <span className="difficulty-wrapper">
                  {gradesList?.current.length && (
                    <Form.Item
                      name={["difficulty", "grade", index]}
                      label={`Grade ${index + 1}`}
                      rules={[{ required: true, message: "Please select an option" }]}
                    >
                      <Select maxTagCount="responsive" placeholder="Select an option" disabled={true}>
                        {gradesList.current.map((grade) => (
                          <Option value={grade._id}>{grade.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    name={["difficulty", "complexity", index]}
                    label={`Complexity ${index + 1}`}
                    rules={[{ required: true, message: "Please select an option" }]}
                  >
                    <Select maxTagCount="responsive" placeholder="Select an option">
                      {complexitiesList &&
                        complexitiesList.map((complexity) => <Option value={complexity._id}>{complexity.name}</Option>)}
                    </Select>
                  </Form.Item>
                </span>
              ))}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Temp. Prompt">
            <Form.Item label="Type" name="promptType">
              <Select onChange={handleChangePromptType} placeholder="Select a type">
                {PromptTypes.map((option) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Form.Item>
            {promptOptions && (
              <Form.Item label="Option" name="promptOption">
                <Select onChange={handleChangePromptTypeOption}>
                  {promptOptions.map((option) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Temp. Question">
            <Form.Item label="Type" name="type">
              <Select onChange={handleChangeQuestionType} placeholder="Select a type">
                {QuestionTypeConfigs.map((option) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Form.Item>
            {typeOptions && (
              <Form.Item label="Option" name="typeOptions">
                <Select onChange={handleChangeTypeOption}>
                  {typeOptions.map((option) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {extraOptions && (
              <Form.Item label="Detail" name="extraOptions">
                <Select onChange={handleChangeExtraOption} value={extraOptions[0].value}>
                  {extraOptions.map((option) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Descriptions.Item>
          {isConfirmedGradeUnit && selectedExtraOption && selectedPromptOption && (
            <>
              <Descriptions.Item label="Topics">
                {topicList?.map((_, index) => (
                  <FormSelect
                    key={`topic-${index}`}
                    label={`Topic ${index + 1}`}
                    name={["topic", index]}
                    optionData={convertToSelectOption(topicsList.current, "_id", "name")}
                    allowClear
                  />
                ))}
                <span className="control" style={{ display: "flex", gap: "8px" }}>
                  <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddTopic} />
                  <Button
                    style={topicList.length > 1 ? { display: "block" } : { display: "none" }}
                    type="ghost"
                    block
                    icon={<MinusOutlined />}
                    onClick={handleRemoveTopic}
                  />
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Others">
                <FormSelect
                  mode="multiple"
                  label="Fields"
                  name="fields"
                  tooltipData={selectedFields}
                  optionData={fieldsList.map((item) => ({ label: item.name, value: item._id }))}
                  callback={(fieldIds: string[]) => {
                    const fieldNames = fieldIds.map((id: string) => {
                      const name = fieldsList.find((field) => field._id === id)?.name;
                      return name;
                    });
                    setSelectedFields(fieldNames);
                  }}
                />
                <span style={{ position: "relative" }}>
                  <Form.Item label="Tags" name="tags">
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="Select an option"
                      onChange={setSelectedTags}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="Enter a tag"
                              ref={tagInputRef}
                              value={addingTag}
                              onChange={(e) => setAddingTag(e.target.value)}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addATag}></Button>
                          </Space>
                        </>
                      )}
                      options={tagsList?.map((tag) => ({ label: tag, value: tag }))}
                    />
                  </Form.Item>
                  {selectedTags?.length ? (
                    <Tooltip color="#fff" title={tagDetails}>
                      <Space
                        style={{ cursor: "pointer", columnGap: "8px", position: "absolute", top: "-24px", right: "0" }}
                      >
                        <InfoCircleOutlined />
                      </Space>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </span>
                <FormInput label="Elo" name="elo" type="number" 
                    rules={[
                      {
                        required: hasCalibrationTopic(),
                        message: "Field is required",
                      },
                      {
                        validator(_, value) {
                          if (Number(value) < 0) {
                            return Promise.reject(
                              new Error("Elo must be greater than 0")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]} disabled={!hasCalibrationTopic()} />
                <Form.Item label="Elective" name="isElective" valuePropName="checked" style={{ flexGrow: "inherit" }}>
                  <Checkbox />
                </Form.Item>
              </Descriptions.Item>

              {generatePrompt(selectedPromptOption)}
              {["QAnswer4-1", "QAnswer5-1"].some((item) => item === selectedExtraOption) && (
                <Descriptions.Item label="Target choices">
                  <Form.Item
                    name="targetNumber"
                    rules={[
                      { required: true, message: "Please enter a value" },
                      {
                        validator(_, value) {
                          let range = [2, 4];
                          if (Number(value) <= range[1] && Number(value) >= range[0]) {
                            setGenerateDisable(false);
                            return Promise.resolve();
                          } else {
                            setGenerateDisable(true);
                            return Promise.reject("Invalid field");
                          }
                        },
                      },
                    ]}
                  >
                    <Input type="number" value={targetNumber} placeholder="Enter a number" />
                  </Form.Item>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Number choices">
                <Form.Item
                  label="Choices"
                  name="numberChoices"
                  rules={[
                    { required: true, message: "Please enter a value" },
                    {
                      validator(_, value) {
                        if (!isInteger(Number(value))) {
                          return Promise.reject("Invalid field");
                        }
                        const targetNumber = form.getFieldValue("targetNumber");
                        let range = [2, 6];
                        if (selectedTypeOption?.includes("Gapfill")) {
                          range[0] = 1;
                        }
                        if (selectedExtraOption === "QAnswer4-2" || selectedExtraOption === "QAnswer5-2") {
                          range = [2, 4];
                        }
                        if (
                          Number(value) <= range[1] &&
                          Number(value) >= range[0] &&
                          (!targetNumber ||
                            (Number(targetNumber) && Number(value) >= Number(targetNumber)) ||
                            selectedTypeOption === "DragDropMulti")
                        ) {
                          setGenerateDisable(false);
                          return Promise.resolve();
                        } else {
                          setGenerateDisable(true);
                          return Promise.reject("Invalid field");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    value={numberChoices}
                    type="number"
                    placeholder="Enter a number"
                    addonAfter={
                      <Button type="primary" disabled={generateDisable} onClick={generateChoices}>
                        Generate questions
                      </Button>
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
              {(selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") && targetNumber && (
                <Descriptions.Item label="Target">{generateTargets()}</Descriptions.Item>
              )}
              {numberChoices && generateQuestionAndSolution(selectedExtraOption)}
              {numberChoices && (
                <Descriptions.Item label="Explanation" className="explain-wrapper">
                  <span className="texts">
                    {explanationTexts.map((_, index) => (
                      <FormInput
                        type="textarea"
                        key={`text-explain-${index + 1}`}
                        label={`Text ${index + 1}`}
                        name={["explanation", `text-explain-${index + 1}`]}
                        latexString={form.getFieldValue(["explanation", `text-explain-${index + 1}`])}
                      />
                    ))}
                    {generateAudioText("explanation")}

                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainTexts} />
                      <Button
                        style={explanationTexts.length > 1 ? { display: "block" } : { display: "none" }}
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainTexts}
                      />
                    </span>
                  </span>
                  <span className="images">
                    {explanationImages.map((_, index) => (
                      <FormSingleFileInput
                        key={`image-explain-${index + 1}`}
                        label={`Image ${index + 1}`}
                        name={["explanation", `image-explain-${index + 1}`]}
                        initValue={listImages[["explanation", `image-explain-${index + 1}`].toString()]}
                        handleUpload={handleLoadImage}
                        handleRemove={handleRemoveImage}
                        folder={folder}
                      />
                    ))}
                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainImages} />
                      <Button
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainImages}
                        style={explanationImages.length > 1 ? { display: "block" } : { display: "none" }}
                      />
                    </span>
                  </span>
                </Descriptions.Item>
              )}
              <Descriptions.Item className="note-text-save">
                <NoteText style={{ margin: "4px 0 4px 150px" }} text={NoteMessage.SaveChangeBeforeAction} />
              </Descriptions.Item>
              <div className="form-controller">
                {roles?.includes(UserRole.ContentCreator) && (
                  <>
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<EyeOutlined />}
                      style={{ backgroundColor: "#2abb2a" }}
                    >
                      Save and Preview
                    </Button>

                    <ConfirmButton
                      title="Submit"
                      buttonType="primary"
                      icon={<CheckOutlined />}
                      onConfirm={() => handleController(submitQuestions([questionId]), FlowCategory.Reviewing)}
                      disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !questionId}
                    />
                  </>
                )}
                {roles?.includes(UserRole.Reviewer) && questionId && (
                  <>
                    <ConfirmButton
                      title="Publish"
                      buttonType="primary"
                      icon={<UploadOutlined />}
                      onConfirm={() => handleController(publishQuestions([questionId]), FlowCategory.Published)}
                      disabled={isDisabledFlow(status, FlowCategory.Published)}
                    />
                    <ConfirmButton
                      title="Unpublish"
                      buttonType="default"
                      icon={<DownloadOutlined />}
                      onConfirm={() => handleController(unPublishQuestions([questionId]), FlowCategory.UnPublished)}
                      disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
                    />
                    <ConfirmButton
                      title="Reject"
                      buttonType="dashed"
                      icon={<CloseOutlined />}
                      onConfirm={() => handleController(rejectQuestions([questionId]), FlowCategory.RequestEdit)}
                      disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
                    />
                  </>
                )}
                <Button
                  type="primary"
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={() => navigate(PageUrl.Questions)}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Descriptions>
        {!isConfirmedGradeUnit && (
          <>
            <NoteText style={{ margin: "-16px 0 4px 150px" }} text={NoteMessage.FillAllFilterFirst} />
            <Button
              type="primary"
              style={{ marginLeft: "150px" }}
              onClick={() => setIsConfirmedGradeUnit(true)}
              disabled={
                !selectedExtraOption ||
                !selectedPromptOption ||
                !form.getFieldValue(["difficulty", "grade", 0]) ||
                !filters[0].gradeId
              }
            >
              Confirm
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default UpdateQuestion;
