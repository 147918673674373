import "./Loading.scss";

// add type className optional
interface Props {
  isOverlay?: boolean;
}

const Loading = ({ isOverlay }: Props) => {
  const className = isOverlay ? "loading-wrapper overlay" : "loading-wrapper";
  return (
    <div className={className}>
      <div className="loading-circle"></div>
      <div className="loading-circle"></div>
      <div className="loading-circle"></div>
      <div>Loading...</div>
    </div>
  );
};

export default Loading;
