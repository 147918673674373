import "./ShowAccountActivities.scss";
import { Card, Empty, Select, Table } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import {
  getAccountUserDailyActive,
  getAccountUserEloHistory,
  getAccountUserLastLesson,
  getAccountUserRoadMapInsights,
  getAccountUserYearExamInsights,
} from "../../../services/AccountUserServices";
import { Message } from "../../../constants/message";
import dayjs from "dayjs";
import EloChart from "./EloChart";

interface UserActivitiesData {
  dailyActive: any;
  lesson: any;
  roadMap: any;
  yearExam: any;
  elo: any;
}

const ShowAccountActivities = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();
  const [allData, setAllData] = useState<UserActivitiesData>();

  const userId = useRef<string>(params.id || "");
  const [isLoading, setIsLoading] = useState(false);
  const [eloData, setEloData] = useState<any>();
  const [eloFilterType, setEloFilterType] = useState("weekly");

  const getEloData = async (type: string) => {
    const eloRes = await getAccountUserEloHistory(userId.current, { filterType: type });
    if ((eloRes as any)?.result) {
      setEloData((eloRes as any).result);
    }
  };

  useEffect(() => {
    if (eloFilterType === "weekly" || eloFilterType === "monthly" || eloFilterType === "quarterly") {
      getEloData(eloFilterType);
    }
  }, [eloFilterType]);

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (userId.current) {
        const [dailyActiveRes, lessonRes, roadMapRes, yearExamRes, eloRes] = await Promise.all([
          getAccountUserDailyActive(userId.current),
          getAccountUserLastLesson(userId.current),
          getAccountUserRoadMapInsights(userId.current),
          getAccountUserYearExamInsights(userId.current),
          getAccountUserEloHistory(userId.current),
        ]);
        let userActivitiesData = {} as UserActivitiesData;
        if ((dailyActiveRes as any)?.result) {
          userActivitiesData.dailyActive = (dailyActiveRes as any).result;
        }
        if ((lessonRes as any)?.result) {
          userActivitiesData.lesson = (lessonRes as any).result;
        }

        if ((roadMapRes as any)?.result) {
          userActivitiesData.roadMap = (roadMapRes as any).result;
        }

        if ((yearExamRes as any)?.result) {
          userActivitiesData.yearExam = (yearExamRes as any).result;
        }

        if ((eloRes as any)?.result) {
          setEloData((eloRes as any).result);
        }
        setAllData(userActivitiesData);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId.current, user]);

  const DailyActiveContent = (data: any) => {
    data = data?.data;
    if (!data) return <Empty description={Message.NoData} />;

    return (
      <Fragment>
        <Table
          dataSource={[
            {
              key: 1,
              label: "Ngày hoạt động gần nhất trong 30 ngày",
              value: data?.lastDailyActiveDate ? dayjs(data?.lastDailyActiveDate).format("DD/MM/YYYY HH:mm:ss") : "N/A",
            },
            { key: 2, label: "Số ngày hoạt động trong 30 ngày", value: data?.totalDailyActive || "N/A" },
          ]}
          bordered
          showHeader={false}
          columns={[
            { title: "Đầu mục", dataIndex: "label", key: "label" },
            { title: "Giá trị", dataIndex: "value", key: "value" },
          ]}
          pagination={false}
        />
      </Fragment>
    );
  };

  const LessonContent = (data: any) => {
    data = data?.data;
    if (!data) return <Empty description={Message.NoData} />;
    const lastLesson = data.lastLesson;
    if (!lastLesson) return <Empty description={Message.NoData} />;

    return (
      <Fragment>
        <Table
          bordered
          dataSource={[lastLesson].map((item: any, index: number) => ({ ...item, key: index }))}
          columns={[
            {
              title: "Bài học gần nhất",
              key: "name",
              render: () => lastLesson.lesson?.name || "Chưa có thông tin",
            },
            {
              title: "Chương",
              key: "unit",
              render: () => lastLesson.unit?.name || "Chưa có thông tin",
            },
            {
              title: "Khối",
              key: "grade",
              render: () => lastLesson.grade?.name || "Chưa có thông tin",
            },
            {
              title: "Tiến độ",
              key: "progress",
              render: () =>
                lastLesson.currentProgress
                  ? `${Math.round(lastLesson.currentProgress * 100) / 100}%`
                  : "Chưa có thông tin",
            },
            {
              title: "Ngày cập nhật",
              dataIndex: "createdDate",
              key: "createdDate",
              render: () =>
                data.dateUpdate ? dayjs(data.dateUpdate).format("DD/MM/YYYY HH:mm:ss") : "Chưa có thông tin",
            },
          ]}
          pagination={false}
        />
      </Fragment>
    );
  };

  const RoadMapContent = (data: any) => {
    data = data?.data;
    if (!data) return <Empty description={Message.NoData} />;

    return (
      <Fragment>
        <Table
          dataSource={data.map((item: any, index: number) => ({ ...item, key: index }))}
          bordered
          columns={[
            {
              title: "Road Map",
              dataIndex: ["roadMap", "name"],
              key: "roadMapName",
            },
            {
              title: "Exam",
              dataIndex: ["exam", "name"],
              key: "examName",
            },
            {
              title: "Ngày tạo",
              dataIndex: "createdDate",
              key: "createdDate",
              render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY HH:mm:ss") : "Chưa có thông tin"),
            },
            {
              title: "Ngày Submit",
              dataIndex: "submittedDate",
              key: "submittedDate",
              render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY HH:mm:ss") : "Chưa submit"),
            },
            {
              title: "Điểm",
              dataIndex: "currentScore",
              key: "currentScore",
            },
          ]}
          pagination={false}
        />
      </Fragment>
    );
  };

  const YearExamContent = (data: any) => {
    data = data?.data;
    if (!data) return <Empty description={Message.NoData} />;

    return (
      <Fragment>
        <Table
          dataSource={data.map((item: any, index: number) => ({ ...item, key: index }))}
          bordered
          columns={[
            {
              title: "Exam",
              dataIndex: ["exam", "name"],
              key: "examName",
            },
            {
              title: "Ngày tạo",
              dataIndex: "createdDate",
              key: "createdDate",
              render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY HH:mm:ss") : "Chưa có thồng tin"),
            },
            {
              title: "Ngày Submit",
              dataIndex: "submittedDate",
              key: "submittedDate",
              render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY HH:mm:ss") : "Chưa submit"),
            },
            {
              title: "Điểm",
              dataIndex: "currentScore",
              key: "currentScore",
            },
          ]}
          pagination={false}
        />
      </Fragment>
    );
  };

  const EloContent = (data: any) => {
    data = data?.data;
    if (!data) return <Empty description={Message.NoData} />;

    return (
      <div style={{ width: "1000px" }}>
        <EloChart data={data} filterType={eloFilterType} />
      </div>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="show-account-activities-container">
      <Card title="Hoạt động gần đây">
        <DailyActiveContent data={allData?.dailyActive} />
      </Card>
      <Card title="Bài giảng">
        <LessonContent data={allData?.lesson} />
      </Card>
      <Card title="Lộ trình">
        <RoadMapContent data={allData?.roadMap} />
      </Card>
      <Card title="Bài thi năm">
        <YearExamContent data={allData?.yearExam} />
      </Card>
      <Card
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>Lịch sử elo</div>
            <div>
              <Select
                defaultValue="weekly"
                style={{ width: 150 }}
                options={[
                  { label: "7 ngày gần nhất", value: "weekly" },
                  { label: "30 ngày gần nhất", value: "monthly" },
                  { label: "3 tháng gần nhất", value: "quarterly" },
                ]}
                onChange={setEloFilterType}
              />
            </div>
          </div>
        }
        className="elo-chart-card"
      >
        <EloContent data={eloData} />
      </Card>
    </div>
  );
};

export default ShowAccountActivities;
