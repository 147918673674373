import { Button, Popconfirm, Tooltip } from "antd";

type ConfirmButtonProps = {
  title: string;
  icon?: JSX.Element;
  buttonType?: "primary" | "default" | "dashed" | "link" | "text";
  disabled?: boolean;
  style?: React.CSSProperties;
  tooltip?: boolean;
  danger?: boolean;
  onConfirm: () => void;
};

export const ConfirmButton = ({ title, icon, buttonType, disabled, style, tooltip, danger, onConfirm }: ConfirmButtonProps) => {
  return (
    <Popconfirm title="Are you sure?" disabled={disabled} onConfirm={() => onConfirm()} okButtonProps={{ loading: false }}>
      {tooltip ? (
        <Tooltip title={title}>
          <Button type={buttonType} icon={icon} disabled={disabled} style={style} danger={danger}></Button>
        </Tooltip>
      ) : (
        <Button type={buttonType} icon={icon} disabled={disabled} style={style} danger={danger}>
          {title}
        </Button>
      )}
    </Popconfirm>
  );
};
