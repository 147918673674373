import { InternalNamePath } from "antd/es/form/interface";
import dayjs from "dayjs";

export const findValueByPrefix = (object: any, prefix: string) => {
  const res = [];
  for (let property in object) {
    if (object.hasOwnProperty(property) && property.toString().startsWith(prefix)) {
      res.push(object[property]);
    }
  }
  return res;
};

export const convertToSelectOption = (data: any[], key: string, value: string) => {
  if (!data || !data.length) return [];
  const convertData = data.map((item) => ({ label: item[value], value: item[key] }));

  return convertData;
};

export const randomId = (): string => {
  return (Math.random() + 1).toString(36).substring(2);
};

export const enumToArray = (type: any) => {
  return Object.keys(type)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({ label: key, value: type[key] }));
};

export const getAllKeys = (obj: Record<string, any>): string[] => {
  let keys: string[] = [];

  for (let key in obj) {
    keys.push(key);

    if (typeof obj[key] === "object") {
      if (Array.isArray(obj[key])) {
        // If the value is an array, recursively check the values inside
        // eslint-disable-next-line no-loop-func
        obj[key].forEach((item: Record<string, any>) => {
          if (typeof item === "object") {
            keys = keys.concat(getAllKeys(item));
          }
        });
      } else {
        // If the value is an object, recursively call getAllKeys
        keys = keys.concat(getAllKeys(obj[key]));
      }
    }
  }

  return keys;
};

export const createObjectFromKeys = (
  keys: (string | number)[] | InternalNamePath,
  lastValue = ""
): Record<string, any> => {
  const obj: Record<string, any> = {};

  let tempObj = obj;
  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      tempObj[key] = lastValue;
    } else {
      if (typeof keys[index + 1] === "number") {
        tempObj[key] = [];
      } else {
        tempObj[key] = {};
      }
      tempObj = tempObj[key];
    }
  });

  return obj;
};

export const convertStringToDate = (date: string, format?: string) => {
  if (!date) return "";
  if (format === "DD/MM/YYYY HH:mm") {
    const time = date.split(" ")[1].split(":");
    const dateParts = date.split(" ")[0].split("/");
    const dateObject = new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0], +time[0], +time[1]);
    return dateObject;
  }
  const newDate = new Date(date);
  return `${newDate.toLocaleDateString("en-GB")} ${newDate.toLocaleTimeString("en-GB")}`;
};

export const datePickerToDateFormat = (date: any, format = "DD/MM/YYYY") => {
  if (!date) return "";
  return dayjs(date).format(format);
};

export const cleanObject = (obj: any) => {
  const newObj = {} as any;

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        newObj[key] = null;
      } else {
        if (key === "targets" || key === "choices") {
          newObj[key] = value;
        } else {
          const cleanedArray = value.map((item) =>
            typeof item === "object" && item !== null ? cleanObject(item) : item === "" ? null : item
          );
          if (cleanedArray.length === 1 && cleanedArray[0] === null) {
            newObj[key] = null;
          } else {
            newObj[key] = cleanedArray;
          }
        }
      }
    } else if (typeof value === "object" && value !== null) {
      newObj[key] = cleanObject(value);
    } else {
      newObj[key] = value === "" ? null : value;
    }
  }

  return newObj;
};

export const cleanNullValues = (items: any[]) => {
  items?.forEach((item: any, index: number) => {
    if (item === null || item === undefined) {
      items[index] = "";
    }
  });
};

export const convertArrayOfObjectsToCSV = (arr: any[]) => {
  if (!arr || !arr.length) return;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";

  const keys = Object.keys(arr[0]);
  let result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  arr.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};
