import {
  EyeOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, DatePicker, TimePicker, Tooltip, Descriptions } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { convertToSelectOption, enumToArray } from "../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import "./UpdateExam.scss";
import { PageUrl, PreviewUrl } from "../../../constants/url";
import {
  addExam,
  editExam,
  getExamById,
  getExams,
  publishExams,
  rejectExams,
  submitExams,
  unPublishExams,
} from "../../../services/ExamServices";
import { getComplexities, getGrades } from "../../../services/LessonServices";
import { UserRole } from "../../../constants/auth";
import { previewWindow } from "../../../utils/preview";
import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { FlowCategory, isDisabledFlow } from "../../../constants/flow";
import { ExamType, MONTHLY_IMAGES } from "../../../configs/examConfigs";
import { Rule } from "antd/es/form";
import dayjs from "dayjs";
import { FormSingleFileInput } from "../../../components/Form/SingleFileInput";
import { FileData } from "../../../components/UploadInput/UploadInput";
import { NamePath } from "antd/es/form/interface";
import { NoteMessage, NoteText } from "../../../components/NoteText/NoteText";
import { getQuestionById } from "../../../services/QuestionServices";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";

const { RangePicker } = DatePicker;

const UpdateExam = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user, token } = useAuth()!;

  const [form] = Form.useForm();

  const [examId, setExamId] = useState<string>(params.id || "");
  const [examName, setExamName] = useState<string>("");
  const [examType, setExamType] = useState<number>();
  const [gradeId, setGradeId] = useState<string>("");
  const [gradeList, setGradeList] = useState<any[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<string[]>([""]);
  const [folder, setFolder] = useState<string>("");
  const [imageList, setImageList] = useState<string[]>([""]);
  const [complexityList, setComplexityList] = useState<string[]>([]);
  const [questionList, setQuestionList] = useState<
    { questionId: string; complexityId: string; hasTopic: 0 | -1 | 1 }[]
  >([{ questionId: "", complexityId: "", hasTopic: -1 }]);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmedNameGrade, setIsConfirmedNameGrade] = useState(false);
  const [status, setStatus] = useState<number>(FlowCategory.Draft);
  const [examRules, setExamRules] = useState<Rule[]>([]);
  const [initDate, setInitDate] = useState<any>([]);
  const [hourDisable, setHourDisable] = useState<boolean>(true);
  const [mobileImage, setMobileImage] = useState<string>("");

  const checkQuestionHasTopicById = async (questionId: string) => {
    const response = await getQuestionById(questionId);
    if (response) {
      const data = (response as any).result;
      return data.topics?.length > 0 ? 1 : 0;
    }
  };

  const checkQuestionListHasTopic = async (questionIds: string[]) => {
    const response = await Promise.all(questionIds.map((questionId) => checkQuestionHasTopicById(questionId)));
    setQuestionList((questionList) =>
      questionList.map((item, index) => {
        return { ...item, hasTopic: response[index] as 0 | -1 | 1 };
      })
    );
  };

  const handleSaveExam = () => {
    try {
      form.validateFields().then(async () => {
        const exam = form.getFieldsValue();
        let startDate, endDate;
        if (exam.examDate) {
          [startDate, endDate] = [exam.examDate[0]?.$d, exam.examDate[1]?.$d];
          const [startTime, endTime] = [exam.Hm[0]?.$d, exam.Hm[1]?.$d];
          startDate?.setHours(startTime?.getHours() || 0, startTime?.getMinutes() || 0, 0);
          endDate?.setHours(endTime?.getHours() || 0, endTime?.getMinutes() || 0, 0);
        }
        const questions = exam.questions.map((item: any) => {
          const score = item.score || item.score === "0" ? Number(item.score) : null;
          return {
            questionId: item.questionId,
            complexityId: item.complexityId,
            score: score,
          };
        });

        // check all question has topic
        if (examType === ExamType.Year) {
          const questionIds = questions.map((item: any) => item.questionId);
          await checkQuestionListHasTopic(questionIds);
        }

        const data = {
          name: exam.name,
          gradeIds: exam.gradeIds,
          type: exam.type,
          startDate: startDate?.toISOString() || null,
          endDate: endDate?.toISOString() || null,
          examTime: Number(exam.examTime) || null,
          questions: questions,
          images: imageList.map((item) => item || null),
          premium: exam.premium,
          mobileImage: exam.mobileImage || null,
        };
        let response: any;
        if (examId) {
          response = await editExam(examId, { ...data });
        } else {
          response = await addExam(data);
        }
        setExamId(response.result ? response.result : examId);
        Modal.success({
          title: "Success",
          type: "success",
          content: `${examId ? "Updated" : "Added"} successfully`,
        });
        previewWindow({ url: PreviewUrl.Exam, data: { token, examId: examId } });
      });
    } catch (error) {}
  };

  const handleChangeType = (value: number) => {
    setExamType(value);
    imageList.forEach((_, index) => handleRemoveImageName(index, ["images", index]));
    setImageList(() => (value === ExamType.Monthly ? MONTHLY_IMAGES.map(() => "") : [""]));
    setExamRules(value !== ExamType.Year ? [{ required: true, message: "Please enter exam time" }] : []);
  };

  const handleAddGrade = () => {
    setSelectedGrades([...selectedGrades, ""]);
  };

  const handleRemoveGrade = () => {
    const newSelectedGrades = [...selectedGrades];
    newSelectedGrades.splice(-1);
    setSelectedGrades(newSelectedGrades);
  };

  const handleAddQuestion = () => {
    setQuestionList([...questionList, { questionId: "", complexityId: "", hasTopic: -1 }]);
  };

  const handleRemoveQuestion = () => {
    const newQuestionList = [...questionList];
    newQuestionList.splice(-1);
    setQuestionList(newQuestionList);
  };

  const handleAddImage = () => {
    setImageList([...imageList, ""]);
  };

  const handleRemoveImage = () => {
    const newSelectImageList = [...imageList];
    newSelectImageList.splice(-1);
    setImageList(newSelectImageList);
  };

  const handleRemoveImageName = (index: number, name: NamePath) => {
    form.resetFields([name]);
    const newSelectImageList = [...imageList];
    newSelectImageList[index] = "";
    setImageList(newSelectImageList);
  };

  const handleUploadImage = (index: number, imageData: FileData) => {
    const newImageList = [...imageList];
    newImageList[index] = imageData.fileLink;
    setImageList(newImageList);
  };

  const handleLoadMobileImage = (field: NamePath, data: FileData) => {
    form.setFieldsValue({ mobileImage: data.fileLink });
  };

  const handleRemoveMobileImage = (field: NamePath) => {
    form.setFieldsValue({ mobileImage: "" });
  };

  const handlePublishExam = async () => {
    if (examType === ExamType.Monthly) {
      const res = (await getExams({
        page: 1,
        size: 100,
        gradeId: selectedGrades.join(","),
        type: ExamType.Monthly,
        status: FlowCategory.Published.toString(),
      })) as any;
      const grades = selectedGrades.filter((grade: any) => {
        const filter: any[] = [];
        res.result.records.forEach((record: any) => {
          const isGrade = record.grades.find((grade1: any) => grade1.id === grade);
          isGrade && filter.push(record);
        });

        return filter.length >= 2;
      });
      if (grades.length) {
        const nameGrades = grades.map((gradeId) => {
          const find = gradeList.find((item: any) => item._id === gradeId);
          return find ? find.name : "";
        });
        Modal.error({
          type: "error",
          title: "Publish error",
          width: 500,
          content: (
            <>
              {nameGrades.map((name: any) => name)?.join(",")} currently has two or more monthly exams published
              <br />
              Please check and try again!
            </>
          ),
        });
        return;
      } else {
        await handleController(publishExams([examId]), FlowCategory.UnPublished);
      }
    } else {
      await handleController(publishExams([examId]), FlowCategory.UnPublished);
    }
  };

  const handleController = async (func: any, type: number) => {
    try {
      const res = await func.then();
      if (res) {
        setStatus(type);
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });
      }
    } catch (error) {}
  };

  const getImageItems = (arr: string[], defaultLabel = true) => {
    return arr.map((item, index) => (
      <span key={index} style={{ position: "relative" }}>
        <FormSingleFileInput
          name={["images", index]}
          label={defaultLabel ? `Image ${index + 1}` : item}
          initValue={imageList[index] || ""}
          handleRemove={(e) => handleRemoveImageName(index, e)}
          handleUpload={(_, data) => handleUploadImage(index, data)}
          folder={folder}
        />
        {index === imageList.length - 1 && defaultLabel && (
          <span className="control">
            <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddImage} />
            <Button
              style={imageList.length > 1 ? { display: "block" } : { display: "none" }}
              type="ghost"
              block
              icon={<MinusOutlined />}
              onClick={handleRemoveImage}
            />
          </span>
        )}
      </span>
    ));
  };

  useEffect(() => {
    if (gradeId && examName) {
      const grade = gradeList.find((item: any) => item._id === gradeId) as any;
      grade && setFolder(`exam/${grade.name}/${examName}/`);
    }
  }, [gradeId, examName, gradeList]);

  useEffect(() => {
    const getExam = async () => {
      const response = await getExamById(examId);
      if (response) {
        const data = (response as any).result;
        setExamName(data.name);
        setExamType(data.type);
        setGradeId(data.grades[0].id);
        setIsConfirmedNameGrade(true);
        setStatus(data.status);
        setSelectedGrades(data.grades.map((item: any) => item.id));
        setQuestionList(
          data.questions?.length
            ? data.questions.map((item: any, index: number) => ({
                questionId: "",
                complexityId: "",
                hasTopic: data.questions[index]?.topics?.length ? 1 : 0,
              }))
            : [{ questionId: "", complexityId: "", hasTopic: -1 }]
        );
        setImageList(data.images);
        if (data.startDate && data.endDate) {
          setInitDate([data.startDate, data.endDate]);
          setHourDisable(false);
        }
        setMobileImage(data.mobileImage);
        form.setFieldsValue({
          name: data.name,
          gradeIds: data.grades.map((item: any) => item.id),
          type: data.type,
          premium: data.premium,
          examTime: data.examTime,
          questions: data.questions.map((item: any) => ({
            questionId: item.questionId,
            complexityId: item.complexity.id,
            score: item.score,
          })),
          images: data.images,
          mobileImage: data.mobileImage,
          folder,
        });
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes, complexityList] = await Promise.all([getGrades(), getComplexities()]);
      gradeRes && setGradeList((gradeRes as any).result);
      complexityList && setComplexityList((complexityList as any).result);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      examId && (await getExam());
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="update-exam-container">
        <Form form={form} onFinish={handleSaveExam}>
          <div className="form-input">
            <Row style={{ maxWidth: "1200px" }}>
              <Col xs={8} md={8}>
                <FormInput
                  label="Exam name"
                  name="name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                  onChange={setExamName}
                  disabled={isConfirmedNameGrade}
                />
              </Col>
              <Col xs={8} md={8}>
                <FormSelect
                  label="Type"
                  name="type"
                  optionData={enumToArray(ExamType)}
                  callback={(e: number) => handleChangeType(e)}
                  rules={[{ required: true, message: "Please select exam type" }]}
                  disabled={isConfirmedNameGrade}
                />
              </Col>
              <Col xs={8} md={8}>
                <FormSelect
                  label="Premium"
                  name="premium"
                  optionData={ELECTIVE_OPTIONS}
                  rules={[{ required: true, message: "Please select exam premium" }]}
                />
              </Col>
            </Row>
            <Row className="time-option" style={{ maxWidth: "1200px" }}>
              <Col xs={8} md={8}>
                <Form.Item
                  label="Exam date"
                  name="examDate"
                  initialValue={initDate?.length ? [dayjs(initDate[0]), dayjs(initDate[1])] : undefined}
                >
                  <RangePicker
                    allowClear
                    format={["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]}
                    disabled={examType === ExamType.Year}
                    onChange={(_, date) => {
                      if (date.length && date[0] && date[1]) {
                        setHourDisable(false);
                      } else {
                        form.setFieldValue("Hm", undefined);
                        setHourDisable(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} md={8}>
                <Form.Item
                  label="Hour&Min"
                  name="Hm"
                  initialValue={initDate?.length ? [dayjs(initDate[0]), dayjs(initDate[1])] : undefined}
                  rules={[{ required: !hourDisable, message: "Please select exam time" }]}
                >
                  <TimePicker.RangePicker allowClear disabled={hourDisable} format={["HH:mm"]} />
                </Form.Item>
              </Col>
              <Col xs={8} md={8}>
                <FormInput type="number" label="Exam time" name="examTime" rules={examRules} />
              </Col>
            </Row>
            <Descriptions>
              <Descriptions.Item label="Grades" className="grade-container">
                {selectedGrades.map((_, index) => (
                  <span key={index} style={{ position: "relative" }}>
                    <FormSelect
                      label={"Grade " + (index + 1)}
                      name={["gradeIds", index]}
                      optionData={convertToSelectOption(gradeList, "_id", "name")}
                      rules={[{ required: true, message: "Please select a grade" }]}
                      callback={index === 0 ? setGradeId : () => {}}
                      disabled={isConfirmedNameGrade && index === 0}
                    />
                    {index === selectedGrades.length - 1 && (
                      <span className="control">
                        <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddGrade} />
                        <Button
                          style={selectedGrades.length > 1 ? { display: "block" } : { display: "none" }}
                          type="ghost"
                          block
                          icon={<MinusOutlined />}
                          onClick={handleRemoveGrade}
                        />
                      </span>
                    )}
                  </span>
                ))}
              </Descriptions.Item>
            </Descriptions>
            {isConfirmedNameGrade && (
              <>
                <Descriptions>
                  <Descriptions.Item label="Images" className="image-container">
                    {examType === ExamType.Monthly ? getImageItems(MONTHLY_IMAGES, false) : getImageItems(imageList)}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="" className="mobile-image">
                    <FormSingleFileInput
                      name="mobileImage"
                      label="Mobile image: "
                      folder={folder}
                      initValue={mobileImage || ""}
                      handleUpload={handleLoadMobileImage}
                      handleRemove={handleRemoveMobileImage}
                    />
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="Questions" className="question-container">
                    {questionList.map((question, index) => (
                      <span key={index} style={{ position: "relative" }}>
                        <span className="input-preview">
                          <FormInput
                            label={`Question ${index + 1}`}
                            name={["questions", index, "questionId"]}
                            colon={index === 0 ? true : false}
                            rules={[{ required: true, message: "Please enter a question" }]}
                          />
                          {examType === ExamType.Year && (
                            <Tooltip
                              title={
                                question.hasTopic === 1
                                  ? "Đã có topic"
                                  : question.hasTopic === 0
                                  ? "Chưa có topic"
                                  : "Chưa có thông tin topic"
                              }
                            >
                              <Button
                                type="ghost"
                                block
                                icon={
                                  question.hasTopic === 1 ? (
                                    <CheckOutlined />
                                  ) : question.hasTopic === 0 ? (
                                    <CloseOutlined />
                                  ) : (
                                    <ExclamationOutlined />
                                  )
                                }
                                style={{
                                  right: "24px",
                                  color: `${
                                    question.hasTopic === 1
                                      ? "#2abb2a"
                                      : question.hasTopic === 0
                                      ? "#ff4d4f"
                                      : "#1677ff"
                                  }`,
                                }}
                              />
                            </Tooltip>
                          )}

                          <Button
                            type="ghost"
                            block
                            icon={<EyeOutlined />}
                            onClick={() =>
                              previewWindow({
                                url: PreviewUrl.Question,
                                data: { token, questionId: form.getFieldValue(["questions", index, "questionId"]) },
                                directly: true,
                              })
                            }
                          />
                        </span>
                        <FormInput
                          type="number"
                          label={`Score ${index + 1}`}
                          name={["questions", index, "score"]}
                          colon={index === 0 ? true : false}
                          rules={[
                            {
                              validator(_, value) {
                                if (Number(value) < 0) {
                                  return Promise.reject(new Error("Score must be greater than 0"));
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        />
                        <FormSelect
                          label={`Complexity ${index + 1}`}
                          name={["questions", index, "complexityId"]}
                          optionData={convertToSelectOption(complexityList, "_id", "name")}
                          rules={[{ required: true, message: "Please select a complexity" }]}
                        />
                        {index === questionList.length - 1 && (
                          <span className="control">
                            <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddQuestion} />
                            <Button
                              style={questionList.length > 1 ? { display: "block" } : { display: "none" }}
                              type="ghost"
                              block
                              icon={<MinusOutlined />}
                              onClick={handleRemoveQuestion}
                            />
                          </span>
                        )}
                      </span>
                    ))}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
          </div>
          {!isConfirmedNameGrade && (
            <>
              <NoteText style={{ margin: "4px 0 4px 100px" }} text={NoteMessage.FillGradeAndExamNameFirst} />
              <Button
                type="primary"
                onClick={() => setIsConfirmedNameGrade(true)}
                style={{ margin: "4px 16px 4px 100px" }}
                disabled={!examName || !gradeId || examType === undefined}
              >
                Confirm
              </Button>
              <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Exams)}>
                Cancel
              </Button>
            </>
          )}
          {isConfirmedNameGrade && (
            <>
              <NoteText style={{ margin: "4px 0 4px 100px" }} text={NoteMessage.SaveChangeBeforeAction} />
              <div className="form-controller">
                {user?.roles?.includes(UserRole.ContentCreator) && (
                  <>
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      style={{ backgroundColor: "#2abb2a" }}
                      onClick={handleSaveExam}
                    >
                      Save and preview
                    </Button>
                    <ConfirmButton
                      title="Submit"
                      buttonType="primary"
                      icon={<CheckOutlined />}
                      onConfirm={() => handleController(submitExams([examId]), FlowCategory.Reviewing)}
                      disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !examId}
                    />
                  </>
                )}
                {user?.roles?.includes(UserRole.Reviewer) && examId && (
                  <>
                    <ConfirmButton
                      title="Publish"
                      buttonType="primary"
                      icon={<UploadOutlined />}
                      onConfirm={() => handlePublishExam()}
                      disabled={isDisabledFlow(status, FlowCategory.Published)}
                    />
                    <ConfirmButton
                      title="Unpublish"
                      buttonType="default"
                      icon={<DownloadOutlined />}
                      onConfirm={() => handleController(unPublishExams([examId]), FlowCategory.UnPublished)}
                      disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
                    />
                    <ConfirmButton
                      title="Reject"
                      buttonType="dashed"
                      icon={<CloseOutlined />}
                      onConfirm={() => handleController(rejectExams([examId]), FlowCategory.RequestEdit)}
                      disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
                    />
                  </>
                )}
                <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Exams)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
      {/* <div className="explain-container">
        <NoteText
          style={{ margin: "4px 0 4px 22px", fontSize: "14px" }}
          text={NoteMessage.ClickSaveToCheckQuestionTopic}
        />
        <Descriptions>
          <Descriptions.Item label="Chú thích" className="">
            <div className="list-icons">
              <span className="icon-item" style={{ color: "#2abb2a" }}>
                <CheckOutlined />
                <span>Đã có topic</span>
              </span>
              <span className="icon-item" style={{ color: "#ff4d4f" }}>
                <CloseOutlined />
                <span>Chưa có topic</span>
              </span>
              <span className="icon-item" style={{ color: "#1677ff" }}>
                <ExclamationOutlined />
                <span>Chưa có thông tin topic</span>
              </span>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div> */}
    </>
  );
};

export default UpdateExam;
