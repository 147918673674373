import instance, { apiConfig } from "./instance";

const trackingUrl = "/tracking/v1/admin";

export interface ITrackingFilter {
  startTime: string;
  endTime: string;
  route?: string;
  isPremium?: boolean;
  minRouteDuration?: number;
  minSessionDuration?: number;
  paymentMethod?: number;
}

export const calcSessionTracking = (filter: ITrackingFilter) => {
  return instance.post(`${trackingUrl}/calc-tracking-time`, filter, apiConfig());
};
