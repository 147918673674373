import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";
import SingleFileInput, { FileData } from "../UploadInput/UploadInput";

export const FormSingleFileInput = ({
  label,
  name,
  initValue,
  folder,
  isGif = false,
  handleUpload,
  handleRemove,
}: {
  label: string;
  name: NamePath;
  initValue?: string;
  folder?: string;
  isGif?: boolean;
  handleUpload: (field: NamePath, data: FileData) => void;
  handleRemove?: (field: NamePath) => void;
}) => {
  return (
    <Form.Item name={name} label={label}>
      <SingleFileInput
        initValue={initValue}
        inputId={name.toString()}
        folder={folder}
        isGif={isGif}
        onData={(data: FileData) => handleUpload(name, data)}
        onRemove={() => handleRemove && handleRemove(name)}
      />
    </Form.Item>
  );
};
