/* eslint-disable @typescript-eslint/no-unused-vars */
import { EyeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { ELECTIVE_OPTIONS, GENDER } from "../../../constants/filter";
import "./UpdateEmployee.scss";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import { addEmployee, editEmployee, getEmployeeById } from "../../../services/EmployeeServices";
import { IEmployee } from "../../../interfaces/IEmployee";
import dayjs from "dayjs";

const UpdateEmployee = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const employeeId = useRef<string>(params.id || "");

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      const employee = form.getFieldsValue() as IEmployee;
      employee.birthday = dayjs(new Date(employee.birthday)).format("DD/MM/YYYY");
      let response: any;
      if (employeeId.current) {
        response = await editEmployee(employeeId.current, employee);
      } else {
        response = await addEmployee(employee);
      }
      employeeId.current = response.result ? response.result : employeeId.current;
      Modal.success({
        title: "Success",
        type: "success",
        content: `${employeeId.current ? "Updated" : "Added"} successfully`,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (employeeId.current) {
        const res = (await getEmployeeById(employeeId.current)) as any;
        if (res?.errorCode !== 200) return;
        form.setFieldsValue({
          fullName: res.result.fullName,
          birthday: dayjs(res.result.birthday, "DD/MM/YYYY"),
          gender: res.result.gender,
          phone: res.result.phone,
          email: res.result.email,
          address: res.result.address,
          active: res.result.active,
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Manager)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId.current, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-employee-container">
      <Form form={form} onFinish={handleSave}>
        <div className="form-input">
          <Row>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Full name" name="fullName" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <Form.Item label="Birthday" name="birthday">
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormSelect label="Gender" name="gender" optionData={GENDER} allowClear />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Phone" name="phone" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Email" name="email" rules={[{ required: true }, { type: "email" }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Address" name="address" type="address" />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={6}>
              <FormSelect label="Active" name="active" optionData={ELECTIVE_OPTIONS} allowClear />
            </Col>
          </Row>
        </div>
        <div className="form-controller">
          <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
            Save
          </Button>
          <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Employees)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateEmployee;
