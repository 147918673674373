import { PlusOutlined, MinusOutlined, SaveOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Form, Card, Modal, Collapse } from "antd";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import { COMPLEXITY_OPTIONS } from "../../../constants/complexities";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { editExamBranches, getAnExam, getRoadMapById } from "../../../services/RoadMapServices";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";

import "./UpdateRoadMap.scss";
import { PageUrl } from "../../../constants/url";
import { convertToSelectOption } from "../../../utils/utils";
import { getGrades, getUnits } from "../../../services/QuestionServices";

type Filter = {
  questionNumber: number;
  isElective: boolean;
  complexityIds: string[];
  unitIds: string[];
  topicId: string;
  unitList?: any[];
};

type ProblemSetFilter = {
  problemSetId: string;
  filters: Filter[];
};

type ExamBranch = {
  scoreFrom: number;
  scoreTo: number;
  problemSetFilters: ProblemSetFilter[];
};

const EditExamBranches = () => {
  const navigate = useNavigate();
  const { user } = useAuth()!;
  const params = useParams();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [roadMapId] = useState<string>(params.id || "");
  const [examId, setExamId] = useState<string>("");
  const [examList, setExamList] = useState<any[]>([]);
  const [problemSetList, setProblemSetList] = useState<any[]>([]);
  const [branches, setBranches] = useState<ExamBranch[]>([]);

  const [gradeList, setGradeList] = useState<any[]>([]);
  const [allUnits, setAllUnits] = useState<any>([]);

  const handleCreateBranch = () => {
    // create filter
    const filter = {
      questionNumber: 0,
      isElective: false,
      complexityIds: [],
      unitIds: [],
      topicId: "",
    };
    // create problem set filter
    const problemSetFilter = {
      problemSetId: "",
      filters: [filter],
    };
    const branch = {
      scoreFrom: 0,
      scoreTo: 0,
      problemSetFilters: [problemSetFilter],
    };
    setBranches([...branches, branch]);
  };

  const handleRemoveBranch = () => {
    branches.pop();
    setBranches([...branches]);
  };

  const handleSaveBranches = () => {
    try {
      form.validateFields().then(async () => {
        const formValues = form.getFieldsValue();
        const postData = {
          branches: formValues.branches?.map((item: any) => ({
            scoreFrom: Number(item.scoreFrom),
            scoreTo: Number(item.scoreTo),
            problemSetFilters: item.problemSetFilters?.map((problemSetFilter: any) => ({
              problemSetId: problemSetFilter.problemSetId,
              filters: problemSetFilter.filters.map((filter: any) => ({
                questionNumber: Number(filter.questionNumber),
                isElective: filter.isElective,
                complexityIds: filter.complexityIds,
                unitIds: filter.unitIds,
                topicId: filter.topicId,
              })),
            })),
          })),
        };
        let response: any;
        response = await editExamBranches(examId, roadMapId, postData);
        if (response.errorCode === 200) {
          Modal.success({
            title: "Success",
            type: "success",
            content: `Updated successfully`,
          });
        }
      });
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: `Updated failed`,
      });
    }
  };

  const getExamDetail = async (examId: string, roadMapId: string) => {
    try {
      const response = (await getAnExam(examId, roadMapId)) as any;
      if (response.errorCode === 200) {
        const data = response.result;
        // set problem set list
        setProblemSetList(() => data.problemSets.map((item: any) => ({ label: item.code, value: item._id })));
        // set branches
        setBranches(
          data.branches.map((item: any) => ({
            scoreFrom: item.scoreFrom,
            scoreTo: item.scoreTo,
            problemSetFilters: item.problemSetFilters.map((problemSetFilter: any) => ({
              problemSetId: problemSetFilter?.problemSetId,
              filters: problemSetFilter?.filters.map((filter: any) => {
                const gradeId = allUnits.find((unit: any) => unit._id === filter.units[0]?.id)?.gradeId;
                return {
                  questionNumber: filter.questionNumber,
                  isElective: filter.isElective,
                  complexities: filter.complexityIds,
                  gradeId,
                  unitList: allUnits.filter((unit: any) => unit.gradeId === gradeId),
                  unitIds: filter.units,
                  topicId: filter.topicId,
                };
              }),
            })),
          }))
        );
        // set form values
        setTimeout(() => {
          form.setFieldsValue({
            branches: data.branches.map((item: any) => ({
              scoreFrom: item.scoreFrom,
              scoreTo: item.scoreTo,
              problemSetFilters: item.problemSetFilters?.map((problemSetFilter: any) => ({
                problemSetId: problemSetFilter?.problemSetId,
                filters: problemSetFilter?.filters.map((filter: any) => {
                  const gradeId = allUnits.find((unit: any) => unit._id === filter.units[0]?.id)?.gradeId;
                  return {
                    questionNumber: filter.questionNumber,
                    isElective: filter.isElective,
                    complexityIds: filter.complexities?.map((item: any) => item.id),
                    gradeId,
                    unitIds: filter.units?.map((item: any) => item.id),
                    topicId: filter.topicId,
                  };
                }),
              })),
            })),
          });
        }, 250);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmExam = () => {
    const examId = form.getFieldValue("examId");
    examId && setExamId(examId);
    // get exam detail
    getExamDetail(examId, roadMapId);
  };

  useEffect(() => {
    const getRoadMap = async () => {
      const response = await getRoadMapById(roadMapId);
      if (response) {
        const data = (response as any).result;
        form.setFieldValue("roadMapId", data.name);
        if (data.exams.length > 0) {
          setExamList(data.exams.map((item: any) => ({ label: item.name, value: item._id })));
        }
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes, unitRes] = await Promise.all([getGrades(), getUnits()]);
      gradeRes && setGradeList((gradeRes as any).result);
      unitRes && setAllUnits(() => (unitRes as any).result.records);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      roadMapId && (await getRoadMap());
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadMapId]);

  return isLoading ? (
    <></>
  ) : (
    <Card title="Edit exam branches">
      <Form form={form} onFinish={handleSaveBranches} className="edit-exam-branches-container">
        <div style={{ display: "flex", gap: "16px", justifyContent: "center" }} className="choose-exam">
          <FormInput label="Road map" name="roadMapId" disabled />
          <FormSelect
            label="Select exam"
            name="examId"
            optionData={examList}
            allowClear
            rules={[{ required: true, message: "Please select an exam" }]}
            disabled={!!examId}
          />
          <Button type="primary" icon={<CheckOutlined />} onClick={handleConfirmExam} disabled={!!examId}>
            Confirm
          </Button>
          {!examId && (
            <Button type="primary" icon={<CloseOutlined />} danger onClick={() => navigate(PageUrl.RoadMap)}>
              Cancel
            </Button>
          )}
        </div>

        {examId && branches.length > 0 && (
          <Collapse defaultActiveKey={branches.map((_, index) => index)} className="branch-container">
            {branches.map((item, index) => (
              <Collapse.Panel header={`Branch ${index + 1}`} key={index}>
                <span key={index} style={{ position: "relative" }}>
                  <div style={{ display: "flex", gap: "16px" }}>
                    <FormInput
                      label="Score from"
                      name={["branches", index, "scoreFrom"]}
                      rules={[{ required: true, message: "Please enter a value" }]}
                      type="number"
                    />
                    <FormInput
                      label="Score to"
                      name={["branches", index, "scoreTo"]}
                      rules={[{ required: true, message: "Please enter a value" }]}
                      type="number"
                    />
                  </div>
                  <div className="problem-set-filters-container">
                    {item.problemSetFilters.map((problemSetFilter, problemSetIndex) => (
                      <span
                        key={problemSetIndex}
                        style={{
                          position: "relative",
                          display: "flex",
                          gap: "16px",
                          padding: "16px",
                          border: "1px dashed #000",
                          borderRadius: "8px",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        <FormSelect
                          label="Select problem set"
                          name={["branches", index, "problemSetFilters", problemSetIndex, "problemSetId"]}
                          optionData={problemSetList}
                          allowClear
                          rules={[{ required: true, message: "Please select a problem set" }]}
                        />

                        <div className="filters-container" style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                          {problemSetFilter.filters.map((filter, filterIndex) => (
                            <span key={filterIndex} style={{ position: "relative" }}>
                              <FormInput
                                label="No. questions"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "questionNumber",
                                ]}
                                rules={[{ required: true, message: "Please enter a value" }]}
                                type="number"
                              />
                              <FormSelect
                                label="Elective"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "isElective",
                                ]}
                                optionData={ELECTIVE_OPTIONS}
                                allowClear
                              />
                              <FormSelect
                                label="Complexities"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "complexityIds",
                                ]}
                                optionData={COMPLEXITY_OPTIONS}
                                mode="multiple"
                                allowClear
                              />
                              <FormSelect
                                label="Grade"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "gradeId",
                                ]}
                                optionData={convertToSelectOption(gradeList, "_id", "name")}
                                callback={(id: any) => {
                                  form.setFieldValue(
                                    [
                                      "branches",
                                      index,
                                      "problemSetFilters",
                                      problemSetIndex,
                                      "filters",
                                      filterIndex,
                                      "unitIds",
                                    ],
                                    []
                                  );
                                  if (id) {
                                    const units = allUnits.filter((unit: any) => unit.gradeId === id);
                                    branches[index].problemSetFilters[problemSetIndex].filters[filterIndex].unitList =
                                      units;
                                  } else {
                                    branches[index].problemSetFilters[problemSetIndex].filters[filterIndex].unitList =
                                      [];
                                  }
                                  setBranches([...branches]);
                                }}
                                allowClear
                              />
                              <FormSelect
                                mode="multiple"
                                label="Units"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "unitIds",
                                ]}
                                optionData={convertToSelectOption(
                                  branches[index]?.problemSetFilters[problemSetIndex]?.filters[filterIndex]
                                    ?.unitList as any[],
                                  "_id",
                                  "unitName"
                                )}
                                allowClear
                              />
                              <FormInput
                                label="Topic id (653793b907113170d80e6149)"
                                name={[
                                  "branches",
                                  index,
                                  "problemSetFilters",
                                  problemSetIndex,
                                  "filters",
                                  filterIndex,
                                  "topicId",
                                ]}
                              />
                              {filterIndex === problemSetFilter.filters.length - 1 && (
                                <span className="control" style={{ top: "160px", right: "-60px" }}>
                                  <Button
                                    type="ghost"
                                    block
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      // create filter
                                      const filter = {
                                        questionNumber: 0,
                                        isElective: false,
                                        complexityIds: [],
                                        unitIds: [],
                                        topicId: "",
                                      };

                                      problemSetFilter.filters.push(filter);
                                      setBranches([...branches]);
                                    }}
                                  />
                                  <Button
                                    style={
                                      problemSetFilter.filters.length > 1 ? { display: "block" } : { display: "none" }
                                    }
                                    type="ghost"
                                    block
                                    icon={<MinusOutlined />}
                                    onClick={() => {
                                      problemSetFilter.filters.pop();
                                      setBranches([...branches]);
                                    }}
                                  />
                                </span>
                              )}
                            </span>
                          ))}
                        </div>
                      </span>
                    ))}
                    <div style={{ display: "flex", gap: "16px" }}>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          // create problem set filter
                          const filter = {
                            problemSetId: "",
                            filters: [
                              {
                                questionNumber: 0,
                                isElective: false,
                                complexityIds: [],
                                unitIds: [],
                                topicId: "",
                              },
                            ],
                          };
                          item.problemSetFilters.push(filter);
                          setBranches([...branches]);
                        }}
                      >
                        Add problem set
                      </Button>
                      {item.problemSetFilters.length > 1 && (
                        <Button
                          danger
                          icon={<MinusOutlined />}
                          onClick={() => {
                            if (item.problemSetFilters.length > 1) {
                              item.problemSetFilters.pop();
                              setBranches([...branches]);
                            }
                          }}
                        >
                          Remove last problem set
                        </Button>
                      )}
                    </div>
                  </div>
                </span>
              </Collapse.Panel>
            ))}
          </Collapse>
        )}

        {examId && (
          <div className="exam-control" style={{ display: "flex", gap: "16px" }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateBranch}>
              Add branch
            </Button>
            {branches.length > 1 && (
              <Button type="dashed" danger icon={<MinusOutlined />} onClick={handleRemoveBranch}>
                Remove last branch
              </Button>
            )}

            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} style={{ backgroundColor: "#2abb2a" }}>
              Save branches
            </Button>
            <Button type="primary" danger icon={<CloseOutlined />} onClick={() => navigate(PageUrl.RoadMap)}>
              Cancel
            </Button>
          </div>
        )}
      </Form>
    </Card>
  );
};

export default EditExamBranches;
