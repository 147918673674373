import { Modal } from "antd";
import axios, { AxiosError, ResponseType } from "axios";
import Cookies from "universal-cookie";

export const apiConfig = (params?: any) => {
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");
  return {
    headers: { Authorization: `Bearer ${token}` },
    params,
  };
};

export const apiExportFileConfig = (responseType: ResponseType, params?: any) => {
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");
  return {
    headers: { Authorization: `Bearer ${token}` },
    params,
    responseType: responseType,
  };
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    Modal.error({
      title: "Error",
      type: "error",
      content: `${((error as AxiosError)?.response?.data as any)?.errorMessage}`,
    });
    return Promise.reject(error);
  }
);

export default instance;
