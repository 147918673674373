import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Space, Table, Tooltip, message } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import Loading from "../../components/Loading/Loading";
import { MONTHLY_MISSION_STATUSES } from "../../configs/monthlyMissionConfigs";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { PageUrl } from "../../constants/url";
import { useAuth } from "../../hooks/useAuth";
import { IMonthlyMissionFilter } from "../../interfaces/filter";
import { getMonthlyMissions } from "../../services/MonthlyMissionServices";
import { customSessionStorage } from "../../utils/storage";
import "./index.scss";

const MonthlyMissionPortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IMonthlyMissionFilter>({ page: 1, size: 10 });

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterMonthlyMission", formValues);

    formValues.startDate = formValues.startDate ? dayjs(formValues.startDate).startOf("day").utc().format() : null;
    formValues.endDate = formValues.endDate ? dayjs(formValues.endDate).startOf("day").utc().format() : null;

    filterData.current = { ...filterData.current, ...formValues };
    filterData.current.page = 1;
    fetchMonthlyMissions();
  };

  const fetchMonthlyMissions = async () => {
    const response = await getMonthlyMissions(filterData.current);
    if (response) {
      setTableData((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchMonthlyMissions();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchMonthlyMissions();
  };

  const handleEditMonthlyMission = async (id: string) => {
    navigate(`${PageUrl.MonthlyMission}${PageUrl.Update}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (value: number) => {
          const index = MONTHLY_MISSION_STATUSES.findIndex((item) => item.value === value);
          return <>{MONTHLY_MISSION_STATUSES[index].label}</>;
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "center",
      },
      {
        title: "Start date",
        dataIndex: "startDate",
        key: "startDate",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "startDate" },
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY HH:mm") : ""),
      },
      {
        title: "End date",
        dataIndex: "endDate",
        key: "endDate",
        align: "center",
        width: 150,
        sorter: { multiple: 5, field: "endDate" },
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY HH:mm") : ""),
      },
      {
        title: "Created at",
        dataIndex: ["recordInfo", "createdAt"],
        key: "createdAt",
        align: "center",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        width: 150,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY HH:mm") : ""),
      },
      {
        title: "Updated at",
        dataIndex: ["recordInfo", "updatedAt"],
        key: "updatedAt",
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        width: 150,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY HH:mm") : ""),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 100,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            <>
              <Space size="middle">
                <Tooltip title="Edit monthlyMission">
                  <Button icon={<EditOutlined />} onClick={() => handleEditMonthlyMission(record._id)} />
                </Tooltip>
              </Space>
            </>
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (sessionStorage.getItem("filterMonthlyMission")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterMonthlyMission")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterMonthlyMission");
        } else {
          form.setFieldsValue({
            filter: sessionFilter.filter,
            gradeId: sessionFilter.gradeId,
            status: sessionFilter.status,
            startDate: sessionFilter.startDate ? dayjs(sessionFilter.startDate) : "",
            endDate: sessionFilter.endDate ? dayjs(sessionFilter.endDate) : "",
          });
          const filters: IMonthlyMissionFilter = {
            filter: sessionFilter["filter"] || "",
            status: sessionFilter["status"] || "",
            startDate: sessionFilter.startDate
              ? (dayjs(sessionFilter.startDate).startOf("day").utc().format() as string)
              : "",
            endDate: sessionFilter.endDate
              ? (dayjs(sessionFilter.endDate).startOf("day").utc().format() as string)
              : "",
          };
          filterData.current = { ...filterData.current, ...filters };
        }
        filterData.current.sort = `recordInfo.createdAt,desc`;
        fetchMonthlyMissions();
      } else {
        fetchMonthlyMissions();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="monthly-mission-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ gap: "16px" }}>
              <Col xs={6} md={6} xl={6}>
                <FormInput label="Filter" name="filter" />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect label="Status" name="status" optionData={MONTHLY_MISSION_STATUSES} allowClear />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <Form.Item label="Start date" name="startDate">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ gap: "16px" }}>
              <Col xs={6} md={6} xl={6}>
                <Form.Item label="End date" name="endDate">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6}>
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.MonthlyMission}${PageUrl.Create}`)}
            >
              Add a monthly mission
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={tableData}
            columns={getColumn()}
            pagination={{
              total: tableTotal.current,
              showSizeChanger: false,
              onChange: handleChangePage,
            }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyMissionPortal;
