/* eslint-disable @typescript-eslint/no-unused-vars */
import "./UpdateAchievement.scss";
import { EyeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import { addAchievement, editAchievement, getAchievementById } from "../../../services/AchievementServices";
import { IAchievement } from "../../../interfaces/IAchievement";
import { ACHIEVEMENT_MENU_ITEMS, ACHIEVEMENT_TYPES } from "../../../configs/achievementConfigs";
import { FormSingleFileInput } from "../../../components/Form/SingleFileInput";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../../../components/UploadInput/UploadInput";

const UpdateAchievement = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const achievementId = useRef<string>(params.id || "");

  const folder = "achievements/";

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      const achievement = form.getFieldsValue() as IAchievement;
      achievement.points = Number(achievement.points) || 0;
      achievement.tokenAward = Number(achievement.tokenAward) || 0;
      achievement.type = Number(achievement.type);
      achievement.menu = Number(achievement.menu);
      achievement.order = Number(achievement.order);
      let response: any;
      if (achievementId.current) {
        response = await editAchievement(achievementId.current, achievement);
      } else {
        response = await addAchievement(achievement);
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${achievementId.current ? "Updated" : "Added"} successfully`,
      });
      achievementId.current = response.result ? response.result : achievementId.current;
    } catch (error) {}
  };
  const handleLoadImage = (name: NamePath, imageData: FileData) => {
    form.setFieldValue(name, imageData?.fileLink);
  };

  const handleClearImage = (name: NamePath) => {
    form.setFieldValue(name, "");
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (achievementId.current) {
        const res = (await getAchievementById(achievementId.current)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          form.setFieldsValue(res.result);
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-achievement-container">
      <Form form={form} onFinish={handleSave}>
        <div className="form-input">
          <Row>
            <Col xs={6} md={6} xl={6}>
              <FormInput label="Name" name="name" rules={[{ required: true }]} />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormInput type="number" label="Points" name="points" />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormInput type="number" label="Token award" name="tokenAward" />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormInput type="number" label="Order" name="order" rules={[{ required: true }]} />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6} xl={6}>
              <FormSelect label="Menu" name="menu" optionData={ACHIEVEMENT_MENU_ITEMS} rules={[{ required: true }]} />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormSelect label="Type" name="type" optionData={ACHIEVEMENT_TYPES} rules={[{ required: true }]} />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormSelect label="Visible" name="visible" optionData={ELECTIVE_OPTIONS} rules={[{ required: true }]} />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6} xl={6}>
              <FormSingleFileInput
                label={`Achieved`}
                name="achievedImageUrl"
                initValue={form.getFieldValue("achievedImageUrl")}
                handleUpload={handleLoadImage}
                handleRemove={handleClearImage}
                folder={folder}
              />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormSingleFileInput
                label={`Unachieved`}
                name="unachievedImageUrl"
                initValue={form.getFieldValue("unachievedImageUrl")}
                handleUpload={handleLoadImage}
                handleRemove={handleClearImage}
                folder={folder}
              />
            </Col>
            <Col xs={6} md={6} xl={6}>
              <FormInput label="Description" name="description" />
            </Col>
          </Row>
        </div>
        <div className="form-controller">
          <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
            Save
          </Button>
          <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Achievements)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateAchievement;
