import { ILessonFilter, IUnitFilter } from "./../interfaces/filter";
import instance, { apiConfig } from "./instance";

const practiceUrl = "/practice/v1/admin";
const categoryUrl = "/category/v1/admin";
const lessonUrl = "/lesson/v1/admin";

export const getTags = () => {
  return instance.get(`${practiceUrl}/question-tag/get-list-tags`, apiConfig());
};

export const getGrades = () => {
  return instance.get(`${categoryUrl}/grade/get-grade-category`, apiConfig({ page: 1, size: 100 }));
};

export const getUnits = (postData?: IUnitFilter) => {
  return instance.get(`${lessonUrl}/unit/get-list-units`, apiConfig(postData));
};

export const addUnit = (data: any) => {
  return instance.put(`${lessonUrl}/unit/create-new-unit`, data, apiConfig());
};

export const editUnit = (id: string, data: any): Promise<any> => {
  return instance.post(`${lessonUrl}/unit/edit-an-unit/${id}`, data, apiConfig());
};

export const getLessonsByUnitId = (gradeId: string, unitId: string) => {
  return instance.get(`${lessonUrl}/lesson/get-list-lessons/`, apiConfig({ gradeId, unitId }));
};

export const getComplexities = () => {
  return instance.get(`${categoryUrl}/complexity/get-complexity-category`, apiConfig());
};

export const getFields = () => {
  return instance.get(`${categoryUrl}/field/get-field-category`, apiConfig());
};

export const getLessons = (postData: ILessonFilter) => {
  const data = { ...postData, status: postData.status?.join(",") };
  return instance.get(`${lessonUrl}/lesson/get-list-lessons`, apiConfig(data));
};

export const getLessonById = (id: string) => {
  return instance.get(`${lessonUrl}/lesson/get-a-lesson/${id}`, apiConfig());
};

export const addLesson = (data: any) => {
  return instance.put(`${lessonUrl}/lesson/create-new-lesson`, data, apiConfig());
};

export const editLesson = (id: string, data: any): Promise<any> => {
  return instance.post(`${lessonUrl}/lesson/edit-a-lesson/${id}`, data, apiConfig());
};

export const editMobileSections = (id: string, data: any): Promise<any> => {
  return instance.post(`${lessonUrl}/lesson/edit-lesson-mobile-sections/${id}`, data, apiConfig());
};

export const publishLessons = (listId: string[]) => {
  return instance.post(`${lessonUrl}/lesson/publish-lessons`, listId, apiConfig());
};

export const unPublishLessons = (listId: string[]) => {
  return instance.post(`${lessonUrl}/lesson/unpublish-lessons`, listId, apiConfig());
};

export const rejectLessons = (listId: string[]) => {
  return instance.post(`${lessonUrl}/lesson/reject-lessons`, listId, apiConfig());
};

export const submitLessons = (listId: string[]) => {
  return instance.post(`${lessonUrl}/lesson/submit-lessons`, listId, apiConfig());
};
