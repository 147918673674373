export enum ExamType {
  Monthly = 0,
  Year = 1,
}

export const EXAM_TYPE_OPTIONS = [
  { label: "Monthly", value: 0 },
  { label: "Year", value: 1 },
]

export const MONTHLY_IMAGES = ['Hub back 1', 'Hub front 1', 'Hub back 2', 'Activity back', 'Month back 1', 'Month front 1', 'Month back 2']