import { S3Client } from "@aws-sdk/client-s3";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

// Create S3 service object
const s3Client = new S3Client({
  region: "ap-southeast-1",
  credentials: {
    accessKeyId: accessKeyId as string,
    secretAccessKey: secretAccessKey as string,
  },
});

export const sendCommandToS3 = async (command: any) => {
  try {
    const response = await s3Client.send(command);
    console.log("success", command.input);
    return Promise.resolve(response);
  } catch (err) {
    console.log("err:", command.input, err);
  }
};
