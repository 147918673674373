import axios from "axios";
import { apiConfig } from "./instance";

const utilVbeeUrl = "/lesson/v1/admin";

const customInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const getVbeeS3AudioLink = (data: any) => {
  return customInstance.post(`${utilVbeeUrl}/lesson/vbeeAudio`, data, apiConfig());
};

export const getVbeeS3AudioLinkStory = (data: any) => {
  return customInstance.post(`${utilVbeeUrl}/story/vbeeAudio`, data, apiConfig());
};
