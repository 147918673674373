import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ELECTIVE_OPTIONS, STATUS_OPTIONS } from "../../constants/filter";
import { IExamFilter } from "../../interfaces/filter";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { previewWindow } from "../../utils/preview";
import { useAuth } from "../../hooks/useAuth";
import { FlowCategory, isDisabledFlow } from "../../constants/flow";
import { PageUrl, PreviewUrl } from "../../constants/url";
import { getExams, publishExams, rejectExams, submitExams, unPublishExams } from "../../services/ExamServices";
import { getGrades } from "../../services/LessonServices";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { EXAM_TYPE_OPTIONS, ExamType } from "../../configs/examConfigs";
import { customSessionStorage } from "../../utils/storage";

const ExamPortal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user, token } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [gradeList, setGradeList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const filterData = useRef<IExamFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterExam", formValues);

    filterData.current = {
      ...filterData.current,
      ...{
        gradeId: formValues["gradeId"]?.join(",") || "",
        status: formValues["status"]?.join(",") || "",
        type: formValues["type"]?.join(",") || "",
        filter: formValues["filter"] || "",
        premium: formValues["premium"],
      },
    };
    filterData.current.page = 1;
    getListExams();
  };

  const getListExams = async () => {
    const response = await getExams(filterData.current);
    if (response) {
      setExamList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListExams();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListExams();
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 300,
      },
      {
        title: "Grade",
        dataIndex: "grades",
        key: "gradeName",
        align: "center",
        width: 150,
        render: (grades) => {
          const listGrades = grades.map((item: any) => item.name);
          return listGrades.join(",");
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "center",
        width: 150,
        render: (status: number) => {
          const index = EXAM_TYPE_OPTIONS.findIndex((item) => item.value === status);
          return <>{EXAM_TYPE_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 300,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && (
              <Space size="middle">
                <Tooltip title="Preview">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() =>
                      previewWindow({
                        url: PreviewUrl.Exam,
                        data: { token: token, examId: record._id },
                        directly: true,
                      }).then(() => navigate(PageUrl.Exams))
                    }
                  />
                </Tooltip>
              </Space>
            )}
            {user?.roles?.includes(UserRole.Reviewer) && (
              <>
                <Space size="middle">
                  <ConfirmButton
                    title="Publish"
                    tooltip={true}
                    icon={<UploadOutlined />}
                    onConfirm={() => handlePublishExam(record)}
                    disabled={isDisabledFlow(record.status, FlowCategory.Published)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Unpublish"
                    tooltip={true}
                    icon={<DownloadOutlined />}
                    onConfirm={() => handleUnPublishExam(record._id)}
                    disabled={isDisabledFlow(record.status, FlowCategory.UnPublished)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Reject"
                    tooltip={true}
                    icon={<CloseOutlined />}
                    onConfirm={() => handleRejectExam(record._id)}
                    disabled={isDisabledFlow(record.status, FlowCategory.RequestEdit)}
                  />
                </Space>
              </>
            )}
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleEditExam(record._id)}
                      disabled={isDisabledFlow(record.status, FlowCategory.Draft)}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Submit"
                    tooltip={true}
                    icon={<CheckOutlined />}
                    onConfirm={() => handleSubmitExam(record._id)}
                    disabled={isDisabledFlow(record.status, FlowCategory.Reviewing)}
                  />
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const handlePublishExam = async (record: any) => {
    try {
      if (record.type === ExamType.Monthly) {
        const res = (await getExams({
          page: 1,
          size: 100,
          gradeId: record.grades.map((grade: any) => grade.id)?.join(","),
          type: ExamType.Monthly,
          status: FlowCategory.Published.toString(),
        })) as any;
        const grades = record.grades.filter((grade: any) => {
          const filter: any[] = [];
          res.result.records.forEach((record: any) => {
            const isGrade = record.grades.find((grade1: any) => grade1.id === grade.id);
            isGrade && filter.push(record);
          });

          return filter.length >= 2;
        });

        if (grades.length) {
          Modal.error({
            type: "error",
            title: "Publish error",
            width: 500,
            content: (
              <>
                {grades.map((grade: any) => grade.name)?.join(",")} currently has two or more monthly exams published
                <br />
                Please check and try again!
              </>
            ),
          });
          return;
        } else {
          await publishExams([record._id]);
          getListExams();
        }
      } else {
        await publishExams([record._id]);
        getListExams();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnPublishExam = async (id: string) => {
    try {
      await unPublishExams([id]);
      getListExams();
    } catch (error) {
      console.log(error);
    }
  };
  const handleRejectExam = async (id: string) => {
    try {
      await rejectExams([id]);
      getListExams();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitExam = async (id: string) => {
    try {
      await submitExams([id]);
      getListExams();
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditExam = async (id: string) => {
    navigate(`${PageUrl.Exams}${PageUrl.Update}`.replace(":id", id));
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterExam")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterExam")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterExam");
        } else {
          filterData.current = {
            ...filterData.current,
            gradeId: sessionFilter["gradeId"]?.join(",") || "",
            status: sessionFilter["status"]?.join(",") || "",
            type: sessionFilter["type"]?.join(",") || "",
            filter: sessionFilter["filter"] || "",
            premium: sessionFilter["premium"],
          };
          form.setFieldsValue(sessionFilter);
        }
        getListExams();
      } else {
        getListExams();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="exam-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={4}>
                <FormInput label="Exam" name="filter" />
              </Col>
              <Col span={4}>
                <FormSelect
                  mode="multiple"
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                />
              </Col>
              <Col span={4}>
                <FormSelect mode="multiple" label="Type" name="type" optionData={EXAM_TYPE_OPTIONS} allowClear />
              </Col>
              <Col span={4}>
                <FormSelect mode="multiple" label="Status" name="status" optionData={STATUS_OPTIONS} allowClear />
              </Col>
              <Col span={4}>
                <FormSelect label="Premium" name="premium" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col span={1} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Exams}${PageUrl.Create}`)}
            >
              Create exam
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={examList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default ExamPortal;
