import {
  CheckOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  SaveOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Form, Button, Descriptions, Modal } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import {
  addStory,
  editStory,
  getStoryById,
  publishStories,
  rejectStories,
  submitStories,
  unPublishStories,
} from "../../../services/StoryServices";
import { getGrades } from "../../../services/QuestionServices";
import { cleanObject, convertToSelectOption, getAllKeys, randomId } from "../../../utils/utils";
import "./UpdateStory.scss";
import { useAuth } from "../../../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { INITIAL_SLIDE_STATE, INITIAL_STORY_STATE, addSlide, updateStory } from "../../../reducers/storySlice";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { debounce, merge } from "lodash";
import { previewWindow } from "../../../utils/preview";
import { UserRole } from "../../../constants/auth";
import { PageUrl, PreviewUrl } from "../../../constants/url";
import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { FlowCategory, isDisabledFlow } from "../../../constants/flow";
import { UploadJson } from "../../../components/UploadJson/UploadJson";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";
import { StorySlides } from "../../../components/StorySlides/ListSlides";
import { FormSingleFileInput } from "./../../../components/Form/SingleFileInput";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../../../components/UploadInput/UploadInput";
import { SlideContentType } from "../../../configs/storySlideConfigs";

const UpdateStory = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAuth();
  const { user, token } = currentUser!;

  const params = useParams();
  const storyId = useRef<string>(params.id || "");
  const story = useAppSelector((state) => state.story);
  const slides = story.slides;

  const [gradeList, setGradeList] = useState([]);

  const [isConfirmedGrade, setIsConfirmedGrade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<FlowCategory>(0);

  const handleSaveAndPreview = () => {
    try {
      form
        .validateFields()
        .then(async () => {
          let response: any;
          const cleanStory = cleanObject(story);
          cleanStory.slides?.forEach((slide: any) => {
            const explanation = slide?.explanation;
            explanation?.texts?.forEach((text: any, index: number) => {
              if (text === null) {
                explanation.texts[index] = "";
              }
            });
            explanation?.images?.forEach((image: any, index: number) => {
              if (image === null) {
                explanation.images[index] = "";
              }
            });

            if (slide.template.question && !slide.template.content) {
              slide.template.content = SlideContentType.NarratorQuestion;
            }
          });
          if (storyId.current) {
            response = await editStory(storyId.current, cleanStory);
          } else {
            response = await addStory(cleanStory);
          }
          Modal.success({
            title: "Success",
            type: "success",
            content: `${storyId.current ? "Updated" : "Added"} successfully`,
          });
          storyId.current = response.result ? response.result : storyId.current;
          previewWindow({ url: PreviewUrl.Story, data: { token, storyId: storyId.current } });
        })
        .catch((error) => {
          console.log(error);
          throw new Error(error);
        });
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: error instanceof Error ? error.message : "Invalid field",
      });
    }
  };

  const handleAddSlide = () => {
    const newKey = randomId();
    const newData = { ...INITIAL_SLIDE_STATE, slideKey: newKey };
    form.setFieldValue(["slides", slides.length], newData);
    dispatch(addSlide(newKey));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFormChange = (e: any) => {
    const allStoryKeys = getAllKeys(INITIAL_STORY_STATE);
    const allEventKeys = getAllKeys(e);
    const changedKey = allEventKeys[allEventKeys.length - 1];
    if (!allStoryKeys.includes(changedKey)) {
      return;
    }

    if (changedKey === "solutions" || changedKey === "question") {
      return;
    }

    if (Object.keys(e).includes("order")) {
      e.order = Number(e.order);
    }

    if (allEventKeys.includes("template") && allEventKeys.includes("content")) {
      e.slides.forEach((slide: any) => {
        if (slide.template?.content !== SlideContentType.NarratorQuestion) {
          slide.template.question = "";
        }
        if (slide.template?.content !== SlideContentType.Right && slide.template?.content !== SlideContentType.Left) {
          slide.template.username = "";
          slide.template.avatar = "";
        }
      });
    }

    let data = { ...story };
    data = merge({}, data, e);
    dispatch(updateStory(data));
  };

  const handleChangeStoryStatus = async (func: any, nextStatus: FlowCategory) => {
    try {
      const res = await func.then();
      if (res) {
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });

        setStatus(nextStatus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadJon = (data: any) => {
    const newSlides = data.slides.map((slide: any, index: number) => {
      form.setFieldValue(["slides", index], slide);
      slide.slides.forEach((slide: any, i: number) => {
        form.setFieldValue(["slides", index, "slides", i], slide);
        slide = { ...slide, slideKey: randomId() };
        return slide;
      });
      slide = { ...slide, slideKey: randomId() };
      return slide;
    });
    let newData = { ...story };
    newData = merge({}, newData, { slides: newSlides });
    dispatch(updateStory(newData));
  };

  const handleExportJson = async () => {
    const storyRes = await getStoryById(storyId.current);
    if (storyRes) {
      const storyData = (storyRes as any).result;
      const data = JSON.stringify(storyData);
      const a = document.createElement("a");
      const blob = new Blob([data], { type: "application/json" });
      a.href = window.URL.createObjectURL(blob);
      a.download = `${storyData.title}.json`;
      a.click();
    }
  };

  const handleLoadImage = (name: NamePath, imageData: FileData) => {
    // set image to form
    form.setFieldValue(name, imageData?.fileLink && imageData.fileLink);

    // set image to story state
    let newData = { ...story };
    const key = name as string;
    newData = merge({}, newData, { [key]: imageData?.fileLink });
    dispatch(updateStory(newData));
  };

  const handleRemoveImage = (name: NamePath) => {
    form.setFieldValue(name, "");
  };

  useEffect(() => {
    const getStory = async () => {
      const storyRes = await getStoryById(storyId.current);
      if (storyRes) {
        const storyData = (storyRes as any).result;
        setStatus(storyData.status);
        storyData.gradeId = storyData.grade?.id;
        form.setFieldsValue(storyData);
        if (storyData.gradeId) {
          setIsConfirmedGrade(true);
          storyData.imageFolder = `story/${storyData.grade?.name}/`;
          storyData.backgroundImage = storyData.backgroundImage || "";
          storyData.thumbnailImage = storyData.thumbnailImage || "";
        }
        storyData.slides = storyData.slides.map((slide: any) => {
          slide = { ...slide, slideKey: randomId() };
          return slide;
        });
        dispatch(updateStory(storyData));
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      storyId.current && (await getStory());
    };

    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId.current]);

  useEffect(() => {
    form.resetFields();
    dispatch(updateStory(INITIAL_STORY_STATE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceHandleChange = useMemo(() => debounce(handleFormChange, DEFAULT_TIMEOUT), [handleFormChange]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-story-container">
      <Form form={form} onFinish={handleSaveAndPreview} onValuesChange={debounceHandleChange}>
        <div className="form-input">
          <Descriptions>
            <Descriptions.Item label="Story info" className="des-item">
              <FormInput
                label="Title"
                name="title"
                rules={[{ required: true, message: "Invalid field" }]}
                latexString={story.title}
                disabled={isConfirmedGrade}
              />
              <FormSelect
                label="Grade"
                name="gradeId"
                optionData={convertToSelectOption(gradeList, "_id", "name")}
                rules={[{ required: true, message: "Invalid field" }]}
                disabled={isConfirmedGrade}
              />
              {isConfirmedGrade && (
                <div style={{ width: "100%", display: "flex", gap: "16px" }}>
                  <FormSingleFileInput
                    name="backgroundImage"
                    label="Background Image"
                    initValue={story.backgroundImage || ""}
                    folder={story.imageFolder}
                    handleUpload={handleLoadImage}
                    handleRemove={handleRemoveImage}
                  />
                  <FormSingleFileInput
                    name="thumbnailImage"
                    label="Thumbnail Image"
                    initValue={story.thumbnailImage || ""}
                    folder={story.imageFolder}
                    handleUpload={handleLoadImage}
                    handleRemove={handleRemoveImage}
                  />
                  <FormInput label="Description" name="description" />
                </div>
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {!isConfirmedGrade && (
          <>
            <NoteText style={{ margin: "4px 0 0 100px" }} text={NoteMessage.FillGradeAndUnitFirst} />
            <Button
              type="primary"
              style={{ marginLeft: "100px" }}
              onClick={() => {
                setIsConfirmedGrade(true);
                let newData = { ...story };
                const grade = gradeList.find((grade: any) => grade._id === story.gradeId) as any;
                if (grade) {
                  newData = merge({}, newData, { imageFolder: `story/${grade.name}/` });
                  dispatch(updateStory(newData));
                }
              }}
              disabled={!story.gradeId}
            >
              Confirm
            </Button>
          </>
        )}
        {slides.length && isConfirmedGrade ? (
          <>
            <div style={{ margin: "0 0 16px 100px" }}>
              <UploadJson onData={handleUploadJon} />
              {storyId.current && (
                <Button
                  type="primary"
                  icon={<ExportOutlined />}
                  style={{ backgroundColor: "#2abb2a" }}
                  onClick={handleExportJson}
                >
                  Export JSON
                </Button>
              )}
            </div>
            <div className="list-slides">
              <StorySlides />
            </div>
          </>
        ) : (
          <></>
        )}
        {isConfirmedGrade && (
          <>
            <NoteText style={{ margin: "4px 0" }} text={NoteMessage.SaveChangeBeforeAction} />
            <div className="form-controller">
              {user?.roles?.includes(UserRole.ContentCreator) && (
                <>
                  <Button type="default" icon={<PlusOutlined />} onClick={handleAddSlide}>
                    Add slide
                  </Button>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{ backgroundColor: "#2abb2a" }}
                    onClick={handleSaveAndPreview}
                  >
                    Save and preview
                  </Button>
                  <ConfirmButton
                    title="Submit"
                    buttonType="primary"
                    icon={<CheckOutlined />}
                    onConfirm={() => handleChangeStoryStatus(submitStories([storyId.current]), FlowCategory.Reviewing)}
                    disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !storyId.current}
                  />
                </>
              )}
              {user?.roles?.includes(UserRole.Reviewer) && storyId.current && (
                <>
                  <ConfirmButton
                    title="Publish"
                    buttonType="primary"
                    icon={<UploadOutlined />}
                    onConfirm={() => handleChangeStoryStatus(publishStories([storyId.current]), FlowCategory.Published)}
                    disabled={isDisabledFlow(status, FlowCategory.Published)}
                  />
                  <ConfirmButton
                    title="Unpublish"
                    buttonType="default"
                    icon={<DownloadOutlined />}
                    onConfirm={() =>
                      handleChangeStoryStatus(unPublishStories([storyId.current]), FlowCategory.UnPublished)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
                  />
                  <ConfirmButton
                    title="Reject"
                    buttonType="dashed"
                    icon={<CloseOutlined />}
                    onConfirm={() =>
                      handleChangeStoryStatus(rejectStories([storyId.current]), FlowCategory.RequestEdit)
                    }
                    disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
                  />
                </>
              )}
              <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Stories)}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default UpdateStory;
