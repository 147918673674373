import { SectionItem } from "./SectionItem";
import CustomCollapse from "../CustomCollapse/CustomCollapse";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { INITIAL_SECTION_STATE, moveSection, removeSection, addMobileSectionAbove } from "../../reducers/lessonSlice";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { ISection } from "../../interfaces/ILesson";
import { randomId } from "../../utils/utils";

export const ListSections = () => {
  const dispatch = useAppDispatch();
  const form = useFormInstance();
  const mobileSections = useAppSelector((state) => state.lesson.mobileSections);

  const handleMove = ({ index, direction }: { index: number; direction: "up" | "down" }) => {
    const formSections = form.getFieldValue(["mobileSections"]);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex >= formSections?.length) return;
    const section = formSections[index];
    formSections.splice(index, 1);
    formSections.splice(newIndex, 0, section);
    form.setFieldValue(["mobileSections"], formSections);
    dispatch(moveSection({ index, direction }));
  };

  const handleRemove = (index: number) => {
    const formSections = form.getFieldValue(["mobileSections"]);
    formSections.splice(index, 1);
    form.setFieldValue(["mobileSections"], formSections);
    dispatch(removeSection(index));
  };

  const handleAddAbove = (index: number) => {
    const newKey = randomId();
    const newData = { ...INITIAL_SECTION_STATE, sectionKey: newKey };

    const formSections = form.getFieldValue(["mobileSections"]);
    formSections.splice(index, 0, newData);
    form.setFieldValue(["mobileSections"], formSections);

    dispatch(addMobileSectionAbove({ sectionIndex: index, sectionKey: newKey }));
  };

  return (
    <>
      {mobileSections?.map((section: ISection, index) => (
        <CustomCollapse
          key={section.sectionKey}
          title={section.sectionName ? `Section: ${section.sectionName}` : `Section ${index}`}
          children={<SectionItem key={section.sectionKey} index={index} />}
          move={(e) => handleMove({ index, direction: e })}
          removeItem={() => handleRemove(index)}
          addAbove={() => handleAddAbove(index)}
        />
      ))}
    </>
  );
};
