import instance, { apiConfig } from "./instance";
import { IDailyQuizEventFilter } from "../interfaces/filter";

const accountUrl = "/practice/v1/admin/daily-quiz-event";

export const getDailyQuizEvents = (filter: IDailyQuizEventFilter) => {
  return instance.get(`${accountUrl}/get-list-events`, apiConfig(filter));
};

export const getDailyQuizEventById = (id: string) => {
  return instance.get(`${accountUrl}/get-an-event/${id}`, apiConfig());
};

export const addDailyQuizEvent = (data: any) => {
  return instance.put(`${accountUrl}/create-new-event`, data, apiConfig());
};

export const editDailyQuizEvent = (id: string, data: any): Promise<any> => {
  return instance.post(`${accountUrl}/edit-an-event/${id}`, data, apiConfig());
};
