export enum PageUrl {
  Default = "/",
  Signin = "/signin",
  Questions = "/questions",
  Lessons = "/lessons",
  Stories = "/stories",
  Units = "/units",
  Quizzes = "/quizzes",
  Exams = "/exams",
  Employees = "/employees",
  AccountAdmins = "/account-admins",
  AccountUsers = "/account-users",
  MonthExams = "/month-exams",
  Achievements = "/achievements",
  DailyQuizEvents = "/daily-quiz-events",
  Tracking = "/tracking",
  RoadMapCategory = "/road-map-category",
  RoadMap = "/road-map",
  EditExamBranches = "/edit-exam-branches/:id",
  Voucher = "/voucher",
  Calibration = "/calibration",
  MonthlyMission = "/monthly-mission",
  PreRegister = "/pre-register",
  
  AccountActivities = "/activities/:id",
  UpdateMobileSections = "/update-mobile-sections/:id",
  UpdateQuestionMobileVersion = "/update-question-mobile-version/:id",
  Update = "/update/:id",
  Create = "/create",
}

export enum PreviewUrl {
  Question = "preview-question",
  Lesson = "preview-lesson",
  Quiz = "preview-quiz",
  Exam = "preview-exam",
  Exercise = "preview-exercise",
  Story = "preview-story",
}
