import { Form, Select, Space, Tag, Tooltip } from "antd";
import { NamePath } from "rc-field-form/lib/interface";
import { ISelectOption } from "../../interfaces/filter";
import { Rule } from "antd/es/form";
import { InfoCircleOutlined } from "@ant-design/icons";
import { randomId } from "../../utils/utils";

export const FormSelect = ({
  customKey,
  mode,
  colon,
  label,
  name,
  rules,
  optionData,
  tooltipData,
  allowClear,
  callback,
  onClear,
  disabled,
}: {
  customKey?: string;
  mode?: "multiple" | "tags" | undefined;
  colon?: boolean;
  label: string;
  name: NamePath;
  rules?: Rule[];
  optionData: ISelectOption[];
  tooltipData?: string[];
  allowClear?: boolean;
  callback?: Function;
  onClear?: () => void;
  disabled?: boolean;
}) => {
  const tooltipRender = tooltipData?.map((item) => (
    <Tag key={item} style={{ margin: "4px" }}>
      {item}
    </Tag>
  ));
  return (
    <span style={{ position: "relative" }}>
      <Form.Item key={customKey || randomId()} label={label} name={name} rules={rules} colon={colon}>
        <Select
          mode={mode}
          onChange={callback?.bind(this)}
          allowClear={allowClear}
          onClear={onClear}
          maxTagCount="responsive"
          placeholder="Select an option"
          disabled={disabled}
        >
          {optionData.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {tooltipData?.length ? (
        <Tooltip color="#fff" title={tooltipRender}>
          <Space style={{ cursor: "pointer", columnGap: "8px", position: "absolute", top: "-24px", right: "0" }}>
            <InfoCircleOutlined />
          </Space>
        </Tooltip>
      ) : (
        <></>
      )}
    </span>
  );
};
