import Descriptions from "antd/es/descriptions";
import { FormInput } from "../components/Form/Input";
import { FormSingleFileInput } from "../components/Form/SingleFileInput";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../components/UploadInput/UploadInput";
import { SlideContentTemplate } from "../configs/slideConfigs";
import { ISlide as ILessonSlide } from "../interfaces/ILesson";
import { ISlide as IStorySlide } from "../interfaces/IStory";
import { SlideContentType } from "../configs/storySlideConfigs";
import { FormSelect } from "../components/Form/Select";
import { VoiceCodeFormOptions } from "../components/VoiceCodeDropDown/VoiceCodeDropDown";
import { Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

type Props = {
  slide: ILessonSlide | IStorySlide;
  numberTexts: number;
  numberImages: number;
  numberVideos: number;
  numberGifs?: number;
  hasAudio?: boolean;
  folder: string;
  prefixName: (string | number)[];
  handleLoadImage: (field: NamePath, data: FileData) => void;
  handleRemoveImage: (field: NamePath) => void;
  handleAddGif?: (field: NamePath) => void;
  handleRemoveGif?: (field: NamePath) => void;
  handleChangeContentVoiceCode?: (value: string) => void;
};

export const generateContentWithOptions = ({
  slide,
  numberTexts,
  numberImages,
  numberVideos,
  numberGifs,
  hasAudio,
  folder,
  prefixName,
  handleLoadImage,
  handleRemoveImage,
  handleAddGif,
  handleRemoveGif,
  handleChangeContentVoiceCode,
}: Props) => {
  return (
    <Descriptions>
      <Descriptions.Item className="prompt-wrapper" label="Content">
        {!!hasAudio && (
          <>
            <FormInput type="textarea" label="AudioText" name={[...prefixName, "content", "audioText"]} />
            <FormSelect
              label="VoiceCode"
              name={[...prefixName, "content", "voiceCode"]}
              optionData={VoiceCodeFormOptions}
              callback={handleChangeContentVoiceCode}
            />

            <FormInput type="textarea" label="Custom AudioLink" name={[...prefixName, "content", "audioLink"]} />
          </>
        )}
        {!!numberTexts && (
          <span className="texts">
            {Array(numberTexts)
              .fill("")
              .map((_, index) => (
                <FormInput
                  key={index}
                  type="textarea"
                  name={[...prefixName, ...["content", "texts", index]]}
                  label={`Text ${index + 1}`}
                  latexString={slide.content.texts?.length ? slide.content.texts[index] : ""}
                />
              ))}
          </span>
        )}
        {!!numberImages && (
          <span className="images">
            {Array(numberImages)
              .fill("")
              .map((_, index) => (
                <FormSingleFileInput
                  key={index}
                  label={`Image ${index + 1}`}
                  name={[...prefixName, ...["content", "images", index]]}
                  initValue={slide.content.images?.length ? slide.content.images[index] : ""}
                  handleUpload={handleLoadImage}
                  handleRemove={handleRemoveImage}
                  folder={folder}
                />
              ))}
          </span>
        )}
        {!!numberVideos && (
          <span className="videos">
            {Array(numberVideos)
              .fill("")
              .map((_, index) => (
                <FormInput
                  key={index}
                  name={[...prefixName, ...["content", "video", index]]}
                  label={`Video ${index + 1}`}
                  latexString={slide.content.video?.length ? slide.content.video[index] : ""}
                />
              ))}
          </span>
        )}
        {!!numberGifs && (
          <span className="images">
            {Array(numberGifs)
              .fill("")
              .map((_, index) => (
                <FormSingleFileInput
                  label={`Gif ${index + 1} (*.riv, *.json)`}
                  name={[...prefixName, ...["content", "gifs", index]]}
                  initValue={slide.content.gifs?.length ? slide.content.gifs[index] : ""}
                  handleUpload={handleLoadImage}
                  handleRemove={handleRemoveImage}
                  folder={folder}
                  isGif={true}
                />
              ))}

            <span className="gif-controller" style={{ display: "flex", gap: "8px" }}>
              <Button
                onClick={() => handleAddGif && handleAddGif([...prefixName, ...["content", "gifs"]])}
                icon={<PlusOutlined />}
              />
              <Button
                onClick={() => handleRemoveGif && handleRemoveGif([...prefixName, ...["content", "gifs"]])}
                icon={<MinusOutlined />}
              />
            </span>
          </span>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const getGenerateOptionFromContentType = (
  type: string
): { numTexts: number; numImgs: number; numVideos: number } => {
  let res = { numTexts: 0, numImgs: 0, numVideos: 0 };
  switch (type) {
    case SlideContentTemplate.MainContent2Image:
      res = { numTexts: 0, numImgs: 1, numVideos: 0 };
      break;
    case SlideContentTemplate.MainContent3Text:
      res = { numTexts: 1, numImgs: 0, numVideos: 0 };
      break;
    case SlideContentTemplate.MainContent4ImageTextLarge:
      res = { numTexts: 1, numImgs: 1, numVideos: 0 };
      break;
    // case SlideContentTemplate.MainContent5ImageTextNormal:
    //   res = { numTexts: 1, numImgs: 1, numVideos: 0 };
    //   break;
    case SlideContentTemplate.MainContent6VideoNormal:
      res = { numTexts: 0, numImgs: 0, numVideos: 1 };
      break;
    case SlideContentTemplate.MainContent7VideoPopupThumbnail:
      res = { numTexts: 1, numImgs: 1, numVideos: 1 };
      break;
    case SlideContentTemplate.MainContent9Conclusion:
      res = { numTexts: 2, numImgs: 1, numVideos: 0 };
      break;
    case SlideContentTemplate.MainContent10MemoryCard:
      res = { numTexts: 1, numImgs: 2, numVideos: 0 };
      break;
    case SlideContentTemplate.MainContent11Mixed:
      res = { numTexts: -1, numImgs: -1, numVideos: 0 };
      break;
    default:
      break;
  }

  return res;
};

export const getGenerateOptionFromContentTypeStory = (
  type: string
): { numTexts: number; numImgs: number; numVideos: number } => {
  let res = { numTexts: 0, numImgs: 0, numVideos: 0 };
  switch (type) {
    case SlideContentType.Left:
    case SlideContentType.Right:
      res = { numTexts: 1, numImgs: 1, numVideos: 0 };
      break;
    case SlideContentType.Video:
      res = { numTexts: 0, numImgs: 0, numVideos: 1 };
      break;
    case SlideContentType.NarratorNormal:
      res = { numTexts: 1, numImgs: 1, numVideos: 0 };
      break;
    case SlideContentType.NarratorQuestion:
      res = { numTexts: 1, numImgs: 1, numVideos: 0 };
      break;

    default:
      break;
  }

  return res;
};
