import { Form, Row, Col, Modal, Button, Collapse } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { convertToSelectOption } from "../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { getGrades } from "../../../services/LessonServices";
import "./UpdateRoadMap.scss";
import Card from "antd/es/card/Card";
import { CloseCircleOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import ExamDetail from "./ExamDetail";
import { editRoadMap, addRoadMap, getRoadMapById, deleteExam } from "../../../services/RoadMapServices";
import { getRoadMapCategories } from "../../../services/RoadMapCategoryServices";

const { Panel } = Collapse;

const UpdateRoadMap = () => {
  const params = useParams();
  const [roadMapId, setRoadMapId] = useState<string>(params.id || "");
  const { user } = useAuth()!;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [gradeList, setGradeList] = useState<any[]>([]);
  const [examList, setExamList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveRoadMap = () => {
    try {
      form.validateFields().then(async () => {
        const formValues = form.getFieldsValue();
        const data = {
          name: formValues.name,
          gradeIds: formValues.gradeIds,
          code: formValues.code,
          categoryId: formValues.categoryId,
        };
        let response: any;
        if (roadMapId) {
          response = await editRoadMap(roadMapId, { ...data });
        } else {
          response = await addRoadMap(data);
        }
        Modal.success({
          title: "Success",
          type: "success",
          content: `${roadMapId ? "Updated" : "Added"} successfully`,
        });
        setRoadMapId(response.result ? response.result : roadMapId);
      });
    } catch (error) {}
  };

  const handleAddExam = () => {
    setExamList((prev) => [
      ...prev,
      {
        key: prev.length + 1,
        title: `Exam ${prev.length + 1}`,
        content: <ExamDetail roadMapId={roadMapId} onDeleteExam={handleDeleteExam} />,
      },
    ]);
  };

  const handleDeleteExam = async (id: string) => {
    try {
      if (!id) {
        Modal.success({
          title: "Success",
          type: "success",
          content: `Removed successfully12`,
        });
        setExamList((prev) => prev.slice(0, prev.length - 1));
        return;
      }
      const response = await deleteExam(id, roadMapId);
      if (response.errorCode === 200) {
        Modal.success({
          title: "Success",
          type: "success",
          content: `Removed successfully`,
        });
        setExamList((prev) => prev.filter((item) => item.content.props.examId && item.content.props.examId !== id));
      }
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: `Removed failed`,
      });
    }
  };

  useEffect(() => {
    const getRoadMap = async () => {
      const response = await getRoadMapById(roadMapId);
      if (response) {
        const data = (response as any).result;
        form.setFieldsValue({
          name: data.name,
          code: data.code,
          gradeIds: data.grades.map((item: any) => item.id),
          categoryId: data.category?.id,
        });
        if (data.exams.length > 0) {
          setExamList(
            data.exams.map((item: any, index: number) => ({
              key: index + 1,
              title: item.name || `Exam ${index + 1}`,
              content: <ExamDetail roadMapId={roadMapId} examId={item?._id} onDeleteExam={handleDeleteExam} />,
            }))
          );
        }
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes, categoryRes] = await Promise.all([getGrades(), getRoadMapCategories({ page: 1, size: 10000 })]);
      gradeRes && setGradeList((gradeRes as any).result);
      categoryRes && setCategoryList((categoryRes as any).result.records);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      roadMapId && (await getRoadMap());
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadMapId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Card title="Road map">
        <div className="update-road-map-container">
          <Form form={form} onFinish={handleSaveRoadMap}>
            <div className="form-input">
              <Row>
                <Col xs={6} md={6}>
                  <FormInput label="Code" name="code" rules={[{ required: true, message: "Please enter a code" }]} />
                </Col>
                <Col xs={6} md={6}>
                  <FormInput label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]} />
                </Col>
                <Col xs={6} md={6}>
                  <FormSelect
                    label="Grade"
                    name="gradeIds"
                    optionData={convertToSelectOption(gradeList, "_id", "name")}
                    rules={[{ required: true, message: "Please select an option" }]}
                    mode="multiple"
                    allowClear
                  />
                </Col>
                <Col xs={6} md={6}>
                  <FormSelect
                    label="Category"
                    name="categoryId"
                    optionData={convertToSelectOption(categoryList, "_id", "name")}
                    rules={[{ required: true, message: "Please select an option" }]}
                    allowClear
                  />
                </Col>
              </Row>
            </div>
          </Form>

          {/* exam */}
          {examList.length > 0 && roadMapId ? (
            <Collapse defaultActiveKey={examList.map((_,index) => index)}>
              {examList.map((item, index) => (
                <Panel header={`Exam ${index + 1}`} key={index}>
                  {item.content}
                </Panel>
              ))}
            </Collapse>
          ) : (
            <></>
          )}
          <div className="road-map-control">
            {roadMapId && (
              <Button type="primary" icon={<PlusCircleOutlined />} onClick={handleAddExam}>
                Add exam
              </Button>
            )}
            <Button
              type="primary"
              icon={<SaveOutlined />}
              style={{ backgroundColor: "#2abb2a" }}
              onClick={handleSaveRoadMap}
            >
              Save road map
            </Button>
            <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.RoadMap)}>
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UpdateRoadMap;
