import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signinApi } from "../services/auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface User {
  userId: string;
  sub: string;
  roles: string[];
  exp: number;
}

interface AuthState {
  user: User | null;
}

const initialState: AuthState = {
  user: { userId: "", sub: "", roles: [], exp: 0 },
};

export const signin = createAsyncThunk("auth/signin", async (account: { userName: string; password: string }) => {
  const res = await signinApi(account);
  const expires = 7 * 24 * 3600;
  if (cookies.get("signinAdmin")) {
    cookies.remove("signinAdmin");
  }
  cookies.set("signinAdmin", res.access_token, {
    maxAge: expires,
  });
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      cookies.remove("signinAdmin");
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
