/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EyeOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Form, Button, Row, Col, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { convertToSelectOption } from "../../../utils/utils";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import "./UpdateQuiz.scss";
import { PageUrl, PreviewUrl } from "../../../constants/url";
import {
  addQuiz,
  editQuiz,
  getQuizDetailByQuizId,
  publishQuizzes,
  rejectQuizzes,
  submitQuizzes,
  unPublishQuizzes,
} from "../../../services/QuizServices";
import { UserRole } from "../../../constants/auth";
import { previewWindow } from "../../../utils/preview";
import { ConfirmButton } from "../../../components/ConfirmButton/ConfirmButton";
import { FlowCategory, isDisabledFlow } from "../../../constants/flow";
import { getGrades, getUnitDetailByUnitId, getUnitsByGradeId } from "../../../services/QuestionServices";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";

const UpdateQuiz = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user, token } = useAuth()!;

  const [form] = Form.useForm();

  const [quizId, setQuizId] = useState<string>(params.id || "");
  const [gradeList, setGradeList] = useState<string[]>([]);
  const [unitList, setUnitList] = useState<string[]>([]);
  const [questionList, setQuestionList] = useState<string[]>([""]);

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<number>(FlowCategory.Draft);

  const onSelectGrade = async (gradeId: string) => {
    form.setFieldValue("unitId", undefined);
    const response = await getUnitsByGradeId(gradeId);
    if (response) {
      setUnitList((response as any).result.records);
    }
  };

  const handleSaveQuiz = () => {
    try {
      form.validateFields().then(async () => {
        const quiz = form.getFieldsValue();
        let response: any;
        if (quizId) {
          response = await editQuiz(quizId, quiz);
        } else {
          response = await addQuiz(quiz);
        }
        setQuizId(response.result ? response.result : quizId);
        setStatus(FlowCategory.Draft);
        Modal.success({
          title: "Success",
          type: "success",
          content: `${quizId ? "Updated" : "Added"} successfully`,
        });
        previewWindow({ url: PreviewUrl.Quiz, data: { token, quizId: quizId } });
      });
    } catch (error) {}
  };

  const handleAddQuestion = () => {
    setQuestionList([...questionList, ""]);
  };

  const handleRemoveQuestion = () => {
    const newQuestionList = [...questionList];
    newQuestionList.splice(-1);
    setQuestionList(newQuestionList);
  };

  const verifyQuizBeforeChange = async (type: number) => {
    const unitRes = await getUnitDetailByUnitId(form.getFieldValue("unitId"));
    const quizInUnit = (unitRes as any)?.result?.quizId;
    if (!quizInUnit || quizInUnit === quizId) {
      handleController(type);
    } else {
      const updateUnitUrl = `${PageUrl.Units}${PageUrl.Update}`.replace(":id", form.getFieldValue("unitId"));
      Modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: (
          <span>
            Một quiz khác đã được gán cho unit hiện tại, hãy kiểm tra lại unit tại&nbsp;
            <a href={updateUnitUrl} target="_blank" rel="noreferrer">
              đây.
            </a>
            <br />
            <span style={{ color: "mediumblue" }}>* Lưu ý: Các thao tác với quiz tại màn hình này sẽ không làm ảnh hưởng đến unit.</span>
          </span>
        ),
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => handleController(type),
      });
    }
  };

  const handleController = async (type: number) => {
    try {
      let res;
      switch (type) {
        case FlowCategory.Reviewing:
          res = await submitQuizzes([quizId]);
          break;
        case FlowCategory.Published:
          res = await publishQuizzes([quizId]);
          break;
        case FlowCategory.UnPublished:
          res = await unPublishQuizzes([quizId]);
          break;
        case FlowCategory.RequestEdit:
          res = await rejectQuizzes([quizId]);
          break;

        default:
          break;
      }
      if (res) {
        setStatus(type);
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getQuiz = async () => {
      const response = await getQuizDetailByQuizId(quizId);
      if (response) {
        const data = (response as any).result;
        setStatus(data.status);
        data.grade.id && (await onSelectGrade(data.grade.id));
        const questions = data.questions.map((item: any) => item.questionId).length
          ? data.questions.map((item: any) => item.questionId)
          : [""];
        setQuestionList(questions);
        form.setFieldsValue({
          name: data.name,
          gradeId: data.grade.id || undefined,
          unitId: data.unit?.id || undefined,
          questions,
        });
      }
    };
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
      quizId && (await getQuiz());
    };

    // (user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && getAllInformation();
    user && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-quiz-container">
      <Form form={form} onFinish={handleSaveQuiz}>
        <div className="form-input">
          <Row>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Quiz name" name="name" rules={[{ required: true, message: "Please enter a name" }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormSelect
                label="Grade"
                name="gradeId"
                optionData={convertToSelectOption(gradeList, "_id", "name")}
                callback={onSelectGrade}
              />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormSelect label="Unit" name="unitId" optionData={convertToSelectOption(unitList, "_id", "unitName")} allowClear />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={6}>
              {questionList.map((_, index) => (
                <div key={index}>
                  <span className="input-preview">
                    <FormInput
                      key={`question-${index}`}
                      label={index === 0 ? "Questions" : " "}
                      name={["questions", index]}
                      colon={index === 0 ? true : false}
                    />
                    <Button
                      type="ghost"
                      block
                      icon={<EyeOutlined />}
                      onClick={() =>
                        previewWindow({
                          url: PreviewUrl.Question,
                          data: { token, questionId: form.getFieldValue(["questions", index]) },
                          directly: true,
                        })
                      }
                    />
                  </span>

                  {index === questionList.length - 1 && (
                    <span className={`control ${questionList.length > 1 ? "" : "none-remove"}`}>
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddQuestion} />
                      <Button
                        style={questionList.length > 1 ? { display: "block" } : { display: "none" }}
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveQuestion}
                      />
                    </span>
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </div>
        <NoteText style={{ margin: "4px 0 4px 100px" }} text={NoteMessage.SaveChangeBeforeAction} />
        <div className="form-controller">
          {user?.roles?.includes(UserRole.ContentCreator) && (
            <>
              <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSaveQuiz}>
                Save and preview
              </Button>
              <ConfirmButton
                title="Submit"
                buttonType="primary"
                icon={<CheckOutlined />}
                onConfirm={() => handleController(FlowCategory.Reviewing)}
                disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !quizId}
              />
            </>
          )}
          {user?.roles?.includes(UserRole.Reviewer) && quizId && (
            <>
              <ConfirmButton
                title="Publish"
                buttonType="primary"
                icon={<UploadOutlined />}
                onConfirm={() => verifyQuizBeforeChange(FlowCategory.Published)}
                disabled={isDisabledFlow(status, FlowCategory.Published)}
              />
              <ConfirmButton
                title="Unpublish"
                buttonType="default"
                icon={<DownloadOutlined />}
                onConfirm={() => verifyQuizBeforeChange(FlowCategory.UnPublished)}
                disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
              />
              <ConfirmButton
                title="Reject"
                buttonType="dashed"
                icon={<CloseOutlined />}
                onConfirm={() => handleController(FlowCategory.RequestEdit)}
                disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
              />
            </>
          )}
          <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.Quizzes)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateQuiz;
