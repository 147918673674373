import instance, { apiConfig } from "./instance";
import { IVoucherFilter } from "../interfaces/filter";

const voucherUrl = "/auth/v1/admin/voucher";

export const generateVouchers = (data: any) => {
  return instance.post(`${voucherUrl}/generate-vouchers`, data, apiConfig());
};

export const getVouchers = (postData: IVoucherFilter, page = 1, size = 10) => {
  return instance.post(`${voucherUrl}/get-list-vouchers-pagination`, postData, apiConfig({ page, size }));
};

export const getVoucherById = (id: string) => {
  return instance.get(`${voucherUrl}/get-a-voucher/${id}`, apiConfig());
};

export const activeVouchers = (listId: string[]) => {
  return instance.post(`${voucherUrl}/active-vouchers`, { voucherIds: listId }, apiConfig());
};

export const inactiveVouchers = (listId: string[]) => {
  return instance.post(`${voucherUrl}/inactive-vouchers`, { voucherIds: listId }, apiConfig());
};
