import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin
} from "antd";
import { NamePath } from "antd/es/form/interface";
import { isInteger } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { decodeToken } from "react-jwt";
import { useNavigate, useParams } from "react-router";
import Cookies from "universal-cookie";
import {
  PromptTypeOptions,
  PromptTypes,
  QDetailOptions,
  QuestionTypeConfigs,
  SelectOption,
  TypeOptions,
} from "../../configs/questionConfigs";
import { UserRole } from "../../constants/auth";
import { FILE_EXTENSION_SUPPORT } from "../../constants/fileExtensions";
import { FlowCategory, isDisabledFlow } from "../../constants/flow";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { PageUrl, PreviewUrl } from "../../constants/url";
import {
  editQuestionMobileVersion,
  getGrades,
  getLessonsByUnitId,
  getQuestionById,
  getUnits,
  getUnitsByGradeId,
  publishQuestions,
  rejectQuestions,
  submitQuestions,
  unPublishQuestions
} from "../../services/QuestionServices";
import { previewWindow } from "../../utils/preview";
import { findValueByPrefix } from "../../utils/utils";
import { ConfirmButton } from "../ConfirmButton/ConfirmButton";
import { FormInput } from "../Form/Input";
import { FormSelect } from "../Form/Select";
import { FormSingleFileInput } from "../Form/SingleFileInput";
import Loading from "../Loading/Loading";
import NoteText, { NoteMessage } from "../NoteText/NoteText";
import SingleFileInput, { FileData } from "../UploadInput/UploadInput";
import { VoiceCodeFormOptions } from "../VoiceCodeDropDown/VoiceCodeDropDown";
import "./UpdateQuestionMobileVersion.scss";

const { Option } = Select;
const genDefaultFilter = () => {
  return { gradeList: [], unitList: [], lessonList: [], gradeId: "", unitId: "", lessonId: "" };
};

const UpdateQuestionMobileVersion: React.FC = () => {
  const [form] = Form.useForm();
  const [, setValuesForm] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generateDisable, setGenerateDisable] = useState(false);

  const gradesList = useRef<any[]>([]);
  const unitsList = useRef<any[]>([]);
  const [folder, setFolder] = useState<string>("");

  const [selectedPromptOption, setSelectedPromptOption] = useState<string>();
  const [promptOptions, setPromptOptions] = useState<SelectOption[]>();
  const [explanationTexts, setExplanationTexts] = useState<string[]>([""]);
  const [explanationImages, setExplanationImages] = useState<string[]>([""]);
  const [filters, setFilters] = useState<any[]>([genDefaultFilter()]);

  const [selectedExtraOption, setSelectedExtraOption] = useState<string>();
  const [selectedTypeOption, setSelectedTypeOption] = useState<string>();
  const [typeOptions, setTypeOptions] = useState<SelectOption[]>();
  const [extraOptions, setExtraOptions] = useState<SelectOption[]>();

  const [numberChoices, setNumberChoices] = useState<number>();
  const [targetNumber, setTargetNumber] = useState<number>();
  const solution = useRef<string | any[]>("");
  const [listImages, setListImages] = useState<{ [T: string]: string }>({});

  const [status, setStatus] = useState<number>(FlowCategory.Draft);

  const [roles, setRoles] = useState<string[]>([""]);
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");
  // const questionId = useRef<string>(params.id || "");
  const [questionId, setQuestionId] = useState<string>(params.id || "");

  const [isConfirmedGradeUnit, setIsConfirmedGradeUnit] = useState(false);

  const initQuestion = (data: any) => {
    setStatus(data.status);
    // set Template
    const mobileVersion = data?.mobileVersion;
    const template = data?.mobileVersion.template as { prompt: string; question: string };

    const promptType = template.prompt.slice(0, 8);
    form.setFieldValue("promptType", promptType);
    setPromptOptions(PromptTypeOptions[promptType]);
    setSelectedPromptOption(template.prompt);
    form.setFieldValue("promptOption", template.prompt);

    const questionType = template.question.includes("DragDrop")
      ? QuestionTypeConfigs[1].value
      : QuestionTypeConfigs[0].value;
    let questionHasImages = FILE_EXTENSION_SUPPORT.some((item) => JSON.stringify(mobileVersion.question).includes(item));
    if (mobileVersion.question.targets.length)
      questionHasImages = !FILE_EXTENSION_SUPPORT.some((item) => JSON.stringify(mobileVersion.question.targets).includes(item));
    if (data.recordInfo.createdBy.name === "MIGRATEDB") questionHasImages = false;
    setTypeOptions(TypeOptions[questionType]);
    setSelectedTypeOption(template.question);
    setExtraOptions(QDetailOptions[template.question]);
    const extraOption = QDetailOptions[template.question][questionHasImages ? 1 : 0].value;
    setSelectedExtraOption(extraOption);
    form.setFieldValue("type", questionType);
    form.setFieldValue("typeOptions", template.question);
    form.setFieldValue("extraOptions", extraOption);
    setIsConfirmedGradeUnit(true);
    // set Grades, Units, Lessons
    if (data.units?.length) {
      while (filters.length < data.units.length) filters.push(genDefaultFilter());
      data.units.forEach(async (item: any, index: number) => {
        const gradeId = unitsList.current.find((i) => i._id === item.id)?.gradeId;
        form.setFieldValue(["filter", "grade", index + 1], gradeId);
        await onSelectGrade(index, gradeId);
        form.setFieldValue(["filter", "unit", index + 1], item.id);
        await onSelectUnit(index, item.id);
        data.lessons.forEach((lesson: any) => {
          const idInFilters = filters.findIndex((filter: any, index: number) =>
            filter.lessonList.find((item: any) => item._id === lesson.id)
          );
          if (idInFilters > -1) {
            form.setFieldValue(["filter", "lesson", idInFilters + 1], lesson?.id);
            onSelectLesson(index, lesson?.id);
          }
        });
      });
    } else {
      form.setFieldValue(["filter", "grade", 1], data.difficulty[0].grade.id);
      onSelectGrade(0, data.difficulty[0].grade.id);
    }

    // set Prompts
    mobileVersion.prompt.texts.forEach((item: string, index: number) =>
      form.setFieldValue(["prompt", `text${index + 1}`], item)
    );
    mobileVersion.prompt.images.forEach((item: string, index: number) => {
      form.setFieldValue(["prompt", `image${index + 1}`], item);
      listImages[["prompt", `image${index + 1}`].toString()] = item;
    });
    form.setFieldValue(["prompt", "audioText"], mobileVersion.prompt.audioText);
    form.setFieldValue(["prompt", "audioLink"], mobileVersion.prompt.audioLink);

    // set Number choices + target choices
    setTargetNumber(mobileVersion.question.targets.length);
    form.setFieldValue("targetNumber", mobileVersion.question.targets.length);
    setNumberChoices(mobileVersion.question.choices.length);
    form.setFieldValue("numberChoices", mobileVersion.question.choices.length);

    // set Choices
    form.setFieldValue(["question", "text"], mobileVersion.question.texts[0]);
    form.setFieldValue(["question", "audioText"], mobileVersion.question.audioText);
    form.setFieldValue(["question", "audioLink"], mobileVersion.question.audioLink);

    mobileVersion.question.choices.forEach((item: string, index: number) => {
      form.setFieldValue(["question", `choice-${index + 1}`], item);
      if (questionHasImages) {
        listImages[["question", `choice-${index + 1}`].toString()] = item;
      }
    });

    // set Solutions
    switch (extraOption) {
      case "QAnswer1-1":
      case "QAnswer1-2":
        const correctIndex = mobileVersion.question.solutions.findIndex((item: boolean) => item);
        if (correctIndex !== -1) {
          solution.current = `solution-${correctIndex + 1}`;
          form.setFieldValue("solution", `solution-${correctIndex + 1}`);
        }
        break;
      case "QAnswer2-1":
      case "QAnswer2-2":
        let correctIndexes: number[] = [];
        mobileVersion.question.solutions.filter((item: boolean, index: number) => {
          if (item) correctIndexes.push(index + 1);
          return item;
        });
        form.setFieldValue("solution", correctIndexes);
        break;
      case "QAnswer3-1":
      case "QAnswer3-2":
        mobileVersion.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        break;
      case "QAnswer4-1":
      case "QAnswer5-1":
        mobileVersion.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        mobileVersion.question.targets.forEach((item: string, index: number) => {
          listImages[["target", index + 1].toString()] = item;
          form.setFieldValue(["target", index + 1], item);
        });
        mobileVersion.question.choices.forEach((item: string, index: number) => {
          form.setFieldValue(["question", `choice-${index + 1}`], item);
          listImages[["question", `choice-${index + 1}`].toString()] = item;
        });
        break;
      case "QAnswer4-2":
      case "QAnswer5-2":
        mobileVersion.question.solutions.forEach((item: string, index: number) =>
          form.setFieldValue(["solution", `solution-${index + 1}`], item)
        );
        break;
      default:
        break;
    }

    // set Explanation
    mobileVersion.explanation?.texts?.length && setExplanationTexts(mobileVersion.explanation?.texts);
    mobileVersion.explanation.texts.forEach((item: string, index: number) =>
      form.setFieldValue(["explanation", `text-explain-${index + 1}`], item)
    );
    mobileVersion.explanation?.images?.length && setExplanationImages(mobileVersion.explanation?.images);
    mobileVersion.explanation.images.forEach((item: string, index: number) => {
      form.setFieldValue(["explanation", `image-explain-${index + 1}`], item);
      listImages[["explanation", `image-explain-${index + 1}`].toString()] = item;
    });
    form.setFieldValue(["explanation", "audioText"], mobileVersion.explanation.audioText);
    form.setFieldValue(["explanation", "audioLink"], mobileVersion.explanation.audioLink);

    // set Others
    setListImages({ ...listImages });
  };

  const handleSaveAndPreview = () => {
    const explanations = form.getFieldValue("explanation");
    const prompts = form.getFieldValue("prompt");
    const questions = form.getFieldValue("question");

    const getTarget = (): string[] => {
      if (!targetNumber) return [];
      if (selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") {
        let formData = form.getFieldValue("target") as string[];
        formData = formData?.filter((item) => !!item) || [];
        return formData;
      } else {
        return [];
      }
    };

    const getSolution = (solutions: any) => {
      let solution;
      switch (selectedExtraOption) {
        case "QAnswer1-1":
        case "QAnswer1-2":
          solution = Array(numberChoices).fill(false);
          solution[Number(solutions?.split("-")[1]) - 1] = true;
          break;
        case "QAnswer2-1":
        case "QAnswer2-2":
          solution = Array(numberChoices).fill(false);
          solutions.forEach((index: number) => {
            solution[index - 1] = true;
          });
          break;
        case "QAnswer3-1":
        case "QAnswer3-2":
          solution = findValueByPrefix(solutions, "solution");
          break;
        case "QAnswer4-1":
        case "QAnswer4-2":
          solution = Object.values(solutions).map((item) => {
            return Number(item);
          });
          break;
        case "QAnswer5-1":
        case "QAnswer5-2":
          solution = Object.values(solutions).map((item) => {
            return Number(item);
          });
          break;
        default:
          break;
      }
      return solution;
    };

    const choices =
      selectedExtraOption === "QAnswer3-1" ? Array(numberChoices).fill("") : findValueByPrefix(questions, "choice");

    let uploadData = {
      folderName: folder,
      mobileVersion:{
        template: {
          prompt: selectedPromptOption,
          question: form.getFieldValue("typeOptions"),
        },
        prompt: {
          texts: findValueByPrefix(prompts, "text"),
          audioText: form.getFieldValue(["prompt", "audioText"]),
          audioTextVoiceCode: form.getFieldValue(["prompt", "audioTextVoiceCode"]),
          images: findValueByPrefix(prompts, "image"),
        },
        question: {
          texts: findValueByPrefix(questions, "text"),
          audioText: form.getFieldValue(["question", "audioText"]),
          audioTextVoiceCode: form.getFieldValue(["question", "audioTextVoiceCode"]),
          targets: getTarget(),
          choices,
          solutions: getSolution(form.getFieldValue("solution")),
        },
        explanation: {
          texts: findValueByPrefix(explanations, "text-explain"),
          audioText: form.getFieldValue(["explanation", "audioText"]),
          audioTextVoiceCode: form.getFieldValue(["explanation", "audioTextVoiceCode"]),
          images: findValueByPrefix(explanations, "image-explain"),
        },
      },
    };
    saveQuestion(uploadData);
  };

  const saveQuestion = async (data: any) => {
    try {
      let response: any;
      setIsSubmitting(true);
      if (questionId) {
        response = await editQuestionMobileVersion(questionId, data);
        if (response.result?.mobileVersion?.prompt?.audioLink) {
          form.setFieldValue(["prompt", "audioLink"], response.result.mobileVersion.prompt.audioLink);
        }
        if (response.result?.mobileVersion?.question?.audioLink) {
          form.setFieldValue(["question", "audioLink"], response.result.mobileVersion.question.audioLink);
        }

        if (response.result?.mobileVersion?.explanation?.audioLink) {
          form.setFieldValue(["explanation", "audioLink"], response.result.mobileVersion.explanation.audioLink);
        }
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${!questionId ? "Added" : "Updated"} successfully`,
      });

      setIsSubmitting(false);
      previewWindow({ url: PreviewUrl.Question, data: { token, questionId: questionId } });
    } catch (error) {
      Modal.error({
        title: "Error",
        content: "Có lỗi xảy ra, vui lòng thử lại sau",
      });
    }
  };

  const handleChangeQuestionType = (value: string) => {
    setTypeOptions(TypeOptions[value]);
    setSelectedTypeOption(TypeOptions[value][0].value);
    setExtraOptions(QDetailOptions[TypeOptions[value][0].value]);
    setSelectedExtraOption(QDetailOptions[TypeOptions[value][0].value][0].value);
    setNumberChoices(undefined);
    solution.current = "";
    form.setFieldValue("typeOptions", TypeOptions[value][0].value);
    form.setFieldValue("extraOptions", QDetailOptions[TypeOptions[value][0].value][0].value);
    resetQuestions();
  };

  const handleChangeTypeOption = (value: string) => {
    setSelectedTypeOption(value);
    setExtraOptions(QDetailOptions[value]);
    setSelectedExtraOption(QDetailOptions[value][0].value);
    setNumberChoices(undefined);
    setTargetNumber(undefined);
    solution.current = "";
    form.setFieldValue("extraOptions", QDetailOptions[value][0].value);
    resetQuestions();
  };

  const handleChangeExtraOption = (value: string) => {
    setSelectedExtraOption(value);
    setNumberChoices(undefined);
    solution.current = "";
    resetQuestions();
  };

  const handleChangePromptType = (value: string) => {
    setPromptOptions(PromptTypeOptions[value]);
    setSelectedPromptOption(PromptTypeOptions[value][0].value);
    form.setFieldValue("promptOption", PromptTypeOptions[value][0].value);
    form.setFieldValue("prompt", undefined);
  };

  const handleChangePromptTypeOption = (value: string) => {
    setSelectedPromptOption(value);
    form.setFieldValue("prompt", undefined);
  };


  const handleAddExplainTexts = () => {
    if (!form.getFieldValue(["explanation", `text-explain-${explanationTexts.length}`])) {
      form.setFieldValue(["explanation", `text-explain-${explanationTexts.length}`], "");
    }
    setExplanationTexts([...explanationTexts, ""]);
  };

  const handleRemoveExplainTexts = () => {
    form.setFieldValue(["explanation", `text-explain-${explanationTexts.length}`], undefined);
    explanationTexts.splice(-1);
    setExplanationTexts([...explanationTexts]);
  };

  const handleAddExplainImages = () => {
    if (!form.getFieldValue(["explanation", `image-explain-${explanationImages.length}`])) {
      form.setFieldValue(["explanation", `image-explain-${explanationImages.length}`], "");
    }
    setExplanationImages([...explanationImages, ""]);
  };

  const handleRemoveExplainImages = () => {
    form.setFieldValue(["explanation", `image-explain-${explanationTexts.length}`], undefined);
    explanationImages.splice(-1);
    setExplanationImages([...explanationImages]);
  };

  const handleLoadImage = (field: NamePath, imageData: FileData) => {
    const key = field.toString();
    form.setFieldValue(field, imageData.fileLink);
    setListImages({ ...listImages, [key]: imageData.fileName });
  };

  const handleRemoveImage = (field: NamePath) => {
    const key = field.toString();
    form.setFieldValue(field, "");
    delete listImages[key];
    setListImages({ ...listImages });
  };

  const generateAudioText = (type: string) => {
    return (
      <>
        <FormInput
          type="textarea"
          key={`audioText-${type}`}
          label="AudioText"
          name={[type, "audioText"]}
          latexString={form.getFieldValue([type, "audioText"]) || ""}
          // latexString={slide.explanation.audioText || ""}
        />
        <FormSelect
          key={`voiceCode-${type}`}
          label="VoiceCode"
          name={[type, "audioTextVoiceCode"]}
          optionData={VoiceCodeFormOptions}
          // latexString={form.getFieldValue([type, "audioTextVoiceCode"]) || ""}

          // callback={handleChangeExplanationVoiceCode}
        />

        <FormInput
          type="textarea"
          key={`audioLink-${type}`}
          label="Custom AudioLink"
          name={[type, "audioLink"]}
          latexString={form.getFieldValue([type, "audioLink"]) || ""}
          rows={2}
          // latexString={}
        />
      </>
    );
  };

  const generatePrompt = (promptType: string): JSX.Element => {
    const generatePromptWithOptions = (numberTexts: number, numberImages: number) => {
      return (
        <Descriptions.Item className="prompt-wrapper" label="Prompt">
          {!!numberTexts && (
            <span className="texts">
              {Array(numberTexts)
                .fill("")
                .map((_, index) => (
                  <FormInput
                    type="textarea"
                    name={["prompt", `text${index + 1}`]}
                    label={`Text ${index + 1}`}
                    latexString={form.getFieldValue(["prompt", `text${index + 1}`])}
                  />
                ))}
            </span>
          )}
          {!!numberImages && (
            <span className="images">
              {Array(numberImages)
                .fill("")
                .map((_, index) => (
                  <FormSingleFileInput
                    label={`Image ${index + 1}`}
                    name={["prompt", `image${index + 1}`]}
                    initValue={listImages[["prompt", `image${index + 1}`].toString()]}
                    handleUpload={handleLoadImage}
                    handleRemove={handleRemoveImage}
                    folder={folder}
                  />
                ))}
            </span>
          )}

          {generateAudioText("prompt")}
        </Descriptions.Item>
      );
    };
    let prompt = <></>;
    switch (promptType) {
      case "QPrompt1_1":
      case "QPrompt1_3":
      case "QPrompt2_1":
      case "QPrompt2_4":
        prompt = generatePromptWithOptions(1, 1);
        break;
      case "QPrompt1_2":
        prompt = generatePromptWithOptions(2, 1);
        break;
      case "QPrompt2_2":
        prompt = generatePromptWithOptions(1, 2);
        break;
      case "QPrompt2_3":
        prompt = generatePromptWithOptions(2, 2);
        break;
      case "QPrompt3_1":
        prompt = generatePromptWithOptions(1, 0);
        break;
      case "QPrompt3_2":
        prompt = generatePromptWithOptions(0, 1);
        break;
      default:
        break;
    }

    return prompt;
  };

  const generateQuestionForm = (type: "texts" | "images") => {
    if (!numberChoices) return <></>;
    const choices = Array(numberChoices).fill("") as string[];
    let result;
    switch (type) {
      case "texts":
        result = choices.map((_, index) => (
          <FormInput
            type="textarea"
            key={`choice-${index + 1}`}
            name={["question", `choice-${index + 1}`]}
            label={`Choice ${index + 1}`}
            latexString={form.getFieldValue(["question", `choice-${index + 1}`])}
          />
        ));
        break;
      case "images":
        result = choices.map((_, index) => (
          <FormSingleFileInput
            key={`choice-${index + 1}`}
            name={["question", `choice-${index + 1}`]}
            label={`Choice ${index + 1}`}
            initValue={listImages[["question", `choice-${index + 1}`].toString()]}
            handleUpload={handleLoadImage}
            handleRemove={handleRemoveImage}
            folder={folder}
          />
        ));
        break;
      default:
        break;
    }

    return <span className={type}>{result}</span>;
  };

  const generateSolutionForm = (
    type: "radio" | "checkbox" | "gap-fill" | "gap-fill-image" | "drag-drop" | "drag-drop-multi"
  ) => {
    if (!numberChoices) return <></>;
    let choices = Array(numberChoices).fill("") as string[];
    if ((type === "drag-drop" || type === "drag-drop-multi") && targetNumber) {
      choices = Array(targetNumber).fill("") as string[];
    }
    let result;
    switch (type) {
      case "radio":
        result = (
          <Form.Item key="solution" name="solution">
            <Radio.Group name="solution" value={solution.current} onChange={(e) => (solution.current = e.target.value)}>
              {choices.map((_, index) => (
                <Radio name="solution" key={`solution-${index + 1}`} value={`solution-${index + 1}`}>
                  Solution {index + 1}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
        break;
      case "checkbox":
        result = (
          <Form.Item key="solution" name="solution">
            <Checkbox.Group name="solution" value={solution.current as any} onChange={(e) => (solution.current = e)}>
              {choices.map((_, index) => (
                <Checkbox name="solution" key={`solution-${index + 1}`} value={index + 1}>
                  Solution {index + 1}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
        break;
      case "gap-fill":
        result = (
          <>
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
              >
                <Input name={`solution-${index + 1}`} placeholder="Enter a solution" />
              </Form.Item>
            ))}
          </>
        );
        break;
      case "gap-fill-image":
        result = (
          <>
            {choices.map((_, index) => (
              <span style={{ display: "grid", gap: "32px" }}>
                <Form.Item
                  key={`solution-${index + 1}`}
                  name={["solution", `solution-${index + 1}`]}
                  label={`Solution ${index + 1}`}
                >
                  <Input name={`solution-${index + 1}`} placeholder="Enter solution" />
                </Form.Item>
                <Form.Item
                  key={`choice-${index + 1}`}
                  name={["question", `choice-${index + 1}`]}
                  label={`Image ${index + 1}`}
                >
                  <SingleFileInput
                    initValue={listImages[["question", `choice-${index + 1}`].toString()]}
                    inputId={`choice-${index + 1}`}
                    onData={(data: FileData) => handleLoadImage(["question", `choice-${index + 1}`], data)}
                    onRemove={() => handleRemoveImage(["question", `choice-${index + 1}`])}
                    folder={folder}
                  />
                </Form.Item>
              </span>
            ))}
          </>
        );
        break;
      case "drag-drop":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      const solutions = form.getFieldValue("solution");
                      if (Object.values(solutions).filter((item) => item === value).length > 1) {
                        return Promise.reject("Exist solution");
                      } else {
                        if (Number(value) > Number(numberChoices) || Number(value) <= 0)
                          return Promise.reject("Must be less than target");
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Enter a solution" allowClear />
              </Form.Item>
            ))}
          </span>
        );
        break;
      case "drag-drop-multi":
        result = (
          <span className="drag-solution">
            {choices.map((_, index) => (
              <Form.Item
                key={`solution-${index + 1}`}
                name={["solution", `solution-${index + 1}`]}
                label={`Solution ${index + 1}`}
                rules={[
                  { required: true, message: "Please enter a value" },
                  {
                    validator(_, value) {
                      if (value === "") return Promise.resolve();
                      if (Number(value) > Number(numberChoices) || Number(value) < 0)
                        return Promise.reject("Invalid target");
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input type="number" placeholder="Enter a solution" />
              </Form.Item>
            ))}
          </span>
        );
        break;
      default:
        break;
    }

    return result;
  };

  const generateQuestionAndSolution = (questionType: string) => {
    let question;
    let solution;
    switch (questionType) {
      case "QAnswer1-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer1-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("radio");
        break;
      case "QAnswer2-1":
        question = generateQuestionForm("texts");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer2-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("checkbox");
        break;
      case "QAnswer3-1":
        solution = generateSolutionForm("gap-fill");
        break;
      case "QAnswer3-2":
        solution = generateSolutionForm("gap-fill-image");
        break;
      case "QAnswer4-1":
      case "QAnswer4-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop");
        break;
      case "QAnswer5-1":
      case "QAnswer5-2":
        question = generateQuestionForm("images");
        solution = generateSolutionForm("drag-drop-multi");
        break;
      default:
        break;
    }

    return (
      <>
        <Descriptions.Item className="question-wrapper" label="Question">
          <FormInput
            type="textarea"
            name={["question", "text"]}
            label="Text"
            latexString={form.getFieldValue(["question", "text"])}
          />
          {generateAudioText("question")}

          {question}
        </Descriptions.Item>
        <Descriptions.Item label="Solution">{solution}</Descriptions.Item>
      </>
    );
  };

  const generateChoices = () => {
    setNumberChoices(Number(form.getFieldValue("numberChoices")));
    setTargetNumber(Number(form.getFieldValue("targetNumber")));
    resetQuestions();
  };

  const generateTargets = () => {
    const res = Array(targetNumber)
      .fill(0)
      .map((_, i) => (
        <Form.Item key={`target-${i + 1}`} label={`Target ${i + 1}`} name={["target", i + 1]}>
          <SingleFileInput
            initValue={listImages[["target", i + 1].toString()]}
            inputId={`target-${i + 1}`}
            onData={(data: FileData) => handleLoadImage(["target", i + 1], data)}
            onRemove={() => handleRemoveImage(["target", i + 1])}
            folder={folder}
          />
        </Form.Item>
      ));
    return res;
  };

  const onSelectGrade = async (index: number, gradeId: string) => {
    const unitRes = await getUnitsByGradeId(gradeId);
    if (unitRes) {
      filters[index].gradeId = gradeId;

      form.setFieldValue(["filter", "unit", index + 1], undefined);
      filters[index].unitId = "";
      filters[index].unitList = (unitRes as any).result?.records;
      form.setFieldValue(["filter", "lesson", index + 1], undefined);
      filters[index].lessonId = "";
      filters[index].lessonList = [];
      setFilters([...filters]);
    }
  };
  const onSelectUnit = async (index: number, unitId: string) => {
    const lessonRes = await getLessonsByUnitId(filters[index].gradeId, unitId);
    if (lessonRes) {
      filters[index].unitId = unitId;
      filters[index].lessonId = "";
      form.setFieldValue(["filter", "lesson", index + 1], undefined);
      filters[index].lessonList = (lessonRes as any).result?.records;
      setFilters([...filters]);
    }
  };

  const onSelectLesson = (index: number, lessonId: string) => {
    filters[index].lessonId = lessonId;
    setFilters([...filters]);
  };

  const handleController = async (func: any, type: number) => {
    try {
      const res = await func.then();
      if (res) {
        setStatus(type);
        Modal.success({
          title: "Success",
          type: "success",
          content: `Successfully`,
        });
      }
    } catch (error) {}
  };

  const resetQuestions = () => {
    form.resetFields(["target"]);
    form.resetFields(["question"]);
    form.resetFields(["solution"]);
    form.resetFields(["explanation"]);
    Object.keys(listImages).forEach((key) => {
      if (
        key.includes("question") ||
        key.includes("solution") ||
        key.includes("explanation") ||
        key.includes("target")
      ) {
        delete listImages[key];
      }
    });
  };

  useEffect(() => {
    let folder = "question/";
    const defaultFilter = filters[0];
    const gradeName = gradesList.current.find((item) => item._id === defaultFilter.gradeId)?.name;
    if (gradeName) {
      folder += gradeName;
      const unitName = defaultFilter.unitList.find((item: any) => item._id === defaultFilter.unitId)?.unitName;
      if (unitName) {
        folder += `/${unitName}`;
        const lessonName = defaultFilter.lessonList.find(
          (item: any) => item._id === defaultFilter.lessonId
        )?.lessonName;
        folder += lessonName ? `/${lessonName}` : "/common";
      } else {
        folder += `/common`;
      }
    }

    setFolder(folder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const getQuestionData = async (id: string) => {
      const res = await getQuestionById(id);
      if (res) {
        const data = (res as any).result;
        initQuestion(data);
      }
    };

    const getAllInformation = async () => {
      setIsLoading(true);
      const [ gradeRes, unitRes] = await Promise.all([
        getGrades(),
        getUnits(),
      ]);

      gradeRes && (gradesList.current = (gradeRes as any).result);
      unitRes && (unitsList.current = (unitRes as any).result?.records);

      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    const getRoles = async () => {
      const cookies = new Cookies();
      const token = cookies.get("signinAdmin");
      if (token) {
        const decode = decodeToken(cookies.get("signinAdmin"));
        decode && setRoles((decode as any).roles);
        await getAllInformation();
        questionId && getQuestionData(questionId);
      } else {
        navigate(PageUrl.Signin);
      }
    };

    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-question-mobile-version">
      {isSubmitting && (
        <Spin
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            background: "rgba(0, 0, 0, 0.5)",
          }}
          indicator={<LoadingOutlined style={{ fontSize: 48, top: "50%" }} spin />}
        />
      )}
      <Form form={form} onFinish={handleSaveAndPreview} onValuesChange={(values) => setValuesForm(values)}>
        <Descriptions column={1}>
          {/* <Descriptions.Item className="filter-wrapper" label="Filter">
            {filters.map((filter, index) => (
              <FilterRow
                key={`filter-row-${index + 1}`}
                filter={filter}
                index={index}
                isLastItem={index === filters.length - 1}
              />
            ))}
          </Descriptions.Item>
          {difficultiesList.length && (
            <Descriptions.Item label="Difficulty">
              {difficultiesList?.map((_, index) => (
                <span className="difficulty-wrapper">
                  {gradesList?.current.length && (
                    <Form.Item
                      name={["difficulty", "grade", index]}
                      label={`Grade ${index + 1}`}
                      rules={[{ required: true, message: "Please select an option" }]}
                    >
                      <Select maxTagCount="responsive" placeholder="Select an option" disabled={true}>
                        {gradesList.current.map((grade) => (
                          <Option value={grade._id}>{grade.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    name={["difficulty", "complexity", index]}
                    label={`Complexity ${index + 1}`}
                    rules={[{ required: true, message: "Please select an option" }]}
                  >
                    <Select maxTagCount="responsive" placeholder="Select an option">
                      {complexitiesList &&
                        complexitiesList.map((complexity) => <Option value={complexity._id}>{complexity.name}</Option>)}
                    </Select>
                  </Form.Item>
                </span>
              ))}
            </Descriptions.Item>
          )} */}
          <Descriptions.Item label="Temp. Prompt">
            <Form.Item label="Type" name="promptType">
              <Select onChange={handleChangePromptType} placeholder="Select a type">
                {PromptTypes.map((option) => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Form.Item>
            {promptOptions && (
              <Form.Item label="Option" name="promptOption">
                <Select onChange={handleChangePromptTypeOption}>
                  {promptOptions.map((option) => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Temp. Question">
            <Form.Item label="Type" name="type">
              <Select onChange={handleChangeQuestionType} placeholder="Select a type">
                {QuestionTypeConfigs.map((option) => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Form.Item>
            {typeOptions && (
              <Form.Item label="Option" name="typeOptions">
                <Select onChange={handleChangeTypeOption}>
                  {typeOptions.map((option) => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {extraOptions && (
              <Form.Item label="Detail" name="extraOptions">
                <Select onChange={handleChangeExtraOption} value={extraOptions[0].value}>
                  {extraOptions.map((option) => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Descriptions.Item>
          {isConfirmedGradeUnit && selectedExtraOption && selectedPromptOption && (
            <>
              {generatePrompt(selectedPromptOption)}
              {["QAnswer4-1", "QAnswer5-1"].some((item) => item === selectedExtraOption) && (
                <Descriptions.Item label="Target choices">
                  <Form.Item
                    name="targetNumber"
                    rules={[
                      { required: true, message: "Please enter a value" },
                      {
                        validator(_, value) {
                          let range = [2, 4];
                          if (Number(value) <= range[1] && Number(value) >= range[0]) {
                            setGenerateDisable(false);
                            return Promise.resolve();
                          } else {
                            setGenerateDisable(true);
                            return Promise.reject("Invalid field");
                          }
                        },
                      },
                    ]}
                  >
                    <Input type="number" value={targetNumber} placeholder="Enter a number" />
                  </Form.Item>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Number choices">
                <Form.Item
                  label="Choices"
                  name="numberChoices"
                  rules={[
                    { required: true, message: "Please enter a value" },
                    {
                      validator(_, value) {
                        if (!isInteger(Number(value))) {
                          return Promise.reject("Invalid field");
                        }
                        const targetNumber = form.getFieldValue("targetNumber");
                        let range = [2, 6];
                        if (selectedTypeOption?.includes("Gapfill")) {
                          range[0] = 1;
                        }
                        if (selectedExtraOption === "QAnswer4-2" || selectedExtraOption === "QAnswer5-2") {
                          range = [2, 4];
                        }
                        if (
                          Number(value) <= range[1] &&
                          Number(value) >= range[0] &&
                          (!targetNumber ||
                            (Number(targetNumber) && Number(value) >= Number(targetNumber)) ||
                            selectedTypeOption === "DragDropMulti")
                        ) {
                          setGenerateDisable(false);
                          return Promise.resolve();
                        } else {
                          setGenerateDisable(true);
                          return Promise.reject("Invalid field");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    value={numberChoices}
                    type="number"
                    placeholder="Enter a number"
                    addonAfter={
                      <Button type="primary" disabled={generateDisable} onClick={generateChoices}>
                        Generate questions
                      </Button>
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
              {(selectedExtraOption === "QAnswer4-1" || selectedExtraOption === "QAnswer5-1") && targetNumber && (
                <Descriptions.Item label="Target">{generateTargets()}</Descriptions.Item>
              )}
              {numberChoices && generateQuestionAndSolution(selectedExtraOption)}
              {numberChoices && (
                <Descriptions.Item label="Explanation" className="explain-wrapper">
                  <span className="texts">
                    {explanationTexts.map((_, index) => (
                      <FormInput
                        type="textarea"
                        key={`text-explain-${index + 1}`}
                        label={`Text ${index + 1}`}
                        name={["explanation", `text-explain-${index + 1}`]}
                        latexString={form.getFieldValue(["explanation", `text-explain-${index + 1}`])}
                      />
                    ))}
                    {generateAudioText("explanation")}

                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainTexts} />
                      <Button
                        style={explanationTexts.length > 1 ? { display: "block" } : { display: "none" }}
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainTexts}
                      />
                    </span>
                  </span>
                  <span className="images">
                    {explanationImages.map((_, index) => (
                      <FormSingleFileInput
                        key={`image-explain-${index + 1}`}
                        label={`Image ${index + 1}`}
                        name={["explanation", `image-explain-${index + 1}`]}
                        initValue={listImages[["explanation", `image-explain-${index + 1}`].toString()]}
                        handleUpload={handleLoadImage}
                        handleRemove={handleRemoveImage}
                        folder={folder}
                      />
                    ))}
                    <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddExplainImages} />
                      <Button
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveExplainImages}
                        style={explanationImages.length > 1 ? { display: "block" } : { display: "none" }}
                      />
                    </span>
                  </span>
                </Descriptions.Item>
              )}
              <Descriptions.Item className="note-text-save">
                <NoteText style={{ margin: "4px 0 4px 150px" }} text={NoteMessage.SaveChangeBeforeAction} />
              </Descriptions.Item>
              <div className="form-controller">
                {roles?.includes(UserRole.ContentCreator) && (
                  <>
                    <Button
                      htmlType="submit"
                      type="primary"
                      icon={<EyeOutlined />}
                      style={{ backgroundColor: "#2abb2a" }}
                    >
                      Save and Preview
                    </Button>

                    <ConfirmButton
                      title="Submit"
                      buttonType="primary"
                      icon={<CheckOutlined />}
                      onConfirm={() => handleController(submitQuestions([questionId]), FlowCategory.Reviewing)}
                      disabled={isDisabledFlow(status, FlowCategory.Reviewing) || !questionId}
                    />
                  </>
                )}
                {roles?.includes(UserRole.Reviewer) && questionId && (
                  <>
                    <ConfirmButton
                      title="Publish"
                      buttonType="primary"
                      icon={<UploadOutlined />}
                      onConfirm={() => handleController(publishQuestions([questionId]), FlowCategory.Published)}
                      disabled={isDisabledFlow(status, FlowCategory.Published)}
                    />
                    <ConfirmButton
                      title="Unpublish"
                      buttonType="default"
                      icon={<DownloadOutlined />}
                      onConfirm={() => handleController(unPublishQuestions([questionId]), FlowCategory.UnPublished)}
                      disabled={isDisabledFlow(status, FlowCategory.UnPublished)}
                    />
                    <ConfirmButton
                      title="Reject"
                      buttonType="dashed"
                      icon={<CloseOutlined />}
                      onConfirm={() => handleController(rejectQuestions([questionId]), FlowCategory.RequestEdit)}
                      disabled={isDisabledFlow(status, FlowCategory.RequestEdit)}
                    />
                  </>
                )}
                <Button
                  type="primary"
                  danger
                  icon={<CloseCircleOutlined />}
                  onClick={() => navigate(PageUrl.Questions)}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Descriptions>
        {!isConfirmedGradeUnit && (
          <>
            <NoteText style={{ margin: "-16px 0 4px 150px" }} text={NoteMessage.FillAllFilterFirst} />
            <Button
              type="primary"
              style={{ marginLeft: "150px" }}
              onClick={() => setIsConfirmedGradeUnit(true)}
              disabled={
                !selectedExtraOption ||
                !selectedPromptOption ||
                !form.getFieldValue(["difficulty", "grade", 0]) ||
                !filters[0].gradeId
              }
            >
              Confirm
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default UpdateQuestionMobileVersion;
