import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { STATUS_OPTIONS } from "../../constants/filter";
import { IQuizFilter } from "../../interfaces/filter";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { previewWindow } from "../../utils/preview";
import { useAuth } from "../../hooks/useAuth";
import { FlowCategory, isDisabledFlow } from "../../constants/flow";
import { PageUrl, PreviewUrl } from "../../constants/url";
import {
  getQuizzes,
  publishQuizzes,
  rejectQuizzes,
  submitQuizzes,
  unPublishQuizzes,
} from "../../services/QuizServices";
import { getGrades } from "../../services/LessonServices";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { getUnitDetailByUnitId, getUnitsByGradeId } from "../../services/QuestionServices";
import { customSessionStorage } from "../../utils/storage";

const QuizPortal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user, token } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [gradeList, setGradeList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const filterData = useRef<IQuizFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterQuiz", formValues);
    filterData.current = {
      ...filterData.current,
      ...{
        gradeId: formValues["gradeId"],
        unitId: formValues["unitId"],
        status: formValues["status"]?.join(","),
        filter: formValues["quiz"],
      },
    };
    filterData.current.page = 1;
    getListQuizzes();
  };

  const getListQuizzes = async () => {
    const response = await getQuizzes(filterData.current);
    if (response) {
      setQuizList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListQuizzes();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListQuizzes();
  };

  const onSelectGrade = async (gradeId: string) => {
    form.setFieldValue("unit", undefined);
    const unitRes = await getUnitsByGradeId(gradeId);
    if (unitRes) {
      setUnitList((unitRes as any).result.records);
    }
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 200,
      },
      {
        title: "Grade",
        dataIndex: "gradeName",
        key: "grade",
        align: "center",
        width: 150,
      },
      {
        title: "Unit",
        dataIndex: "unitName",
        key: "unit",
        align: "center",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 250,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && (
              <Space size="middle">
                <Tooltip title="Preview">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() =>
                      previewWindow({
                        url: PreviewUrl.Quiz,
                        data: { token: token, quizId: record._id },
                        directly: true,
                      }).then(() => navigate(PageUrl.Quizzes))
                    }
                  />
                </Tooltip>
              </Space>
            )}
            {user?.roles?.includes(UserRole.Reviewer) && (
              <>
                <Space size="middle">
                  <ConfirmButton
                    title="Publish"
                    tooltip={true}
                    icon={<UploadOutlined />}
                    onConfirm={() => verifyQuizBeforeChange(record._id, record.unitId, FlowCategory.Published)}
                    disabled={isDisabledFlow(record.status, FlowCategory.Published)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Unpublish"
                    tooltip={true}
                    icon={<DownloadOutlined />}
                    onConfirm={() => verifyQuizBeforeChange(record._id, record.unitId, FlowCategory.UnPublished)}
                    disabled={isDisabledFlow(record.status, FlowCategory.UnPublished)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Reject"
                    tooltip={true}
                    icon={<CloseOutlined />}
                    onConfirm={() => handleRejectQuiz(record._id)}
                    disabled={isDisabledFlow(record.status, FlowCategory.RequestEdit)}
                  />
                </Space>
              </>
            )}
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleEditQuiz(record._id)}
                      disabled={isDisabledFlow(record.status, FlowCategory.Draft)}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Submit"
                    tooltip={true}
                    icon={<CheckOutlined />}
                    onConfirm={() => handleSubmitQuiz(record._id)}
                    disabled={isDisabledFlow(record.status, FlowCategory.Reviewing)}
                  />
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const verifyQuizBeforeChange = async (quizId: string, unitId: string, type: number) => {
    const unitRes = await getUnitDetailByUnitId(unitId);
    const quizInUnit = (unitRes as any)?.result?.quizId;
    if (!quizInUnit || quizInUnit === quizId) {
      if (type === FlowCategory.Published) {
        handlePublishQuiz(quizId);
      } else {
        handleUnPublishQuiz(quizId);
      }
    } else {
      const updateUnitUrl = `${PageUrl.Units}${PageUrl.Update}`.replace(":id", form.getFieldValue("unitId"));
      Modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: (
          <span>
            Một quiz khác đã được gán cho unit hiện tại, hãy kiểm tra lại unit tại&nbsp;
            <a href={updateUnitUrl} target="_blank" rel="noreferrer">
              đây.
            </a>
            <br />
            <span style={{ color: "mediumblue" }}>
              * Lưu ý: Các thao tác với quiz tại màn hình này sẽ không làm ảnh hưởng đến unit.
            </span>
          </span>
        ),
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          if (type === FlowCategory.Published) {
            handlePublishQuiz(quizId);
          } else {
            handleUnPublishQuiz(quizId);
          }
        },
      });
    }
  };

  const handlePublishQuiz = async (id: string) => {
    try {
      await publishQuizzes([id]);
      getListQuizzes();
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnPublishQuiz = async (id: string) => {
    try {
      await unPublishQuizzes([id]);
      getListQuizzes();
    } catch (error) {
      console.log(error);
    }
  };
  const handleRejectQuiz = async (id: string) => {
    try {
      await rejectQuizzes([id]);
      getListQuizzes();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitQuiz = async (id: string) => {
    try {
      await submitQuizzes([id]);
      getListQuizzes();
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditQuiz = async (id: string) => {
    navigate(`${PageUrl.Quizzes}${PageUrl.Update}`.replace(":id", id));
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterQuiz")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterQuiz")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterQuiz");
        } else {
          sessionFilter.gradeId && (await onSelectGrade(sessionFilter.gradeId));
          filterData.current = { ...filterData.current, ...sessionFilter };
          form.setFieldsValue(sessionFilter);
        }
        getListQuizzes();
      } else {
        getListQuizzes();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="quiz-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={5}>
                <FormSelect
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                  callback={onSelectGrade}
                />
              </Col>
              <Col span={5}>
                <FormSelect
                  label="Unit"
                  name="unitId"
                  optionData={convertToSelectOption(unitList, "_id", "unitName")}
                />
              </Col>
              <Col span={5}>
                <FormInput label="Quiz" name="filter" />
              </Col>
              <Col span={5}>
                <FormSelect mode="multiple" label="Status" name="status" optionData={STATUS_OPTIONS} allowClear />
              </Col>
              <Col span={2} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Quizzes}${PageUrl.Create}`)}
            >
              Create quiz
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={quizList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default QuizPortal;
