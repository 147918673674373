import { IStoryFilter, IUnitFilter } from "./../interfaces/filter";
import instance, { apiConfig } from "./instance";

const categoryUrl = "/category/v1/admin";
const storyUrl = "/lesson/v1/admin";

export const getGrades = () => {
  return instance.get(`${categoryUrl}/grade/get-grade-category`, apiConfig({ page: 1, size: 100 }));
};

export const getUnits = (postData?: IUnitFilter) => {
  return instance.get(`${storyUrl}/unit/get-list-units`, apiConfig(postData));
};

export const addUnit = (data: any) => {
  return instance.put(`${storyUrl}/unit/create-new-unit`, data, apiConfig());
};

export const editUnit = (id: string, data: any): Promise<any> => {
  return instance.post(`${storyUrl}/unit/edit-an-unit/${id}`, data, apiConfig());
};

export const getStories = (postData: IStoryFilter) => {
  const data = { ...postData, status: postData.status?.join(",") };
  return instance.get(`${storyUrl}/story/get-list-stories`, apiConfig(data));
};

export const getStoryById = (id: string) => {
  return instance.get(`${storyUrl}/story/get-a-story/${id}`, apiConfig());
};

export const addStory = (data: any) => {
  return instance.put(`${storyUrl}/story/create-new-story`, data, apiConfig());
};

export const editStory = (id: string, data: any): Promise<any> => {
  return instance.post(`${storyUrl}/story/edit-a-story/${id}`, data, apiConfig());
};

export const publishStories = (listId: string[]) => {
  return instance.post(`${storyUrl}/story/publish-stories`, listId, apiConfig());
};

export const unPublishStories = (listId: string[]) => {
  return instance.post(`${storyUrl}/story/unpublish-stories`, listId, apiConfig());
};

export const rejectStories = (listId: string[]) => {
  return instance.post(`${storyUrl}/story/reject-stories`, listId, apiConfig());
};

export const submitStories = (listId: string[]) => {
  return instance.post(`${storyUrl}/story/submit-stories`, listId, apiConfig());
};
