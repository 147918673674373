/* eslint-disable @typescript-eslint/no-unused-vars */
import "./UpdateAccountAdmin.scss";
import { EyeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import { addAccountAdmin, editAccountAdmin, getAccountAdminById } from "../../../services/AccountAdminServices";
import { IAccountAdmin } from "../../../interfaces/IAccountAdmin";
import { getAllActiveRoles } from "../../../services/AccountAdminServices";
import { ISelectOption } from "../../../interfaces/filter";

const UpdateAccountAdmin = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const accountAdminId = useRef<string>(params.id || "");
  const [roleList, setRoleList] = useState<ISelectOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      const accountAdmin = form.getFieldsValue() as IAccountAdmin;
      let response: any;
      if (accountAdminId.current) {
        response = await editAccountAdmin(accountAdminId.current, accountAdmin);
      } else {
        response = await addAccountAdmin(accountAdmin);
      }
      accountAdminId.current = response.result ? response.result : accountAdminId.current;
      Modal.success({
        title: "Success",
        type: "success",
        content: `${accountAdminId.current ? "Updated" : "Added"} successfully`,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const roleRes = (await getAllActiveRoles()) as any;
      if (roleRes.errorCode !== 200) return;
      setRoleList(roleRes.result?.map((item: any) => ({ label: item.role, value: item._id })));

      if (accountAdminId.current) {
        const res = (await getAccountAdminById(accountAdminId.current)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          form.setFieldsValue({
            userName: res.result.userName,
            employeeId: res.result.employee.id,
            roles: res.result.roles.map((item: any) => item.id),
            active: res.result.active,
          });
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAdminId.current, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-account-admin-container">
      <Form form={form} onFinish={handleSave}>
        <div className="form-input">
          <Row>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Full name" name="userName" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormInput label="Employee ID" name="employeeId" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={6}>
              <FormSelect label="Active" name="active" optionData={ELECTIVE_OPTIONS} />
            </Col>
          </Row>
          <Row>
            <Col xs={16} md={16} xl={12}>
              <FormSelect mode="multiple" label="Roles" name="roles" optionData={roleList} allowClear />
            </Col>
          </Row>
        </div>
        <div className="form-controller">
          <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
            Save
          </Button>
          <Button type="primary" danger icon={<CloseCircleOutlined />} onClick={() => navigate(PageUrl.AccountAdmins)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateAccountAdmin;
