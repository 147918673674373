import { SlideItem } from "./SlideItem";
import CustomCollapse from "../CustomCollapse/CustomCollapse";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { addSlideAbove, INITIAL_SLIDE_STATE, moveSlide, removeSlide } from "../../reducers/lessonSlice";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { ISlide } from "../../interfaces/ILesson";
import { randomId } from "../../utils/utils";

export const ListSlides = ({ sectionIndex }: { sectionIndex: number }) => {
  const dispatch = useAppDispatch();
  const slides = useAppSelector((state) => state.lesson.sections[sectionIndex].slides);
  const form = useFormInstance();

  const handleMove = ({
    sectionIndex,
    index,
    direction,
  }: {
    sectionIndex: number;
    index: number;
    direction: "up" | "down";
  }) => {
    const formSlides = form.getFieldValue(["sections", sectionIndex, "slides"]);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex >= formSlides?.length) return;
    const slide = formSlides[index];
    formSlides.splice(index, 1);
    formSlides.splice(newIndex, 0, slide);
    form.setFieldValue(["sections", sectionIndex, "slides"], formSlides);
    dispatch(moveSlide({ sectionIndex, index, direction }));
  };

  const handleRemove = (index: number) => {
    const formSlides = form.getFieldValue(["sections", sectionIndex, "slides"]);
    formSlides.splice(index, 1);
    form.setFieldValue(["sections", sectionIndex, "slides"], formSlides);
    dispatch(removeSlide({ sectionIndex, index }));
  };

  const handleAddSlideAbove = (index: number) => {
    const newKey = randomId();
    const newData = { ...INITIAL_SLIDE_STATE, slideKey: newKey };

    const formSlides = form.getFieldValue(["sections", sectionIndex, "slides"]);
    formSlides.splice(index, 0, newData);
    form.setFieldValue(["sections", sectionIndex, "slides"], formSlides);

    dispatch(addSlideAbove({ sectionIndex, index, slideKey: newKey }));
  };

  return (
    <>
      {slides?.map((slide: ISlide, index) => (
        <CustomCollapse
          key={slide.slideKey}
          title={slide.title ? `Slide: ${slide.title}` : `Slide ${index}`}
          children={<SlideItem key={slide.slideKey} sectionIndex={sectionIndex} index={index} />}
          move={(e) => handleMove({ sectionIndex, index, direction: e })}
          removeItem={() => handleRemove(index)}
          addAbove={() => handleAddSlideAbove(index)}
        />
      ))}
    </>
  );
};
