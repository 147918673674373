import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, type UploadFile, type UploadProps } from "antd";

type Props = {
  onUploadList: (fileList: UploadFile[]) => void;
};

const { Dragger } = Upload;

const DragDropUpload = ({ onUploadList }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    fileList: fileList,
    directory: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    onChange(info: any) {
      setFileList(info.fileList);
      onUploadList(info.fileList);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">Support for a single or bulk upload. Upload directly when files are dropped</p>
    </Dragger>
  );
};

export default DragDropUpload;
