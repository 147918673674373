import instance, { apiConfig } from "./instance";
import { IEmployeeFilter } from "../interfaces/filter";

const employeeUrl = "/auth/v1/admin/employee";

export const getEmployees = (filter: IEmployeeFilter) => {
  return instance.get(`${employeeUrl}/get-list-employees`, apiConfig(filter));
};

export const getEmployeeById = (id: string) => {
  return instance.get(`${employeeUrl}/get-an-employee/${id}`, apiConfig());
};

export const addEmployee = (data: any) => {
  return instance.put(`${employeeUrl}/create-new-employee`, data, apiConfig());
};

export const editEmployee = (id: string, data: any): Promise<any> => {
  return instance.post(`${employeeUrl}/edit-an-employee/${id}`, data, apiConfig());
};
