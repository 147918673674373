import { ResponseType } from "axios";
import { IPreRegisterFilter } from "../interfaces/filter";
import instance, { apiConfig, apiExportFileConfig } from "./instance";

const preRegisterUrl = "/auth/v1/admin/pre-registration";

export const getPreRegisters = (filter: IPreRegisterFilter) => {
  const { page, size, ...rest } = filter;

  return instance.post(`${preRegisterUrl}/get-list-pre-registrations`, rest, apiConfig({ page, size }));
};

export const getPreRegisterById = (id: string) => {
  return instance.get(`${preRegisterUrl}/get-a-pre-registration/${id}`, apiConfig());
};

export const createPreRegister = (data: any): Promise<any> => {
  return instance.post(`${preRegisterUrl}/create-pre-registration`, data, apiConfig());
};

export const editPreRegister = (id: string, data: any): Promise<any> => {
  return instance.post(`${preRegisterUrl}/update-pre-registration/${id}`, data, apiConfig());
};

export const exportPreRegisters = (filter: IPreRegisterFilter) => {
  const responseType = "arraybuffer" as ResponseType;

  return instance.post(`${preRegisterUrl}/export-pre-registrations`, filter, apiExportFileConfig(responseType));
};
