/* eslint-disable @typescript-eslint/no-unused-vars */
import "./UpdateDailyQuizEvent.scss";
import { EyeOutlined, CloseCircleOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col, Modal, Card, DatePicker, TimePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import { FormSelect } from "../../../components/Form/Select";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { ELECTIVE_OPTIONS } from "../../../constants/filter";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import { addDailyQuizEvent, editDailyQuizEvent, getDailyQuizEventById } from "../../../services/DailyQuizEventServices";
import { IDailyQuizEvent } from "../../../interfaces/IDailyQuizEvent";
import { DAILY_QUIZ_EVENT_STATUSES } from "../../../configs/dailyQuizEventConfigs";
import { convertToSelectOption, datePickerToDateFormat } from "../../../utils/utils";
import { getComplexities, getGrades, getUnits } from "../../../services/QuestionServices";
import { getAchievements } from "../../../services/AchievementServices";
import dayjs from "dayjs";
import { FormSingleFileInput } from "../../../components/Form/SingleFileInput";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../../../components/UploadInput/UploadInput";
import NoteText, { NoteMessage } from "../../../components/NoteText/NoteText";
import { DAILY_QUIZ_IMAGES_TITLE } from "./configs";

const UpdateDailyQuizEvent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const dailyQuizEventId = useRef<string>(params.id || "");
  const [duration, setDuration] = useState<string[]>([]);
  const [gradeList, setGradeList] = useState<string[]>([]);
  const [unitList, setUnitList] = useState<string[]>([]);
  const [allUnits, setAllUnits] = useState<string[]>([]);
  const [difficultyList, setDifficultyList] = useState<string[]>([]);
  const [achievementList, setAchievementList] = useState<string[]>([]);
  const [imageList, setImageList] = useState<string[]>(DAILY_QUIZ_IMAGES_TITLE);
  const [folder, setFolder] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      const formValues = form.getFieldsValue();
      const [startHourMin, endHourMin] = [formValues.Hm[0]?.$d, formValues.Hm[1]?.$d];
      const startDate = dayjs(
        `${duration[0]}T${startHourMin.getHours()}:${startHourMin.getMinutes()}`,
        "DD-MM-YYYYTHH:mm"
      )
        .utc()
        .format();
      const endDate = dayjs(`${duration[1]}T${endHourMin.getHours()}:${endHourMin.getMinutes()}`, "DD-MM-YYYYTHH:mm")
        .utc()
        .format();
      const eventData: IDailyQuizEvent = {
        eventName: formValues.eventName,
        description: formValues.description,
        startDate,
        endDate,
        status: Number(formValues.status),
        monsterName: formValues.monsterName,
        imageUrls: formValues.imageUrls,
        startingHp: Number(formValues.startingHp),
        hpRegen: Number(formValues.hpRegen),
        maximumRegen: Number(formValues.maximumRegen),
        questionsPerDay: Number(formValues.questionsPerDay),
        achievementId: formValues.achievementId,
        gradeId: formValues.gradeId,
        unitIds: formValues.unitIds || [],
        difficultyIds: formValues.difficultyIds || [],
        ikmcQuestionsPerDay: Number(formValues.ikmcQuestionsPerDay),
        ikmcGradeId: formValues.ikmcGradeId,
        ikmcDifficultyIds: formValues.ikmcDifficultyIds || [],
      };

      let response: any;
      if (dailyQuizEventId.current) {
        response = await editDailyQuizEvent(dailyQuizEventId.current, eventData);
      } else {
        response = await addDailyQuizEvent(eventData);
      }
      Modal.success({
        title: "Success",
        type: "success",
        content: `${dailyQuizEventId.current ? "Updated" : "Added"} successfully`,
      });
      dailyQuizEventId.current = response.result ? response.result : dailyQuizEventId.current;
    } catch (error) {
      console.log("error:", error);
    }
  };

  // const handleAddImage = () => {
  //   setImageList([...imageList, ""]);
  // };

  // const handleRemoveImage = () => {
  //   setImageList((prev) => prev.slice(0, -1));
  // };

  const handleLoadImage = (name: NamePath, imageData: FileData) => {
    form.setFieldValue(name, imageData?.fileLink);
  };

  const handleClearImage = (name: NamePath) => {
    form.setFieldValue(name, "");
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [complexityRes, gradeRes, unitRes, achievementRes] = await Promise.all([
        getComplexities(),
        getGrades(),
        getUnits(),
        getAchievements({ page: 1, size: 1000, menu: 4 }),
      ]);
      complexityRes && setDifficultyList((complexityRes as any).result);
      gradeRes && setGradeList((gradeRes as any).result);
      unitRes && setAllUnits(() => (unitRes as any).result.records);
      achievementRes && setAchievementList((achievementRes as any).result.records);
      if (dailyQuizEventId.current) {
        const res = (await getDailyQuizEventById(dailyQuizEventId.current)) as any;
        if (res?.errorCode !== 200) return;
        const units = (unitRes as any).result.records.filter((unit: any) => unit.gradeId === res.result.grade.id);
        setUnitList(units);
        setTimeout(() => {
          form.setFieldsValue(res.result);
          form.setFields([
            { name: "gradeId", value: res.result.grade.id },
            { name: "unitIds", value: res.result.units.map((item: any) => item.id) },
            { name: "difficultyIds", value: res.result.difficulties?.map((item: any) => item.id) },
            { name: "achievementId", value: res.result.achievement.id },
            { name: "Hm", value: [dayjs(res.result.startDate), dayjs(res.result.endDate)] },
            { name: "ikmcDifficultyIds", value: res.result.ikmcDifficulties?.map((item: any) => item.id) },
            { name: "ikmcGradeId", value: res.result.ikmcGrade?.id },
            
          ]);
          setDuration([datePickerToDateFormat(res.result.startDate), datePickerToDateFormat(res.result.endDate)]);
          // setImageList(() => res.result.imageUrls || [""]);
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-daily-quiz-event-container">
      <Form form={form} onFinish={handleSave}>
        <div className="form-input" style={{ display: "grid", gap: "16px" }}>
          <Card title="Event settings">
            <Row>
              <Col xs={12} md={12} xl={12} xxl={6}>
                <FormInput
                  label="Event name"
                  name="eventName"
                  rules={[{ required: true }]}
                  onChange={(e) => setFolder(e ? `daily-event/${e}` : undefined)}
                />
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <FormSelect
                  label="Status"
                  name="status"
                  optionData={DAILY_QUIZ_EVENT_STATUSES}
                  allowClear
                  rules={[{ required: true }]}
                />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  label="Achievement"
                  name="achievementId"
                  optionData={convertToSelectOption(achievementList, "_id", "name")}
                  allowClear
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} xl={12} xxl={6}>
                <Form.Item label="Duration" name="duration" rules={[{ required: true }]}>
                  <DatePicker.RangePicker
                    allowClear
                    defaultValue={
                      !duration[0] && !duration[1]
                        ? null
                        : [dayjs(duration[0], "DD/MM/YYYY"), dayjs(duration[1], "DD/MM/YYYY")]
                    }
                    format={["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]}
                    onChange={(_, date) => setDuration(date)}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <Form.Item label="Hour&Min" name="Hm" rules={[{ required: true, message: "Please select exam time" }]}>
                  <TimePicker.RangePicker allowClear format={["HH:mm"]} />
                </Form.Item>
              </Col>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <FormInput label="Description" name="description" />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <FormInput type="number" label="Questions/day" name="questionsPerDay" />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                  callback={(id: any) => {
                    form.setFieldValue("unitIds", undefined);
                    if (id) {
                      const units = allUnits.filter((unit: any) => unit.gradeId === id);
                      setUnitList(units);
                    } else {
                      setUnitList([]);
                    }
                  }}
                  allowClear
                  rules={[{ required: true }]}
                />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  mode="multiple"
                  label="Units"
                  name="unitIds"
                  optionData={convertToSelectOption(unitList, "_id", "unitName")}
                  allowClear
                />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  mode="multiple"
                  label="Difficulties"
                  name="difficultyIds"
                  optionData={convertToSelectOption(difficultyList, "_id", "name")}
                  allowClear
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6} xl={6} xxl={6}>
                <FormInput type="number" label="IKMCQ/day" name="ikmcQuestionsPerDay" />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  label="Grade"
                  name="ikmcGradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                  allowClear
                />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormSelect
                  mode="multiple"
                  label="Difficulties"
                  name="ikmcDifficultyIds"
                  optionData={convertToSelectOption(difficultyList, "_id", "name")}
                  allowClear
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Images" style={{ marginBottom: "unset", marginTop: "8px" }}>
                  <span className="images">
                    {imageList.map((title, index: number) => (
                      <FormSingleFileInput
                        key={index}
                        label={title}
                        name={["imageUrls", index]}
                        initValue={form.getFieldValue(["imageUrls", index])}
                        handleUpload={handleLoadImage}
                        handleRemove={handleClearImage}
                        folder={folder}
                      />
                    ))}
                    {/* <span className="control">
                      <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddImage} />
                      <Button
                        type="ghost"
                        block
                        icon={<MinusOutlined />}
                        onClick={handleRemoveImage}
                        style={imageList.length > 1 ? { display: "block" } : { display: "none" }}
                      />
                    </span> */}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <NoteText style={{ margin: "4px 0 4px 100px" }} text={NoteMessage.ReUploadImageAfterChangeEventName} />
          </Card>

          <Card title="Monster settings">
            <Row>
              <Col xs={12} md={12} xl={12}>
                <FormInput label="Name" name="monsterName" rules={[{ required: true }]} />
              </Col>
            </Row>
            <Row className="last-row">
              <Col xs={6} md={6} xl={6}>
                <FormInput type="number" label="Starting HP" name="startingHp" rules={[{ required: true }]} />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormInput type="number" label="HP regen" name="hpRegen" rules={[{ required: true }]} />
              </Col>
              <Col xs={6} md={6} xl={6}>
                <FormInput type="number" label="Max regen" name="maximumRegen" rules={[{ required: true }]} />
              </Col>
            </Row>
          </Card>
          <div className="form-controller">
            <Button type="primary" icon={<EyeOutlined />} style={{ backgroundColor: "#2abb2a" }} onClick={handleSave}>
              Save
            </Button>
            <Button
              type="primary"
              danger
              icon={<CloseCircleOutlined />}
              onClick={() => navigate(PageUrl.DailyQuizEvents)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UpdateDailyQuizEvent;
