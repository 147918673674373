import React, { useRef } from "react";
import { Input, Modal, Space } from "antd";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./UploadInput.scss";
import { uploadFile } from "../../services/UploadFileServices";

export interface FileData {
  fileName: string;
  fileLink: any;
}
interface InputProps {
  inputId: string;
  initValue?: string;
  folder?: string;
  isGif?: boolean;
  onData: ({ fileName, fileLink }: FileData) => void;
  onRemove?: () => void;
}

const SingleFileInput = ({ inputId, initValue, folder, onData, onRemove, isGif = false }: InputProps) => {
  const inputRef = useRef<string>(initValue || "");

  const accept = isGif
    ? ".riv, .json"
    : ".apng, .avif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp, .bmp, .ico, .cur, .tif, .tiff";

  const extractFileNameAndExt = (str: string) => {
    if (str.includes(".")) return [str.slice(0, str.lastIndexOf(".")), str.slice(str.lastIndexOf(".") + 1, str.length)];
    return ["", ""];
  };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const nameParts = extractFileNameAndExt(file.name);
      const newFile = new File([file], `${nameParts[0]}-${Math.floor(new Date().getTime() / 1000)}.${nameParts[1]}`, {
        type: file.type,
      });
      const res = (await uploadFile(newFile, folder || "")) as any;
      if (res.result[0].message) {
        Modal.error({
          type: "error",
          title: "Upload error",
          content: res.result[0].message,
        });
        return;
      }
      Modal.success({
        type: "success",
        title: "Success",
        content: "Uploaded image successfully to cloud",
      });
      inputRef.current = res.result[0].objectId;
      onData({ fileName: res.result[0].name, fileLink: res.result[0].objectId });
    }
  };

  const handleInputChange = (e: any) => {
    inputRef.current = e.target.value;
    onData({ fileName: "", fileLink: e.target.value });
  };

  const handleClick = (e: any) => {
    e.target.value = null;
  };

  const handleRemove = () => {
    inputRef.current = "";
    onRemove && onRemove();
  };

  return (
    <div className="upload-input">
      <Input value={inputRef.current} onChange={handleInputChange} placeholder="Upload an image" readOnly />
      <Space style={{ columnGap: "8px", position: "absolute", top: "-24px", right: "0px" }}>
        {inputRef.current && <DeleteOutlined onClick={handleRemove} />}
        <CloudUploadOutlined onClick={() => document.getElementById(inputId)?.click()} />
      </Space>
      <input
        id={inputId}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileUpload}
        onClick={handleClick}
        accept={accept}
      />
    </div>
  );
};

export default SingleFileInput;
