import { Modal } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { IAccountUserFilter, ITrackUserDailyActive } from "../interfaces/filter";
import instance, { apiConfig } from "./instance";
import { IPicSetting } from "../interfaces/IAccountUser";

const accountUrl = "/auth/v1/admin/account";

export const getAccountUsers = (filter: IAccountUserFilter) => {
  return instance.get(`${accountUrl}/get-list-accounts`, apiConfig(filter));
};

export const getAccountUserById = (id: string) => {
  return instance.get(`${accountUrl}/get-an-account/${id}`, apiConfig());
};

export const getAccountUserDailyActive = (id: string) => {
  return instance.get(`${accountUrl}/get-user-daily-active/${id}`, apiConfig());
};

export const getAccountUserLastLesson = (id: string) => {
  return instance.get(`${accountUrl}/get-user-last-lesson/${id}`, apiConfig());
};

export const getAccountUserRoadMapInsights = (id: string) => {
  return instance.get(`${accountUrl}/get-user-road-map-insights/${id}`, apiConfig());
};

export const getAccountUserYearExamInsights = (id: string) => {
  return instance.get(`${accountUrl}/get-user-year-exam-insights/${id}`, apiConfig());
};

export const getAccountUserEloHistory = (id: string, filter?: any) => {
  return instance.get(`${accountUrl}/get-user-elo-history/${id}`, filter ? apiConfig(filter) : apiConfig());
};

export const addAccountUser = (data: any) => {
  return instance.put(`${accountUrl}/create-new-account`, data, apiConfig());
};

export const editAccountUser = (id: string, data: any): Promise<any> => {
  return instance.post(`${accountUrl}/edit-an-account/${id}`, data, apiConfig());
};

export const changeUserEmailValidatedStatus = (id: string, status: boolean) => {
  return instance.post(
    `${accountUrl}/change-user-email-validated-status/${id}`,
    { emailValidated: status },
    apiConfig()
  );
};

export const changeUserPremiumTime = (id: string, premiumFrom: string, premiumTo: string, isTransfer = false) => {
  return instance.post(
    `${accountUrl}/change-user-premium-time/${id}`,
    { premiumFrom, premiumTo, isTransfer },
    apiConfig()
  );
};

export const importAccountUsers = (data: FormData) => {
  const cookies = new Cookies();
  const token = cookies.get("signinAdmin");

  const newInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  newInstance.interceptors.response.use(
    function (response) {
      Modal.success({
        title: "Success",
        type: "success",
        content: "Import thành công, danh sách users đã được tải về!",
      });
      return Promise.resolve(response);
    },
    function (error) {
      if (error.response.status === 422) {
        Modal.error({
          title: "Error",
          type: "error",
          content: "Dữ liệu chưa đúng, kiểm tra lại file kết quả vừa trả về!",
        });
        return Promise.resolve(error);
      }

      Modal.error({
        title: "Error",
        type: "error",
        content: `${error?.message}`,
      });
      return Promise.reject(error);
    }
  );

  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    responseType: "arraybuffer",
  } as any;

  return newInstance.post(`${accountUrl}/import-users-from-excel-file`, data, config);
};
export const getUserDailyActiveRecords = (filter: ITrackUserDailyActive) => {
  return instance.get(`${accountUrl}/get-daily-active-records`, apiConfig(filter));
};
export const trackUserDailyActive = (filter: ITrackUserDailyActive) => {
  return instance.get(`${accountUrl}/track-user-daily-active`, apiConfig(filter));
};
export const trackUserCohort = (filter: ITrackUserDailyActive) => {
  return instance.get(`${accountUrl}/track-user-cohort`, apiConfig(filter));
};

export const updateUserPic = (id: string, data: IPicSetting) => {
  return instance.post(`${accountUrl}/update-user-sale-info/${id}`, data, apiConfig());
};
