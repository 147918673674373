export enum FlowCategory {
  Draft = 0,
  Published = 1,
  Reviewing = 2,
  RequestEdit = 3,
  UnPublished = 4,
  Delete = 5,
}

export const WORK_FLOW_NAME = [["Submit"], ["Reject", "Unpublish"], ["Publish", "Reject"], ["Submit"], ["Publish", "Reject"]];

export const WORK_FLOW = [
  [FlowCategory.Draft, FlowCategory.Reviewing],
  [FlowCategory.RequestEdit, FlowCategory.UnPublished],
  [FlowCategory.Published, FlowCategory.RequestEdit],
  [FlowCategory.Reviewing, FlowCategory.Draft],
  [FlowCategory.Published, FlowCategory.RequestEdit],
];

export const isDisabledFlow = (currentStatus: number, nextStatus: number) => {
  return !WORK_FLOW[currentStatus].includes(nextStatus);
};
