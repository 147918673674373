import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILesson, ISection, ISlide } from "../interfaces/ILesson";
import { randomId } from "../utils/utils";

export const INITIAL_SLIDE_STATE: ISlide = {
  title: "",
  backgroundImage: "",
  template: { question: "", content: "" },
  content: { texts: [], audioText: "", audioVoiceCode: "", audioLink: "", images: [], video: [], backgroundImage: "" },
  question: {
    texts: [],
    audioText: "",
    audioVoiceCode: "",
    audioLink: "",
    targets: [],
    choices: [],
    solutions: [],
    backgroundImage: "",
  },
  explanation: { texts: [], images: [], audioText: "", audioVoiceCode: "", audioLink: "" },
  slideKey: randomId(),
};
export const INITIAL_SECTION_STATE: ISection = {
  sectionName: "",
  backgroundImage: "",
  slides: [INITIAL_SLIDE_STATE],
  sectionKey: randomId(),
};
export const INITIAL_LESSON_STATE: ILesson = {
  recordId: null,
  title: "",
  description: "",
  order: 0,
  unitId: "",
  gradeId: "",
  imageFolder: "",
  sections: [INITIAL_SECTION_STATE],
  mobileSections: [],
};

export const lessonSlice = createSlice({
  name: "lesson",
  initialState: INITIAL_LESSON_STATE,
  reducers: {
    updateLesson: (state, action: PayloadAction<ILesson>) => {
      return { ...state, ...action.payload };
    },
    addSection: (state, action: PayloadAction<string>) => {
      const initSlide = { ...INITIAL_SLIDE_STATE, slideKey: randomId() };
      const newSection = { ...{ ...INITIAL_SECTION_STATE, slides: [initSlide] }, sectionKey: action.payload };
      state.sections = [...state.sections, newSection];
    },
    addSectionAbove: (state, action: PayloadAction<{ sectionIndex: number; sectionKey: string }>) => {
      const { sectionIndex, sectionKey } = action.payload;
      const newSection = { ...INITIAL_SECTION_STATE, sectionKey: sectionKey };
      state.sections.splice(sectionIndex, 0, newSection);
    },
    updateSection: (state, action: PayloadAction<{ index: number; section: ISection }>) => {
      const { index, section } = action.payload;
      state.sections[index] = section;
    },
    moveSection: (state, action: PayloadAction<{ index: number; direction: "up" | "down" }>) => {
      const { index, direction } = action.payload;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= state.sections.length) return;
      const section = state.sections[index];
      state.sections.splice(index, 1);
      state.sections.splice(newIndex, 0, section);
    },
    removeSection: (state, action: PayloadAction<number>) => {
      state.sections.splice(action.payload, 1);
    },
    addSlide: (state, action: PayloadAction<{ sectionIndex: number; slideKey: string }>) => {
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: action.payload.slideKey };
      state.sections[action.payload.sectionIndex].slides = [
        ...state.sections[action.payload.sectionIndex].slides,
        newSlide,
      ];
    },
    addSlideAbove: (state, action: PayloadAction<{ sectionIndex: number; index: number; slideKey: string }>) => {
      const { sectionIndex, index, slideKey } = action.payload;
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: slideKey };
      state.sections[sectionIndex].slides.splice(index, 0, newSlide);
    },
    updateSlide: (state, action: PayloadAction<{ sectionIndex: number; index: number; slide: ISlide }>) => {
      const { sectionIndex, index, slide } = action.payload;
      state.sections[sectionIndex].slides[index] = slide;
    },
    moveSlide: (state, action: PayloadAction<{ sectionIndex: number; index: number; direction: "up" | "down" }>) => {
      const { sectionIndex, index, direction } = action.payload;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= state.sections[sectionIndex].slides.length) return;
      const slide = state.sections[sectionIndex].slides[index];
      state.sections[sectionIndex].slides.splice(index, 1);
      state.sections[sectionIndex].slides.splice(newIndex, 0, slide);
    },
    removeSlide: (state, action: PayloadAction<{ sectionIndex: number; index: number }>) => {
      const { sectionIndex, index } = action.payload;
      state.sections[sectionIndex].slides.splice(index, 1);
    },

    //Update Audio Link
    updateAudioLink: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        slideIndex: number;
        explanationAudioLink: string;
        contentAudioLink: string;
        questionAudioLink: string;
      }>
    ) => {
      const { sectionIndex, slideIndex, explanationAudioLink, contentAudioLink, questionAudioLink } = action.payload;
      const slide = state.sections[sectionIndex].slides[slideIndex];

      slide.explanation.audioLink = explanationAudioLink;
      slide.content.audioLink = contentAudioLink;
      slide.question.audioLink = questionAudioLink;
    },

    // Update Mobile Sections
    addMobileSection: (state, action: PayloadAction<string>) => {
      const newSection = { ...INITIAL_SECTION_STATE, sectionKey: action.payload };
      state.mobileSections = [...state.mobileSections, newSection];
    },
    addMobileSectionAbove: (state, action: PayloadAction<{ sectionIndex: number; sectionKey: string }>) => {
      const { sectionIndex, sectionKey } = action.payload;
      const newSection = { ...INITIAL_SECTION_STATE, sectionKey: sectionKey };
      state.mobileSections.splice(sectionIndex, 0, newSection);
    },
    updateMobileSection: (state, action: PayloadAction<{ index: number; section: ISection }>) => {
      const { index, section } = action.payload;
      state.mobileSections[index] = section;
    },
    moveMobileSection: (state, action: PayloadAction<{ index: number; direction: "up" | "down" }>) => {
      const { index, direction } = action.payload;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= state.mobileSections.length) return;
      const section = state.mobileSections[index];
      state.mobileSections.splice(index, 1);
      state.mobileSections.splice(newIndex, 0, section);
    },
    removeMobileSection: (state, action: PayloadAction<number>) => {
      state.mobileSections.splice(action.payload, 1);
    },

    // Update Mobile Slides
    addMobileSlide: (state, action: PayloadAction<{ sectionIndex: number; slideKey: string }>) => {
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: action.payload.slideKey };
      state.mobileSections[action.payload.sectionIndex].slides = [
        ...state.mobileSections[action.payload.sectionIndex].slides,
        newSlide,
      ];
    },
    addMobileSlideAbove: (state, action: PayloadAction<{ sectionIndex: number; index: number; slideKey: string }>) => {
      const { sectionIndex, index, slideKey } = action.payload;
      const newSlide = { ...INITIAL_SLIDE_STATE, slideKey: slideKey };
      state.mobileSections[sectionIndex].slides.splice(index, 0, newSlide);
    },
    updateMobileSlide: (state, action: PayloadAction<{ sectionIndex: number; index: number; slide: ISlide }>) => {
      const { sectionIndex, index, slide } = action.payload;
      state.mobileSections[sectionIndex].slides[index] = slide;
    },
    moveMobileSlide: (
      state,
      action: PayloadAction<{ sectionIndex: number; index: number; direction: "up" | "down" }>
    ) => {
      const { sectionIndex, index, direction } = action.payload;
      const newIndex = direction === "up" ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= state.mobileSections[sectionIndex].slides.length) return;
      const slide = state.mobileSections[sectionIndex].slides[index];
      state.mobileSections[sectionIndex].slides.splice(index, 1);
      state.mobileSections[sectionIndex].slides.splice(newIndex, 0, slide);
    },
    removeMobileSlide: (state, action: PayloadAction<{ sectionIndex: number; index: number }>) => {
      const { sectionIndex, index } = action.payload;
      state.mobileSections[sectionIndex].slides.splice(index, 1);
    },

    updateMobileAudioLink: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        slideIndex: number;
        explanationAudioLink: string;
        contentAudioLink: string;
        questionAudioLink: string;
      }>
    ) => {
      const { sectionIndex, slideIndex, explanationAudioLink, contentAudioLink, questionAudioLink } = action.payload;
      const slide = state.mobileSections[sectionIndex].slides[slideIndex];

      slide.explanation.audioLink = explanationAudioLink;
      slide.content.audioLink = contentAudioLink;
      slide.question.audioLink = questionAudioLink;
    },
  },
});

export const {
  updateLesson,
  addSection,
  addSectionAbove,
  updateSection,
  moveSection,
  removeSection,
  addSlide,
  addSlideAbove,
  updateSlide,
  moveSlide,
  removeSlide,
  updateAudioLink,
  addMobileSection,
  addMobileSectionAbove,
  updateMobileSection,
  moveMobileSection,
  removeMobileSection,
  addMobileSlide,
  addMobileSlideAbove,
  updateMobileSlide,
  moveMobileSlide,
  removeMobileSlide,
  updateMobileAudioLink,
} = lessonSlice.actions;
export default lessonSlice.reducer;
