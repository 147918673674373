import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import "./CustomCollapse.scss";
import { Button } from "antd";

interface CustomCollapseProps {
  title: string;
  children: any;
  move: (direction: "up" | "down") => void;
  removeItem: () => void;
  addAbove?: () => void;
}

const CustomCollapse = ({ title, children, move, removeItem, addAbove }: CustomCollapseProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="collapse">
      <div className="header" onClick={toggle}>
        <span>{title}</span>
        {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </div>
      <div className="controller">
        {addAbove && <Button title="Add slide above" icon={<PlusOutlined />} onClick={() => addAbove()}></Button>}
        <Button title="Move up" icon={<ArrowUpOutlined />} onClick={() => move("up")}></Button>
        <Button title="Move down" icon={<ArrowDownOutlined />} onClick={() => move("down")}></Button>
        <Button title="Remove" icon={<DeleteOutlined />} onClick={() => removeItem()}></Button>
      </div>
      <div className="content" style={{ display: isOpen ? "block" : "none" }}>
        {children}
      </div>
    </div>
  );
};

export default CustomCollapse;
