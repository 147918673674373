import { Button, Card, Checkbox, Collapse, DatePicker, Descriptions, Form, Modal, Table, Tooltip, message } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs from "dayjs";
import { FC, useRef, useState } from "react";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { ELECTIVE_OPTIONS, PAYMENT_METHOD } from "../../constants/filter";
import { ITrackUserDailyActive } from "../../interfaces/filter";
import { getUserDailyActiveRecords, trackUserCohort, trackUserDailyActive } from "../../services/AccountUserServices";
import { calcSessionTracking } from "../../services/TrackingServices";
import "./index.scss";
import Loading from "../../components/Loading/Loading";

interface ITrackingData {
  totalUsers: number;
  totalAPIs: number;
  totalTime: number;
  averageSessionTime: number;
  totalRouteTime: number;
  averageRouteTime: number;
}

const Tracking: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [trackingData, setTrackingData] = useState<ITrackingData>();
  const [isLoading, setIsLoading] = useState(false);
  const [tableUserDailyActiveData, setTableUserDailyActiveData] = useState<any>({
    totalUniqueUsers: 0,
    userDailyActiveData: [],
  });
  const [tableUserCohortByLoginRecordsData, setTableUserCohortByLoginRecordsData] = useState<any>([]);
  const [tableUserCohortByDailyActiveRecordsData, setTableUserCohortByDailyActiveRecordsData] = useState<any>([]);
  const [tableUserDailyActiveRecordsData, setTableUserDailyActiveRecordsData] = useState<any>([]);
  const tableUserDailyActiveTotal = useRef<number>();
  const [isPremium, setIsPremium] = useState<boolean>(false);
  const tableUserDailyActiveRecordsTotal = useRef<number>();
  const filterUserActiveData = useRef<ITrackUserDailyActive>({});
  const filterUserActiveRecordsData = useRef<ITrackUserDailyActive>({
    page: 1,
    size: 10,
  });
  const filterUserCohortData = useRef<ITrackUserDailyActive>({});
  const filterUserCohortByDailyActiveRecordsData = useRef<ITrackUserDailyActive>({});
  const fetchTrackUserDailyActive = async () => {
    const response = (await trackUserDailyActive(filterUserActiveData.current)) as any;
    if (response?.errorCode === 200) {
      Modal.success({
        title: "Success",
        type: "success",
        content: `Track User Daily Active successfully`,
      });
      if (response) {
        setTableUserDailyActiveData({
          totalUniqueUsers: (response as any).result.totalUniqueUsers,
          userDailyActiveData: (response as any).result.dailyActivities,
        });
        tableUserDailyActiveTotal.current = (response as any).result.dailyActivities.length;
      }
    } else {
      Modal.error({
        title: "Error",
        type: "error",
        content: `${response?.message}`,
      });
    }
  };
  const fetchTrackUserDailyActiveRecords = async () => {
    const response = (await getUserDailyActiveRecords(filterUserActiveRecordsData.current)) as any;
    if (response?.errorCode === 200) {
      Modal.success({
        title: "Success",
        type: "success",
        content: `Track User Daily Active Records successfully`,
      });
      if (response) {
        setTableUserDailyActiveRecordsData((response as any).result.records);
        tableUserDailyActiveRecordsTotal.current = (response as any).result.recordCount;
      }
    } else {
      Modal.error({
        title: "Error",
        type: "error",
        content: `${response?.message}`,
      });
    }
  };
  const fetchTrackUserCohortByLoginRecords = async () => {
    const response = (await trackUserCohort(filterUserCohortData.current)) as any;
    if (response?.errorCode === 200) {
      Modal.success({
        title: "Success",
        type: "success",
        content: `Track User Cohort By Login Records successfully`,
      });
      if (response) {
        setTableUserCohortByLoginRecordsData((response as any).result.userCohorts.reverse());
      }
    } else {
      Modal.error({
        title: "Error",
        type: "error",
        content: `${response?.message}`,
      });
    }
  };
  const fetchTrackUserCohortByDailyActiveRecords = async () => {
    const response = (await trackUserCohort(filterUserCohortByDailyActiveRecordsData.current)) as any;
    if (response?.errorCode === 200) {
      Modal.success({
        title: "Success",
        type: "success",
        content: `Track User Cohort By Daily Active Records successfully`,
      });
      if (response) {
        setTableUserCohortByDailyActiveRecordsData((response as any).result.userCohorts.reverse());
      }
    } else {
      Modal.error({
        title: "Error",
        type: "error",
        content: `${response?.message}`,
      });
    }
  };
  const tableRef = useRef<any>();
  const handleFilter = () => {
    const formValues = trackUserForm.getFieldsValue();
    const filter: ITrackUserDailyActive = {
      isPremium: formValues.isPremium,
    };
    if (formValues.startDate) {
      filter.startDate = dayjs(formValues.startDate).startOf("day").format("DD/MM/YYYY");
    }
    if (formValues.endDate) {
      filter.endDate = dayjs(formValues.endDate).endOf("day").format("DD/MM/YYYY");
    }
    filterUserActiveData.current = filter;
    fetchTrackUserDailyActive();
  };
  const handleFilterUserDailyActiveRecords = () => {
    const formValues = userDailyActiveRecordsForm.getFieldsValue();
    const filter: ITrackUserDailyActive = {
      sort: "dailyActiveRecords.date,asc",
    };
    if (formValues.isPremium !== undefined) {
      filter.isPremium = formValues.isPremium;
    }
    if (formValues.startDate) {
      filter.startDate = dayjs(formValues.startDate).startOf("day").format("DD/MM/YYYY");
    }
    if (formValues.endDate) {
      filter.endDate = dayjs(formValues.endDate).endOf("day").format("DD/MM/YYYY");
    }
    filterUserActiveRecordsData.current = {
      page: filterUserActiveRecordsData.current.page,
      size: filterUserActiveRecordsData.current.size,
      ...filter,
    };
    filterUserActiveRecordsData.current.page = 1;
    fetchTrackUserDailyActiveRecords();
  };

  const handleTrackUserCohortByLoginRecordsFilter = () => {
    const formValues = trackUserCohortForm.getFieldsValue();
    const filter: ITrackUserDailyActive = {
      filterBy: 0,
    };
    if (formValues.isPremium !== undefined) {
      filter.isPremium = formValues.isPremium;
    }
    if (formValues.startDate) {
      filter.startDate = dayjs(formValues.startDate).startOf("day").format("DD/MM/YYYY");
    }
    if (formValues.endDate) {
      filter.endDate = dayjs(formValues.endDate).endOf("day").format("DD/MM/YYYY");
    }
    filterUserCohortData.current = filter;
    fetchTrackUserCohortByLoginRecords();
  };
  const handleTrackUserCohortByDailyActiveRecordsFilter = () => {
    const formValues = trackUserCohortByDailyActiveRecordsForm.getFieldsValue();
    const filter: ITrackUserDailyActive = {
      filterBy: 1,
    };
    if (formValues.isPremium !== undefined) {
      filter.isPremium = formValues.isPremium;
    }
    if (formValues.startDate) {
      filter.startDate = dayjs(formValues.startDate).startOf("day").format("DD/MM/YYYY");
    }
    if (formValues.endDate) {
      filter.endDate = dayjs(formValues.endDate).endOf("day").format("DD/MM/YYYY");
    }
    filterUserCohortByDailyActiveRecordsData.current = filter;
    fetchTrackUserCohortByDailyActiveRecords();
  };

  const handleChangePage = async (page: number) => {
    filterUserActiveRecordsData.current.page = page;
    await fetchTrackUserDailyActiveRecords();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    filterUserActiveRecordsData.current.page = pagination.current;
    filterUserActiveRecordsData.current.size = pagination.pageSize;
    await fetchTrackUserDailyActiveRecords();
  };

  const { Panel } = Collapse;

  const [form] = Form.useForm();
  const [trackUserForm] = Form.useForm();
  const [userDailyActiveRecordsForm] = Form.useForm();
  const [trackUserCohortForm] = Form.useForm();
  const [trackUserCohortByDailyActiveRecordsForm] = Form.useForm();
  const getNestedColumns = () => [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value: any) => dayjs(value).format("DD/MM/YYYY"),
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Is Premium",
      dataIndex: "isPremium",
      key: "isPremium",
      render: (isPremium: boolean) => (isPremium ? "Yes" : "No"),
    },
  ];

  const getColumnTrackUserDailyActiveRecords = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Daily Active Records",
        dataIndex: "dailyActiveRecords",
        key: "dailyActiveRecords",
        align: "center",
        render: (dailyActiveRecords) => (
          <>
            {dailyActiveRecords === null || dailyActiveRecords?.length === 0 ? (
              <p>No data</p>
            ) : (
              <Table
                dataSource={dailyActiveRecords}
                columns={getNestedColumns()}
                pagination={false} // Tắt phân trang cho nested table
                rowKey={(record) => record._id}
              />
            )}
          </>
        ),
      },
    ] as ColumnsType<any>;
  };
  const getColumnTrackUserActive = (): ColumnsType<any> => {
    return [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        align: "center",
      },
      {
        title: "Number Users",
        dataIndex: "numberUsers",
        key: "numberUsers",
        align: "center",
      },
    ] as ColumnsType<any>;
  };
  const getColumnTrackUserCohort = (): ColumnsType<any> => {
    return [
      {
        title: "Date Range",
        dataIndex: "week",
        key: "week",
        align: "center",
      },
      {
        title: "Number Users",
        dataIndex: "numberUsers",
        key: "numberUsers",
        align: "center",
      },
    ] as ColumnsType<any>;
  };
  // useEffect(() => {
  //   fetchTrackUserDailyActive();
  // }, []);

  return (
    <>
      {isLoading && <Loading isOverlay />}
      <div className="tracking-container" style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        <Collapse defaultActiveKey={"1"}>
          <Panel header="Sessions Tracking" key="1">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "100px",
                rowGap: "20px",
              }}
            >
              <Form form={form}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Filter
                </div>
                <Form.Item label="Time range" name="timeRange" rules={[{ required: true, message: "" }]}>
                  <DatePicker.RangePicker allowClear format={["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]} />
                </Form.Item>
                <FormInput label="Route" name="route" />
                <div>
                  <FormInput label="Min route duration(s)" name="minRouteDuration" type="number" />
                  <FormInput
                    label="Min session duration(s)"
                    name="minSessionDuration"
                    type="number"
                    rules={[
                      {
                        validator: (_, value) => {
                          const minRouteDuration = Number(form.getFieldValue("minRouteDuration"));

                          if (value && minRouteDuration && value < minRouteDuration) {
                            return Promise.reject("Min session must be greater than min route");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  />
                </div>
                <Form.Item label="Premium" name="isPremium" valuePropName="checked">
                  <Checkbox.Group onChange={(v) => setIsPremium(v.length === 1 && v[0] === "premium")}>
                    <Checkbox value="premium">Premium</Checkbox>
                    <Checkbox value="free">Free</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                {isPremium && (
                  <FormSelect allowClear label="Payment Method" name="paymentMethod" optionData={PAYMENT_METHOD} />
                )}
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={async () => {
                      if (!(await form.validateFields())) {
                        return;
                      }
                      const formValues = form.getFieldsValue();
                      if (formValues.timeRange === undefined) {
                        return;
                      }

                      let filter = {
                        startTime: dayjs(formValues.timeRange[0]).startOf("day").utc().format(),
                        endTime: dayjs(formValues.timeRange[1]).endOf("day").utc().format(),
                        route: formValues.route ? formValues.route : undefined,
                        minRouteDuration: formValues.minRouteDuration ? Number(formValues.minRouteDuration) : undefined,
                        minSessionDuration: formValues.minSessionDuration ? Number(formValues.minSessionDuration) : undefined,
                      };
                      if (formValues.isPremium?.length === 1) {
                        filter = {
                          ...filter,
                          isPremium: formValues.isPremium[0] === "premium",
                        } as typeof filter & {
                          isPremium: boolean;
                        };
                        if (isPremium) {
                          filter = { ...filter, paymentMethod: formValues.paymentMethod } as typeof filter & {
                            paymentMethod: number;
                          };
                        }
                      }
                      setIsLoading(true);
                      const res = (await calcSessionTracking(filter)) as any;
                      setIsLoading(false);
                      if (res?.errorCode === 200) {
                        Modal.success({
                          title: "Success",
                          type: "success",
                          content: `Calculate successfully`,
                        });
                        setTrackingData(res?.result);
                      } else {
                        Modal.error({
                          title: "Error",
                          type: "error",
                          content: `${res?.message}`,
                        });
                      }
                    }}
                  >
                    Calculate
                  </Button>
                </div>
              </Form>
              <div style={{ width: "500px" }}>
                <Descriptions title="Sessions Information" bordered size="small" style={{ textAlign: "center" }}>
                  <Descriptions.Item label="Total users" span={3}>
                    {trackingData?.totalUsers === undefined ? "N/A" : trackingData?.totalUsers}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total APIs" span={3}>
                    {trackingData?.totalAPIs === undefined ? "N/A" : trackingData?.totalAPIs}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total time (minutes)" span={3}>
                    {trackingData?.totalTime === undefined ? "N/A" : trackingData?.totalTime.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Average session time (minutes)" span={3}>
                    {trackingData?.averageSessionTime === undefined
                      ? "N/A"
                      : trackingData?.averageSessionTime.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total route time (minutes)" span={3}>
                    {trackingData?.totalRouteTime === undefined ? "N/A" : trackingData?.totalRouteTime.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Average route time (minutes)" span={3}>
                    {trackingData?.averageRouteTime === undefined ? "N/A" : trackingData?.averageRouteTime.toFixed(2)}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </Panel>
          <Panel header="User Daily Active Records" key="2" style={{ display: "none" }}>
            <Card title="User Daily Active Records">
              {contextHolder}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  columnGap: "100px",
                  rowGap: "20px",
                }}
              >
                <Form form={userDailyActiveRecordsForm} onFinish={handleFilterUserDailyActiveRecords}>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: 1.5,
                      marginBottom: "20px",
                      textAlign: "center",
                    }}
                  >
                    Filter
                  </div>
                  <Form.Item label="Start date" name="startDate">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Form.Item label="End Date" name="endDate">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <Button type="primary" htmlType="submit">
                      Track User Daily Active Records
                    </Button>
                  </div>
                </Form>
                <div style={{ width: "600px" }}>
                  <Table
                    dataSource={tableUserDailyActiveRecordsData}
                    columns={getColumnTrackUserDailyActiveRecords()}
                    pagination={{
                      total: tableUserDailyActiveRecordsTotal.current,
                      showSizeChanger: false,
                      onChange: handleChangePage,
                    }}
                    onChange={handleTableChange}
                    ref={tableRef}
                    rowKey={(record) => record._id}
                  />
                </div>
              </div>
            </Card>
          </Panel>
          <Panel header="User Daily Active Tracking" key="3">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "50px",
                rowGap: "20px",
              }}
            >
              <Form form={trackUserForm} onFinish={handleFilter}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Filter
                </div>
                <Form.Item label="Start date" name="startDate">
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    {
                      validator: (_, value) => {
                        const startDateValue = trackUserForm.getFieldValue("startDate");
                        if (
                          value &&
                          (dayjs(value).isBefore(startDateValue, "day") || dayjs(value).isSame(startDateValue, "day"))
                        ) {
                          return Promise.reject("End date must be greater than start date");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>

                <FormSelect label="Premium" name="isPremium" optionData={ELECTIVE_OPTIONS} allowClear />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    Track User Daily Active
                  </Button>
                </div>
              </Form>
              <div style={{ width: "500px" }}>
                <Table
                  dataSource={tableUserDailyActiveData?.userDailyActiveData}
                  columns={getColumnTrackUserActive()}
                  pagination={{
                    pageSize: 5,
                    total: tableUserDailyActiveTotal.current,
                    showSizeChanger: false,
                    // onChange: handleChangePage,
                  }}
                  // onChange={handleTableChange}
                  ref={tableRef}
                  rowKey={(record) => record.date}
                />
              </div>
              <div>
                <p style={{ fontWeight: "bold" }}>Total Unique Users: {tableUserDailyActiveData?.totalUniqueUsers}</p>
              </div>
            </div>
          </Panel>
          <Panel header="User Cohort Tracking By Login Records" key="4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "100px",
                rowGap: "20px",
              }}
            >
              <Form form={trackUserCohortForm} onFinish={handleTrackUserCohortByLoginRecordsFilter}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Filter
                </div>
                <Form.Item label="Start date" name="startDate" rules={[{ required: true, message: "" }]}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: (_, value) => {
                        const startDateValue = trackUserCohortForm.getFieldValue("startDate");
                        if (
                          value &&
                          (dayjs(value).isBefore(startDateValue, "day") || dayjs(value).isSame(startDateValue, "day"))
                        ) {
                          return Promise.reject("End date must be greater than start date");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>

                <FormSelect label="Premium" name="isPremium" optionData={ELECTIVE_OPTIONS} allowClear />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    Track User Cohort
                  </Button>
                </div>
              </Form>
              <div style={{ width: "500px" }}>
                <Table
                  dataSource={tableUserCohortByLoginRecordsData}
                  columns={getColumnTrackUserCohort()}
                  pagination={false}
                  ref={tableRef}
                  rowKey={(record) => record.week}
                />
              </div>
            </div>
          </Panel>
          <Panel header="User Cohort Tracking By Daily Active Records" key="5">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "100px",
                rowGap: "20px",
              }}
            >
              <Form
                form={trackUserCohortByDailyActiveRecordsForm}
                onFinish={handleTrackUserCohortByDailyActiveRecordsFilter}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Filter
                </div>
                <Form.Item label="Start date" name="startDate" rules={[{ required: true, message: "" }]}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: (_, value) => {
                        const startDateValue = trackUserCohortByDailyActiveRecordsForm.getFieldValue("startDate");
                        if (
                          value &&
                          (dayjs(value).isBefore(startDateValue, "day") || dayjs(value).isSame(startDateValue, "day"))
                        ) {
                          return Promise.reject("End date must be greater than start date");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>

                <FormSelect label="Premium" name="isPremium" optionData={ELECTIVE_OPTIONS} allowClear />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit">
                    Track User Cohort
                  </Button>
                </div>
              </Form>
              <div style={{ width: "500px" }}>
                <Table
                  dataSource={tableUserCohortByDailyActiveRecordsData}
                  columns={getColumnTrackUserCohort()}
                  pagination={false}
                  ref={tableRef}
                  rowKey={(record) => record.week}
                />
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default Tracking;
