import "./UpdatePreRegister.scss";
import { Form, Button, Row, Col, Modal, Card } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../../components/Form/Input";
import Loading from "../../../components/Loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import { DEFAULT_TIMEOUT } from "../../../constants/timeout";
import { PageUrl } from "../../../constants/url";
import { UserRole } from "../../../constants/auth";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormSelect } from "../../../components/Form/Select";
import { getListSiteAdmins } from "../../../services/AccountAdminServices";
import {
  getGoalCategories,
  getPackageCategories,
  getSaleCategories,
  getTagCategories,
} from "../../../services/CategoryService";
import { createPreRegister, editPreRegister, getPreRegisterById } from "../../../services/PreRegisterServices";
import CustomTag from "../../../components/CustomTag/CustomTag";

dayjs.extend(utc);

const UpdatePreRegister = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const [preRegisterId, setPreRegisterId] = useState<string | null>(null);
  const [siteAdminList, setSiteAdminList] = useState([]);
  const [saleCategories, setSaleCategories] = useState([]);
  const [goalCategories, setGoalCategories] = useState([]);
  const [packageCategories, setPackageCategories] = useState([]);
  const [tagCategories, setTagCategories] = useState([]);

  const [checkedTags, setCheckedTags] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSavePic = async () => {
    try {
      form.validateFields().then(async () => {
        if (!preRegisterId) {
          const preRegisterInfo = form.getFieldsValue();
          preRegisterInfo.age = preRegisterInfo.age ? Number(preRegisterInfo.age) : null;
          const response = await createPreRegister(preRegisterInfo);
          if (response) {
            setPreRegisterId(response.result);
            Modal.success({
              title: "Success",
              type: "success",
              content: `Created pre-register successfully`,
            });
          }
        } else {
          const preRegisterInfo = form.getFieldsValue();
          preRegisterInfo.age = preRegisterInfo.age ? Number(preRegisterInfo.age) : null;
          preRegisterInfo.verifiedPhone = preRegisterInfo.verifiedPhone || null;
          preRegisterInfo.note = preRegisterInfo.note || null;
          preRegisterInfo.revenue = preRegisterInfo.revenue ? Number(preRegisterInfo.revenue) : null;
          const response = await editPreRegister(preRegisterId, preRegisterInfo);
          if (response) {
            Modal.success({
              title: "Success",
              type: "success",
              content: `Updated PIC successfully`,
            });
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const getAllInformation = async () => {
      const [siteAdminRes, saleCategoryRes, goalCategoryRes, packageCategoryRes, tagCategoryRes] = await Promise.all([
        getListSiteAdmins(),
        getSaleCategories(),
        getGoalCategories(),
        getPackageCategories(),
        getTagCategories(),
      ]);

      siteAdminRes &&
        setSiteAdminList((siteAdminRes as any).result?.map((item: any) => ({ label: item.userName, value: item._id })));
      saleCategoryRes &&
        setSaleCategories((saleCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item._id })));
      goalCategoryRes &&
        setGoalCategories(
          (goalCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item.type }))
        );
      packageCategoryRes &&
        setPackageCategories(
          (packageCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item.type }))
        );
      tagCategoryRes &&
        setTagCategories((tagCategoryRes as any).result?.map((item: any) => ({ label: item.name, value: item._id })));

      setIsLoading(true);
      if (preRegisterId) {
        const res = (await getPreRegisterById(preRegisterId)) as any;
        if (res?.errorCode !== 200) return;
        setTimeout(() => {
          if (res.result.saleInfo) {
            form.setFields([
              { name: "name", value: res.result.name },
              { name: "phone", value: res.result.phone },
              { name: "email", value: res.result.email },
              { name: "age", value: res.result.age },
              { name: "goals", value: res.result.goals || [] },
              { name: "package", value: res.result.package },
              {
                name: "saleCategoryId",
                value:
                  res.result.saleInfo?.category?.id === "000000000000000000000000"
                    ? null
                    : res.result.saleInfo?.category?.id,
              },
              { name: "verifiedPhone", value: res.result?.verifiedUser?.name },
              { name: "note", value: res.result.saleInfo?.note },
              { name: "revenue", value: res.result.saleInfo?.revenue },
            ]);

            if (res.result.saleInfo?.pics) {
              form.setFields([{ name: "picIds", value: res.result.saleInfo?.pics.map((item: any) => item.id) }]);
            }
          }

          if (res.result.tags) {
            setCheckedTags(res.result.tags);
          }
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.SiteAdmin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preRegisterId]);

  useEffect(() => {
    if (params.id) {
      setPreRegisterId(params.id);
    }
  }, [navigate, params.id, user?.roles]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="update-pre-register-container">
      <Form form={form} className="pic-form">
        <Card title="Pre-register information">
          <Row>
            <Col xs={8} md={8} xl={8}>
              <FormInput label="Name" name="name" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={8}>
              <FormInput type="number" label="Phone" name="phone" rules={[{ required: true }]} />
            </Col>
            <Col xs={8} md={8} xl={8}>
              <FormInput type="number" label="Age" name="age" />
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8} xl={8}>
              <FormInput type="email" label="Email" name="email" />
            </Col>
            <Col xs={8} md={8} xl={8}>
              <FormSelect mode="multiple" label="Goals" name="goals" optionData={goalCategories} />
            </Col>
            <Col xs={8} md={8} xl={8}>
              <FormSelect label="Package" name="package" optionData={packageCategories} />
            </Col>
          </Row>

          {preRegisterId && (
            <>
              <Row>
                <Col xs={8} md={8} xl={8}>
                  <FormSelect
                    mode="multiple"
                    label="PICs"
                    name="picIds"
                    optionData={siteAdminList}
                    rules={[{ required: true, message: "Pic required" }]}
                  />
                </Col>
                <Col xs={8} md={8} xl={8}>
                  <FormSelect
                    label="Sale category"
                    name="saleCategoryId"
                    optionData={saleCategories}
                    rules={[{ required: true, message: "Sale category required" }]}
                  />
                </Col>
                <Col xs={8} md={8} xl={8}>
                  <FormInput
                    label="Verify phone"
                    name="verifiedPhone"
                    rules={[{ pattern: /^0\d{9}$/, message: "Invalid phone" }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={8} md={8} xl={8}>
                  <FormInput type="textarea" rows={4} label="Note" name="note" />
                </Col>
                <Col xs={8} md={8} xl={8}>
                  <FormInput type="number" label="Revenue" name="revenue" />
                </Col>
                <Col xs={8} md={8} xl={8}>
                  <Form.Item label="Tags" name="tags" className="tags-form-item">
                    <CustomTag tagsData={tagCategories} checkedTags={checkedTags} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Card>

        <div className="form-controller">
          <Button type="primary" style={{ backgroundColor: "#2abb2a" }} onClick={handleSavePic}>
            {preRegisterId ? "Save" : "Create"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdatePreRegister;
