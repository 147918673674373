import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface EloData {
  elo: number;
  date: string;
  delta: number;
}

interface EloChartProps {
  data: EloData[];
  filterType: string;
}

const EloChart: React.FC<EloChartProps> = ({ data, filterType }) => {
  const chartData = {
    labels: data.map((item) => dayjs(item.date).format("DD/MM/YYYY")),
    datasets: [
      {
        label: "Elo",
        data: data.map((item) => item.elo),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: false,
      },
    ],
  };

  const filterText =
    filterType === "weekly"
      ? "7 ngày gần nhất (từng ngày)"
      : filterType === "monthly"
      ? "30 ngày gần nhất (từng ngày)"
      : "3 tháng gần nhất (từng tuần)";

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `Điểm elo ${filterText}`,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default EloChart;
