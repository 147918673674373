import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";
import {
  getQuestions,
  getComplexities,
  getFields,
  getGrades,
  getUnitsByGradeId,
  getLessonsByUnitId,
  publishQuestions,
  unPublishQuestions,
  rejectQuestions,
  submitQuestions,
  deleteQuestion,
  deleteManyQuestions,
  getTopics,
} from "../../services/QuestionServices";
import Cookies from "universal-cookie";
import {
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  DeleteOutlined,
  ClearOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ELECTIVE_OPTIONS, STATUS_OPTIONS } from "../../constants/filter";
import { IGradeFilter, ISelectOption, ICategoryRes, IQuestionFilter } from "../../interfaces/filter";
import { decodeToken } from "react-jwt";
import { UserRole } from "../../constants/auth";
import { previewWindow } from "../../utils/preview";
import { FlowCategory, WORK_FLOW, WORK_FLOW_NAME, isDisabledFlow } from "../../constants/flow";
import { FormSelect } from "../../components/Form/Select";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { PageUrl, PreviewUrl } from "../../constants/url";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { customSessionStorage } from "../../utils/storage";

const QuestionPortal = () => {
  const genDefaultFilter = (index = 0): IGradeFilter => {
    return { filterIndex: index, gradeList: [], unitList: [], lessonList: [], gradeId: "", unitId: "", lessonId: "" };
  };

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [fieldList, setFieldList] = useState<ISelectOption[]>([]);
  const [complexitiesList, setComplexitiesList] = useState<ISelectOption[]>([]);
  const [gradeList, setGradeList] = useState<any[]>([]);
  const [topicList, setTopicList] = useState<any[]>([]);
  const [questionList, setQuestionList] = useState([]);
  const [filters, setFilters] = useState<IGradeFilter[]>([genDefaultFilter()]);
  const [roles, setRoles] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredStatus, setFilteredStatus] = useState<FlowCategory[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const filterData = useRef<IQuestionFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();
  const token = useRef<string>();
  const tableRef = useRef<any>();

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
  };

  const handleFilter = () => {
    const gradeFilters = filters.map((filter) => {
      if (filter.lessonId) {
        return { lessonId: filter.lessonId };
      } else if (filter.unitId) {
        return { unitId: filter.unitId };
      } else if (filter.gradeId) {
        return { gradeId: filter.gradeId };
      }
      return {};
    });
    const formData = form.getFieldsValue();
    customSessionStorage("filterQuestion", formData);
    customSessionStorage("gradeFilter", { grades: filters });
    filterData.current = {
      ...filterData.current,
      ...{
        isElective: form.getFieldValue("isElective"),
        status: form.getFieldValue("status"),
        fields: form.getFieldValue("field"),
        complexities: form.getFieldValue("complexities"),
        prompt: form.getFieldValue("prompt"),
        question: form.getFieldValue("question"),
        templatePrompt: form.getFieldValue("templatePrompt"),
        templateQuestion: form.getFieldValue("templateQuestion"),
        tags: form.getFieldValue("tags"),
        topics: form.getFieldValue("topics"),
        filters: gradeFilters,
      },
    };
    filterData.current.page = 1;
    getListQuestions();
  };

  const getListQuestions = async () => {
    const questionRes = await getQuestions(filterData.current);
    if (questionRes) {
      const status = filterData.current.status;
      setFilteredStatus(status);
      setSelectedRowKeys([]);
      setQuestionList((questionRes as any).result.records);
      tableTotal.current = (questionRes as any).result.recordCount;
    }
  };

  const generateInput = (label: string, name: string) => {
    return (
      <Form.Item label={label} name={name}>
        <Input placeholder="Enter a value" autoComplete="off" />
      </Form.Item>
    );
  };

  const onSelectGrade = async (index: number, gradeId: string) => {
    const unitRes = await getUnitsByGradeId(gradeId);
    if (unitRes) {
      filters[index].gradeId = gradeId;
      filters[index].unitList = (unitRes as any).result?.records;
      filters[index].unitId = "";
      form.setFieldValue(["filters", "unit", index], undefined);
      filters[index].lessonList = [];
      filters[index].lessonId = "";
      form.setFieldValue(["filters", "lesson", index], undefined);
      setFilters([...filters]);
    }
  };

  const onSelectUnit = async (index: number, unitId: string) => {
    const lessonRes = await getLessonsByUnitId(filters[index].gradeId, unitId);
    if (lessonRes) {
      filters[index].unitId = unitId;
      filters[index].lessonList = (lessonRes as any).result?.records;
      filters[index].lessonId = "";
      form.setFieldValue(["filters", "lesson", index], undefined);
      setFilters([...filters]);
    }
  };

  const onSelectLesson = (index: number, lessonId: string) => {
    filters[index].lessonId = lessonId;
    setFilters([...filters]);
  };

  const convertResponseToOption = (data: ICategoryRes[]) => {
    if (!data || !data.length) return [];
    const convertData = data.map((item) => ({
      label: item.name || item["lessonName"] || item["unitName"],
      value: item._id,
    }));

    return convertData;
  };

  const previewInLessons = async (lessonId: string) => {
    const filter: IQuestionFilter = {
      page: 1,
      size: 1000,
      filters: [{ lessonId }],
      isElective: form.getFieldValue("isElective"),
      status: form.getFieldValue("status"),
      fields: form.getFieldValue("field"),
      complexities: form.getFieldValue("complexities"),
      prompt: form.getFieldValue("prompt"),
      question: form.getFieldValue("question"),
      templatePrompt: form.getFieldValue("templatePrompt"),
      templateQuestion: form.getFieldValue("templateQuestion"),
      tags: form.getFieldValue("tags"),
    };
    const res = await getQuestions(filter);
    if (!res || !(res as any).result?.records.length) {
      messageApi.open({
        type: "error",
        content: "Không có bài tập hoặc lỗi!",
        duration: 2,
      });
      return;
    }
    previewWindow({
      url: PreviewUrl.Exercise,
      data: { token: token.current, questionIds: (res as any).result?.records?.map((item: any) => item._id) },
      directly: true,
    });
  };

  const addFilterRow = () => {
    setFilters([...filters, genDefaultFilter(filters.length)]);
  };

  const removeFilterRow = (index: number) => {
    filters.splice(index, 1);
    setFilters([...filters]);
  };

  const FilterRow = ({ filter }: { filter: IGradeFilter }) => {
    const { filterIndex, unitList, lessonList } = filter;

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Row>
          <Col xs={7} md={7} xl={7}>
            <FormSelect
              label="Grade"
              name={["filters", "grade", filterIndex]}
              optionData={convertToSelectOption(gradeList, "_id", "name")}
              callback={(e: string) => onSelectGrade(filterIndex, e)}
              allowClear
              onClear={() => {
                onSelectGrade(filterIndex, "");
                onSelectUnit(filterIndex, "");
                onSelectLesson(filterIndex, "");
              }}
            />
          </Col>
          <Col xs={7} md={7} xl={7}>
            <FormSelect
              label="Unit"
              name={["filters", "unit", filterIndex]}
              optionData={convertToSelectOption(unitList, "_id", "unitName")}
              callback={(e: string) => onSelectUnit(filterIndex, e)}
              allowClear
              onClear={() => {
                onSelectUnit(filterIndex, "");
                onSelectLesson(filterIndex, "");
              }}
            />
          </Col>
          <Col xs={7} md={7} xl={7}>
            <FormSelect
              label="Lesson"
              name={["filters", "lesson", filterIndex]}
              optionData={convertToSelectOption(lessonList, "_id", "lessonName")}
              callback={(e: string) => onSelectLesson(filterIndex, e)}
              allowClear
              onClear={() => onSelectLesson(filterIndex, "")}
            />
          </Col>
          <Col>
            <Popconfirm
              title="Are you sure to delete this item?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => removeFilterRow(filterIndex)}
            >
              <Button disabled={filterIndex === 0} style={{ margin: "0 8px" }} danger icon={<DeleteOutlined />} />
            </Popconfirm>
            <Tooltip title="Preview exercise">
              <Button
                icon={<EyeOutlined />}
                disabled={!filters[filterIndex]?.lessonId}
                onClick={() => previewInLessons(filters[filterIndex]?.lessonId)}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Prompt",
        dataIndex: ["template", "prompt"],
        key: "prompt",
        align: "center",
        width: 150,
      },
      {
        title: "Question",
        dataIndex: ["template", "question"],
        key: "question",
        align: "center",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 125,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "isElective",
        key: "isElective",
        align: "center",
        sorter: { multiple: 5, field: "isElective" },
        width: 150,
        render: (e: any) => `${e.isElective ? "True" : "False"}`,
      },
      {
        title: "Difficulty",
        key: "difficulty",
        align: "center",
        width: 125,
        render: (e: any) => {
          const title = e.difficulty.map((diff: any) => `${diff.grade.name}-${diff.complexity.name}, `);
          return (
            <Tooltip title={title}>
              <span>Difficulty</span>
            </Tooltip>
          );
        },
      },
      {
        title: "Elo",
        key: "elo",
        dataIndex: "elo",
        align: "center",
        width: 75,
        sorter: { multiple: 6, field: "elo" },
        render: (e: any) => (
          <Tooltip title={e}>
            <span>{e?.toString()?.split(".")[0]}</span>
          </Tooltip>
        ),
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        defaultSortOrder: "descend",
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: "auto",
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(roles?.includes(UserRole.ContentCreator) || roles?.includes(UserRole.Reviewer)) && (
              <Space size="middle">
                <Tooltip title="Preview">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() =>
                      previewWindow({
                        url: PreviewUrl.Question,
                        data: { token: token.current, questionId: record._id },
                        directly: true,
                      }).then(() => navigate(PageUrl.Questions))
                    }
                  />
                </Tooltip>
              </Space>
            )}
            {roles?.includes(UserRole.Reviewer) && (
              <>
                <Space size="middle">
                  <ConfirmButton
                    title="Publish"
                    tooltip={true}
                    icon={<UploadOutlined />}
                    onConfirm={() => handleAction(FlowCategory.Published, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.Published)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Unpublish"
                    tooltip={true}
                    icon={<DownloadOutlined />}
                    onConfirm={() => handleAction(FlowCategory.UnPublished, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.UnPublished)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Reject"
                    tooltip={true}
                    icon={<CloseOutlined />}
                    onConfirm={() => handleAction(FlowCategory.RequestEdit, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.RequestEdit)}
                  />
                </Space>
              </>
            )}
            {roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleAction(FlowCategory.Draft, [record._id])}
                      disabled={isDisabledFlow(record.status, FlowCategory.Draft)}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Edit mobile version">
                    <Button
                      icon={<SettingOutlined />}
                      onClick={() =>
                        navigate(`${PageUrl.Questions}${PageUrl.UpdateQuestionMobileVersion}`.replace(":id", record._id))
                      }
                      disabled={isDisabledFlow(record.status, FlowCategory.Draft)}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Submit"
                    tooltip={true}
                    icon={<CheckOutlined />}
                    onConfirm={() => handleAction(FlowCategory.Reviewing, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.Reviewing)}
                  />
                </Space>
              </>
            )}
            {roles?.includes(UserRole.Admin) && (
              <Space size="middle">
                <ConfirmButton
                  title="Delete"
                  tooltip={true}
                  icon={<DeleteOutlined />}
                  onConfirm={async () => {
                    await deleteQuestion(record._id);
                    Modal.success({
                      title: "Success",
                      type: "success",
                      content: "Deleted successfully",
                    });
                    getListQuestions();
                  }}
                />
              </Space>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const handleAction = async (action: number, ids: string[]) => {
    try {
      if (action === FlowCategory.Published) {
        await publishQuestions(ids);
      } else if (action === FlowCategory.UnPublished) {
        await unPublishQuestions(ids);
      } else if (action === FlowCategory.RequestEdit) {
        await rejectQuestions(ids);
      } else if (action === FlowCategory.Draft) {
        navigate(`${PageUrl.Questions}${PageUrl.Update}`.replace(":id", ids[0]));
      } else if (action === FlowCategory.Reviewing) {
        await submitQuestions(ids);
      } else if (action === FlowCategory.Delete) {
        await deleteManyQuestions(ids);
      }
      action !== FlowCategory.Draft &&
        Modal.success({
          title: "Success",
          type: "success",
          content: "Updated successfully",
        });
      await getListQuestions();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListQuestions();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    setSelectedRowKeys([]);
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter.map((item: any) => ({
      field: item.column?.sorter?.field,
      order: item.order === "descend" ? "desc" : "asc",
    }));

    await getListQuestions();
  };

  const getTableActions = () => {
    if (filteredStatus?.length !== 1)
      return (
        <ConfirmButton
          title={`Delete selected`}
          buttonType="default"
          // icon={<DeleteOutlined />}
          onConfirm={() => handleAction(FlowCategory.Delete, selectedRowKeys as string[])}
          disabled={!selectedRowKeys.length}
          danger
          style={{ marginBottom: "16px" }}
        />
      );
    const actions =
      filteredStatus[0] === 0 || filteredStatus[0] === 3 ? [FlowCategory.Reviewing] : WORK_FLOW[filteredStatus[0]];
    const render = actions.map((item, index) => {
      const title = WORK_FLOW_NAME[filteredStatus[0]][index];
      return (
        <span key={index} style={{ display: "flex", gap: "16px" }}>
          <ConfirmButton
            title={`${title} selected`}
            buttonType="default"
            onConfirm={() => handleAction(item, selectedRowKeys as string[])}
            disabled={!selectedRowKeys.length}
          />
          <ConfirmButton
            title={`${title} all filtered`}
            buttonType="default"
            onConfirm={async () => {
              const res = (await getQuestions({ ...filterData.current, ...{ page: 1, size: 10000 } })) as any;
              const listIds = res.result.records.map((item: any) => item._id);
              if (listIds?.length) {
                await handleAction(item, listIds);
              }
            }}
          />
        </span>
      );
    });
    return (
      <div className="table-control" style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
        {render}
      </div>
    );
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      // get fields, complexities, grades
      const [fieldRes, complexityRes, gradeRes, topicRes] = await Promise.all([
        getFields(),
        getComplexities(),
        getGrades(),
        getTopics(),
      ]);
      gradeRes && setGradeList((gradeRes as any).result);
      fieldRes && setFieldList(convertResponseToOption((fieldRes as any).result));
      complexityRes && setComplexitiesList(convertResponseToOption((complexityRes as any).result));
      topicRes && setTopicList(convertResponseToOption((topicRes as any).result?.records));

      filterData.current.sort = [{ field: "recordInfo.createdAt", order: "desc" }];
      if (sessionStorage.getItem("filterQuestion")) {
        let gradeFilters;
        if (sessionStorage.getItem("gradeFilter")) {
          const gradeFilterStorage = JSON.parse(sessionStorage.getItem("gradeFilter")!);
          if (gradeFilterStorage?.expiresAt < Date.now()) {
            sessionStorage.removeItem("gradeFilter");
          } else {
            gradeFilterStorage.grades && setFilters(gradeFilterStorage.grades);
            gradeFilters = gradeFilterStorage.grades?.map((filter: any) => {
              if (filter.lessonId) {
                return { lessonId: filter.lessonId };
              } else if (filter.unitId) {
                return { unitId: filter.unitId };
              } else if (filter.gradeId) {
                return { gradeId: filter.gradeId };
              }
              return {};
            });
            filterData.current = { ...filterData.current, filters: gradeFilters };
          }
        }
        const filterStorage = JSON.parse(sessionStorage.getItem("filterQuestion")!);
        if (filterStorage?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterQuestion");
        } else {
          delete filterStorage.expiresAt;
          form.setFieldsValue(filterStorage);
          delete filterStorage.filters;
          filterData.current = { ...filterData.current, ...filterStorage };
        }
        await getListQuestions();
      } else {
        await getListQuestions();
      }

      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };
    const getAdminRole = () => {
      const cookies = new Cookies();
      token.current = cookies.get("signinAdmin");
      if (token.current) {
        const decode = decodeToken(token.current);
        const listRoles = (decode as any).roles;
        decode && setRoles(listRoles);
        if (listRoles?.length === 1 && listRoles[0] === UserRole.SiteAdmin) {
          navigate(PageUrl.AccountUsers);
        } else {
          getAllInformation();
        }
      }
    };
    getAdminRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (roles && (!roles.length || !(roles.includes(UserRole.ContentCreator) || roles.includes(UserRole.Reviewer)))) {
      navigate(PageUrl.Signin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="portal question">
        <div className="filter">
          <Form form={form} onFinish={handleFilter}>
            <Row>
              <Col xs={7} md={7} xl={7}>
                {generateInput("Prompt", "prompt")}
              </Col>
              <Col xs={7} md={7} xl={7}>
                {generateInput("Question", "question")}
              </Col>
            </Row>
            <Row>
              <Col xs={7} md={7} xl={7}>
                {generateInput("Temp. Prompt", "templatePrompt")}
              </Col>
              <Col xs={7} md={7} xl={7}>
                {generateInput("Temp. Question", "templateQuestion")}
              </Col>
            </Row>
            <Row>
              <Col xs={7} md={7} xl={7}>
                <FormSelect mode="multiple" label="Field" name="field" optionData={fieldList} allowClear />
              </Col>
              <Col xs={7} md={7} xl={7}>
                <FormSelect
                  mode="multiple"
                  label="Complexity"
                  name="complexities"
                  optionData={complexitiesList}
                  allowClear
                />
              </Col>
              <Col xs={7} md={7} xl={7}>
                <FormSelect mode="multiple" label="Status" name="status" optionData={STATUS_OPTIONS} allowClear />
              </Col>
            </Row>
            <Row>
              <Col xs={7} md={7} xl={7}>
                <FormSelect label="Elective" name="isElective" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col xs={7} md={7} xl={7}>
                {generateInput("Tag", "tags")}
              </Col>
              <Col xs={7} md={7} xl={7}>
                <FormSelect mode="multiple" label="Topics" name="topics" optionData={topicList} allowClear />
              </Col>
            </Row>
            {filters?.map((filter, index) => (
              <FilterRow key={`filter-${index}`} filter={filter} />
            ))}
            <Form.Item label={<>&nbsp;</>} colon={false} className="filter-controller">
              <Button type="default" icon={<PlusOutlined />} onClick={addFilterRow}>
                Add filter
              </Button>
              <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
                Filter
              </Button>
              <Button
                icon={<ClearOutlined />}
                onClick={() => {
                  form.resetFields();
                  setFilters(() => [genDefaultFilter()]);
                  sessionStorage.removeItem("filterQuestion");
                  sessionStorage.removeItem("gradeFilter");
                  filterData.current = { page: 1, size: 10, sort: [{ field: "recordInfo.createdAt", order: "desc" }] };
                  getListQuestions();
                }}
                danger
              >
                Reset filter
              </Button>
            </Form.Item>
          </Form>
          {/* <div style={{ fontSize: "12px", margin: "8px 0 0 125px", color: "mediumblue" }}>
            * Lưu ý: Button xem trước nhiều câu hỏi trong bài giảng không ảnh hưởng gì đến bảng đã filter hiện tại.
          </div> */}
        </div>
        <div className="table">
          {roles?.includes(UserRole.ContentCreator) && (
            <div style={{ margin: "0 0 16px 0" }}>
              <Button
                type="default"
                icon={<PlusOutlined />}
                onClick={() => navigate(`${PageUrl.Questions}${PageUrl.Create}`)}
              >
                Add question
              </Button>
            </div>
          )}
          {getTableActions()}
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={questionList}
            columns={getColumn()}
            rowSelection={rowSelection}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          ></Table>
        </div>
      </div>
    </>
  );
};

export default QuestionPortal;
