import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { STATUS_OPTIONS } from "../../constants/filter";
import { IStoryFilter } from "../../interfaces/filter";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import {
  getGrades,
  getStories,
  publishStories,
  rejectStories,
  submitStories,
  unPublishStories,
} from "../../services/StoryServices";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { previewWindow } from "../../utils/preview";
import { useAuth } from "../../hooks/useAuth";
import { FlowCategory, WORK_FLOW, WORK_FLOW_NAME, isDisabledFlow } from "../../constants/flow";
import { PageUrl, PreviewUrl } from "../../constants/url";
import { ConfirmButton } from "../../components/ConfirmButton/ConfirmButton";
import { customSessionStorage } from "../../utils/storage";

const StoryPortal = () => {
  const navigate = useNavigate();
  const { user, token } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const [gradeList, setGradeList] = useState([]);
  const [storyList, setStoryList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState<boolean>();
  const [filteredStatus, setFilteredStatus] = useState<FlowCategory[]>();

  const tableRef = useRef<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const filterData = useRef<IStoryFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
    getCheckboxProps: () => {
      return { disabled: checkboxDisabled };
    },
  };

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterStory", formValues);
    filterData.current = { ...filterData.current, ...formValues };
    filterData.current.page = 1;
    getListStory();
  };

  const getListStory = async () => {
    const response = await getStories(filterData.current);
    if (response) {
      const status = filterData.current.status;
      setCheckboxDisabled(status?.length !== 1);
      setFilteredStatus(status);
      setSelectedRowKeys([]);
      setStoryList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListStory();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    setSelectedRowKeys([]);
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListStory();
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Grade",
        dataIndex: "gradeName",
        key: "grade",
        align: "center",
        width: 100,
      },
      {
        title: "Story",
        dataIndex: "storyName",
        key: "story",
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        sorter: { multiple: 4, field: "status" },
        render: (status: number) => {
          const index = STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
        width: 125,
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        width: 150,
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        width: 150,
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        fixed: "right",
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && (
              <Space size="middle">
                <Tooltip title="Preview">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() =>
                      previewWindow({
                        url: PreviewUrl.Story,
                        data: { token: token, storyId: record._id },
                        directly: true,
                      }).then(() => navigate(PageUrl.Stories))
                    }
                  />
                </Tooltip>
              </Space>
            )}
            {user?.roles?.includes(UserRole.Reviewer) && (
              <>
                <Space size="middle">
                  <ConfirmButton
                    title="Publish"
                    tooltip={true}
                    icon={<UploadOutlined />}
                    onConfirm={() => handleAction(FlowCategory.Published, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.Published)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Unpublish"
                    tooltip={true}
                    icon={<DownloadOutlined />}
                    onConfirm={() => handleAction(FlowCategory.UnPublished, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.UnPublished)}
                  />
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Reject"
                    tooltip={true}
                    icon={<CloseOutlined />}
                    onConfirm={() => handleAction(FlowCategory.RequestEdit, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.RequestEdit)}
                  />
                </Space>
              </>
            )}
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => handleAction(FlowCategory.Draft, [record._id])}
                      disabled={isDisabledFlow(record.status, FlowCategory.Draft)}
                    />
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <ConfirmButton
                    title="Submit"
                    tooltip={true}
                    icon={<CheckOutlined />}
                    onConfirm={() => handleAction(FlowCategory.Reviewing, [record._id])}
                    disabled={isDisabledFlow(record.status, FlowCategory.Reviewing)}
                  />
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  const handleAction = async (action: number, ids: string[]) => {
    try {
      if (action === FlowCategory.Published) {
        await publishStories(ids);
      } else if (action === FlowCategory.UnPublished) {
        await unPublishStories(ids);
      } else if (action === FlowCategory.RequestEdit) {
        await rejectStories(ids);
      } else if (action === FlowCategory.Draft) {
        navigate(`${PageUrl.Stories}${PageUrl.Update}`.replace(":id", ids[0]));
      } else if (action === FlowCategory.Reviewing) {
        await submitStories(ids);
      }
      action !== FlowCategory.Draft &&
        Modal.success({
          title: "Success",
          type: "success",
          content: "Updated successfully",
        });
      getListStory();
    } catch (error) {
      console.log(error);
    }
  };

  const getTableActions = () => {
    if (filteredStatus?.length !== 1) return <></>;
    const actions =
      filteredStatus[0] === 0 || filteredStatus[0] === 3 ? [FlowCategory.Reviewing] : WORK_FLOW[filteredStatus[0]];
    const render = actions.map((item, index) => {
      const title = WORK_FLOW_NAME[filteredStatus[0]][index];
      return (
        <span key={index} style={{ display: "flex", gap: "16px" }}>
          <ConfirmButton
            title={`${title} selected`}
            buttonType="default"
            onConfirm={() => handleAction(item, selectedRowKeys as string[])}
            disabled={!selectedRowKeys.length}
          />
          <ConfirmButton
            title={`${title} all filtered`}
            buttonType="default"
            onConfirm={async () => {
              const res = (await getStories({ ...filterData.current, ...{ page: 1, size: 10000 } })) as any;
              const listIds = res.result.records.map((item: any) => item._id);
              if (listIds?.length) {
                await handleAction(item, listIds);
              }
            }}
          />
        </span>
      );
    });
    return (
      <div className="table-control" style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
        {render}
      </div>
    );
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterStory")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterStory")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterStory");
        } else {
          form.setFieldsValue(sessionFilter);
          filterData.current = { ...filterData.current, ...sessionFilter };
        }
        getListStory();
      } else {
        getListStory();
      }

      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="story-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={5}>
                <FormSelect
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                  allowClear
                />
              </Col>
              <Col span={5}>
                <FormInput label="Story" name="filter" allowClear />
              </Col>
              <Col span={5}>
                <FormSelect mode="multiple" label="Status" name="status" optionData={STATUS_OPTIONS} allowClear />
              </Col>
              <Col span={2} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div className="create-upload" style={{ margin: "0 0 16px 0", display: "flex", gap: "16px" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Stories}${PageUrl.Create}`)}
            >
              Thêm mới truyện
            </Button>
          </div>
          {getTableActions()}
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={storyList}
            columns={getColumn()}
            rowSelection={rowSelection}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
            scroll={{ x: 1000 }}
          />
        </div>
      </div>
    </>
  );
};

export default StoryPortal;
