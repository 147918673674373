import React, { useEffect, useState } from "react";
import { Menu, Button, Popover } from "antd";
import Cookies from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { PageUrl } from "../../constants/url";
import { useAuth } from "../../hooks/useAuth";
import { MANAGE_SCREENS, PORTAL_SCREENS, UserRole } from "../../constants/auth";
import { LogoutOutlined, MenuOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [selectedItems, setSelectedItems] = useState<string[]>([""]);

  const onLogout = () => {
    const cookies = new Cookies();
    cookies.get("signinAdmin") && cookies.remove("signinAdmin");
    setTimeout(() => {
      navigate(PageUrl.Signin);
    }, 100);
  };

  let portalScreens = PORTAL_SCREENS;
  let manageScreens = [];

  if (user?.roles.includes(UserRole.Manager)) {
    manageScreens.push(...MANAGE_SCREENS[UserRole.Manager]);
  }

  if (user?.roles.includes(UserRole.Admin)) {
    manageScreens.push(...MANAGE_SCREENS[UserRole.Admin]);
  }

  if (user?.roles.includes(UserRole.SiteAdmin)) {
    manageScreens.push(...MANAGE_SCREENS[UserRole.SiteAdmin]);
    if (user?.roles.length === 1) {
      // Site Admin only has access to manage screens
      portalScreens = [];
    }
  }

  manageScreens = manageScreens.filter((item, index, self) => self.findIndex((t) => t.key === item.key) === index);
  manageScreens = manageScreens.sort((a, b) => a.label.localeCompare(b.label));
  portalScreens = portalScreens.sort((a, b) => a.label.localeCompare(b.label));

  const menuItems: any = [
    {
      label: "Portal",
      key: "portal",
      icon: <MenuOutlined />,
      children: portalScreens?.map((item: any) => ({
        label: item.label,
        key: item.key,
      })),
    },
    {
      label: "Manage",
      key: "manage",
      icon: <SettingOutlined />,
      children: manageScreens?.map((item: any) => ({
        label: item.label,
        key: item.key,
      })),
    },
  ];

  const handleClick = (e: any) => {
    e?.key && navigate(e.key);
  };

  useEffect(() => {
    setSelectedItems([location.pathname !== "/" ? location.pathname : PageUrl.Questions]);
  }, [location]);

  return (
    <>
      <Menu
        theme="dark"
        mode="horizontal"
        items={menuItems}
        disabledOverflow={true}
        onClick={handleClick}
        selectedKeys={selectedItems}
      />
      <div style={{ color: "#ffffffa6" }}>
        {user && (
          <Popover
            content={
              <Button icon={<LogoutOutlined />} type="primary" danger onClick={onLogout}>
                Logout
              </Button>
            }
          >
            <span>
              <UserOutlined />
              &nbsp;{`Hi, ${user?.sub}`}&nbsp;
            </span>
          </Popover>
        )}
      </div>
    </>
  );
};

export default Navbar;
