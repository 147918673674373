import "./index.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Row, Space, Table, Tooltip, message } from "antd";
import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IAchievementFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { getAchievements } from "../../services/AchievementServices";
import { FormSelect } from "../../components/Form/Select";
import { ELECTIVE_OPTIONS } from "../../constants/filter";
import dayjs from "dayjs";
import { ACHIEVEMENT_MENU_ITEMS, ACHIEVEMENT_TYPES } from "../../configs/achievementConfigs";
import { customSessionStorage } from "../../utils/storage";

const AchievementPortal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const tableTotal = useRef<number>();
  const filterData = useRef<IAchievementFilter>({ page: 1, size: 10 });

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterAchievement", formValues);

    const filters = {
      filter: formValues["filter"],
      menu: formValues["menu"]?.join(","),
      type: formValues["type"]?.join(","),
      visible: formValues["visible"],
    };
    filterData.current = { ...filterData.current, ...filters };
    filterData.current.page = 1;
    fetchAchievements();
  };

  const fetchAchievements = async () => {
    const response = await getAchievements(filterData.current);
    if (response) {
      setTableData((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await fetchAchievements();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    await fetchAchievements();
  };

  const handleEditAchievement = async (id: string) => {
    navigate(`${PageUrl.Achievements}${PageUrl.Update}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },

      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        minWidth: 300,
      },
      {
        title: "Points",
        dataIndex: "points",
        key: "points",
        align: "center",
        width: 100,
      },
      {
        title: "Token",
        dataIndex: "tokenAward",
        key: "tokenAward",
        align: "center",
        width: 100,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "center",
        sorter: { multiple: 6, field: "type" },
        width: 100,
        render: (value: number) => {
          const index = ACHIEVEMENT_TYPES.findIndex((item) => item.value === value);
          return <>{ACHIEVEMENT_TYPES[index].label}</>;
        },
      },
      {
        title: "Menu",
        dataIndex: "menu",
        key: "menu",
        align: "center",
        width: 100,
        sorter: { multiple: 4, field: "menu" },
        render: (value: number) => {
          const index = ACHIEVEMENT_MENU_ITEMS.findIndex((item) => item.value === value);
          return <>{ACHIEVEMENT_MENU_ITEMS[index].label}</>;
        },
      },
      {
        title: "Order",
        dataIndex: "order",
        key: "order",
        align: "center",
        width: 100,
        sorter: { multiple: 5, field: "order" },
        render: (e) => e,
      },
      {
        title: "Visible",
        dataIndex: "visible",
        key: "visible",
        align: "center",
        width: 100,
        render: (e) => (!!e ? "Yes" : "No"),
      },
      {
        title: "Created at",
        dataIndex: ["recordInfo", "createdAt"],
        key: "createdAt",
        align: "center",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        width: 150,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      },
      {
        title: "Updated at",
        dataIndex: ["recordInfo", "updatedAt"],
        key: "updatedAt",
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        width: 150,
        render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 50,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            <>
              <Space size="middle">
                <Tooltip title="Edit achievement">
                  <Button icon={<EditOutlined />} onClick={() => handleEditAchievement(record._id)} />
                </Tooltip>
              </Space>
            </>
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      if (sessionStorage.getItem("filterAchievement")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterAchievement")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterAchievement");
        } else {
          form.setFieldsValue(sessionFilter);
          const filters = {
            filter: sessionFilter["filter"],
            menu: sessionFilter["menu"]?.join(","),
            type: sessionFilter["type"]?.join(","),
            visible: sessionFilter["visible"],
          };
          filterData.current = { ...filterData.current, ...filters };
        }
        filterData.current.sort = `recordInfo.createdAt,desc`;
        fetchAchievements();
      } else {
        fetchAchievements();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    if (!user?.userId) return;
    if (user?.roles?.includes(UserRole.Admin)) {
      getAllInformation();
    } else {
      navigate(PageUrl.Default);
    }
  }, [form, navigate, user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="achievement-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ gap: "16px" }}>
              <Col xs={5} md={5} xl={5}>
                <FormInput label="Filter" name="filter" />
              </Col>
              <Col xs={5} md={5} xl={5}>
                <FormSelect mode="multiple" label="Menu" name="menu" optionData={ACHIEVEMENT_MENU_ITEMS} allowClear />
              </Col>
              <Col xs={5} md={5} xl={5}>
                <FormSelect mode="multiple" label="Type" name="type" optionData={ACHIEVEMENT_TYPES} allowClear />
              </Col>
              <Col xs={5} md={5} xl={5}>
                <FormSelect label="Visible" name="visible" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Achievements}${PageUrl.Create}`)}
            >
              Add an achievement
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={tableData}
            columns={getColumn()}
            pagination={{
              total: tableTotal.current,
              showSizeChanger: false,
              onChange: handleChangePage,
            }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default AchievementPortal;
