import instance, { apiConfig } from "./instance";
import { ICalibrationFilter } from "../interfaces/filter";

const calibrationUrl = "/practice/v1/admin/calibration";

export const addCalibration = (data: any) => {
  return instance.put(`${calibrationUrl}/create-new-calibration`, data, apiConfig());
};

export const getCalibrations = (postData: ICalibrationFilter) => {
  return instance.get(`${calibrationUrl}/get-list-calibrations`, apiConfig(postData));
};

export const getCalibrationById = (id: string) => {
  return instance.get(`${calibrationUrl}/get-a-calibration/${id}`, apiConfig());
};

export const editCalibration = (id: string, data: any): Promise<any> => {
  return instance.post(`${calibrationUrl}/edit-a-calibration/${id}`, data, apiConfig());
};

export const activeCalibration = (id: string) => {
  return instance.get(`${calibrationUrl}/active-calibration/${id}`, apiConfig());
};

export const inactiveCalibration = (id: string) => {
  return instance.get(`${calibrationUrl}/inactive-calibration/${id}`, apiConfig());
};
