import { Button, Descriptions, Modal, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { INITIAL_SLIDE_STATE, addMobileSlide, updateMobileSection, updateMobileAudioLink } from "../../reducers/lessonSlice";
import { FormInput } from "../Form/Input";
import { FormSingleFileInput } from "../Form/SingleFileInput";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { NamePath } from "antd/es/form/interface";
import { FileData } from "../UploadInput/UploadInput";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { randomId } from "../../utils/utils";
import { getVbeeS3AudioLink } from "../../services/VbeeAudioTextServices";
import { useState } from "react";
import { ListSlides } from "../MobileSlides/ListSlides";

export const SectionItem = ({ index }: { index: number }) => {
  const dispatch = useAppDispatch();
  const mobileSection = useAppSelector((state) => state.lesson.mobileSections[index]);
  const folder = useAppSelector((state) => state.lesson.imageFolder);
  const form = useFormInstance();

  const [loading, setLoading] = useState(false);

  const handleAddSlide = () => {
    const newKey = randomId();
    const newData = [...mobileSection.slides, { ...INITIAL_SLIDE_STATE, slideKey: newKey }];
    form.setFieldsValue([{ mobileSections: { [index]: { slides: newData } } }]);
    dispatch(
      addMobileSlide({
        sectionIndex: index,
        slideKey: newKey,
      })
    );
  };

  const handleLoadImage = (_: NamePath, imageData: FileData) => {
    dispatch(
      updateMobileSection({ index: index, section: { ...mobileSection, ...{ backgroundImage: imageData.fileLink } } })
    );
  };

  const handleRemoveImage = (_: NamePath) => {
    dispatch(updateMobileSection({ index: index, section: { ...mobileSection, ...{ backgroundImage: "" } } }));
  };

  const handleGetSectionData = () => {
    setLoading(true);
    setTimeout(() => {
      if (loading) setLoading(false);
    }, 20000);
    try {
      form.validateFields().then(async () => {
        const reqObject = {
          typeName: "lesson",
          folderName: folder + "/audio",
          slides: mobileSection.slides?.map((slide) => {
            return {
              explanationAudioText: slide.explanation.audioText || "",
              explanationAudioVoiceCode: slide.explanation.audioVoiceCode || "",

              contentAudioText: slide.content.audioText || "",
              contentAudioVoiceCode: slide.content.audioVoiceCode || "",

              questionAudioText: slide.question.audioText || "",
              questionAudioVoiceCode: slide.question.audioVoiceCode || "",
            };
          }),
        };

        let response = await getVbeeS3AudioLink(reqObject);
        let responseData = JSON.parse(JSON.stringify(response.data));
        if (responseData.errorMessage !== "success") {
          Modal.error({
            type: "error",
            title: "Vbee-Util service error",
            content: responseData.errorCode + ": " + responseData.errorMessage,
          });
          setLoading(false);
          return;
        }

        Modal.success({
          type: "success",
          title: "Success",
          content: "Uploaded mp3 successfully to cloud",
        });

        let resultSlides = responseData?.result?.slides;
        mobileSection.slides?.forEach((_, sliceIndex) => {
          let audioLink = {
            sectionIndex: index,
            slideIndex: sliceIndex,
            explanationAudioLink: resultSlides[sliceIndex].explanation_audio_link,
            contentAudioLink: resultSlides[sliceIndex].content_audio_link,
            questionAudioLink: resultSlides[sliceIndex].question_audio_link,
          };
          audioLink.explanationAudioLink &&
            form.setFieldValue(
              ["mobileSections", index, "slides", sliceIndex, "explanation", "audioLink"],
              audioLink.explanationAudioLink
            );

          audioLink.contentAudioLink &&
            form.setFieldValue(
              ["mobileSections", index, "slides", sliceIndex, "content", "audioLink"],
              audioLink.contentAudioLink
            );

          audioLink.questionAudioLink &&
            form.setFieldValue(
              ["mobileSections", index, "slides", sliceIndex, "question", "audioLink"],
              audioLink.questionAudioLink
            );

          dispatch(updateMobileAudioLink(audioLink));
        });
        setLoading(false);
      });
    } catch (error) {
      console.error("Error validating form fields:", error);
      setLoading(false);
      return;
    }
  };

  return (
    <span key={`Section-${index}`}>
      {!!loading && (
        <Spin
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            background: "rgba(0, 0, 0, 0.5)",
          }}
          indicator={<LoadingOutlined style={{ fontSize: 48, top: "50%" }} spin />}
        />
      )}
      <div className="section-info">
        <Descriptions>
          <Descriptions.Item label="Section info">
            <FormInput
              name={["mobileSections", index, "sectionName"]}
              label="Name"
              latexString={mobileSection.sectionName}
            />
            <FormSingleFileInput
              name={["mobileSections", index, "backgroundImage"]}
              label="Background"
              initValue={mobileSection.backgroundImage}
              folder={folder}
              handleUpload={handleLoadImage}
              handleRemove={handleRemoveImage}
            />
          </Descriptions.Item>
        </Descriptions>
        <Button style={{ backgroundColor: "orange", color: "white" }} onClick={handleGetSectionData}>
          Get VbeeAudio
        </Button>
      </div>
      {mobileSection.slides?.length ? (
        <div className="list-slides">{mobileSection.slides && <ListSlides sectionIndex={index} />}</div>
      ) : (
        <></>
      )}
      <div className="form-controller">
        <Button type="default" icon={<PlusOutlined />} onClick={handleAddSlide}>
          Add slide
        </Button>
      </div>
    </span>
  );
};
