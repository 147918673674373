import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, DatePicker, Form, Row, Space, Table, Tooltip, message } from "antd";
import "./index.scss";

import {
  ExportOutlined,
  PlusOutlined,
  SearchOutlined,
  ToTopOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ACTIVE_STATUS_OPTIONS } from "../../constants/filter";
import { IVoucherFilter } from "../../interfaces/filter";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import { PageUrl } from "../../constants/url";
import { getVouchers, activeVouchers, inactiveVouchers } from "../../services/VoucherServices";
import dayjs from "dayjs";
import { customSessionStorage } from "../../utils/storage";
import { convertArrayOfObjectsToCSV } from "../../utils/utils";

const VoucherPortal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
  };

  const [voucherList, setVoucherList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef<any>();
  const filterData = useRef<IVoucherFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterVoucher", formValues);
    filterData.current = {
      ...filterData.current,
      ...{
        status: formValues.status,
        usedDate:
          formValues.usedDate?.length === 2
            ? [
                dayjs(formValues.usedDate[0]).startOf("day").toISOString(),
                dayjs(formValues.usedDate[1]).startOf("day").toISOString(),
              ]
            : undefined,
        expiredAt:
          formValues.expiredAt?.length === 2
            ? [
                dayjs(formValues.expiredAt[0]).startOf("day").toISOString(),
                dayjs(formValues.expiredAt[1]).startOf("day").toISOString(),
              ]
            : undefined,
      },
    };
    if (formValues.duration) {
      filterData.current.duration = Number(formValues.duration);
    }
    filterData.current.page = 1;
    getListVouchers();
  };

  const getListVouchers = async () => {
    const response = await getVouchers(filterData.current, filterData.current.page, filterData.current.size);
    if (response) {
      setVoucherList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getListVouchers();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getListVouchers();
  };

  const handleActivateVoucher = async (listIds: string[]) => {
    const response = await activeVouchers(listIds);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Activated successfully!",
        duration: 1,
      });
      await getListVouchers();
    }
  };

  const handleInActivateVoucher = async (listIds: string[]) => {
    const response = await inactiveVouchers(listIds);
    if (response) {
      messageApi.open({
        type: "success",
        content: "Inactivated successfully!",
        duration: 1,
      });
      await getListVouchers();
    }
  };

  const handleActiveAllVoucher = async (status: number) => {
    // get all voucher id from filter
    const filter = filterData.current;
    const response = await getVouchers(filter, 1, 10000);
    if (response) {
      const listIds = (response as any).result.records.map((item: any) => item._id);
      if (status === 1) {
        await handleActivateVoucher(listIds);
      } else {
        await handleInActivateVoucher(listIds);
      }
    }
  };

  const handleActiveSelectedVouchers = async (status: number) => {
    if (selectedRowKeys.length) {
      if (status === 1) {
        await handleActivateVoucher(selectedRowKeys as string[]);
      } else {
        await handleInActivateVoucher(selectedRowKeys as string[]);
      }
    }
  };

  const handleExportAllFiltered = async () => {
    const filter = filterData.current;
    const response = await getVouchers(filter, 1, 10000);
    if (response) {
      const data = (response as any).result.records as any[];
      const optimizeData = data.map((item) => {
        return {
          Code: item.voucherId,
          Duration: item.duration,
          Status: ACTIVE_STATUS_OPTIONS.find((status) => status.value === item.status)?.label,
          "Expired at": dayjs(item.expiredAt).format("DD/MM/YYYY"),
          "Used at": item.usedDate ? dayjs(item.usedDate).format("DD/MM/YYYY") : "",
        };
      });
      const csvData = convertArrayOfObjectsToCSV(optimizeData) || "";
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "vouchers.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Voucher code",
        dataIndex: "voucherId",
        key: "voucherId",
        align: "center",
        width: 150,
      },
      {
        title: "Duration (month)",
        dataIndex: "duration",
        key: "duration",
        align: "center",
        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 200,
        render: (status: number) => {
          const index = ACTIVE_STATUS_OPTIONS.findIndex((item) => item.value === status);
          return <>{ACTIVE_STATUS_OPTIONS[index].label}</>;
        },
      },
      {
        title: "Expired at",
        dataIndex: "expiredAt",
        key: "expiredAt",
        align: "center",
        width: 200,
        render: (expiredAt: string) => <>{dayjs(expiredAt).format("DD/MM/YYYY")}</>,
      },
      {
        title: "Used at",
        dataIndex: "usedDate",
        key: "usedDate",
        align: "center",
        width: 200,
        render: (usedDate: string) => <>{usedDate ? dayjs(usedDate).format("DD/MM/YYYY") : ""}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {user?.roles?.includes(UserRole.ContentCreator) && (
              <>
                <Space size="middle">
                  <Tooltip title="Active">
                    <Button icon={<ToTopOutlined />} onClick={() => handleActivateVoucher([record._id])}>
                      Active
                    </Button>
                  </Tooltip>
                </Space>
                <Space size="middle">
                  <Tooltip title="Inactive">
                    <Button
                      icon={<VerticalAlignBottomOutlined />}
                      onClick={() => handleInActivateVoucher([record._id])}
                    >
                      Inactive
                    </Button>
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterVoucher")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterVoucher")!);
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterVoucher");
        } else {
          filterData.current = {
            ...filterData.current,
          };
          const formValues = form.getFieldsValue();
          if (sessionFilter["status"]) {
            filterData.current.status = Number(sessionFilter["status"]);
            formValues["status"] = Number(sessionFilter["status"]);
          }
          if (sessionFilter["duration"]) {
            filterData.current.duration = Number(sessionFilter["duration"]);
            formValues["duration"] = Number(sessionFilter["duration"]);
          }
          if (sessionFilter["usedDate"]) {
            filterData.current.usedDate = sessionFilter["usedDate"]?.map((item: string) =>
              dayjs(item).startOf("day").toISOString()
            );
            formValues["usedDate"] = [dayjs(sessionFilter["usedDate"][0]), dayjs(sessionFilter["usedDate"][1])];
          }
          if (sessionFilter["expiredAt"]) {
            filterData.current.expiredAt = sessionFilter["expiredAt"]?.map((item: string) =>
              dayjs(item).startOf("day").toISOString()
            );
            formValues["expiredAt"] = [dayjs(sessionFilter["expiredAt"][0]), dayjs(sessionFilter["expiredAt"][1])];
          }
          form.setFieldsValue(formValues);
        }
        getListVouchers();
      } else {
        getListVouchers();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="voucher-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ gap: "16px", justifyContent: "center" }}>
              <Col xl={6} lg={6} md={6}>
                <FormInput label="Duration" name="duration" type="number" />
              </Col>
              <Col xl={6} lg={6} md={6}>
                <FormSelect label="Status" name="status" optionData={ACTIVE_STATUS_OPTIONS} allowClear />
              </Col>
            </Row>
            <Row style={{ gap: "16px", justifyContent: "center" }}>
              <Col xl={6} lg={6} md={12}>
                <Form.Item label="Expired at" name="expiredAt">
                  <DatePicker.RangePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>

              <Col xl={6} lg={6} md={12}>
                <span style={{ position: "relative" }}>
                  <Form.Item label="Used at" name="usedDate">
                    <DatePicker.RangePicker format="DD/MM/YYYY" />
                  </Form.Item>
                  <Button
                    icon={<SearchOutlined />}
                    type="primary"
                    htmlType="submit"
                    style={{ position: "absolute", top: 0, left: "calc(100% + 16px)" }}
                  >
                    Filter
                  </Button>
                </span>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0", display: "flex", gap: "16px" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Voucher}${PageUrl.Create}`)}
            >
              Generate vouchers
            </Button>
            <Button type="default" icon={<ToTopOutlined />} onClick={() => handleActiveAllVoucher(1)}>
              Active all filtered
            </Button>
            <Button type="default" icon={<VerticalAlignBottomOutlined />} onClick={() => handleActiveAllVoucher(0)}>
              Inactive all filtered
            </Button>
            <Button type="default" icon={<ToTopOutlined />} onClick={() => handleActiveSelectedVouchers(1)}>
              Active all selected
            </Button>
            <Button
              type="default"
              icon={<VerticalAlignBottomOutlined />}
              onClick={() => handleActiveSelectedVouchers(0)}
            >
              Inactive all selected
            </Button>
            <Button type="default" icon={<ExportOutlined />} onClick={() => handleExportAllFiltered()}>
              Export all filtered
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={voucherList}
            columns={getColumn()}
            rowSelection={rowSelection}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default VoucherPortal;
