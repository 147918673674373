import { useEffect, useState } from "react";
import { Button, Col, Descriptions, Form, Modal, Row } from "antd";
import { FormInput } from "../../../components/Form/Input";
import { PlusOutlined, MinusOutlined, SaveOutlined } from "@ant-design/icons";
import { addExam, editExam, getAnExam } from "../../../services/RoadMapServices";

type ExamDetailProps = {
  examId?: string;
  roadMapId: string;

  onDeleteExam: (examId: string) => void;
};

const ExamDetail = (props: ExamDetailProps) => {
  const [form] = Form.useForm();
  const [questionList, setQuestionList] = useState<string[]>([""]);
  const [problemSetList, setProblemSetList] = useState<string[]>([""]);
  const [examId, setExamId] = useState<string>("");

  const handleAddQuestion = () => {
    questionList.push("");
    setQuestionList([...questionList]);
  };

  const handleRemoveQuestion = () => {
    questionList.pop();
    setQuestionList([...questionList]);
  };

  const handleAddProblemSet = () => {
    problemSetList.push("");
    setProblemSetList([...problemSetList]);
  };

  const handleRemoveProblemSet = () => {
    problemSetList.pop();
    setProblemSetList([...problemSetList]);
  };

  const handleSaveExam = () => {
    try {
      form.validateFields().then(async () => {
        const formValues = { ...form.getFieldsValue() };
        let postData = {
          code: formValues.code,
          name: formValues.name,
          order: Number(formValues.order),
          questions: formValues.questions.map((item: any) => ({
            questionId: item.questionId,
            score: Number(item.score),
          })),
          problemSets: formValues.problemSets?.map((item: any, index: number) => {
            let data = {
              code: item.code,
              order: Number(item.order),
              description: item.description,
              questionNumber: Number(item.numberQuestion),
            };
            if (problemSetList[index]) {
              data = { ...data, _id: problemSetList[index] } as any;
            }
            return data;
          }),
        } as any;
        if (formValues.examTime) {
          postData = { ...postData, examTime: Number(formValues.examTime) };
        }
        let response: any;
        if (examId) {
          response = await editExam(examId, props.roadMapId, postData);
        } else {
          response = await addExam(props.roadMapId, postData);
        }
        Modal.success({
          title: "Success",
          type: "success",
          content: `${examId ? "Updated" : "Added"} successfully`,
        });
        setExamId(response.result ? response.result : examId);
      });
    } catch (error) {
      Modal.error({
        title: "Error",
        type: "error",
        content: `Updated failed`,
      });
    }
  };

  const handleRemoveExam = () => {
    Modal.confirm({
      title: "Confirm",
      type: "warning",
      content: "Do you want to remove this exam?",
      onOk: () => props.onDeleteExam(examId),
    });
  };

  useEffect(() => {
    const getExamDetail = async (examId: string, roadMapId: string) => {
      try {
        const response = (await getAnExam(examId, roadMapId)) as any;
        if (response.errorCode === 200) {
          const data = response.result;
          // set question list
          setQuestionList(data.questions.map((item: any) => item.questionId));
          // set problem set list
          setProblemSetList(data.problemSets.map((item: any) => item._id));

          // set form values
          setTimeout(() => {
            form.setFieldsValue({
              code: data.code,
              name: data.name,
              examTime: data.examTime,
              order: data.order,
              questions: data.questions.map((item: any) => ({
                questionId: item.questionId,
                score: item.score,
              })),
              problemSets: data.problemSets.map((item: any) => ({
                id: item._id,
                code: item.code,
                order: item.order,
                description: item.description,
                numberQuestion: item.questionNumber,
              })),
            });
          }, 250);
        }
      } catch (error) {}
    };

    if (props.examId) {
      setExamId(props.examId);
      // get exam detail
      getExamDetail(props.examId, props.roadMapId);
      // handleCreateBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.examId]);

  return (
    <div>
      <Form form={form} onFinish={handleSaveExam}>
        <Row>
          <Col xs={6} md={6}>
            <FormInput label="Code" name="code" rules={[{ required: true, message: "Please enter a code" }]} />
          </Col>
          <Col xs={6} md={6}>
            <FormInput label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]} />
          </Col>

          <Col xs={6} md={6}>
            <FormInput label="Exam time" name="examTime" type="number" />
          </Col>
          <Col xs={6} md={6}>
            <FormInput
              label="Order"
              name="order"
              rules={[{ required: true, message: "Please enter a value" }]}
              type="number"
            />
          </Col>
        </Row>
        <Descriptions>
          <Descriptions.Item label="Questions" className="question-container">
            {questionList.map((question, index) => (
              <span key={index} style={{ position: "relative" }}>
                <FormInput
                  label={`Question ${index + 1}`}
                  name={["questions", index, "questionId"]}
                  colon={index === 0 ? true : false}
                  rules={[{ required: true, message: "Please enter a question" }]}
                />
                <FormInput
                  type="number"
                  label={`Score ${index + 1}`}
                  name={["questions", index, "score"]}
                  colon={index === 0 ? true : false}
                  rules={[
                    {
                      validator(_, value) {
                        if (Number(value) < 0) {
                          return Promise.reject(new Error("Score must be greater than 0"));
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                />
                {index === questionList.length - 1 && (
                  <span className="control">
                    <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddQuestion} />
                    <Button
                      style={questionList.length > 1 ? { display: "block" } : { display: "none" }}
                      type="ghost"
                      block
                      icon={<MinusOutlined />}
                      onClick={handleRemoveQuestion}
                    />
                  </span>
                )}
              </span>
            ))}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label="Problem set" className="problem-set-container">
            {problemSetList.map((item, index) => (
              <span key={index} style={{ position: "relative" }}>
                <FormInput
                  label="Code"
                  name={["problemSets", index, "code"]}
                  rules={[{ required: true, message: "Please enter a value" }]}
                />
                <FormInput
                  label="Order"
                  name={["problemSets", index, "order"]}
                  rules={[{ required: true, message: "Please enter a value" }]}
                  type="number"
                />
                <FormInput label="Description" name={["problemSets", index, "description"]} />
                <FormInput
                  label="No. questions"
                  name={["problemSets", index, "numberQuestion"]}
                  rules={[{ required: true, message: "Please enter a value" }]}
                  type="number"
                />
                {index === problemSetList.length - 1 && (
                  <span className="control">
                    <Button type="ghost" block icon={<PlusOutlined />} onClick={handleAddProblemSet} />
                    <Button
                      style={problemSetList.length > 0 ? { display: "block" } : { display: "none" }}
                      type="ghost"
                      block
                      icon={<MinusOutlined />}
                      onClick={handleRemoveProblemSet}
                    />
                  </span>
                )}
              </span>
            ))}
            {problemSetList.length === 0 && (
              <Button
                style={{ width: "200px" }}
                type="primary"
                block
                icon={<PlusOutlined />}
                onClick={handleAddProblemSet}
              >
                Add new problem set
              </Button>
            )}
          </Descriptions.Item>
        </Descriptions>

        <div className="exam-control" style={{ marginLeft: "100px", display: "flex", gap: "16px" }}>
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />} style={{ backgroundColor: "#2abb2a" }}>
            Save exam
          </Button>
          <Button type="primary" danger icon={<MinusOutlined />} onClick={handleRemoveExam}>
            Remove exam
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExamDetail;
