import instance, { apiConfig } from "./instance";
import { IExamFilter } from "../interfaces/filter";

const examUrl = "/practice/v1/admin/exam";

export const getExams = (postData: IExamFilter) => {
  return instance.get(`${examUrl}/get-list-exams`, apiConfig(postData));
};

export const getExamById = (id: string) => {
  return instance.get(`${examUrl}/get-an-exam/${id}`, apiConfig());
};

export const addExam = (data: any) => {
  return instance.put(`${examUrl}/create-new-exam`, data, apiConfig());
};

export const editExam = (id: string, data: any): Promise<any> => {
  return instance.post(`${examUrl}/edit-an-exam/${id}`, data, apiConfig());
};

export const publishExams = (listId: string[]) => {
  return instance.post(`${examUrl}/publish-exams`, listId, apiConfig());
};

export const unPublishExams = (listId: string[]) => {
  return instance.post(`${examUrl}/unpublish-exams`, listId, apiConfig());
};

export const rejectExams = (listId: string[]) => {
  return instance.post(`${examUrl}/reject-exams`, listId, apiConfig());
};

export const submitExams = (listId: string[]) => {
  return instance.post(`${examUrl}/submit-exams`, listId, apiConfig());
};

export const summarizeExam = (id: string) => {
  return instance.post(`${examUrl}/summarize-month-exam/${id}`, {}, apiConfig());
};
