import { useEffect, useRef, useState } from "react";
import Loading from "../../components/Loading/Loading";
import { Button, Col, Form, Row, Space, Table, Tooltip, message } from "antd";
import { EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ELECTIVE_OPTIONS } from "../../constants/filter";
import { IUnitFilter } from "../../interfaces/filter";
import { convertStringToDate, convertToSelectOption } from "../../utils/utils";
import { FormInput } from "../../components/Form/Input";
import { FormSelect } from "../../components/Form/Select";
import { getGrades, getUnits } from "../../services/LessonServices";
import { UserRole } from "../../constants/auth";
import { DEFAULT_TIMEOUT } from "../../constants/timeout";
import { useAuth } from "../../hooks/useAuth";
import "./index.scss";
import { PageUrl } from "../../constants/url";
import { customSessionStorage } from "../../utils/storage";

const UnitPortal = () => {
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [gradeList, setGradeList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  const tableRef = useRef<any>();
  const filterData = useRef<IUnitFilter>({ page: 1, size: 10 });
  const tableTotal = useRef<number>();

  const handleFilter = () => {
    const formValues = form.getFieldsValue();
    customSessionStorage("filterUnit", formValues);
    filterData.current = {
      ...filterData.current,
      ...formValues,
    };
    filterData.current.page = 1;
    getUnitList();
  };

  const getUnitList = async () => {
    const response = await getUnits(filterData.current);
    if (response) {
      setUnitList((response as any).result.records);
      tableTotal.current = (response as any).result.recordCount;
    }
  };

  const handleChangePage = async (page: number) => {
    filterData.current.page = page;
    await getUnitList();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, _: any, sorter: any) => {
    const reSorter = (Array.isArray(sorter) ? sorter : sorter.column ? [sorter] : []).sort(
      (sort1, sort2) => sort1.column?.sorter?.multiple - sort2.column?.sorter?.multiple
    );
    filterData.current.page = pagination.current;
    filterData.current.size = pagination.pageSize;
    filterData.current.sort = reSorter
      .map((item) => `${item.column?.sorter?.field},${item.order === "descend" ? "desc" : "asc"}`)
      .join(";");
    await getUnitList();
  };

  const handleEditUnit = async (id: string) => {
    navigate(`${PageUrl.Units}${PageUrl.Update}`.replace(":id", id));
  };

  const getColumn = (): ColumnsType<any> => {
    return [
      {
        title: "ID",
        dataIndex: "_id",
        key: "id",
        align: "center",
        sorter: { multiple: 1, field: "_id" },
        width: 50,
        render: (e) => (
          <Tooltip title={e}>
            <span
              className="none-select"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(e);
                messageApi.open({
                  type: "success",
                  content: "Copied to clipboard!",
                  duration: 1,
                });
              }}
            >
              ID
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Grade",
        dataIndex: "gradeName",
        key: "gradeId",
        align: "center",
        width: 150,
        sorter: { multiple: 8, field: "grade.name" },
      },
      {
        title: "Unit",
        dataIndex: "unitName",
        key: "unit",
        align: "center",
        width: 150,
        sorter: { multiple: 4, field: "unitName" },
      },
      {
        title: "Order",
        dataIndex: "order",
        key: "order",
        align: "center",
        width: 100,
        sorter: { multiple: 7, field: "order" },
      },
      {
        title: "Active",
        key: "active",
        align: "center",
        sorter: { multiple: 5, field: "active" },
        render: (e) => (e.active ? "Yes" : "No"),
        width: 50,
      },
      {
        title: "Premium",
        key: "premium",
        align: "center",
        sorter: { multiple: 6, field: "premium" },
        render: (e) => (e.premium ? "Yes" : "No"),
        width: 50,
      },
      {
        title: "Created by",
        key: "createdBy",
        dataIndex: ["recordInfo", "createdBy", "name"],
        align: "center",
      },
      {
        title: "Created at",
        key: "createdAt",
        dataIndex: ["recordInfo", "createdAt"],
        align: "center",
        defaultSortOrder: "descend",
        sorter: { multiple: 2, field: "recordInfo.createdAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Updated at",
        key: "updatedAt",
        dataIndex: ["recordInfo", "updatedAt"],
        align: "center",
        sorter: { multiple: 3, field: "recordInfo.updatedAt" },
        render: (e) => <>{convertStringToDate(e)}</>,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: 100,
        render: (record: any) => (
          <span style={{ display: "inline-flex", gap: "8px" }}>
            {(user?.roles?.includes(UserRole.ContentCreator) || user?.roles?.includes(UserRole.Reviewer)) && (
              <>
                <Space size="middle">
                  <Tooltip title="Edit unit">
                    <Button icon={<EditOutlined />} onClick={() => handleEditUnit(record._id)} />
                  </Tooltip>
                </Space>
              </>
            )}
          </span>
        ),
      },
    ] as ColumnsType<any>;
  };

  useEffect(() => {
    const getAllInformation = async () => {
      setIsLoading(true);
      const [gradeRes] = await Promise.all([getGrades()]);
      gradeRes && setGradeList((gradeRes as any).result);
      filterData.current.sort = `recordInfo.createdAt,desc`;
      if (sessionStorage.getItem("filterUnit")) {
        const sessionFilter = JSON.parse(sessionStorage.getItem("filterUnit")!);
        // check expires session
        if (sessionFilter?.expiresAt < Date.now()) {
          sessionStorage.removeItem("filterUnit");
        } else {
          form.setFieldsValue(sessionFilter);
          filterData.current = { ...filterData.current, ...sessionFilter };
        }
        getUnitList();
      } else {
        getUnitList();
      }
      setTimeout(() => {
        setIsLoading(false);
      }, DEFAULT_TIMEOUT);
    };

    user?.roles?.length && getAllInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {contextHolder}
      <div className="unit-portal-container">
        <div className="filter-container">
          <Form form={form} onFinish={handleFilter}>
            <Row style={{ justifyContent: "space-between" }}>
              <Col span={4}>
                <FormSelect
                  allowClear
                  label="Grade"
                  name="gradeId"
                  optionData={convertToSelectOption(gradeList, "_id", "name")}
                />
              </Col>
              <Col span={4}>
                <FormInput label="Unit" name="filter" allowClear />
              </Col>
              <Col span={4}>
                <FormSelect label="Active" name="active" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col span={4}>
                <FormSelect label="Premium" name="premium" optionData={ELECTIVE_OPTIONS} allowClear />
              </Col>
              <Col span={2} className="filter-controller">
                <Button icon={<SearchOutlined />} type="primary" htmlType="submit" />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="table-container">
          <div style={{ margin: "0 0 16px 0" }}>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => navigate(`${PageUrl.Units}${PageUrl.Create}`)}
            >
              Add an unit
            </Button>
          </div>
          <div style={{ fontSize: "12px", color: "mediumblue" }}>* Nhấp chuột vào từng ID để copy</div>
          <Table
            dataSource={unitList}
            columns={getColumn()}
            pagination={{ total: tableTotal.current, showSizeChanger: false, onChange: handleChangePage }}
            onChange={handleTableChange}
            ref={tableRef}
            rowKey={(record) => record._id}
          />
        </div>
      </div>
    </>
  );
};

export default UnitPortal;
